export const API = process.env.NEXT_PUBLIC_API_URL;
export const ECOMM_API = process.env.NEXT_PUBLIC_ECOMM_API_URL || process.env.NEXT_PUBLIC_API_URL;
export const GRAPHQL_API = API + '/graphql';
export const MAGIC_KEY = process.env.NEXT_PUBLIC_MAGIC_KEY;
export const EXPLORER_BASE_URL = process.env.NEXT_PUBLIC_EXPLORER_BASE_URL;
export const STREAM_IO_KEY = process.env.NEXT_PUBLIC_STREAM_IO_KEY;
export const DOMAIN = `app.${(process.env.NEXT_PUBLIC_DOMAIN || '').replace('app.', '')}`;
export const IS_DEV_MODE = !!process.env.NEXT_PUBLIC_IS_DEV_MODE;

export const WEB_APP_BASE_URL = `${IS_DEV_MODE ? 'http' : 'https'}://${DOMAIN!}`;
export const ADMIN_BASE_URL = `${IS_DEV_MODE ? 'http' : 'https'}://${DOMAIN!}/admin`;

export const USE_HEADERS = process.env.NEXT_PUBLIC_USE_HEADERS
  ? process.env.NEXT_PUBLIC_USE_HEADERS === 'true'
    ? true
    : false
  : false;

export const PAGE_ROUTES = {
  BRAND_ADMIN: 'brand-admin',
  BRAND_PUBLIC_PAGE: 'brand',
};

export const ERC721GeneralTokenId = 'ERC721GeneralTokenId';
export const ERC721RareGeneralTokenId = 'ERC721RareGeneralTokenId';
export const ERC721MembershipGeneralTokenId = 'ERC721MembershipGeneralTokenId';

export const FS_ORG_ID = '169RJ0';
export const IntercomAppId = 'zyj01m98';

export enum LaunchDarklyFlags {
  BRAND_ADMIN_EVENTS = 'brandAdminEvents',
  SOCIAL_REPCARD = 'socialRepcard',
  SOCIAL_REPCARD_INSTAGRAM = 'socialRepcardInstagram',
  SOCIAL_REPCARD_TIKTOK = 'socialRepcardTikTok',
  SOCIAL_SHARE_APP_SETUP = 'socialShareAppSetup',
  BRAND_ADMIN_MEMBERS = 'brandAdminMembers',
  REDEMPTION = 'redemption',
  AIRDROP_COLLECTIBLE = 'airdropCollectible',
  COLLECTIBLE_MEDIA_OBJECT_FIT = 'collectibleMediaObjectFit',
  MAINTENANCE_MODE = 'maintenanceMode',
  TYB_ADMIN = 'tybAdmin',
  BRAND_ADMIN_REDIRECT = 'brandAdminRedirect',
  FRONTEND_GLOBAL_BANNER = 'frontendGlobalBanner',
  SOCIAL_LOGIN_WEB = 'socialLoginWeb',
  WALLET_TRANSFER_COINS = 'walletTransferCoins',
}

export enum CollectionTypes {
  ERC1155 = 'ERC1155',
  ERC721 = 'ERC721',
  ERC721Rare = 'ERC721Rare',
  ERC721Membership = 'ERC721Membership',
  ERC20 = 'ERC20',
}

export const TYB_TOKEN_KEY = 'tybToken';

export const BRAND_ONBOARDING_CARD_WIDTH = 356;

export const IMAGE_MIME_TYPES = {
  WEBP: 'image/webp',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PNG: 'image/png',
  GIF: 'image/gif',
};

export const VIDEO_MIME_TYPES = {
  MP4: 'video/mp4',
  QUICKTIME: 'video/quicktime',
};

export const TEXT_MIME_TYPES = {
  CSV: 'text/csv',
};

export const SUPPORTED_MIME_TYPES_EXTENSIONS = {
  [IMAGE_MIME_TYPES.WEBP]: '.webp',
  [IMAGE_MIME_TYPES.JPEG]: '.jpeg',
  [IMAGE_MIME_TYPES.JPG]: '.jpg',
  [IMAGE_MIME_TYPES.PNG]: '.png',
  [IMAGE_MIME_TYPES.GIF]: '.gif',
  [VIDEO_MIME_TYPES.MP4]: '.mp4',
  [VIDEO_MIME_TYPES.QUICKTIME]: '.mov',
  [TEXT_MIME_TYPES.CSV]: '.csv',
};

export const MAXIMUM_CHAT_FILE_UPLOAD_SIZE_MB = 256;

export const SHOW_BLOCKCHAIN_DETAILS = false;

export enum BrandEventTabs {
  Upcoming = 'Upcoming',
  Past = 'Past',
}

export enum UserWalletTabs {
  Collectibles = 'Collectibles',
  Coins = 'Coins',
  Activity = 'Activity',
}

export const GENERATED_DATA_WAREHOUSE_MESSAGE =
  'This data is generated from our data warehouse. It is slightly delayed from real time.';

export const BRAND_AGE_GATE_VERIFICATION_KEY = 'brand_age_gate_by_uuid';
