import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { extractAdditionalDetailsFromMetadata } from 'src/utils/extractAdditionalDetailsFromMetadata';

import CollectiblePreview from '../../../components/CollectiblePreview';
import { ICollectible } from '../../../types';
import CollectibleVisibility from './CollectibleVisibility';

type CollectibleInfoProps = {
  collectible: ICollectible;
  isVisible: boolean;
  isOnBrandPage?: boolean;
  isPendingCollectible: boolean;
  ecommBrandRedemption: IBrandEcommRedemptionData;
  onRedeem: () => void;
};

const CollectibleInfo: React.FC<CollectibleInfoProps> = ({
  collectible,
  isVisible,
  isOnBrandPage,
  isPendingCollectible,
  ecommBrandRedemption,
  onRedeem,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const additionalDetails = extractAdditionalDetailsFromMetadata(collectible.metadata);

  return (
    <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="space-around" __css={{ gap: '20px' }}>
      <Flex flexDirection="column" alignItems="center" __css={{ gap: '10px' }} width="80%">
        <Box>
          <CollectiblePreview collectible={collectible} width="170px" height="170px" borderRadius="16px" />
        </Box>
        {!isOnBrandPage && <CollectibleVisibility isVisible={isVisible} />}

        <Flex flexDirection="column" textAlign="center" __css={{ gap: '5px' }}>
          <Text textAlign="center" variant="text3-600">
            {collectible.metadata.name}
          </Text>

          {collectible.tokenId && <Text variant="text5-400">Unique collectible #{collectible.tokenId}</Text>}
        </Flex>

        {!!ecommBrandRedemption && !isPendingCollectible && (
          <Button width="215px" onClick={() => onRedeem()} disabled={isPendingCollectible}>
            Redeem
          </Button>
        )}

        <Flex flexDirection="column" __css={{ gap: '15px', ...(!isMobile && { maxHeight: '30vh', overflow: 'auto' }) }}>
          {collectible?.metadata.description.length > 0 && (
            <Box data-testid="collectible-info-description">
              <Text color="neutrals-500" variant="text5-500" mb={2}>
                About collectible
              </Text>
              <Text variant="text5-400" color="black">
                {collectible?.metadata.description}
              </Text>
            </Box>
          )}
          {additionalDetails && (
            <Box mb="2" data-testid="collectible-info-additional-details">
              <Text color="neutrals-500" variant="text5-500" mb={2}>
                {additionalDetails.title}
              </Text>
              <Text
                className="collectible__additional-details"
                dangerouslySetInnerHTML={{
                  __html: additionalDetails.body,
                }}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CollectibleInfo;
