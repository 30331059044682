import * as boxiconsLogos from '@styled-icons/boxicons-logos';
import * as boxiconsRegular from '@styled-icons/boxicons-regular';
import * as boxiconsSolid from '@styled-icons/boxicons-solid';
import * as boxiconsEvil from '@styled-icons/evil';
import { StyledIconProps } from '@styled-icons/styled-icon';

export type IconProps = StyledIconProps;

export const BoxiconsRegular = boxiconsRegular;
export const BoxiconsSolid = boxiconsSolid;

export default {
  BoxiconsRegular: boxiconsRegular,
  BoxiconsSolid: boxiconsSolid,
  BoxiconsLogos: boxiconsLogos,
  BoxiconsEvil: boxiconsEvil,
};
