import { useEffect } from 'react';

import { useWalletSignature } from '../../hooks/useWalletSignature';
import { useAppSelector } from '../../redux/hooks';

const WalletSignatureProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const user = useAppSelector((state) => state.user);
  const { signERC20Permit } = useWalletSignature();

  useEffect(() => {
    if (user.pendingPermitSignatures?.length) {
      signERC20Permit(
        user.pendingPermitSignatures.map((v) => ({
          id: v.id,
          contractAddress: v.contractAddress,
          quantity: v.quantity,
          spenderAddress: v.brand.brandWallet.address,
        }))
      );
    }
  }, []);

  return <>{children}</>;
};

export default WalletSignatureProvider;
