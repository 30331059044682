import React from 'react';

import Flex from '../Flex/Flex';
import Image from '../Image/Image';
import Text from '../Text/Text';

type Type = 'initials' | 'image';

export interface AvatarProps {
  type?: Type;
  title: string;
  subtitle?: string;
  image?: string;
  children?: React.ReactNode;
}

const Avatar: React.FC<AvatarProps> = ({ type, title, subtitle, image }) => {
  return (
    <Flex flexDirection="row" alignItems="center">
      <Flex
        width="28px"
        height="28px"
        borderRadius="100%"
        justifyContent="center"
        alignItems="center"
        bg="#AF9CAD"
        overflow="hidden"
      >
        {type === 'image' && !!image && <Image src={image} data-testid="initial-image" />}
        {type === 'initials' && (
          <Text fontWeight="900" fontSize="xl" data-testid="initial-text">
            {title.slice(0, 1)}
          </Text>
        )}
      </Flex>
      <Text ml="2" fontSize="1rem" fontWeight="bold">
        {title}
      </Text>
      {subtitle && (
        <Text ml="1" fontSize="sm" opacity={0.5} fontWeight="medium">
          {subtitle}
        </Text>
      )}
    </Flex>
  );
};

Avatar.defaultProps = {
  type: 'initials',
};

export default Avatar;
