import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { mainMenu } from '../../config/menu';
import { useIntercom } from '../../hooks/useIntercom';
import { AuthStatus } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks';

export const AppHead = () => {
  const router = useRouter();
  const [title, setTitle] = useState('TYB');

  const isLoading = useAppSelector((state) => state.auth.status === AuthStatus.LOADING);
  const notificationCount = useAppSelector((state) => state.user.notificationCount);
  const routeItem = mainMenu.find((v) => router.route == v.path);

  useIntercom();

  useEffect(() => {
    const title = new Set(['Try Your Best |']);

    if (isLoading || !routeItem) {
      if (isLoading) {
        title.add('Loading');
      } else if (router.asPath === '/login') {
        title.add('Authentication');
      } else {
        title.clear();
        title.add('Try Your Best');
      }
    }

    setTitle(Array.from(title).join(' '));
  }, [isLoading, routeItem, router.asPath]);

  const metaTitle = notificationCount > 0 ? `(${notificationCount}) ${title}` : title;

  return (
    <Head>
      <title>{metaTitle}</title>
    </Head>
  );
};
