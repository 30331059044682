import parseS3URL from './parseS3URL';

type ImageSize = 'lg' | 'md' | 'sm';

const getThumbnail = (url: string, size: ImageSize = 'md') => {
  if (size === null) return url;

  const s3Url = parseS3URL(url);

  if (s3Url.key.includes('.gif') || s3Url.key.includes('.mp4')) {
    return url;
  }

  return `https://${s3Url.bucket}-resized.s3.${s3Url.region}.amazonaws.com/${size}/${s3Url.key}`;
};

export default getThumbnail;
