import { gql } from '@apollo/client';

import { IUser } from '../../interface/IUser';

export interface UserByWalletVars {
  walletAddress: string;
}

export interface UserByWalletData {
  userByWalletAddress: Pick<
    IUser,
    'avatarUrl' | 'bio' | 'email' | 'firstName' | 'lastName' | 'userName' | 'urlSlug' | 'cChainPublicAddress'
  >;
}

export const USER_BY_ADDRESS = gql`
  query userByWalletAddress($walletAddress: String!) {
    userByWalletAddress(walletAddress: $walletAddress) {
      avatarUrl
      bio
      email
      firstName
      lastName
      userName
      urlSlug
      cChainPublicAddress
    }
  }
`;
