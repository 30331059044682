import styled from 'styled-components';

export const Divider = styled.span`
  color: #c7c7c7;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
  font-size: 14px;
  align-items: center;

  :before,
  :after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #ededed;
    margin: auto;
  }
  :before {
    margin-right: 15px;
  }
  :after {
    margin-left: 15px;
  }
`;
