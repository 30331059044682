/* eslint-disable @typescript-eslint/no-explicit-any */
import { ethers } from 'ethers';

import { BaseContract, StandardTypes } from './base';
import { EventListener } from './types';

const EventTypeArray = ['CreatedBeaconProxy'] as const;
export type ERC20BeaconProxyFactoryType = (typeof EventTypeArray)[number];

export type ERC20BeaconProxyFactoryTypeArgs = {
  ['CreatedBeaconProxy']: {
    name: string;
    symbol: string;
    owner: string;
    proxy: string;
    contractURI: string;
    initialSupply: number;
  };
};

export class ContractERC20BeaconProxy extends BaseContract {
  standardName = StandardTypes.ERC20_BEACON_FACTORY;

  public on<R = ERC20BeaconProxyFactoryTypeArgs>(
    eventType: ERC20BeaconProxyFactoryType,
    args: any[],
    listener: EventListener<R>
  ): void {
    const eventFilter = this.contract.filters[eventType as any](...args);

    console.debug(
      '[ContractManager] Listening to event ',
      eventType,
      ' with args ',
      args,
      ' on ContractERC20BeaconProxy: ',
      this.contractAddress
    );

    this.addEventListener(eventFilter, (...args: any[]) => {
      const event = args[args.length - 1] as ethers.Event;
      const type = event.event as ERC20BeaconProxyFactoryType;

      console.debug('-------------------');
      console.debug('[ContractManager] Raw event of ', type, ' with hash: ', event.transactionHash);
      console.debug(args);
      console.debug('-------------------');

      switch (type) {
        case 'CreatedBeaconProxy':
          return listener(event, {
            name: args[0],
            symbol: args[1],
            owner: args[2],
            proxy: args[3],
            contractURI: args[4],
            initialSupply: args[5],
          } as any);
      }
    });
  }
}
