import mParticle, { MPConfiguration } from '@mparticle/web-sdk';
import { ReactElement, useEffect } from 'react';

import { useAppSelector } from '../../redux/hooks';
import { getEnvironment } from '../../utils';

const MparticleProvider = ({ children }: { children: ReactElement }) => {
  const { sub, email, firstName, phoneNumber, userName, publicAddress, activeBrands } = useAppSelector(
    (state) => state.user
  );
  const environment = getEnvironment(process.env.NEXT_PUBLIC_API_URL || '');

  const mParticleConfig: mParticle.MPConfiguration = {
    isDevelopmentMode: environment !== 'prod',
    logLevel: (process.env.NEXT_PUBLIC_MPARTICLE_LOG_LEVEL || 'warning') as MPConfiguration['logLevel'],
  };

  useEffect(() => {
    mParticle.init(process.env.NEXT_PUBLIC_MPARTICLE_API_KEY, mParticleConfig);
  }, []);

  useEffect(() => {
    if (sub) {
      mParticle?.Identity.login(
        {
          userIdentities: {
            customerid: sub.toString(),
            email: email,
            mobile_number: phoneNumber,
          },
        },
        (result) => {
          const user = result.getUser();
          console.log('mparticle login', result?.httpCode, { user });

          if (user) {
            user.setUserAttribute('$FirstName', firstName);
            user.setUserAttribute('$Mobile', phoneNumber);
            user.setUserAttribute('user_id', sub.toString());
            user.setUserAttribute('email', email);
            user.setUserAttribute('phone_number', phoneNumber);
            user.setUserAttribute('user_name', userName);
            user.setUserAttribute('wallet_address', publicAddress);
            user.setUserAttribute('is_tyb_admin', (email?.includes('tyb.xyz') || false).toString());
            user.setUserAttributeList(
              'brand_member',
              activeBrands.map((v) => v.name)
            );
          }
        }
      );
    }
  }, [email, firstName, phoneNumber, publicAddress, activeBrands, sub, userName]);

  return children;
};

export default MparticleProvider;
