import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from 'src/utils';
import { useTheme } from 'styled-components';

import ContentModal from '../../components/Modals/Content';

const ShowUnlockCollectibleToCreateWalletModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const router = useRouter();

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      fullScreen={isMobile}
      body={
        <Flex padding="20px" flexDirection="column" width={['auto', '400px']} height={'350px'}>
          <Flex justifyContent="center" height="100%">
            {/* <Flex
              __css={{
                cursor: 'pointer',
                position: 'absolute',
                right: '16px',
                ':hover': { backgroundColor: 'rgba(239, 239, 239, 0.8)' },
              }}
              onClick={onClose}
              color={'black'}
              backgroundColor={'rgba(239, 239, 239, 1)'}
              borderRadius="50%"
              height="32px"
              width="32px"
              justifyContent="center"
              alignItems="center"
            >
              <Icon.BoxiconsRegular.X size={20} />
            </Flex> */}
            <Flex px="16px" my="30px" flexDirection="column" justifyContent="center" alignItems="center">
              <Flex my="20px" flexDirection="column" justifyContent="center">
                <Box>
                  <Text textAlign="center" variant="text4-400">
                    You must be a brand member to continue. Discover what brands are on TYB & join your favorite to get
                    started.
                  </Text>
                  <br />
                  <Text textAlign="center" variant="text4-400">
                    Already a member? Make sure you&rsquo;re using the correct method to login (email or phone number).
                  </Text>
                  <br />
                  <Button
                    onClick={() => {
                      router.push('https://www.tyb.xyz/discover');
                    }}
                    width="100%"
                    scale="lg"
                  >
                    Discover and join on TYB
                  </Button>
                  <Button onClick={onClose} width="100%" scale="lg" mt="10px" variant="secondary">
                    Login
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
};

export default ShowUnlockCollectibleToCreateWalletModal;
