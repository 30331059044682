import { gql } from '@apollo/client';

export interface LogoutData {
  logoutWithMagic: boolean;
}

export const LOGOUT_WITH_MAGIC = gql`
  mutation logoutWithMagic {
    logoutWithMagic
  }
`;
