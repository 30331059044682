import React from 'react';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';

type TabType = string | number;

export interface TabProps {
  onClick?: (tab: TabType) => void;
  selectedTab?: TabType;
  children?: React.ReactNode;
}

export interface TabItemProps {
  active?: boolean;
  tab: TabType;
  onClick?: (tab: TabType) => void;
  children?: React.ReactNode;
}

const Tab: React.FC<TabProps> & { Item: React.FC<TabItemProps> } = ({ children, onClick, selectedTab }) => {
  return (
    <Flex bg="gray02" padding="1" borderRadius="2xl" display="inline-flex">
      {React.Children.map(children, (child: React.ReactElement) => {
        return React.cloneElement(child, {
          onClick: (tab) => {
            onClick(tab);
          },
          active: selectedTab == child.props.tab,
        });
      })}
    </Flex>
  );
};

const TabItem: React.FC<TabItemProps> = ({ children, onClick, tab, active }) => {
  return (
    <Box
      paddingTop="2"
      paddingBottom="2"
      paddingLeft="3"
      paddingRight="3"
      backgroundColor={active ? 'white' : 'transparent'}
      elevation={active ? 'low' : 'none'}
      borderRadius="xl"
      onClick={() => onClick(tab)}
    >
      <Text>{children}</Text>
    </Box>
  );
};

Tab.defaultProps = {
  onClick: (tab) => null,
};

TabItem.defaultProps = {
  active: false,
};

Tab.Item = TabItem;

export default Tab;
