import { useMutation } from '@apollo/client';
import mParticle from '@mparticle/web-sdk';
import { Box, Button, Flex, Input, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useMemo, useState } from 'react';
import CollectiblePreview from 'src/components/CollectiblePreview';
import RedemptionReviewInfo from 'src/components/Redemption/RedemptionReviewInfo';
import {
  REDEEM_ECOMM_REDEMPTION,
  RedeemEcommRedemptionData,
  RedeemEcommRedemptionVars,
} from 'src/graphql/mutations/RedeemEcommRedemption';
import { ICollectionInfoData } from 'src/graphql/queries/collectionInfo';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { IUser } from 'src/interface/IUser';
import { ContractManager } from 'src/services/contract-manager';
import { BaseContract } from 'src/services/contract-manager/contract';
import { CoinRedemptionType, ICollectible } from 'src/types';
import { logMparticleEvent } from 'src/utils/mparticle';

import { getCoinRedemptionValue } from '../../helpers';

interface CoinRedemptionReviewProps {
  collectible: ICollectible;
  amount: number;
  collectionInfoData: ICollectionInfoData;
  user: IUser;
  applyTo: CoinRedemptionType;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  isMobile: boolean;
  onPreviousStep: () => void;
  onNextStep: (redeemEcommRedemptionData: RedeemEcommRedemptionData) => void;
}

const CoinRedemptionReview: React.FC<CoinRedemptionReviewProps> = ({
  collectible,
  amount,
  collectionInfoData,
  user,
  applyTo,
  ecommBrandRedemption,
  isMobile,
  onPreviousStep,
  onNextStep,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const brandName = collectionInfoData?.collectionInfo?.brand?.name;

  const inputStyle = {
    fontSize: '60px',
    border: 0,
    outline: 0,
    padding: '0px',
  };

  const inputSize = useMemo(() => {
    const [oneCount, restCount] = amount
      .toString()
      .split('')
      .reduce((acc, n) => (n == '1' ? [acc[0] + 1, acc[1]] : [acc[0], acc[1] + 1]), [0, 0]);

    return oneCount * 27 + restCount * 37 + 10;
  }, [amount]);

  const approveCoins = useCallback(
    async (amount: number) => {
      const type = ContractManager.getType(collectible.type);
      const contract = ContractManager.instance().loadContract<BaseContract>(collectible.contractAddress, type);

      return await contract.approvalSignature(collectionInfoData.collectionInfo.account, {
        amount,
      });
    },
    [collectible, collectionInfoData?.collectionInfo?.account]
  );

  const [redeemEcommRedemption] = useMutation<RedeemEcommRedemptionData, RedeemEcommRedemptionVars>(
    REDEEM_ECOMM_REDEMPTION
  );

  const coinAmount = `${amount} ${collectible.symbol}`;
  const redemptionValue = useMemo(
    () => getCoinRedemptionValue({ amount, effect: ecommBrandRedemption?.effect }),
    [ecommBrandRedemption?.effect]
  );

  const stepLabel = useMemo(() => (isLoading ? 'Generating the code' : 'Confirm'), [isLoading]);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const parsedAmount = amount || 1;

      logMparticleEvent('confirm_redeeming_collectible', mParticle.EventType.Other, {
        user_id: user.id,
        user_email: user.email,
        user_name: user.userName,
        wallet_address: user.cChainPublicAddress,
        collectible_name: collectible.metadata.name,
        collectible_type: collectible.type,
        redemption_type: ecommBrandRedemption.type,
        amount: parsedAmount,
        brand_name: brandName,
      });

      const approvalTx = await approveCoins(amount);

      const { data } = await redeemEcommRedemption({
        variables: {
          quantity: amount,
          uuid: ecommBrandRedemption.uuid,
          applyTo,
          approvalTx: {
            uuid: approvalTx.uuid,
            walletAddress: approvalTx.publicAddress,
            contractAddress: approvalTx.contractAddress,
            contractType: approvalTx.collectionType,
            data: approvalTx.encodedData,
            signature: approvalTx.metaTx.signature,
            gas: approvalTx.gas,
            raw: {
              function: approvalTx.calldata.method,
              args: approvalTx.calldata.args.map((v) => v.toString()),
            },
          },
        },
      });

      onNextStep(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex flexDirection="column" flex={1} justifyContent={isLoading && 'center'}>
        {isLoading && (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" __css={{ gap: '20px' }}>
            <Box maxWidth="300px">
              <CollectiblePreview collectible={collectible} width="170px" height="170px" />
            </Box>
            <Text variant="text4-400" textAlign="center" width="300px">
              {applyTo === 'checkout'
                ? `We’re generating your discount code! Please wait a moment.`
                : `We're applying this to your subscription! Please wait a moment.`}
            </Text>
          </Flex>
        )}

        {!isLoading && (
          <>
            <Flex flexDirection="column" __css={{ gap: '10px' }}>
              <Text variant="text1" color="neutrals-900">
                Review and confirm
              </Text>
              <RedemptionReviewInfo
                coinAmount={coinAmount}
                redemptionValue={redemptionValue}
                brandName={brandName}
                applyTo={applyTo}
              />
            </Flex>
            <Flex flex={1} flexDirection="column" justifyContent="center">
              <Flex alignItems="center" justifyContent="center" __css={{ gap: '10px' }}>
                <Input
                  style={inputStyle}
                  readOnly
                  width={`${inputSize}px`}
                  name="redeem-coins"
                  type="tel"
                  value={amount}
                  onFocus={(event) => event.target.select()}
                />
                <Flex alignItems="center">
                  <CollectiblePreview collectible={collectible} width="50px" height="50px" borderRadius="12px" />
                </Flex>
              </Flex>
              {isMobile && (
                <Button
                  variant="link"
                  color="neutrals-700"
                  onClick={onPreviousStep}
                  style={{ textDecoration: 'underline' }}
                >
                  Edit amount
                </Button>
              )}
            </Flex>
          </>
        )}
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        {!isMobile && (
          <Text variant="text4-400" color="neutrals-900">
            2 / 3 - {stepLabel}
          </Text>
        )}

        <Flex alignItems="center" __css={{ gap: '10px' }} width={isMobile ? '100%' : null}>
          {!isMobile && (
            <Button variant="secondary" disabled={isLoading} onClick={onPreviousStep}>
              Edit amount
            </Button>
          )}
          <Button
            onClick={handleConfirm}
            disabled={isLoading}
            style={isMobile ? { flex: 1, padding: '15px', fontSize: '16px', fontWeight: 600 } : null}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default CoinRedemptionReview;
