import { OAuthRedirectResult } from '@magic-ext/oauth';
import { Flex, Grid } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ShowUnlockCollectibleToCreateWalletModal from 'src/modals/ShowUnlockCollectibleToCreateWalletModal/ShowUnlockCollectibleToCreateWalletModal';
import { extractErrorsFromGraphQl } from 'src/utils/extractErrorsFromGraphQl';
import { useTheme } from 'styled-components';

import ImageCDN from '../components/ImageCDN';
import Loading from '../components/Loading';
import LoginCard from '../components/LoginCard';
import LoginOnboarding from '../components/LoginOnboarding';
import LoginRegister from '../components/LoginRegister';
import Logo from '../components/Logo';
import PageLayout from '../components/PageLayout';
import { magic } from '../config/magic';
import withoutAuth from '../hoc/withoutAuth';
import useAuth from '../hooks/useAuth';
import { useBrandCustomization } from '../hooks/useBrandCustomization';
import useWindowSize from '../hooks/useWindowSize/useWindowSize';
import { AuthStatus } from '../redux/auth/authSlice';
import { useAppSelector } from '../redux/hooks';
import { AuthType } from '../types';
import { convertRemToPx } from '../utils';

const LoginPage: React.FC = () => {
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const router = useRouter();
  const theme = useTheme();
  const size = useWindowSize();
  const auth = useAuth();

  const { brand } = router.query as Record<string, string>;
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const [loading, setLoading] = useState(true);

  const [desktopStep, setDesktopStep] = useState(1);
  const [mobileStep, setMobileStep] = useState(2);

  const [loginParams, setLoginParams] = useState<{ authType: AuthType; email?: string; phoneNumber?: string }>();
  const [defaultEmail, setDefaultEmail] = useState<string>();
  const [oauthRedirectResult, setOauthRedirectResult] = useState<OAuthRedirectResult | null>(null);

  const [showUnlockCollectibleToCreateWalletModal, setShowUnlockCollectibleToCreateWalletModal] =
    useState<boolean>(false);

  const { customization, getCustomizationBySlug } = useBrandCustomization();

  useEffect(() => {
    if (authStatus === AuthStatus.LOGGED_OUT && isMobile && router.query.forceTutorial == 'true') {
      setMobileStep(1);
    }

    if (authStatus === AuthStatus.LOGGED_IN) {
      changeStep(3, 2);
    }
  }, [isMobile, router.query.forceTutorial, authStatus]);

  useEffect(() => {
    if (router.query.email) {
      setDefaultEmail(router.query.email.toString());
    }
  }, []);

  const changeStep = (mobileStep?: number, desktopStep?: number) => {
    mobileStep && setMobileStep(mobileStep);
    desktopStep && setDesktopStep(desktopStep);
  };

  const onLoginSubmit = (authType: AuthType, email?: string, phoneNumber?: string) => {
    changeStep(3, 2);
    setLoginParams({ authType, email, phoneNumber });
  };

  useEffect(() => {
    (async () => {
      const { oauthredirect, provider } = router.query as Record<string, string | undefined>;
      if (oauthredirect) {
        try {
          const { href } = window.location;
          const result = await magic.oauth.getRedirectResult();
          const url = new URL(href);
          const remove = ['oauthredirect', 'provider', 'state', 'scope', 'magic_oauth_request_id', 'magic_credential'];
          for (const key of remove) {
            url.searchParams.delete(key);
          }
          window.history.replaceState(null, '', url.toString());

          const validationData = await auth.validate({
            authType: 'SOCIAL',
            publicAddress: result.magic.userMetadata.publicAddress,
            brandSlug: brand,
          });

          setOauthRedirectResult(result);
          if (validationData.userExists) {
            await auth.login({
              authType: 'SOCIAL',
              oAuthProvider: provider,
              email: result.magic.userMetadata.email,
              didToken: result.magic.idToken,
            });
          } else {
            onLoginSubmit('SOCIAL', result.magic.userMetadata.email);
          }
        } catch (error) {
          const graphqlError = extractErrorsFromGraphQl(error);
          const unlockCollectible = graphqlError?.find(
            (e) => e?.customBehaviorName == 'UNLOCK_COLLECTIBLE_TO_CREATE_WALLET'
          );

          if (unlockCollectible) {
            setShowUnlockCollectibleToCreateWalletModal(true);
            setLoading(false);
            return;
          }

          console.error(error);
        }
      }
      if (brand) {
        getCustomizationBySlug(brand).finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    })();
  }, [brand]);

  const isCustomizationEnabled = customization?.loginPage?.enabled || false;
  const brandName = isCustomizationEnabled ? customization?.brandName : null;
  const brandLogo = isCustomizationEnabled ? customization?.brandLogoUrl : null;

  return (
    <PageLayout
      hideNavbar
      showSignup={false}
      centerLogo
      showLogo
      minimal
      disableLogoRedirect
      mparticleEventName="page_view_login"
    >
      {loading && <Loading />}

      {!loading && (
        <Flex flexDirection="column" justifyItems="center" width="100%" height="100%">
          <Grid mb="20px" height="100%" gridTemplateColumns={isMobile ? '1fr' : '2fr 1fr'}>
            {isMobile && (
              <>
                <Flex flexDirection="column" justifyContent="center" alignItems="center">
                  {brandLogo ? <ImageCDN src={brandLogo} maxWidth="90%" data-testid="brand_logo" /> : <Logo />}
                </Flex>
                {mobileStep == 1 && (
                  <LoginOnboarding
                    isMobile={isMobile}
                    brandName={brandName}
                    brandCoinImage={isCustomizationEnabled ? customization?.loginPage?.coinImage : null}
                    loginAction={() => setMobileStep(2)}
                  />
                )}
                {mobileStep == 2 && (
                  <>
                    <LoginCard brandName={brandName} defaultEmail={defaultEmail} onLoginSubmit={onLoginSubmit} />
                  </>
                )}
                {mobileStep == 3 && (
                  <LoginRegister
                    authType={loginParams.authType}
                    email={loginParams.email}
                    phoneNumber={loginParams.phoneNumber}
                    onChangeStep={(email) => {
                      changeStep(2, 1);
                      setDefaultEmail(email);
                    }}
                    oauthRedirectResult={oauthRedirectResult}
                  />
                )}

                {brandLogo && (
                  <Flex justifyContent="center" alignItems="flex-end">
                    <Logo height={40} />
                  </Flex>
                )}
              </>
            )}

            {!isMobile && (
              <>
                <Flex justifyContent="center" alignItems="center" flexDirection="column" mr="40px">
                  <LoginOnboarding
                    isMobile={isMobile}
                    brandName={brandName}
                    brandCoinImage={isCustomizationEnabled ? customization?.loginPage?.coinImage : null}
                  />
                </Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  elevation="high"
                  borderRadius="25px"
                  px="5px"
                  width="100%"
                >
                  <Flex flexDirection="column" justifyContent="center" alignItems="center" pt="40px">
                    {brandLogo ? (
                      <ImageCDN src={brandLogo} maxWidth="90%" data-testid="brand_logo" />
                    ) : (
                      <Logo height={50} />
                    )}
                  </Flex>
                  <Flex flexDirection="column" justifyContent="center" flex={1} width="100%" pb={5}>
                    {desktopStep === 1 && (
                      <>
                        <LoginCard brandName={brandName} defaultEmail={defaultEmail} onLoginSubmit={onLoginSubmit} />
                      </>
                    )}
                    {desktopStep === 2 && (
                      <LoginRegister
                        authType={loginParams.authType}
                        email={loginParams.email}
                        phoneNumber={loginParams.phoneNumber}
                        onChangeStep={(email) => {
                          changeStep(2, 1);
                          setDefaultEmail(email);
                        }}
                        oauthRedirectResult={oauthRedirectResult}
                      />
                    )}

                    {brandLogo && <Logo height={40} />}
                  </Flex>
                </Flex>
              </>
            )}
          </Grid>
        </Flex>
      )}
      <ShowUnlockCollectibleToCreateWalletModal
        isOpen={showUnlockCollectibleToCreateWalletModal}
        onClose={() => {
          setShowUnlockCollectibleToCreateWalletModal(false);
        }}
      />
    </PageLayout>
  );
};

export default withoutAuth(LoginPage);
