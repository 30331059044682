import 'react-phone-input-2/lib/style.css';

import { Button, Flex } from '@tyb-u/tyb-ui-components';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import { AuthType } from '../../types';

export type Inputs = {
  phoneNumber: string;
};

export type PhoneNumberInputsSubmitHandler = (input: Inputs) => void | Promise<void>;

export interface LoginPhoneNumberFormProps {
  onSubmit: PhoneNumberInputsSubmitHandler;
  loading?: boolean;
  setAuthType: Dispatch<SetStateAction<AuthType>>;
}

const LoginPhoneNumberForm: React.FC<LoginPhoneNumberFormProps> = ({ onSubmit, loading = false, setAuthType }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const handleSubmit = useCallback(
    (ev) => {
      ev.preventDefault();

      onSubmit({ phoneNumber });
    },
    [onSubmit, phoneNumber]
  );

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Flex width="100%">
        <ReactPhoneInput
          country="us"
          containerStyle={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            width: '100%',
            height: '52px',
          }}
          inputStyle={{
            borderRadius: '30px',
            border: '1px solid #E8E8E8',
            paddingLeft: '20px',
            paddingRight: '20px',
            width: 'calc(100% - 57px)',
            height: '52px',
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
          }}
          buttonStyle={{
            position: 'unset',
            backgroundColor: '#fff',
            borderRadius: '30px',
            border: '1px solid #E8E8E8',
            width: '52px',
            height: '52px',
            marginRight: '10px',
            pointerEvents: 'none',
          }}
          onChange={(phone) => setPhoneNumber(phone)}
          disableDropdown
        />
      </Flex>

      <Flex flexDirection="column" mt="2">
        <Button type="submit" fontSize="16px" height={52} scale="lg" py="12px" disabled={loading}>
          {!loading ? 'Next' : 'Loading...'}
        </Button>
      </Flex>
    </form>
  );
};

export default LoginPhoneNumberForm;
