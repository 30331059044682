import { Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

import Loading from '../../../components/Loading';
import UserAvatar from '../../../components/UserAvatar';
import { ICollectionInfoData } from '../../../graphql/queries/collectionInfo';

type CollectibleBrandProps = {
  collectionInfoData: ICollectionInfoData;
  router: { push: (url: string) => void };
  isOnBrandPage: boolean;
  isMobile: boolean;
  onClose: (submitted?: boolean) => void;
};

const CollectibleBrand: React.FC<CollectibleBrandProps> = ({
  collectionInfoData,
  router,
  isOnBrandPage,
  isMobile,
  onClose,
}) => {
  return (
    <Flex flexDirection="column" data-testid="collectible-modal-brand-owner-info">
      {collectionInfoData?.collectionInfo?.brand ? (
        <Flex __css={{ gap: '15px' }} justifyContent={isMobile ? 'space-between' : ''}>
          <Flex alignItems="center">
            <UserAvatar
              avatarUrl={
                collectionInfoData.collectionInfo.brand.avatarUrl ||
                collectionInfoData.collectionInfo.brand.heroImageUrl
              }
              size={40}
              css={{ objectFit: 'cover' }}
            />

            <Text variant="text4-600" ml="11px">
              {collectionInfoData.collectionInfo.brand.name}
            </Text>
          </Flex>
          <Button
            variant="secondary"
            alignItems="center"
            borderColor="#E1E0E0"
            display="flex"
            alignContent="center"
            onClick={() =>
              !isOnBrandPage ? router.push(`/brand/${collectionInfoData.collectionInfo.brand.urlSlug}`) : onClose()
            }
          >
            <Text variant="text5-400" color="#272424">
              View brand
            </Text>
          </Button>
        </Flex>
      ) : (
        <Loading />
      )}
    </Flex>
  );
};

export default CollectibleBrand;
