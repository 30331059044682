import { gql } from '@apollo/client';

export interface ValidateAuthVars {
  user: {
    email?: string;
    phoneNumber?: string;
    brandSlug?: string;
    publicAddress?: string;
  };
}

export interface ValidateAuthData {
  validateAuthentication: {
    userExists: boolean;
  };
}

export const VALIDATE_AUTHENTICATION = gql`
  query validateAuthentication($user: ValidateAuthenticationInput!) {
    validateAuthentication(user: $user) {
      userExists
    }
  }
`;
