/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import React from 'react';
import { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { validateFileUpload } from 'src/utils/file';

import {
  ABORT_MULTIPART_UPLOAD,
  COMPLETE_MULTIPART_UPLOAD,
  CREATE_MULTIPART_UPLOAD,
  IAbortMultipartUploadData,
  IAbortMultipartUploadVars,
  ICompleteMultipartUploadData,
  ICompleteMultipartUploadVars,
  ICreateMultipartUploadData,
  ICreateMultipartUploadVars,
} from '../../graphql/mutations/createMultipartUpload';
import { IMultipartUpload, MultipartUploadStatus } from '../../types';
import { uploadMultipartFile } from '../../utils/uploadMultipartFile';

const FILE_CHUNK_SIZE = 6_000_000; // 6mb per chunk (CHUNK SIZE CANNOT BE EQUAL OR LESS THAN 5mb)

type MultipartUploaderOptions = {
  status: MultipartUploadStatus;
  file: File;
  abort: () => void;
  setFile: (file: File) => void;
};

export type MultipartUploaderRef = {
  upload: (file?: File) => Promise<string>;
  abort: () => void;
  setFile: (file: File) => void;
};

export type MultipartUploaderProps = {
  name?: string;
  accept?: string;
  folder?: string;
  /**
   * @description Maximum file size in megabytes
   */
  maxFileSize?: number;
  ref: any;
  onChange?: (
    status: MultipartUploadStatus,
    file: File,
    uploadedUrl: string,
    uploadProgress?: number,
    setFile?: (file: File) => void,
    error?: string
  ) => void;
  onFileChange?: (name: string, file: File, upload: (file?: File) => Promise<string>) => void;
  children: (
    openDialog: () => void,
    upload: (file?: File) => Promise<string>,
    uploadedUrl: string,
    options?: MultipartUploaderOptions
  ) => React.ReactElement;
};

const MultipartUploader = React.forwardRef<any, MultipartUploaderProps>((props, ref) => {
  const {
    name = 'multipart_uploader',
    accept = '*/*',
    folder = '/',
    maxFileSize,
    onChange = () => null,
    onFileChange = () => null,
    children,
  } = props;

  const [file, setFile] = useState<File>();
  const [currentMultipartUpload, setCurrentMultipartUpload] = useState<IMultipartUpload | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<string>();
  const [status, setStatus] = useState<MultipartUploadStatus>('IDLE');

  const fileInput = useRef<any>(null);

  const [createMultipartUpload] = useMutation<ICreateMultipartUploadData, ICreateMultipartUploadVars>(
    CREATE_MULTIPART_UPLOAD
  );
  const [completeMultipartUpload] = useMutation<ICompleteMultipartUploadData, ICompleteMultipartUploadVars>(
    COMPLETE_MULTIPART_UPLOAD
  );
  const [abortMultipartUpload] = useMutation<IAbortMultipartUploadData, IAbortMultipartUploadVars>(
    ABORT_MULTIPART_UPLOAD
  );

  const handleChangeFile = useCallback(
    (ev) => {
      if (ev?.target?.files?.length) {
        const file: File = ev.target.files[0];
        const validationError = validateFileUpload({ mimeTypes: accept, size: maxFileSize, file });

        if (validationError) {
          onChange('ERROR', file, '', 0, setFile, validationError);
          return;
        }

        setFile(file);
        onChange(status, file, uploadedUrl);
        onFileChange(name, file, upload);
      }
    },
    [maxFileSize, name, onChange, onFileChange, status, uploadedUrl]
  );

  const openDialog = useCallback(() => {
    if (status !== 'PREPARING' && status !== 'UPLOADING') {
      fileInput?.current?.click();
    }
  }, [status]);

  const abort = useCallback(async () => {
    if (currentMultipartUpload) {
      await abortMultipartUpload({
        variables: {
          data: {
            multipartUpload: currentMultipartUpload,
          },
        },
      });

      setCurrentMultipartUpload(null);
      onChange('ABORTED', file, uploadedUrl);
    }
  }, [abortMultipartUpload, currentMultipartUpload]);

  const upload = useCallback(
    async (_file?: File) => {
      if (!_file) {
        _file = file;
      }

      if (_file) {
        setStatus('PREPARING');
        onChange('PREPARING', _file, uploadedUrl);

        const chunksCount = Math.ceil(_file.size / FILE_CHUNK_SIZE);

        const { data, errors } = await createMultipartUpload({
          variables: {
            filename: _file.name,
            folder: folder,
            partsNumber: chunksCount,
          },
        });

        if (errors?.length) {
          setStatus('ERROR');
          onChange(status, _file, uploadedUrl);
        }

        if (data?.createMultipartUpload?.uploadParts) {
          const { uploadParts, bucketName, objectKey, uploadId } = data.createMultipartUpload;
          setCurrentMultipartUpload({
            bucketName,
            objectKey,
            uploadId,
          });

          try {
            setStatus('UPLOADING');
            onChange('UPLOADING', _file, uploadedUrl);

            const allETags = [];
            let uploadedBytes = 0;

            for (let chunk = 0; chunk < chunksCount; chunk++) {
              const offset = chunk * FILE_CHUNK_SIZE;
              const blob = _file.slice(offset, offset + FILE_CHUNK_SIZE);

              const etag = await uploadMultipartFile(uploadParts[chunk], blob);

              uploadedBytes += blob.size;
              // eslint-disable-next-line prettier/prettier
              onChange('UPLOADING', _file, uploadedUrl, Math.round(uploadedBytes / _file.size * 100));

              allETags.push({
                ETag: etag,
                PartNumber: chunk + 1,
              });
            }

            const { data: completedData } = await completeMultipartUpload({
              variables: {
                data: {
                  multipartUpload: {
                    bucketName,
                    objectKey,
                    uploadId,
                  },
                  multipartETags: allETags,
                },
              },
            });

            setUploadedUrl(decodeURIComponent(completedData?.completeMultipartUpload));
            setStatus('FINISHED');
            onChange('FINISHED', _file, decodeURIComponent(completedData?.completeMultipartUpload), 100, setFile);

            return decodeURIComponent(completedData?.completeMultipartUpload);
          } catch (statusCodeOrError) {
            // Not found means that the operation does not exists anymore
            if (statusCodeOrError === 404) {
              setStatus('ABORTED');
            } else {
              console.error(statusCodeOrError);

              setStatus('ERROR');
              setCurrentMultipartUpload(null);

              await abortMultipartUpload({
                variables: {
                  data: {
                    multipartUpload: {
                      bucketName,
                      objectKey,
                      uploadId,
                    },
                  },
                },
              });

              onChange('ERROR', _file, uploadedUrl, 0, setFile, statusCodeOrError?.message);
            }
          }
        }
      }
    },
    [file, onChange, uploadedUrl, createMultipartUpload, folder, status, completeMultipartUpload, abortMultipartUpload]
  );

  const _setFile = useCallback(
    (_file: File) => {
      setFile(_file);
      onFileChange(name, _file, upload);
    },
    [maxFileSize, onFileChange, name, upload, onChange]
  );

  useImperativeHandle(ref, () => ({
    upload,
    abort,
    setFile,
  }));

  return (
    <>
      <input
        type="file"
        accept={accept}
        ref={fileInput}
        multiple={false}
        style={{ display: 'none' }}
        onChange={handleChangeFile}
      />

      {children(openDialog, upload, uploadedUrl, { file, status, abort, setFile: _setFile })}
    </>
  );
});

export default MultipartUploader;
