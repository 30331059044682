import { Box } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  variant?: 'default' | 'filled' | 'success' | 'error';
  as?: 'textarea' | 'input';
  rows?: number;
  __css?: any;
}

const InputV2 = React.forwardRef<HTMLInputElement, InputProps>(({ name, variant, as, rows, __css, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      as={as}
      variant={`input.${variant}`}
      name={name}
      data-testid="input"
      rows={rows}
      {...props}
      __css={{
        display: 'block',
        appearance: 'none',
        outline: 'none',
        paddingY: '8px',
        paddingX: '18px',
        width: '100%',
        minHeight: '57px',
        variant: 'default',
        borderStyle: 'solid',
        color: 'black',
        fontFamily: 'Inter',
        fontSize: 'md',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 3px 14px',
        '::placeholder': {
          color: '#BEBABA',
        },
        ...__css,
      }}
    />
  );
});

InputV2.defaultProps = {
  variant: 'default',
  disabled: false,
  as: 'input',
  rows: 4,
};

export default InputV2;
