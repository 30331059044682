import { useCallback, useEffect, useMemo } from 'react';

import { useContractManager } from '../../hooks/useContractManager';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { rehydrateCollectibles, removeContractCollectible } from '../../redux/web3/web3Slice';
import { ContractManager, EventListenerType } from '../../services/contract-manager';
import { StandardTypes } from '../../services/contract-manager/contracts/base';
import { ICollectible } from '../../types';

export const ContractEventProvider = ({ children }) => {
  const web3State = useAppSelector((state) => state.web3);
  const user = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();

  const relatedContracts = user?.userWallet?.relatedContracts;
  const numRelatedContract = relatedContracts?.length || 0;

  const { addEventListener } = useContractManager();

  const handleNewCollectible = useCallback(
    (
      type: EventListenerType,
      contractAddress: string,
      address: string,
      blockNumber: number,
      nonce: number,
      standardName: StandardTypes,
      collectible: ICollectible
    ) => {
      if (type === 'NEW_COLLECTIBLE') {
        dispatch(
          rehydrateCollectibles({
            contractAddress,
            collectibles: [
              {
                ...collectible,
                address,
                nonce,
                blockNumber,
              },
            ],
            ownerAddress:
              standardName === StandardTypes.ERC721RARE || standardName === StandardTypes.ERC721Membership
                ? address
                : undefined,
          })
        );
      }

      if (type === 'CHANGE_COLLECTIBLE') {
        if (collectible.balance > 0) {
          dispatch(
            rehydrateCollectibles({
              contractAddress,
              collectibles: [
                {
                  ...collectible,
                  nonce,
                  blockNumber,
                },
              ],
              ownerAddress: address,
            })
          );
        } else {
          dispatch(
            removeContractCollectible({
              contractAddress,
              collectible,
            })
          );
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    web3State.listeners.forEach(async (mapper) => {
      const { key: contractAddress, value } = mapper;

      const relatedContractType = relatedContracts?.find((v) => v.contractAddress === contractAddress)?.type;

      const contractType =
        relatedContractType === 'ERC721Membership'
          ? StandardTypes.ERC721Membership
          : await ContractManager.instance().supportsInterface(contractAddress);
      console.debug(`[EVENT] Adding listener to ${contractAddress}`, contractType);

      ContractManager.instance().loadContract(contractAddress, contractType || StandardTypes.ERC1155);

      // New Collectible events
      ContractManager.instance().listenBatch(
        value.map((address) => ({
          contractAddress,
          address,
          cb: handleNewCollectible,
          type: 'NEW_COLLECTIBLE',
          contractType,
        }))
      );

      // Changed Collectible events
      ContractManager.instance().listenBatch(
        value.map((address) => ({
          contractAddress,
          address,
          cb: handleNewCollectible,
          type: 'CHANGE_COLLECTIBLE',
          contractType,
        }))
      );
    });
  }, [handleNewCollectible, web3State.listeners, numRelatedContract]);

  useMemo(() => {
    if (numRelatedContract > 0) {
      user.userWallet.relatedContracts.forEach((v) => {
        addEventListener(v.contractAddress, user.publicAddress);
      });
    }
  }, [numRelatedContract]);

  return children;
};
