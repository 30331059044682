import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { DOMAIN } from 'src/constants';

import { apollo } from '../config/apolloClient';
import { BRAND_PUBLIC_PAGE, BrandPageData, BrandPageVars } from '../graphql/queries/brandPage';
import { resetBrand, setBrand, setLoading } from '../redux/brand/selectedBrandSlice';
import { useAppDispatch } from '../redux/hooks';

const useSelectedBrand = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const fetch = useCallback(
    async (urlSlug: string) => {
      try {
        console.log(`loading selected brand data: ${urlSlug}...`);
        dispatch(setLoading(urlSlug));

        const { data } = await apollo.client.query<BrandPageData, BrandPageVars>({
          query: BRAND_PUBLIC_PAGE,
          fetchPolicy: 'no-cache',
          variables: {
            urlSlug,
          },
        });

        if (data.brandBySlug) {
          dispatch(setBrand(data.brandBySlug));

          return {
            data,
          };
        }

        return null;
      } catch (error) {
        if (error.graphQLErrors.length && error.graphQLErrors[0].extensions?.code == 409) {
          router.push(`http://${DOMAIN}`);
        } else {
          throw error;
        }
      }
    },
    [dispatch]
  );
  const reset = useCallback(async () => {
    console.log('reseting selected brand data...');
    dispatch(resetBrand());
  }, [dispatch]);

  return {
    fetch,
    reset,
  };
};

export default useSelectedBrand;
