import { Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import RedemptionCouponCode from 'src/components/Redemption/RedemptionCouponCode';
import RedemptionCouponInfo from 'src/components/Redemption/RedemptionCouponInfo';
import { RedemptionCoupon } from 'src/types';

interface CollectibleRedemptionCompleteProps {
  brandName: string;
  brandWebsiteURL: string;
  coupon: RedemptionCoupon;
  isMobile: boolean;
  onNextStep: () => void;
}

const CollectibleRedemptionComplete: React.FC<CollectibleRedemptionCompleteProps> = ({
  brandName,
  brandWebsiteURL,
  coupon,
  isMobile,
  onNextStep,
}) => {
  return (
    <>
      <Flex flexDirection="column" flex={1} __css={{ gap: '10px' }}>
        <RedemptionCouponInfo brandName={brandName} brandWebsiteURL={brandWebsiteURL} />

        <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" __css={{ gap: '10px' }}>
          <RedemptionCouponCode coupon={coupon} />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        {!isMobile && (
          <Text variant="text4-400" color="neutrals-900">
            2 / 2 - Your code is ready
          </Text>
        )}

        <Button
          onClick={onNextStep}
          style={isMobile ? { flex: 1, padding: '15px', fontSize: '16px', fontWeight: 600 } : null}
        >
          Sounds good!
        </Button>
      </Flex>
    </>
  );
};

export default CollectibleRedemptionComplete;
