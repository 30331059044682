import { ICollectibleExtraMetadata, ICollectibleMetadata } from '../types';

export function extractAdditionalDetailsFromMetadata(
  metadata: ICollectibleMetadata | ICollectibleExtraMetadata
): { title: string; body: string } | null {
  let additionalDetails: { title: string; body: string } = null;
  try {
    additionalDetails = JSON.parse(metadata.additionalDetails)[0];
  } catch {
    // do nothing
  }

  return additionalDetails;
}
