import { toast, ToastOptions } from 'react-toastify';

export function errorToast(message: string, options?: ToastOptions) {
  if (message.includes('JSON Parse Error')) {
    console.error(message);
    message = 'The application has encountered an unknown error.';
  }
  toast.error(message, {
    position: options?.position ?? 'bottom-center',
    autoClose: options?.autoClose ?? 5000,
    hideProgressBar: options?.hideProgressBar ?? false,
    closeOnClick: options?.closeOnClick ?? true,
    pauseOnHover: options?.pauseOnHover ?? true,
    draggable: options?.draggable ?? true,
    progress: options?.progress,
  });
}

export function successToast(message: string, options?: ToastOptions) {
  toast.success(message, {
    position: options?.position ?? 'bottom-center',
    autoClose: options?.autoClose ?? 5000,
    hideProgressBar: options?.hideProgressBar ?? false,
    closeOnClick: options?.closeOnClick ?? true,
    pauseOnHover: options?.pauseOnHover ?? true,
    draggable: options?.draggable ?? true,
    progress: options?.progress,
  });
}
