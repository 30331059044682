/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, ethers, FixedNumber } from 'ethers';
import { signPermit } from 'src/config/magic';

import { BaseContract, StandardTypes } from './base';

const EventTypeArray = ['Transfer'] as const;
export type ERC20EventType = (typeof EventTypeArray)[number];

export type ERC20EventListener<T> = (event: ethers.Event, args: T) => void;
export type ERC20EventTypeArgs = {
  ['Transfer']: {
    from: string;
    to: string;
    value: number;
  };
};

export class ContractERC20 extends BaseContract {
  standardName = StandardTypes.ERC20;

  public on<R = ERC20EventTypeArgs>(eventType: ERC20EventType, args: any[], listener: ERC20EventListener<R>) {
    const eventFilters: ethers.EventFilter[] = [];
    if (args.length > 0 && Array.isArray(args[0])) {
      eventFilters.push(...args.map((eventArgs) => this.contract.filters[eventType as any](...eventArgs)));
    } else {
      eventFilters.push(this.contract.filters[eventType as any](...args));
    }

    console.debug(
      '[ContractManager] Listening to event ',
      eventType,
      ' with args ',
      args,
      ' on ContractERC20: ',
      this.contractAddress
    );

    eventFilters.forEach((eventFilter) => {
      this.addEventListener(eventFilter, (...args: any[]) => {
        const event = args[args.length - 1] as ethers.Event;
        const type = event.event as ERC20EventType;

        switch (type) {
          case 'Transfer':
            return listener(event, {
              from: args[0],
              to: args[1],
              value: args[2],
            } as any);
        }
      });
    });
  }

  public async getAddressBalance(address: string): Promise<FixedNumber> {
    const balance = await this.contract.balanceOf(address);
    const decimals = await this.getDecimals();
    const fixed = FixedNumber.fromValue(balance, decimals);

    return fixed;
  }

  public async getContractURI(): Promise<string> {
    return this.contract.contractURI();
  }

  public async getTotalSupply(): Promise<FixedNumber> {
    const totalSupply: BigNumber = await this.contract.totalSupply();
    const decimals = await this.getDecimals();
    const fixed = FixedNumber.fromValue(totalSupply, decimals);

    return fixed;
  }

  public async getDecimals(): Promise<number> {
    return await this.contract.decimals();
  }

  public async getSymbol(): Promise<string> {
    return await this.contract.symbol();
  }

  async transfer(from: string, to: string, args: { tokenId?: string; amount: number }): Promise<string> {
    const decimals = await this.getDecimals();

    const baseAmount = BigNumber.from(10)
      .pow(decimals)
      .mul(BigNumber.from(BigNumber.from(args.amount)));

    return super.transfer(from, to, { tokenId: args.tokenId, amount: baseAmount });
  }

  async approval(adress: string, args: { amount: number }) {
    const decimals = await this.getDecimals();

    const baseAmount = BigNumber.from(10)
      .pow(decimals)
      .mul(BigNumber.from(BigNumber.from(args.amount)));

    return super.approval(adress, { amount: baseAmount });
  }

  async approvalSignature(adress: string, args: { amount: number | BigNumber }) {
    const decimals = await this.getDecimals();

    const baseAmount = BigNumber.from(10)
      .pow(decimals)
      .mul(BigNumber.from(BigNumber.from(args.amount)));

    return super.approvalSignature(adress, { amount: baseAmount });
  }

  async permit(spenderAddress: string, value: number, expireInSeconds: number) {
    const deadline = Math.floor(Date.now() / 1000) + expireInSeconds;
    const decimals = await this.getDecimals();

    const baseValue = BigNumber.from(10)
      .pow(decimals)
      .mul(BigNumber.from(BigNumber.from(value)));

    return await signPermit(this.contractAddress, this.iface, {
      spenderAddress,
      value: baseValue.toString(),
      deadline: deadline.toString(),
    });
  }
}
