import React from 'react';

import TableRowActions from './TableRowActions';

export interface RowProps {
  isStripped?: boolean;
  data: (string | React.ReactNode)[];
  rowActions?: {
    onClick?: () => void | null;
    onClickDetail?: () => void | null;
    onClickEdit?: () => void | null;
    onClickDelete?: () => void | null;
  };
}

const TableRow: React.FC<RowProps> = ({ isStripped = false, data, rowActions }) => {
  return (
    <tr
      style={{
        borderBottom: isStripped ? '1px solid #E1E0E0' : 'none',
      }}
    >
      {data.map((item, idx) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <td
          key={idx}
          onClick={rowActions?.onClick}
          style={{
            textAlign: 'left',
            padding: '8px',
            cursor: rowActions?.onClick ? 'pointer' : 'default',
          }}
        >
          {item}
        </td>
      ))}
      <td>
        <TableRowActions
          onClickDelete={rowActions?.onClickDelete}
          onClickDetail={rowActions?.onClickDetail}
          onClickEdit={rowActions?.onClickEdit}
        />
      </td>
    </tr>
  );
};

export default TableRow;

TableRow.defaultProps = {};
