import { gql } from '@apollo/client';
import { ICollectionTypes } from 'src/types';

export interface ICreatePoolItemVars {
  uuid: string;
  walletAddress: string;
  contractAddress: string;
  contractType: ICollectionTypes;
  data: string;
  signature: string;
  gas: string;
  raw: {
    function: string;
    args: string[];
  };
}

export interface ICreatePoolItemData {
  createPoolItem: {
    uuid: string;
  };
}

export const CREATE_POOL_ITEM = gql`
  mutation CreatePoolItem(
    $walletAddress: String!
    $contractAddress: String!
    $contractType: String!
    $data: String!
    $raw: PoolItemRawDto
    $signature: String!
    $uuid: String!
    $gas: String!
  ) {
    createPoolItem(
      walletAddress: $walletAddress
      contractAddress: $contractAddress
      contractType: $contractType
      data: $data
      raw: $raw
      signature: $signature
      uuid: $uuid
      gas: $gas
    ) {
      uuid
    }
  }
`;
