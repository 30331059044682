import React, { useCallback, useState } from 'react';

import Button from '../../Button/Button';
import Flex from '../../Flex/Flex';
import Text from '../../Text/Text';

export interface TablePaginationProps {
  total: number;
  take: number;
  refetch: (take: number, skip: number) => void;
  limit?: number;
}

const TablePagination: React.FC<TablePaginationProps> = ({ total, take, refetch, limit = 3 }) => {
  const indexes = Array.from(new Array(Math.ceil(total / take)).keys());
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCurrentIndex = useCallback(
    (index: number) => {
      setCurrentIndex(index);
      refetch(take, index * take);
    },
    [refetch, take]
  );

  let currentList = [];
  if (indexes.length < 5) {
    currentList = indexes;
  } else {
    const offset = currentIndex > indexes.length - limit ? currentIndex - indexes.length : currentIndex > 1 ? -1 : 0;
    currentList = [
      0,
      ...indexes.slice(Math.max(1, currentIndex + offset), Math.min(indexes.length - 1, currentIndex + offset + limit)),
      indexes[indexes.length - 1],
    ];

    if (currentList[1] > 1) {
      currentList.splice(1, 0, -1);
    }

    if (currentList[currentList.length - 2] < indexes.length - 2) {
      currentList.splice(currentList.length - 1, 0, -1);
    }
  }

  return (
    <Flex justifyContent="end">
      {currentList.map((index) =>
        index === -1 ? (
          <Text key={`pagination_key_${index}`}>...</Text>
        ) : (
          <Button
            key={`pagination_key_${index}`}
            variant={index === currentIndex ? 'primary' : 'secondary'}
            p={12}
            m={1}
            width={16}
            height={16}
            borderRadius="100%"
            onClick={() => handleCurrentIndex(index)}
          >
            {index + 1}
          </Button>
        )
      )}
    </Flex>
  );
};

TablePagination.defaultProps = {
  take: 10,
};

export default TablePagination;
