import mParticle from '@mparticle/web-sdk';
import { createSlice } from '@reduxjs/toolkit';

export enum AuthStatus {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

interface AuthState {
  status: AuthStatus;
}

const initialState: AuthState = {
  status: AuthStatus.LOADING,
};

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.status = AuthStatus.LOADING;
      mParticle.Identity.logout();
    },
    login: (state) => {
      state.status = AuthStatus.LOGGED_IN;
    },
    logOut: (state) => {
      state.status = AuthStatus.LOGGED_OUT;
    },
  },
});

export const { setLoading, login, logOut } = userSlice.actions;

export default userSlice.reducer;
