import {
  ApolloClient,
  ApolloProvider as Provider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import mParticle from '@mparticle/web-sdk';
import * as Sentry from '@sentry/nextjs';
import { SentryLink } from 'apollo-link-sentry';
import { Kind } from 'graphql';
import Router, { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { logOut } from 'src/redux/auth/authSlice';
import { resetUser } from 'src/redux/user/userSlice';
import { logMparticleEvent } from 'src/utils/mparticle';

import { apollo } from '../../config/apolloClient';
import { GRAPHQL_API, TYB_TOKEN_KEY, USE_HEADERS } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import splitRoute from '../../utils/splitRoute';
import { errorToast } from '../../utils/toasts';

const ApolloProvider = ({ children }: { children: ReactElement }) => {
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const nextRouter = useRouter();
  const dispatch = useAppDispatch();

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const useHeaders = USE_HEADERS;

    const httpLink = createHttpLink({
      credentials: !useHeaders ? 'include' : undefined,
      uri: (operation) => {
        for (const definition of operation.query.definitions) {
          if (definition.kind === Kind.OPERATION_DEFINITION) {
            for (const selection of definition.selectionSet.selections) {
              if (selection.kind === Kind.FIELD) {
                return `${GRAPHQL_API}/${definition.operation}/${selection.name.value}`;
              }
            }
          }
        }
        return GRAPHQL_API;
      },
    });

    const authLink = setContext(async (_, { headers }) => {
      const authToken = localStorage.getItem('tybToken');

      if (useHeaders && authToken) {
        headers = {
          ...headers,
          authorization: `Bearer ${authToken}`,
        };
      }

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
        },
      };
    });

    const withBrandLink = setContext(async (_, { headers }) => {
      if (Router.query['uuid'] || Router.query['brandUuid']) {
        const brandUuid = Router.query['uuid'] || Router.query['brandUuid'];

        return {
          headers: {
            ...headers,
            'x-brand-uuid': brandUuid as string,
          },
        };
      }

      return {
        headers,
      };
    });

    const errorLink = onError((error) => {
      const { operation, graphQLErrors: preFilteredGraphQLErrors, networkError, response } = error;
      const nonValidationGraphQLErrors = (preFilteredGraphQLErrors ?? []).filter((graphQLError) => {
        try {
          const statusCode = Number(graphQLError.extensions.response.statusCode);
          if (graphQLError.extensions.response.statusCode && statusCode >= 400 && statusCode < 500) {
            if (statusCode === 401 || statusCode === 403) {
              dispatch(resetUser());
              dispatch(logOut());
              localStorage.removeItem(TYB_TOKEN_KEY);
            }

            // true: if we want to retry we return false, but in some cases where a retry
            // will not solve the problem the apollo server will keep in loop forever, we
            // have to implement a way to retry x times
            return true;
          }
        } catch {
          //
        }
        return true;
      });
      if (networkError || nonValidationGraphQLErrors.length > 0) {
        Sentry.withScope((scope) => {
          scope.setTransactionName(operation.operationName);
          scope.setContext('Apollo Error Response', {
            operation,
            graphQLErrors: nonValidationGraphQLErrors,
            networkError,
            response,
          });
          Sentry.captureMessage(`GraphQL Error`, {
            level: Sentry.Severity.Error,
            fingerprint: ['{{ default }}', '{{ transaction }}', operation.operationName],
          });
        });
        if (nonValidationGraphQLErrors.length > 0) {
          nonValidationGraphQLErrors.map((error) => {
            logMparticleEvent('error_message', mParticle.EventType.Other, {
              message: error.message,
              ...splitRoute(nextRouter.asPath),
              auth_status: authStatus.toLowerCase(),
              is_apollo_error: true,
            });
          });
        }
      }
      if (preFilteredGraphQLErrors && preFilteredGraphQLErrors.length > 0) {
        preFilteredGraphQLErrors.forEach((error) => {
          if (!error.extensions?.exception?.response?.supressToast) {
            const message = error.message || 'Something went wrong';

            if (error.extensions?.exception?.response?.isCustomInternalErrorMessage) {
              toast.info(message, {
                autoClose: false,
              });
            } else {
              errorToast(message);
            }
          }
        });
      }
    });

    const sentryLink = new SentryLink({
      setTransaction: false,
      setFingerprint: false,
      attachBreadcrumbs: {
        includeError: true,
        includeFetchResult: true,
        includeQuery: true,
        includeContext: ['headers'],
      },
    });

    apollo.client = new ApolloClient({
      link: errorLink.concat(sentryLink).concat(authLink).concat(withBrandLink).concat(httpLink),
      cache: new InMemoryCache(),
    });

    setClient(apollo.client);
  }, []);

  return client && <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
