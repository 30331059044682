/* eslint-disable @typescript-eslint/no-explicit-any */
import { ethers } from 'ethers';

import { BaseContract, StandardTypes } from './base';
import { ParsedEtherEvent } from './types';

type AddressTokenBalance = {
  tx?: string;
  blockNumber?: number;
  tokenId: string;
  balance: number;
  totalSupply?: number;
};

const EventTypeArray = ['TransferSingle', 'TransferBatch', 'ApprovalForAll', 'URI'] as const;
export type ERC1155EventType = (typeof EventTypeArray)[number];

export type ERC1155EventListener<T> = (event: ethers.Event, args: T) => void;
export type ERC1155EventTypeArgs = {
  ['TransferSingle']: {
    operator: string;
    from: string;
    to: string;
    tokenId: string;
    amount: number;
  };
  ['TransferBatch']: {
    operator: string;
    from: string;
    to: string;
    tokenId: string[];
    amount: number[];
  };
  ['ApprovalForAll']: {
    account: string;
    operator: string;
    approved: boolean;
  };
  ['URI']: {
    value: string;
    id: string;
  };
};

export class ContractERC1155 extends BaseContract {
  standardName = StandardTypes.ERC1155;

  public on<R = ERC1155EventTypeArgs>(eventType: ERC1155EventType, args: any[], listener: ERC1155EventListener<R>) {
    const eventFilters: ethers.EventFilter[] = [];
    if (args.length > 0 && Array.isArray(args[0])) {
      eventFilters.push(...args.map((eventArgs) => this.contract.filters[eventType as any](...eventArgs)));
    } else {
      eventFilters.push(this.contract.filters[eventType as any](...args));
    }

    console.debug(
      '[ContractManager] Listening to event ',
      eventType,
      ' with args ',
      args,
      ' on ContractERC1155: ',
      this.contractAddress
    );

    eventFilters.forEach((eventFilter) => {
      this.addEventListener(eventFilter, (...args: any[]) => {
        const event = args[args.length - 1] as ethers.Event;
        const type = event.event as ERC1155EventType;

        switch (type) {
          case 'TransferSingle':
            return listener(event, {
              operator: args[0],
              from: args[1],
              to: args[2],
              tokenId: args[3].toNumber().toString(),
              amount: args[4].toNumber(),
            } as any);
          case 'TransferBatch':
            return listener(event, {
              operator: args[0],
              from: args[1],
              to: args[2],
              tokenId: args[3].map((v) => v.toNumber().toString()),
              amount: args[4].map((v) => v.toNumber()),
            } as any);
          case 'ApprovalForAll':
            return listener(event, {
              account: args[0],
              operator: args[1],
              approved: args[2],
            } as any);
          case 'URI':
            return listener(event, {
              value: args[0],
              id: args[1].toNumber(),
            } as any);
        }
      });
    });
  }

  public async getEventToken(address: string, event: ParsedEtherEvent) {
    // Get token balance
    const balance = (await this.getTokenBalance(address, event.tokenId)) as AddressTokenBalance;
    return this.parseToken(event.tokenId, event.txHash, event.blockNumber, {
      balance: balance.balance,
      totalSupply: balance.totalSupply,
    });
  }

  private async getTokenBalance(
    address: string,
    tokenId: string
  ): Promise<AddressTokenBalance[] | AddressTokenBalance> {
    const balance = await this.contract.balanceOf(address, tokenId);

    return {
      tokenId: tokenId as string,
      balance: Number(ethers.BigNumber.from(balance).toString()),
    };

    // TODO: We need to index transaction in real time to properly get the current balance
    // if (transactions.length > 0) {
    //   const balance = transactions
    //     .filter((v) => v.payload.tokenId === tokenId)
    //     .reduce((prev, cur) => {
    //       if (cur.to === address) {
    //         prev += Number(cur.payload.quantity);
    //       } else {
    //         prev -= Number(cur.payload.quantity);
    //       }

    //       return prev;
    //     }, initialBalance || 0);

    //   return {
    //     tokenId: tokenId as string,
    //     balance,
    //   };
    // } else {
    //   const balance = await this.contract.balanceOf(address, tokenId);

    //   return {
    //     tokenId: tokenId as string,
    //     balance: Number(ethers.BigNumber.from(balance).toString()),
    //   };
    // }
  }
}
