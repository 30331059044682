import React, { InputHTMLAttributes } from 'react';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Checkbox = React.forwardRef<HTMLElement, CheckboxProps>(({ name, ...props }, ref) => {
  return (
    <Box
      __css={{
        cursor: 'pointer',
        'input:disabled': {
          opacity: 0.5,
        },
      }}
    >
      <Box
        as="input"
        type="checkbox"
        ref={ref}
        name={name}
        data-testid="checkbox"
        __css={{
          opacity: 0,
          position: 'absolute',
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
        {...props}
      />
      <Box
        borderRadius="sm"
        borderStyle="solid"
        borderWidth="2px"
        borderColor="black"
        width="20px"
        height="20px"
        mr="2"
        bg="white"
        __css={{
          cursor: 'pointer',
          'input:checked ~ &': {
            bg: 'black',
          },
        }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          __css={{
            'input:checked ~ &': {
              display: 'block',
            },
          }}
        >
          <Icon.BoxiconsRegular.Check size={16} color="white" />
        </Flex>
      </Box>
    </Box>
  );
});

export default Checkbox;
