import { gql } from '@apollo/client';

export interface IReadNotificationsVars {
  notificationUuids: string[];
}

export const READ_NOTIFICATIONS = gql`
  mutation ReadUserNotifications($notificationUuids: [String!]!) {
    readUserNotifications(notificationUuids: $notificationUuids)
  }
`;
