import { SsoGoogle } from '@tyb-browser/lib/components/ssoButtons/Google';
import { Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useMemo } from 'react';
import { magic } from 'src/config/magic';

const SsoButtons = (): JSX.Element => {
  const redirectUri = useMemo(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('oauthredirect', '1');
    return url.toString();
  }, [window.location.href]);

  const beginGoogleOAuthFlow = useCallback(async () => {
    await magic.oauth.loginWithRedirect({
      provider: 'google',
      redirectURI: redirectUri,
    });
  }, [redirectUri]);

  return <SsoGoogle provider="Google" label={<Text>Sign in with Google</Text>} onClick={beginGoogleOAuthFlow} />;
};

export { SsoButtons };
