export const onlyNumbers = (s: string) => {
  return s.replace(/\D/g, '');
};

export const getRootElementFontSize = () => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const convertRemToPx = (value: string | number) => {
  if (typeof value === 'string') {
    return parseInt(onlyNumbers(value)) * getRootElementFontSize();
  }
  return value * getRootElementFontSize();
};
