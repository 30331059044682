import { useQuery } from '@apollo/client';
import { Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useMemo } from 'react';
import {
  REDEEM_ECOMM_REDEMPTION_CHECK_SUBSCRIPTIONS,
  RedeemEcommRedemptionCheckSubscriptionsData,
  RedeemEcommRedemptionCheckSubscriptionsVars,
} from 'src/graphql/mutations/redeemEcommRedemptionCheckSubscriptions';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import CollectibleAmount from 'src/modals/CollectibleViewModal/components/CollectibleAmount';
import { CoinRedemptionType, ICollectible } from 'src/types';

export interface CoinRedemptionDetailsNextStepParams {
  applyTo: CoinRedemptionType;
  applicableSubscription?: RedeemEcommRedemptionCheckSubscriptionsData;
}

interface CoinRedemptionDetailsProps {
  collectible: ICollectible;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  onNextStep: ({ applyTo, applicableSubscription }: CoinRedemptionDetailsNextStepParams) => void;
}

const CoinRedemptionDetails: React.FC<CoinRedemptionDetailsProps> = ({
  collectible,
  ecommBrandRedemption,
  onNextStep,
}) => {
  const { loading: checkApplicableSubscriptionsLoading, data: checkApplicableSubscriptionsData } = useQuery<
    RedeemEcommRedemptionCheckSubscriptionsData,
    RedeemEcommRedemptionCheckSubscriptionsVars
  >(REDEEM_ECOMM_REDEMPTION_CHECK_SUBSCRIPTIONS, {
    variables: {
      ecommBrandRedemptionUuid: ecommBrandRedemption?.uuid,
    },
    fetchPolicy: 'no-cache',
  });

  const handleNextStep = (applyTo: CoinRedemptionType) => {
    const data: CoinRedemptionDetailsNextStepParams =
      applyTo === 'checkout'
        ? {
            applyTo,
          }
        : {
            applyTo,
            applicableSubscription: checkApplicableSubscriptionsData,
          };

    onNextStep(data);
  };

  const nextSubscriptionCharge = useMemo(
    () => checkApplicableSubscriptionsData?.redeemEcommRedemptionCheckSubscriptions?.nextCharge,
    [checkApplicableSubscriptionsData?.redeemEcommRedemptionCheckSubscriptions?.nextCharge]
  );

  return (
    <Flex flexDirection="column" flex={1} justifyContent="space-between" __css={{ gap: '20px' }}>
      <Flex flexDirection="column" __css={{ gap: '10px' }}>
        <Text variant="text1" color="neutrals-900">
          Redeem your coins
        </Text>
        <Text color="neutrals-900">
          Redeem your coins for a coupon code that you can use at checkout, or automatically apply a discount to your
          next subscription charge.
        </Text>
      </Flex>
      <CollectibleAmount
        collectible={collectible}
        ecommBrandRedemption={ecommBrandRedemption}
        isLoading={checkApplicableSubscriptionsLoading}
      >
        <Flex flexDirection={['column', 'row']} width="100%" justifyContent="center" __css={{ gap: '15px' }}>
          <Button variant="secondary" width={['100%', '330px']} onClick={() => handleNextStep('checkout')}>
            <Text variant="text4-600">Redeem for coupon code</Text>
          </Button>
          {nextSubscriptionCharge && (
            <Button variant="secondary" width={['100%', '330px']} onClick={() => handleNextStep('subscription')}>
              <Text variant="text4-600">Apply to subscription</Text>
            </Button>
          )}
        </Flex>
      </CollectibleAmount>
    </Flex>
  );
};

export default CoinRedemptionDetails;
