import { Flex, Icon } from '@tyb-u/tyb-ui-components';
import React, { useEffect, useState } from 'react';

import ImageCDN from '../ImageCDN';

interface UserAvatarProps {
  avatarUrl: string | null;
  size?: number | string[];
  borderRadius?: number;
  css?: Record<string, unknown>;
  resizeTo?: 'thumbnail' | 'sm' | 'md' | 'lg' | 'original';
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  avatarUrl,
  size = 24,
  borderRadius = 24,
  resizeTo = 'thumbnail',
  ...rest
}) => {
  const baseSize = Array.isArray(size) ? Number(size[0]) : size;
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(false);
  }, [avatarUrl]);

  if (avatarUrl && !error) {
    return (
      <ImageCDN
        data-testid="user-avatar-row"
        width={size}
        height={!Array.isArray(size) ? size : 'unset'}
        minHeight={Array.isArray(size) ? size : 'unset'}
        variant="avatar"
        src={avatarUrl}
        onError={() => setError(true)}
        style={{
          aspectRatio: '1/1',
          objectFit: 'cover',
        }}
        resizeTo={resizeTo}
        {...rest}
      />
    );
  }

  return (
    <Flex
      data-testid="user-avatar-row"
      width={size}
      height={!Array.isArray(size) ? size : 'unset'}
      minHeight={Array.isArray(size) ? size : 'unset'}
      justifyContent="center"
      alignItems="center"
      bg="gray02"
      borderRadius={`${borderRadius}px`}
      color="white"
    >
      <Icon.BoxiconsRegular.User size={(2 / 3) * baseSize} />
    </Flex>
  );
};

export default UserAvatar;
