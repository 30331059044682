import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISessionUser } from 'src/interface/ISessionUser';

import { IBrand } from '../../interface/IBrand';
import { IMagicLinkUser } from '../../interface/IMagicLinkUser';
import { IUser } from '../../interface/IUser';
import { IUserWallet } from '../../interface/IUserWallet';
import { IWallletActivity } from '../../interface/IWallletActivity';
import { ICollection, INotification } from '../../types';

export interface UserState extends IUser {
  sub: string | null;
  emailVerified: boolean;
  phoneNumberVerified: boolean;
  reps: number;
  managedBrands: IBrand[];
  activeBrands: IBrand[];
  publicAddress: string;
  relatedContracts?: ICollection[];
  notificationCount?: number;
  notifications?: INotification[];
  userWallet: IUserWallet;
  activity?: IWallletActivity[];
  magicLinkUsers: IMagicLinkUser[];
  pendingPermitSignatures?: ISessionUser['pendingPermitSignatures'];
  sessionDateTime?: number;
}

const initialState: UserState = {
  id: null,
  email: null,
  phoneNumber: null,
  userName: null,
  firstName: null,
  lastName: null,
  avatarUrl: null,
  bio: null,
  urlSlug: null,
  birthdate: null,
  cChainPublicAddress: null,
  instagramUrl: null,
  twitterUrl: null,
  tiktokUrl: null,

  sub: null,
  emailVerified: false,
  phoneNumberVerified: false,
  reps: 0,
  managedBrands: [],
  activeBrands: [],
  publicAddress: null,
  relatedContracts: [],
  notificationCount: 0,
  notifications: [],
  userWallet: null,
  activity: null,
  magicLinkUsers: [],
  pendingPermitSignatures: [],
  sessionDateTime: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      if (state.activity && !action.payload.activity) {
        action.payload.activity = state.activity;
      }

      action.payload.sessionDateTime = new Date().getTime();

      return action.payload;
    },
    setWallet: (state, action: PayloadAction<IUserWallet>) => {
      state.userWallet = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload;
    },
    setBio: (state, action: PayloadAction<string>) => {
      state.bio = action.payload;
    },
    setBirthDate: (state, action: PayloadAction<Date>) => {
      state.birthdate = action.payload;
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload;
    },
    setNotifications: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.emailVerified = action.payload;
    },
    setActivity: (state, action: PayloadAction<IWallletActivity[]>) => {
      state.activity = action.payload;
    },
    updateActivity: (state, action: PayloadAction<IWallletActivity>) => {
      if (state.activity) {
        const index = state.activity.findIndex((v) => v.uuid === action.payload.uuid);
        if (index === -1) {
          state.activity.unshift(action.payload);
        } else {
          state.activity[index] = action.payload;
        }
      }
    },
    removePendingPermitSignature: (state, action: PayloadAction<string>) => {
      state.pendingPermitSignatures = state.pendingPermitSignatures.filter((v) => v.id !== action.payload);
    },
    resetUser: () => {
      return initialState;
    },
  },
});

export const {
  setUser,
  setWallet,
  resetUser,
  setUsername,
  setEmail,
  setAvatar,
  setBio,
  setBirthDate,
  setNotificationCount,
  setNotifications,
  setEmailVerified,
  setActivity,
  updateActivity,
  removePendingPermitSignature,
} = userSlice.actions;

export default userSlice.reducer;
