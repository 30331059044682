import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Props } from 'react-modal';

interface IModal {
  key: string;
  Component: React.ReactNode;
  props?: Props;
}

interface ModalState {
  modals: IModal[];
}

const initialState: ModalState = {
  modals: [],
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    stackModal: (state, action: PayloadAction<IModal>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.modals = [...state.modals, action.payload];
    },
    closeModal: (state, action: PayloadAction<{ key: string }>) => {
      state.modals = [...state.modals.filter((v) => v.key != action.payload.key)];
    },
  },
});

export const { stackModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
