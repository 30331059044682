import { gql } from '@apollo/client';

export interface SaveUserCollectibleSettingsVars {
  item: {
    contractAddress: string;
    tokenId: string;
    visibility: boolean;
  };
}

export interface SaveUserCollectibleSettingsData {
  saveCollectibleSettings: {
    contractAddress: string;
    tokenId: string;
    visibility: boolean;
  };
}

export const SAVE_USER_COLLECTIBLE_SETTINGS = gql`
  mutation SaveCollectibleSettings($item: SaveCollectibleSettingInput!) {
    saveCollectibleSettings(item: $item) {
      contractAddress
      tokenId
      visibility
    }
  }
`;
