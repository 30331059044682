import mParticle from '@mparticle/web-sdk';
import { Flex, Icon } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import useAuth from '../../hooks/useAuth';
import DropDown from '../DropDown';

interface MenuDropdownProps {
  children: ReactElement;
  position?: 'above' | 'bellow';
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({ children, position = 'bellow' }) => {
  const router = useRouter();
  const auth = useAuth();

  return (
    <DropDown
      position={position}
      items={[
        {
          icon: <Icon.BoxiconsRegular.UserCircle size={16} />,
          title: 'Personal info',
          onClick: () => router.push('/settings'),
        },
        {
          icon: <Icon.BoxiconsRegular.Cog size={16} />,
          title: 'Notification settings',
          onClick: () => router.push('/settings/notifications'),
        },
        {
          icon: <Icon.BoxiconsRegular.HelpCircle size={16} />,
          title: 'Help',
          onClick: () => {
            logMparticleEvent('page_view_support', mParticle.EventType.Other);
            window.open(
              'https://tryyourbest.notion.site/b193b090869443e29bcd2d9cd1b76e49?v=be04a04f638345f1ae28cf5c1e8a7fa2&pvs=4',
              '_blank'
            );
          },
        },
        {
          icon: <Icon.BoxiconsRegular.LogOut size={16} />,
          title: 'Logout',
          onClick: () => auth.logout(),
        },
      ]}
    >
      <Flex alignItems="center" __css={{ cursor: 'pointer' }}>
        {children}
      </Flex>
    </DropDown>
  );
};

export default MenuDropdown;
