import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import { AuthStatus } from '../redux/auth/authSlice';
import { useAppSelector } from '../redux/hooks';

const withoutAuth = (Component: NextPage) => {
  const Auth = (props: JSX.IntrinsicAttributes) => {
    const authStatus = useAppSelector(({ auth }) => auth.status);
    const router = useRouter();

    if (authStatus == AuthStatus.LOGGED_IN) {
      router.push('/');
      return <></>;
    }

    return <Component {...props} />;
  };

  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
};

export default withoutAuth;
