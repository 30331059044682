import { ERC721GeneralTokenId, ERC721MembershipGeneralTokenId, ERC721RareGeneralTokenId } from '../constants';
import { ICollectibleExtraMetadata, ICollectibleMetadata, ICollectionTypes } from '../types';

export function getCollectibleMetadata(options: {
  metadata: ICollectibleMetadata | ICollectibleExtraMetadata;
  collectibleExtraMetadatas: ICollectibleExtraMetadata[];
  contractAddress: string;
  type?: ICollectionTypes;
  tokenId?: string;
}): ICollectibleMetadata | ICollectibleExtraMetadata {
  const { metadata, collectibleExtraMetadatas, contractAddress, type } = options;

  let { tokenId } = options;

  const isErc721 = ['ERC721', 'ERC721Membership', 'ERC721Rare'].includes(type);

  if (type == 'ERC721') {
    tokenId = ERC721GeneralTokenId;
  } else if (type == 'ERC721Rare') {
    tokenId = ERC721RareGeneralTokenId;
  } else if (type == 'ERC721Membership') {
    tokenId = ERC721MembershipGeneralTokenId;
  }

  if (!tokenId && !isErc721) {
    return metadata; // https://tyb-llc.atlassian.net/browse/WRLD-1233
  }

  if (tokenId) {
    const extraMetadata = collectibleExtraMetadatas.find(
      (em) => em?.contractAddress === contractAddress && em?.tokenId === tokenId
    );

    if (extraMetadata) {
      return extraMetadata;
    }
  }
  return metadata;
}
