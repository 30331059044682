import { ethers } from 'ethers';
import { EventEmitter } from 'events';

export class ContractManagerProvider extends EventEmitter {
  private _provider: ethers.providers.WebSocketProvider;
  private _inactive: boolean;

  private _url: string;
  private _network: ethers.providers.Networkish;

  private _blockNumber: number;

  constructor(url: string, network?: ethers.providers.Networkish) {
    super();

    this._url = url;
    this._network = network;
    this._inactive = false;

    this._provider = new ethers.providers.WebSocketProvider(url, network);
    this.setup();

    // Handle reconnection on socket disconnect
    this._provider._websocket.onclose = (ev: CloseEvent) => {
      console.debug('[ContractManager] WebSocket connection closed. Type: ', ev.type);
      setTimeout(this.reconnect.bind(this), 3000);
    };

    this._provider.on('debug', (ev) => {
      if (ev.action === 'response' && ev.request.method === 'eth_chainId') {
        this._inactive = false;
      }
    });

    // Handle page visibility change
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this._inactive = true;
      }

      if (document.visibilityState === 'visible' && this._inactive) {
        this._provider.send('eth_chainId');

        setTimeout(() => {
          if (this._inactive) {
            this.reconnect();
          }
        }, 3000);
      }
    });
  }

  public async reconnect() {
    console.debug('[ContractManager] WebSocket reconnecting...');
    await this._provider.destroy();

    this._provider = new ethers.providers.WebSocketProvider(this._url, this._network);
    this.setup();
    this.emit('change', this._provider);
  }

  public get provider() {
    return this._provider;
  }

  public get blockNumber() {
    return this._blockNumber;
  }

  private async setup() {
    this._blockNumber = await this._provider.getBlockNumber();
    this._provider.on('block', (blockNumber) => {
      this._blockNumber = blockNumber;
    });
  }
}
