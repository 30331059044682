import { useMutation } from '@apollo/client';
import mParticle from '@mparticle/web-sdk';
import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useMemo } from 'react';
import CollectiblePreview from 'src/components/CollectiblePreview';
import {
  REDEEM_ECOMM_REDEMPTION,
  RedeemEcommRedemptionData,
  RedeemEcommRedemptionVars,
} from 'src/graphql/mutations/RedeemEcommRedemption';
import { ICollectionInfoData } from 'src/graphql/queries/collectionInfo';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { IUser } from 'src/interface/IUser';
import { ICollectible } from 'src/types';
import { logMparticleEvent } from 'src/utils/mparticle';

type CollectibleRedemptionDetailsProps = {
  collectible: ICollectible;
  collectionInfoData: ICollectionInfoData;
  user: IUser;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  brandName: string;
  brandWebsiteURL?: string;
  isMobile: boolean;
  onNextStep: (redeemEcommRedemptionData: RedeemEcommRedemptionData) => void;
};

const CollectibleRedemptionDetails: React.FC<CollectibleRedemptionDetailsProps> = ({
  collectible,
  collectionInfoData,
  user,
  ecommBrandRedemption,
  brandName,
  brandWebsiteURL,
  isMobile,
  onNextStep,
}) => {
  const [redeemEcommRedemption, { loading: redeemEcommRedemptionLoading }] = useMutation<
    RedeemEcommRedemptionData,
    RedeemEcommRedemptionVars
  >(REDEEM_ECOMM_REDEMPTION);

  const collectibleRedemptionValue = useMemo(() => {
    const suffix = ecommBrandRedemption?.effect?.method === 'FLAT_AMOUNT' ? ' USD' : '%';
    return `${ecommBrandRedemption?.effect?.value}${suffix}`;
  }, [ecommBrandRedemption?.effect]);

  const storeText = brandWebsiteURL ? (
    <span>
      at
      <a style={{ cursor: 'pointer', textDecoration: 'underline' }} href={`https://${brandWebsiteURL}`}>
        {brandWebsiteURL}
      </a>
    </span>
  ) : (
    <span>in {brandName} online store</span>
  );

  const redemptionInfo = useMemo(
    () => (
      <Text color="neutrals-900">
        Redeem your collectible for a <span style={{ fontWeight: 600 }}>{collectibleRedemptionValue} discount</span>{' '}
        {storeText}. Note that once you generate the discount code, you will have{' '}
        <span style={{ fontWeight: 600 }}>24 hours</span> to use it.
      </Text>
    ),
    [brandWebsiteURL, brandName, collectibleRedemptionValue]
  );

  const stepLabel = useMemo(
    () => (redeemEcommRedemptionLoading ? 'Generating the code' : 'Continue'),
    [redeemEcommRedemptionLoading]
  );

  const handleContinue = async () => {
    try {
      const amount = 1;

      logMparticleEvent('confirm_redeeming_collectible', mParticle.EventType.Other, {
        user_id: user.id,
        user_email: user.email,
        user_name: user.userName,
        wallet_address: user.cChainPublicAddress,
        collectible_name: collectible.metadata.name,
        collectible_type: collectible.type,
        redemption_type: ecommBrandRedemption.type,
        amount,
        brand_name: collectionInfoData?.collectionInfo?.brand?.name,
      });

      const { data } = await redeemEcommRedemption({
        variables: {
          quantity: amount,
          uuid: ecommBrandRedemption.uuid,
          applyTo: 'checkout',
        },
      });

      onNextStep(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Flex flexDirection="column" flex={1} __css={{ gap: '10px' }}>
        <Flex flexDirection="column" __css={{ gap: '10px' }}>
          <Text variant="text1" color="neutrals-900">
            Redeem your collectible
          </Text>
          {redemptionInfo}
        </Flex>
        <Flex flex={1} flexDirection="column" justifyContent="center" __css={{ gap: '10px' }}>
          <Flex flexDirection="column" alignItems="center" justifyContent="center" __css={{ gap: '20px' }}>
            <Box maxWidth="300px">
              <CollectiblePreview collectible={collectible} width="170px" height="170px" />
            </Box>
            {redeemEcommRedemptionLoading ? (
              <Text variant="text4-400" textAlign="center" width="300px">
                We’re generating your discount code! Please wait a moment.
              </Text>
            ) : (
              <Text textAlign="center" variant="text3-400">
                {collectible.metadata.name}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        {!isMobile && (
          <Text variant="text4-400" color="neutrals-900">
            1 / 2 - {stepLabel}
          </Text>
        )}

        <Button
          onClick={() => handleContinue()}
          disabled={redeemEcommRedemptionLoading}
          style={isMobile ? { flex: 1, padding: '15px', fontSize: '16px', fontWeight: 600 } : null}
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default CollectibleRedemptionDetails;
