import 'react-image-crop/dist/ReactCrop.css';

import { Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import React, { useCallback, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { IMAGE_MIME_TYPES } from 'src/constants';
import { getSupportedImageMimeTypes } from 'src/utils/file';
import { useTheme } from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from '../../utils';
import FileUploader, { FileUploaderRef } from '../FileUploader/FileUploader';
import CenteredModal from '../Modals/Centered';

const CROP_IMAGE_MINIMUM_SIZE = 30;

const ImageCanvasPreview = React.forwardRef((props: any, ref: React.Ref<HTMLCanvasElement>) => (
  <canvas
    ref={ref}
    style={{
      width: props.isMobile ? 128 : 230,
      height: props.isMobile ? 128 : 230,
      borderRadius: props.isMobile ? 128 : 230 / 2,
      marginTop: 8,
      marginBottom: 8,
    }}
  />
));

interface ImageCropper {
  src: string;
  /**
   * @description Maximum file size in megabytes
   */
  maxFileSize?: number;
  onChange(src: string): void;
  onChangeLoading?(loading: boolean): void;
}

const ImageCropper: React.FC<ImageCropper> = ({ src, maxFileSize, onChange, onChangeLoading = () => null }) => {
  const [cropImage, setCropImage] = useState<string>();
  const [crop, setCrop] = useState<Partial<Crop>>();
  const [isCropped, setIsCropped] = useState(false);
  const [error, setError] = useState<string>();

  const fileUploaderRef = useRef<FileUploaderRef>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef(null);

  const size = useWindowSize();
  const theme = useTheme();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const handleFileChange = useCallback((file: File) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      setCropImage(url);
      setIsCropped(false);
      setCrop({ width: img.width, aspect: 1 });
    };
    img.src = url;
  }, []);

  const handleCropChange = useCallback((crop) => {
    const hasInvalidCropArea =
      !crop?.width || crop?.width < CROP_IMAGE_MINIMUM_SIZE || !crop?.height || crop?.height < CROP_IMAGE_MINIMUM_SIZE;

    if (hasInvalidCropArea) {
      return;
    }

    if (crop.width !== crop.height) {
      crop.width = crop.height;
    }

    setCrop(crop);
    drawImage(crop);
  }, []);

  const handleCropSave = useCallback(() => {
    if (previewCanvasRef?.current) {
      setIsCropped(true);
      setCrop(null);
      setCropImage(null);

      onChangeLoading(true);
      previewCanvasRef.current.toBlob(async (blob) => {
        if (fileUploaderRef?.current) {
          const file = new File([blob], 'avatar.png', { type: 'image/png' });

          fileUploaderRef.current.setFile(file);
          const url = await fileUploaderRef.current.upload(file);

          onChangeLoading(false);
          onChange(decodeURIComponent(url));
        }
      });
    }
  }, [previewCanvasRef]);

  const handleCropCancel = useCallback(() => {
    setIsCropped(true);
    setCrop(undefined);

    if (fileUploaderRef) {
      fileUploaderRef.current.setFile(null);
    }
  }, []);

  const handleImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const drawImage = useCallback(
    (_crop: Crop) => {
      if (isCropped || !previewCanvasRef?.current || !imgRef?.current) {
        return;
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      // const pixelRatio = window.devicePixelRatio;
      const pixelRatio = 0.5;

      canvas.width = _crop.width * pixelRatio * scaleX;
      canvas.height = _crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        _crop.x * scaleX,
        _crop.y * scaleY,
        _crop.width * scaleX,
        _crop.height * scaleY,
        0,
        0,
        _crop.width * scaleX,
        _crop.height * scaleY
      );
    },
    [isCropped]
  );

  const supportedMimeTypes = getSupportedImageMimeTypes({ exclude: [IMAGE_MIME_TYPES.GIF] });

  return (
    <Flex width="100%">
      {!isCropped && cropImage && (
        <CenteredModal
          isOpen={true}
          style={
            isMobile
              ? {
                  content: {
                    width: '100%',
                    top: '0px',
                    left: '0px',
                  },
                }
              : undefined
          }
          body={
            <Flex flexDirection={['column', 'row']} justifyContent="space-between">
              <ReactCrop
                src={cropImage}
                onImageLoaded={handleImageLoaded}
                crop={crop}
                onChange={handleCropChange}
                circularCrop
                style={{
                  width: !isMobile ? 256 : undefined,
                  maxHeight: isMobile ? '450px' : undefined,
                }}
              />

              <Flex flexDirection="column" minWidth="256px" alignItems="center" justifyContent="space-between">
                <ImageCanvasPreview ref={previewCanvasRef} isMobile={isMobile} />

                <Flex flexDirection="row" justifyContent="end">
                  <Button variant="secondary" mr="2" onClick={handleCropCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleCropSave}>
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          }
        />
      )}

      {error && (
        <Text color="red" mb="20px">
          {error}
        </Text>
      )}
      <FileUploader
        ref={fileUploaderRef}
        placeholderImage={src}
        folder="profile_pictures"
        accept={supportedMimeTypes}
        width="100%"
        hide={!isCropped && !!cropImage}
        maxFileSize={maxFileSize ?? 5}
        onFileChange={handleFileChange}
        onChange={(status, file, uploadedUrl, uploadProgress, _, error) => {
          if (error) {
            setError(error);
          } else {
            setError(null);
          }
        }}
        withBorderRadius
      />
    </Flex>
  );
};

export default ImageCropper;
