import css from '@styled-system/css';
import { AllHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  border,
  BorderProps,
  BoxShadowProps,
  color,
  ColorProps,
  display,
  DisplayProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

type BaseProps = AllHTMLAttributes<HTMLElement> &
  ColorProps &
  BorderProps &
  SpaceProps &
  SizeProps &
  LayoutProps &
  PositionProps &
  BoxShadowProps &
  DisplayProps &
  TypographyProps;

export type BoxProps = Omit<BaseProps, 'width'> & {
  as?: string;
  elevation?: 'none' | 'low' | 'high';
  width?: string | number | (string | number)[];
  variant?: string;
  ref?: React.Ref<HTMLElement>;
  __css?: unknown;
};

const base = (props) => css(props.__css);

const Box: React.FC<BoxProps> = styled.div<BoxProps>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  ${base}
  ${variant({
    scale: 'variants',
    prop: 'variant',
  })}
  ${variant({
    prop: 'elevation',
    variants: {
      low: {
        boxShadow: 'low',
        borderRadius: 'sm',
      },
      high: {
        boxShadow: 'high',
        borderRadius: 'sm',
      },
    },
  })}
  ${color}
  ${border}
  ${space} 
  ${size} 
  ${layout}
  ${position}
  ${display}
  ${typography}
`;

Box.defaultProps = {
  elevation: 'none',
};

export default Box;
