import { Text } from '@tyb-u/tyb-ui-components';
import React from 'react';
import Modal, { Props } from 'react-modal';

Modal.setAppElement('#__next');

const centererModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface CenteredModalProps extends Props {
  title?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  style?: Modal.Styles;
}

const CenteredModal: React.FC<CenteredModalProps> = ({ title, body, footer, style, ...props }) => {
  return (
    <Modal style={{ ...centererModalStyle, ...style }} {...props}>
      {title && <Text fontWeight="bold">{title}</Text>}
      {body}
      {footer}
    </Modal>
  );
};

export default CenteredModal;
