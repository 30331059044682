import { Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import RedemptionCouponCode from 'src/components/Redemption/RedemptionCouponCode';
import RedemptionCouponInfo from 'src/components/Redemption/RedemptionCouponInfo';
import { RedeemEcommRedemptionCheckSubscriptionsData } from 'src/graphql/mutations/redeemEcommRedemptionCheckSubscriptions';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { CoinRedemptionType, RedemptionCoupon } from 'src/types';

interface CoinRedemptionCompleteProps {
  applyTo: CoinRedemptionType;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  applicableSubscription?: RedeemEcommRedemptionCheckSubscriptionsData;
  brandName: string;
  brandWebsiteURL: string;
  coupon: RedemptionCoupon;
  amount: number;
  isMobile: boolean;
  onNextStep: () => void;
}

const CoinRedemptionComplete: React.FC<CoinRedemptionCompleteProps> = ({
  applyTo,
  ecommBrandRedemption,
  applicableSubscription,
  brandName,
  brandWebsiteURL,
  coupon,
  amount,
  isMobile,
  onNextStep,
}) => (
  <>
    <Flex flexDirection="column" flex={1} __css={{ gap: '10px' }}>
      <RedemptionCouponInfo
        brandName={brandName}
        brandWebsiteURL={brandWebsiteURL}
        ecommBrandRedemption={ecommBrandRedemption}
        amount={amount}
        applicableSubscription={applicableSubscription}
      />

      {applyTo === 'checkout' && (
        <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" __css={{ gap: '10px' }}>
          <RedemptionCouponCode coupon={coupon} />
        </Flex>
      )}
    </Flex>
    <Flex justifyContent="space-between" alignItems="center">
      {!isMobile && (
        <Text variant="text4-400" color="neutrals-900">
          3 / 3 - Your code is ready
        </Text>
      )}

      <Button
        onClick={onNextStep}
        style={isMobile ? { width: '100%', padding: '15px', fontSize: '16px', fontWeight: 600 } : null}
      >
        Sounds good!
      </Button>
    </Flex>
  </>
);

export default CoinRedemptionComplete;
