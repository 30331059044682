import mParticle from '@mparticle/web-sdk';
import { Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import { clearURL } from '../../utils';

interface BioSocialProps {
  website?: string;
  twitter?: string;
  instagram?: string;
  tiktok?: string;
  brand?: {
    uuid: string;
    name: string;
  };
  minimalist?: boolean;
  origin: 'USER' | 'BRAND';
}

const navigableUrl = (url) => `https://${clearURL(url)}`;

const BioSocial: React.FC<BioSocialProps> = ({
  website,
  twitter,
  instagram,
  tiktok,
  brand,
  minimalist = true,
  origin,
}) => (
  <Flex flexDirection={minimalist ? 'row' : 'column'} mt="2" data-testid="social-icons">
    {website && (
      <Flex
        data-testid="bio-social-website"
        alignItems="center"
        __css={{ cursor: 'pointer' }}
        onClick={() => {
          if (origin == 'BRAND') {
            logMparticleEvent('brand_url_click', mParticle.EventType.Other, {
              brand_uuid: brand?.uuid,
              brand_name: brand?.name,
              url: navigableUrl(website),
            });
          }

          window.open(navigableUrl(website), '_blank');
        }}
      >
        <Icon.BoxiconsRegular.Globe size={22} />
        {!minimalist && <Text ml="1">{clearURL(website)}</Text>}
      </Flex>
    )}

    {twitter && (
      <Flex
        data-testid="bio-social-twitter"
        alignItems="center"
        __css={{ cursor: 'pointer' }}
        onClick={() => {
          if (origin == 'BRAND') {
            logMparticleEvent('brand_twitter_click', mParticle.EventType.Other, {
              brand_uuid: brand?.uuid,
              brand_name: brand?.name,
              url: navigableUrl(twitter),
            });
          }

          window.open(navigableUrl(twitter), '_blank');
        }}
      >
        <Icon.BoxiconsLogos.Twitter size={22} />
        {!minimalist && <Text>{clearURL(twitter)}</Text>}
      </Flex>
    )}

    {instagram && (
      <Flex
        data-testid="bio-social-instagram"
        alignItems="center"
        __css={{ cursor: 'pointer' }}
        onClick={() => {
          if (origin == 'BRAND') {
            logMparticleEvent('brand_instagram_click', mParticle.EventType.Other, {
              brand_uuid: brand?.uuid,
              brand_name: brand?.name,
              url: navigableUrl(instagram),
            });
          }

          window.open(navigableUrl(instagram), '_blank');
        }}
      >
        <Icon.BoxiconsLogos.Instagram size={22} />
        {!minimalist && <Text>{clearURL(instagram)}</Text>}
      </Flex>
    )}

    {tiktok && (
      <Flex
        data-testid="bio-social-tiktok"
        alignItems="center"
        __css={{ cursor: 'pointer' }}
        onClick={() => {
          if (origin == 'BRAND') {
            logMparticleEvent('brand_tiktok_click', mParticle.EventType.Other, {
              brand_uuid: brand?.uuid,
              brand_name: brand?.name,
              url: navigableUrl(tiktok),
            });
          }

          window.open(navigableUrl(tiktok), '_blank');
        }}
      >
        <Icon.BoxiconsLogos.Tiktok size={22} />
        {!minimalist && <Text>{clearURL(tiktok)}</Text>}
      </Flex>
    )}
  </Flex>
);

export default BioSocial;
