// import { useEffect } from 'react';

// import { AuthStatus } from '../../redux/auth/authSlice';
// import { useAppSelector } from '../../redux/hooks';

const FullStoryProvider = ({ children }) => {
  // const user = useAppSelector((state) => state.user);
  // const auth = useAppSelector((state) => state.auth);

  // useEffect(() => {
  //   if (!!window['FS'] && auth.status !== AuthStatus.LOADING) {
  //     const FS = window['FS'];

  //     if (user && !!user.sub) {
  //       FS.identify(user.sub, {
  //         displayName: user.userName,
  //         email: user.email,
  //       });
  //     } else {
  //       FS.anonymize();
  //     }
  //   }
  // }, [user, auth]);

  return children;
};

export default FullStoryProvider;
