import { theme as uiTheme } from '../design-system';
import { ITheme } from './theme.types';

const defaultTheme: ITheme = {
  ...uiTheme,
};

export const theme: ITheme = {
  ...defaultTheme,
};
