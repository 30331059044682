import { gql } from '@apollo/client';

import { ISessionUser } from '../../interface/ISessionUser';

export interface LoginVars {
  didToken: string;
}

export interface LoginData {
  loginWithMagic: ISessionUser;
}

export const LOGIN_WITH_MAGIC = gql`
  mutation loginWithMagic($didToken: String!) {
    loginWithMagic(didToken: $didToken) {
      id
      email
      isEmailVerified
      phoneNumber
      userName
      firstName
      lastName
      avatarUrl
      bio
      token
      urlSlug
      birthdate
      reps
      cChainPublicAddress
      unnotifiedNotificationsCount
      instagramUrl
      twitterUrl
      tiktokUrl
      managedBrands {
        id
        uuid
        name
        isLive
        avatarUrl
        heroImageUrl
        minParticipationAge
        description
        websiteUrl
        twitterUrl
        instagramUrl
        tiktokUrl
        logoUrl
        urlSlug
      }
      activeBrands {
        id
        uuid
        name
        isLive
        avatarUrl
        heroImageUrl
        minParticipationAge
        description
        websiteUrl
        twitterUrl
        instagramUrl
        tiktokUrl
        logoUrl
        urlSlug
      }
      userWallet {
        relatedContracts {
          type
          contractAddress
          collectibleExtraMetadatas {
            contractAddress
            tokenId
            description
            additionalDetails
          }
        }
        collectiblesSettings {
          contractAddress
          tokenId
          visibility
        }
      }
      magicLinkUsers {
        email
        phoneNumber
        mainAddress
      }
      pendingPermitSignatures {
        id
        contractAddress
        tokenId
        quantity
        brand {
          id
          name
          brandWallet {
            address
          }
        }
      }
    }
  }
`;
