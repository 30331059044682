import { cdnPath } from '@tyb-browser/lib/components/cdn/utils';
import { convertRemToPx } from '@tyb-browser/lib/utils';
import mimeTypes from 'mime-types';

import { EXPLORER_BASE_URL } from '../constants';
import {
  ICollectible,
  ICollectibleExtraMetadata,
  ICollectionTypes,
  IFundingProjectStatus,
  PeriodTypes,
  RepCardReward,
  RepcardStatusTypes,
  RepCardTypes,
} from '../types';
import { dayjs } from './dayjs';
import { getCollectibleMetadata } from './getCollectibleMetadata';

export { convertRemToPx };
export { cdnPath };

export const TIKTOK_REGEX = /(https?:\/\/(?:www\.)?tiktok\.com\/@([^/?#&]+)).*/;
export const INSTAGRAM_REGEX = /(https?:\/\/(?:www\.)?instagram\.com\/([^/?#&]+)).*/;
export const TWITTER_REGEX = /(https?:\/\/(?:www\.)?twitter\.com\/([^/?#&]+)).*/;

export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const onlyUrlFileName = (url: string): string => {
  return url.replace(/.*\//, '');
};

export const isTybEmployee = (email: string) => {
  if (!email) {
    return false;
  }
  const idx = email.indexOf('@tyb.');
  if (idx > -1) {
    return true;
  }
  return false;
};

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]+$/i;

export const timePeriodString = (time: number, period: PeriodTypes) => {
  let str = '';

  switch (period) {
    case 'm':
      return (str += time + ' minute' + (time <= 1 ? '' : 's'));
    case 'h':
      return (str += time + ' hour' + (time <= 1 ? '' : 's'));
    case 'd':
      return (str += time + ' day' + (time <= 1 ? '' : 's'));
    case 'w':
      return (str += time + ' week' + (time <= 1 ? '' : 's'));
  }
};

export const periodFromTimeLimit = (timeLimit: string): PeriodTypes => {
  if (timeLimit.includes('m')) return 'm';
  if (timeLimit.includes('h')) return 'h';
  if (timeLimit.includes('d')) return 'd';
  if (timeLimit.includes('w')) return 'w';
  return 'm';
};

export const extractTimeLimit = (datetime: string): [number, 'w' | 'd' | 'h' | 'm'] => {
  const dt = dayjs.now(datetime);
  const now = dayjs.now();

  // Get duration between datetime
  const duration = dayjs.duration(dt.diff(now));

  const weeks = duration.asWeeks();
  const days = duration.asDays();
  const hours = duration.asHours();
  const minutes = duration.asMinutes();

  if (weeks >= 1) return [weeks, 'w'];
  if (days >= 1) return [days, 'd'];
  if (hours >= 1) return [hours, 'h'];

  return [minutes, 'm'];
};

export const getAvascanTransactionUrl = (txid: string): string => {
  return `${EXPLORER_BASE_URL}/blockchain/x/tx/${txid}`;
};

export const getSnowTraceTransactionUrl = (txid: string): string => {
  return `${EXPLORER_BASE_URL}/tx/${txid}`;
};

export const getCChainAddressUrl = (address: string): string => {
  return `${EXPLORER_BASE_URL}/address/${address}`;
};

export const getCChainTokenUrl = (address: string): string => {
  return `${EXPLORER_BASE_URL}/token/${address}`;
};

export const getNFTMediaURL = (url: string) => {
  if (url.match(/^(http|https):\/\//)) {
    return url;
  }
  if (url.match(/^(ipfs):\/\//)) {
    return url.replace('ipfs://', 'https://gateway.ipfs.io/ipfs/');
  }
  return url;
};

export const clearURL = (url: string) => {
  if (url.match(/^(http):\/\//)) {
    return url.replace('http://', '');
  }
  if (url.match(/^(https):\/\//)) {
    return url.replace('https://', '');
  }
  return url;
};

export interface IMintStorage {
  tokenId: string;
  brandId: string;
  name: string;
  description: string;
  mediaUrl: string;
  mime: string;
}

export const addMintedTokenToStorage = (token: IMintStorage) => {
  const mintedStorage = JSON.parse(localStorage.getItem('mintedTokens')) || [];
  mintedStorage.push(token);
  localStorage.setItem('mintedTokens', JSON.stringify(mintedStorage));
};

export const getMintedTokensFromStorage = (brandId: string) => {
  const minted = (JSON.parse(localStorage.getItem('mintedTokens')) || []) as IMintStorage[];
  return minted.filter((mint) => mint.brandId === brandId);
};

export const getRepCardTypeName = (type: RepCardTypes) => {
  if (type === 'SURVEY') {
    return 'Survey';
  } else if (type === 'PRODUCT_PURCHASE') {
    return 'Product purchase';
  } else if (type === 'INSTANT_GRATIFICATION') {
    return 'Instant gratification';
  } else if (type === 'GALLERY') {
    return 'UGC Upload';
  } else if (type === 'SOCIAL_SHARE') {
    return 'Social share';
  } else {
    return '';
  }
};

export const bytesToMegaBytes = (sizeInBytes: number): number => sizeInBytes / (1024 * 1024);

export const isVideo = (mime: string): boolean => mime === mimeTypes.lookup('.mp4');

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const numberFormatter = new Intl.NumberFormat('en-US');

export const getFundingProjectStatus = (
  status: IFundingProjectStatus
): {
  name: string;
  color: string;
} => {
  switch (status) {
    case 'DRAFT':
      return { name: 'Draft', color: 'orange' };
    case 'COMPLETED':
      return { name: 'Completed', color: 'green' };
    case 'ACTIVE':
      return { name: 'Active', color: 'gray04' };
    default:
      return { name: 'Unknown', color: 'black' };
  }
};

export const getRepcardStatus = (
  status: RepcardStatusTypes,
  timeLimit?: string
): {
  name: string;
  tooltip: string;
  color: string;
} => {
  if (timeLimit && dayjs.now(timeLimit).isBefore(dayjs.now())) {
    return { name: 'Inactive', tooltip: 'Archived challenge', color: 'neutrals-300' };
  }

  switch (status) {
    case 'DRAFT':
      return { name: 'Draft', tooltip: 'Draft', color: 'neutrals-300' };
    case 'PUBLIC':
      return { name: 'Published', tooltip: 'Open challenge', color: 'success-default' };
    case 'INACTIVE':
    case 'ARCHIVED':
      return { name: 'Inactive', tooltip: 'Archived challenge', color: 'neutrals-300' };
    default:
      return { name: 'Unknown', tooltip: 'Unknown status', color: 'black' };
  }
};

export const getScheduledRepcardFakeStatus = () => {
  return { name: 'Scheduled', tooltip: 'Scheduled challenge', color: 'primary-500' };
};

export const getEnvironment = (url) => {
  if (url.includes('stage')) {
    return 'stage';
  }
  if (url.includes('demo.xyz')) {
    return 'demo';
  }
  if (url.includes('tyb.xyz')) {
    return 'prod';
  }

  return 'development';
};

export const getCollectionTypeName = (type: ICollectionTypes) => {
  switch (type) {
    case 'ERC1155':
      return 'ERC 1155';
    case 'ERC721':
      return 'ERC 721';
    case 'ERC721Rare':
      return 'ERC 721 (Rare)';
    default:
      return type;
  }
};

export const getTokenStandard = (type: ICollectionTypes, withSubtype = false) => {
  if (type.toLowerCase().includes('1155')) {
    return 'ERC-1155';
  }
  if (type.toLowerCase().includes('721')) {
    if (withSubtype) {
      if (type.toLowerCase().includes('rare')) return 'ERC-721 (Rare)';
    }
    return 'ERC-721';
  }
  if (type.toLowerCase().includes('20')) {
    return 'ERC-20';
  }
  return '';
};

export function stringMiddleCrop(value, totalLength, endLength = 5) {
  const end = value.substr(-endLength);
  const start = value.substr(0, Math.max(0, Math.min(totalLength, value.length) - end.length));
  const middle = value.length > totalLength ? '...' : '';

  return `${start}${middle}${end}`;
}

export const getTimeDuration = (datetime: string): [number, string] => {
  const dt = dayjs.now(datetime);
  const now = dayjs.now();

  // Get duration between datetime
  const duration = dayjs.duration(now.diff(dt));

  const weeks = Math.round(duration.asWeeks());
  const days = Math.round(duration.asDays());
  const hours = Math.round(duration.asHours());
  const minutes = Math.round(duration.asMinutes());
  const seconds = Math.round(duration.asSeconds());

  if (weeks >= 1) return [weeks, weeks === 1 ? 'week' : 'weeks'];
  if (days >= 1) return [days, days === 1 ? 'day' : 'days'];
  if (hours >= 1) return [hours, hours === 1 ? 'hour' : 'hours'];
  if (minutes >= 1) return [minutes, minutes === 1 ? 'min' : 'mins'];

  return [seconds, seconds === 1 ? 'sec' : 'secs'];
};

export const getRepCardRewardMetadata = (
  reward: RepCardReward,
  collectibles: ICollectible[],
  options: {
    collectibleExtraMetadatas: ICollectibleExtraMetadata[];
  }
) => {
  const { collectibleExtraMetadatas } = options;

  if (!reward.assetId) {
    if (reward.contractAddress?.length) {
      const collectible = collectibles.find((v) => v.contractAddress === reward.contractAddress);
      if (collectible) {
        const collectibleMetadata = getCollectibleMetadata({
          contractAddress: reward.contractAddress,
          metadata: collectible.metadata,
          type: collectible.type,
          tokenId: collectible.tokenId,
          collectibleExtraMetadatas,
        });

        return {
          ...collectible.metadata,
          description: collectibleMetadata?.description,
          additionalDetails: collectibleMetadata?.additionalDetails,
        };
      }
    }

    return reward.metadata;
  }

  const collectible = collectibles.find((v) => v.tokenId === reward.assetId);
  if (collectible) {
    const collectibleMetadata = getCollectibleMetadata({
      contractAddress: collectible.contractAddress,
      metadata: collectible.metadata,
      type: collectible.type,
      tokenId: collectible.tokenId,
      collectibleExtraMetadatas,
    });

    return {
      ...collectible.metadata,
      description: collectibleMetadata?.description,
      additionalDetails: collectibleMetadata?.additionalDetails,
    };
  }

  return reward.metadata;
};

export const safeJSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
};
