import React, { VideoHTMLAttributes } from 'react';

type VideoProps = VideoHTMLAttributes<HTMLVideoElement> & {
  src: string;
};

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(({ src, ...props }, ref) => {
  return (
    <video
      key={src}
      ref={ref}
      muted
      autoPlay
      controls={false}
      loop
      height="100%"
      {...props}
      playsInline
      preload="metadata"
    >
      <source src={src} type="video/mp4" />
    </video>
  );
});

export default Video;
