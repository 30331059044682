import { Channel } from 'pusher-js';
import { useCallback, useEffect, useState } from 'react';

import getPusherInstance from '../../config/pusher';
import { useAppSelector } from '../../redux/hooks';

type PusherConsumerProps = {
  channel?: string;
  event: string;
  onData?: (data: any) => void;
};

const PusherConsumer: React.FC<PusherConsumerProps> = ({ channel: channelName, event, onData = () => null }) => {
  const [channel, setChannel] = useState<Channel>();

  const user = useAppSelector((state) => state.user);

  const handleData = useCallback(
    (data) => {
      onData(data);
    },
    [onData]
  );

  useEffect(() => {
    if (user?.cChainPublicAddress) {
      const pusher = getPusherInstance();

      pusher.signin();
      setChannel(pusher.subscribe(channelName || `private-${user.cChainPublicAddress.toLowerCase()}`));

      if (!channelName) {
        pusher.bind(event, handleData);
        return () => {
          pusher.unbind(event);
        };
      }
    }
  }, [user?.cChainPublicAddress]);

  useEffect(() => {
    if (channel) {
      channel.bind(event, handleData);

      return () => {
        channel.unbind(event, handleData);

        // TODO: Create a provide to manage all events and channels, so we will prevent unsunbscribing existing channels
        // channel.unsubscribe();
      };
    }
  }, [channel]);

  return null;
};

export default PusherConsumer;
