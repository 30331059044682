import mParticle from '@mparticle/web-sdk';
import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { LaunchDarklyFlags } from 'src/constants';
import ShowUnlockCollectibleToCreateWalletModal from 'src/modals/ShowUnlockCollectibleToCreateWalletModal/ShowUnlockCollectibleToCreateWalletModal';
import { extractErrorsFromGraphQl } from 'src/utils/extractErrorsFromGraphQl';
import { logMparticleEvent } from 'src/utils/mparticle';

import LoginEmailForm, { EmailInputsSubmitHandler } from '../../forms/LoginEmailForm/LoginEmailForm';
import LoginPhoneNumberForm, {
  PhoneNumberInputsSubmitHandler,
} from '../../forms/LoginPhoneNumberForm/LoginPhoneNumberForm';
import useAuth from '../../hooks/useAuth';
// import useFullStory from '../../hooks/useFullStory';
import { AuthType } from '../../types';
import { Divider } from './Divider';
import { SsoButtons } from './SsoButtons';

type SubmitValidated = {
  email: string;
  phoneNumber: string;
  isFakeLogin?: boolean;
};

type LoginCardProps = {
  onLoginSubmit: (authType: AuthType, email?: string, sms?: string) => void;
  defaultEmail?: string;
  brandName?: string;
};

const LoginCard: React.FC<LoginCardProps> = ({ onLoginSubmit, defaultEmail, brandName }) => {
  // const FS = useFullStory();
  const auth = useAuth();
  const flags = useFlags();

  const router = useRouter();
  const { brand } = router.query as Record<string, string>;
  const [loginDispatched, setLoginDispatched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(defaultEmail);
  const [phoneNumber, setPhoneNumber] = useState<string>(null);
  const [authType, setAuthType] = useState<AuthType>('EMAIL');
  const [showUnlockCollectibleToCreateWalletModal, setShowUnlockCollectibleToCreateWalletModal] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmitValidated = useCallback(
    async (payload: SubmitValidated) => {
      const { email, phoneNumber } = payload;

      try {
        if (authType === 'EMAIL') {
          setEmail(email);
        }
        if (authType === 'SMS') {
          setPhoneNumber(phoneNumber);
        }

        setLoginDispatched(true);

        await auth.login({
          authType,
          email,
          phoneNumber,
        });
      } catch (error) {
        setLoginDispatched(false);
        throw error;
      }
    },
    [auth, authType]
  );

  const onSubmit: EmailInputsSubmitHandler & PhoneNumberInputsSubmitHandler = async (formData) => {
    try {
      // FS.event('Auth Start - Submit Email', {
      //   email: email,
      //   phoneNumber: formData.phoneNumber,
      // });

      logMparticleEvent('submit_login_info', mParticle.EventType.Other, {
        method: authType.toLowerCase(),
        email,
        phone_number: formData.phoneNumber,
      });

      const validationData = await auth.validate({
        authType,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        brandSlug: brand,
      });

      if (validationData.userExists) {
        await onSubmitValidated({
          email: formData.email,
          phoneNumber: formData.phoneNumber,
        });
      } else {
        onLoginSubmit(authType, formData.email, formData.phoneNumber);
      }
    } catch (error) {
      const graphqlError = extractErrorsFromGraphQl(error);
      const unlockCollectible = graphqlError?.find(
        (e) => e?.customBehaviorName == 'UNLOCK_COLLECTIBLE_TO_CREATE_WALLET'
      );

      if (unlockCollectible) {
        setShowUnlockCollectibleToCreateWalletModal(true);
        return;
      }

      throw error;
    }
  };

  return (
    <Flex flexDirection="column" flex={1}>
      {loginDispatched && (
        <Flex
          flex={1}
          p="20px"
          mb="4"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          <Text variant="text0" textAlign="center">
            Check your inbox
          </Text>

          <Text textAlign="center" maxWidth="320px" variant="text5-400" mt="2">
            We sent an {authType.toLocaleLowerCase()} to <b>{authType === 'EMAIL' ? email : phoneNumber}</b> with a code
            to sign in.
          </Text>
        </Flex>
      )}
      {!loginDispatched && (
        <>
          <Flex flex={1} mx="20px" flexDirection="column" justifyContent="center" alignItems="center">
            <Text variant="text1" fontWeight="500" mt="4" textAlign="center">
              {authType === 'EMAIL' && `What's your email?`}
              {authType === 'SMS' && `What's your number?`}
            </Text>
            <Text variant="text5-400" mt="3" mb="5" textAlign="center">
              {brandName
                ? `This is the key into your ${brandName} community on TYB`
                : 'This is the key into your TYB wallet'}
            </Text>

            {authType === 'EMAIL' && (
              <LoginEmailForm
                defaultValues={{ email: defaultEmail }}
                onSubmit={onSubmit}
                setAuthType={setAuthType}
                loading={isLoading}
              />
            )}
            {authType === 'SMS' && (
              <LoginPhoneNumberForm onSubmit={onSubmit} setAuthType={setAuthType} loading={isLoading} />
            )}

            <Flex flexDirection="column" justifyContent="flex-end" alignItems="center" mt={2}>
              <Text unselectable="on" variant="text6-400" color="gray03" textAlign="center" px="3">
                {'By clicking “Next” you agree to our '}
                <a target="_blank" rel="noreferrer" href="https://www.tyb.xyz/terms-of-service">
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>T&amp;Cs</span>
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noreferrer" href="https://www.tyb.xyz/privacy-policy">
                  <span style={{ textDecoration: 'underline' }}>Privacy Policy</span>
                </a>
              </Text>
            </Flex>

            <Flex
              mt={4}
              justifyContent="center"
              __css={{ cursor: 'pointer' }}
              onClick={() => setAuthType(authType === 'EMAIL' ? 'SMS' : 'EMAIL')}
            >
              <Text variant="text6-400" borderBottom="1px solid black">
                {`Or sign in with ${authType === 'EMAIL' ? 'mobile' : 'email'}`}
              </Text>
            </Flex>

            {flags[LaunchDarklyFlags.SOCIAL_LOGIN_WEB] && (
              <Box width={'100%'} mt={4}>
                <Divider>or</Divider>

                <Flex
                  mt={4}
                  onClick={() => {
                    setIsLoading(true);
                  }}
                >
                  <SsoButtons />
                </Flex>
              </Box>
            )}
          </Flex>
        </>
      )}
      <ShowUnlockCollectibleToCreateWalletModal
        isOpen={showUnlockCollectibleToCreateWalletModal}
        onClose={() => {
          setShowUnlockCollectibleToCreateWalletModal(false);
        }}
      />
    </Flex>
  );
};

export default LoginCard;
