import mParticle from '@mparticle/web-sdk';
import jwt from 'jsonwebtoken';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import { apollo } from '../config/apolloClient';
import { magic } from '../config/magic';
import { TYB_TOKEN_KEY } from '../constants';
import { LOGIN_WITH_MAGIC, LoginData, LoginVars } from '../graphql/mutations/loginWithMagic';
import { LOGOUT_WITH_MAGIC, LogoutData } from '../graphql/mutations/logoutWithMagic';
import { REGISTER_USER, RegisterUserData, RegisterUserVars } from '../graphql/mutations/registerUser';
import {
  VALIDATE_REGISTER_USER,
  ValidateRegisterUserData,
  ValidateRegisterUserVars,
} from '../graphql/mutations/validateRegisterUser';
import { GET_USER_WALLET, GetUserWalletData } from '../graphql/queries/getUserWallet';
import { MAGIC_USER, MagicUserData } from '../graphql/queries/magicUser';
import { VALIDATE_AUTHENTICATION, ValidateAuthData, ValidateAuthVars } from '../graphql/queries/validateAuthentication';
import { ISessionUser } from '../interface/ISessionUser';
import { login as sliceLogin, logOut as sliceLogout } from '../redux/auth/authSlice';
import { useAppDispatch } from '../redux/hooks';
import { resetUser, setUser, setWallet } from '../redux/user/userSlice';
import { AuthType } from '../types';
import { getEnvironment } from '../utils';
import { useContractManager } from './useContractManager';

const useAuth = () => {
  const router = useRouter();
  const { unloadAllContracts } = useContractManager();
  const dispatch = useAppDispatch();

  const canAuthenticateWithFakeLogin = useCallback((authType: AuthType, email: string) => {
    if (authType !== 'EMAIL') return [false, ''];

    // match any text ending with .dev
    const re = new RegExp('(.+)\\.dev');
    const isFakeLogin = re.test(email);

    const environment = getEnvironment(process.env.NEXT_PUBLIC_API_URL || '');
    const isValidEnvironment = environment === 'demo' || environment === 'stage' || environment === 'development';

    const canAuthenticate = isValidEnvironment && isFakeLogin;
    const originalEmail = canAuthenticate ? re.exec(email)[1] : '';

    return [canAuthenticate, originalEmail];
  }, []);

  const dispatchUser = useCallback(
    (user: ISessionUser) => {
      dispatch(
        setUser({
          id: user.id,
          email: user.email,
          phoneNumber: user.phoneNumber,
          userName: user.userName,
          firstName: user.firstName,
          lastName: user.lastName,
          avatarUrl: user.avatarUrl,
          bio: user.bio,
          urlSlug: user.urlSlug,
          birthdate: user.birthdate,
          cChainPublicAddress: user.cChainPublicAddress,
          instagramUrl: user.instagramUrl,
          twitterUrl: user.twitterUrl,
          tiktokUrl: user.tiktokUrl,

          sub: user.id,
          emailVerified: user.isEmailVerified,
          phoneNumberVerified: user.isPhoneNumberVerified,
          magicLinkUsers: user.magicLinkUsers,
          reps: user.reps || 0,
          managedBrands: user.managedBrands,
          activeBrands: user.activeBrands,
          publicAddress: user.cChainPublicAddress,
          notificationCount: user.unnotifiedNotificationsCount,
          userWallet: user.userWallet,
          pendingPermitSignatures: user.pendingPermitSignatures,
        })
      );
      dispatch(sliceLogin());
    },
    [dispatch]
  );

  const discardSession = useCallback(
    async (params: { forceMagicLogout: boolean } = { forceMagicLogout: true }) => {
      dispatch(resetUser());
      dispatch(sliceLogout());
      params.forceMagicLogout && magic.user.logout();
      localStorage.removeItem(TYB_TOKEN_KEY);
    },
    [dispatch]
  );

  const validate = useCallback(
    async (payload: {
      authType: AuthType;
      email?: string;
      phoneNumber?: string;
      publicAddress?: string;
      brandSlug?: string;
    }) => {
      const [isFakeLogin, originalEmail] = canAuthenticateWithFakeLogin(payload.authType, payload.email);
      const email = (isFakeLogin ? originalEmail : payload.email) as string;

      const result = await apollo.client.query<ValidateAuthData, ValidateAuthVars>({
        query: VALIDATE_AUTHENTICATION,
        fetchPolicy: 'no-cache',
        variables: {
          user: {
            email,
            phoneNumber: payload.phoneNumber,
            brandSlug: payload.brandSlug,
            publicAddress: payload.publicAddress,
          },
        },
      });

      return result?.data?.validateAuthentication;
    },
    [canAuthenticateWithFakeLogin]
  );

  const login = useCallback(
    async (payload: {
      authType: AuthType;
      email: string;
      phoneNumber?: string;
      oAuthProvider?: string;
      didToken?: string;
    }) => {
      try {
        let didToken = payload.didToken ?? '';
        let email = payload.email;
        if (!didToken) {
          const [isFakeLogin, originalEmail] = canAuthenticateWithFakeLogin(payload.authType, payload.email);
          email = (isFakeLogin ? originalEmail : payload.email) as string;

          if (isFakeLogin) {
            didToken = jwt.sign(
              payload.authType === 'EMAIL'
                ? {
                    email,
                  }
                : {
                    phoneNumber: payload.phoneNumber,
                  },
              '_'
            );
          } else {
            logMparticleEvent('send_verification_code', mParticle.EventType.Other, {
              method: payload.authType.toLowerCase(),
              email,
              phone_number: payload.phoneNumber,
              mode: 'LOGIN',
            });

            try {
              if (payload.authType === 'SMS') {
                didToken = await magic.auth.loginWithSMS({
                  phoneNumber: `+${payload.phoneNumber}`,
                });
              } else {
                didToken = await magic.auth.loginWithEmailOTP({
                  email,
                });
              }
            } catch (e) {
              logMparticleEvent('verification_code_error', mParticle.EventType.Other, {
                method: payload.authType.toLowerCase(),
                email,
                phone_number: payload.phoneNumber,
                message: e.message,
                code: e.code,
                mode: 'LOGIN',
              });

              throw e;
            }
          }
        }

        logMparticleEvent('verify_login_info', mParticle.EventType.Other, {
          method: payload.authType.toLowerCase(),
          oAuthProvider: payload.oAuthProvider,
          email,
          phone_number: payload.phoneNumber,
          mode: 'LOGIN',
        });

        const result = await apollo.client.query<LoginData, LoginVars>({
          query: LOGIN_WITH_MAGIC,
          fetchPolicy: 'no-cache',
          variables: {
            didToken,
          },
        });

        // Save our new JWT
        localStorage.setItem(TYB_TOKEN_KEY, result.data?.loginWithMagic.token);

        logMparticleEvent('user_login', mParticle.EventType.Other, {
          method: payload.authType.toLowerCase(),
          oAuthProvider: payload.oAuthProvider,
          email,
          phone_number: payload.phoneNumber,
        });

        dispatchUser(result.data?.loginWithMagic);
      } catch (e) {
        discardSession();
        throw e;
      }
    },
    [canAuthenticateWithFakeLogin, discardSession, dispatchUser]
  );

  const registerValidate = useCallback(
    async (payload: {
      authType: AuthType;
      email: string;
      phoneNumber: string;
      username: string;
      avatar?: string;
      brandSlug?: string;
    }) => {
      logMparticleEvent('submit_profile_info', mParticle.EventType.Other, {
        method: payload.authType.toLowerCase(),
        email: payload.email,
        phone_number: payload.phoneNumber,
        upload_profile_picture: !!payload?.avatar,
      });

      const validateRegisterUserData = await apollo.client.query<ValidateRegisterUserData, ValidateRegisterUserVars>({
        query: VALIDATE_REGISTER_USER,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            userName: payload.username,
            email: payload.email,
            emailOrPhoneNumber: payload.email || payload.phoneNumber,
            avatarUrl: payload.avatar,
            brandSlug: payload.brandSlug,
          },
        },
      });

      return validateRegisterUserData.data;
    },
    []
  );

  const register = useCallback(
    async (payload: {
      authType: AuthType;
      email: string;
      phoneNumber: string;
      username: string;
      avatar?: string;
      brandSlug?: string;
    }) => {
      logMparticleEvent('send_verification_code', mParticle.EventType.Other, {
        method: payload.authType.toLowerCase(),
        email: payload.email,
        phone_number: payload.phoneNumber,
        mode: 'SIGN_UP',
      });

      let didToken;

      try {
        if (payload.phoneNumber) {
          didToken = await magic.auth.loginWithSMS({
            phoneNumber: `+${payload.phoneNumber}`,
          });
        } else if (payload.email) {
          didToken = await magic.auth.loginWithEmailOTP({
            email: payload.email,
          });
        }
      } catch (e) {
        logMparticleEvent('verification_code_error', mParticle.EventType.Other, {
          method: payload.authType.toLowerCase(),
          email: payload.email,
          phone_number: payload.phoneNumber,
          message: e.message,
          code: e.code,
          mode: 'SIGN_UP',
        });

        throw e;
      }

      logMparticleEvent('verify_login_info', mParticle.EventType.Other, {
        method: payload.authType.toLowerCase(),
        email: payload.email,
        phone_number: payload.phoneNumber,
        mode: 'SIGN_UP',
      });

      const registerUserData = await apollo.client.query<RegisterUserData, RegisterUserVars>({
        query: REGISTER_USER,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            didToken: didToken,
            userName: payload.username,
            avatarUrl: payload.avatar,
            email: payload.authType == 'SMS' ? payload.email : undefined,
            brandSlug: payload.brandSlug,
          },
        },
      });

      logMparticleEvent('user_signup', mParticle.EventType.Other, {
        method: payload.authType.toLowerCase(),
        email: payload.email,
        phone_number: payload.phoneNumber,
      });

      const user = registerUserData.data?.registerUser.user;
      const joinedBrands = registerUserData.data?.registerUser.joinedBrands;

      // Save our new JWT
      localStorage.setItem(TYB_TOKEN_KEY, user?.token);

      localStorage.setItem('tybToken', user?.token);

      dispatchUser(user);

      return { user, joinedBrands };
    },
    [dispatchUser]
  );

  const loadActiveSession = useCallback(async () => {
    try {
      // TODO: update the validate session in frontend before call backend
      const result = await apollo.client.query<MagicUserData>({
        query: MAGIC_USER,
        fetchPolicy: 'no-cache',
      });

      if (result?.data?.magicUser) {
        dispatchUser(result.data?.magicUser);
      } else {
        dispatch(sliceLogout());
      }
    } catch (e) {
      discardSession();
      throw e;
    }
  }, [discardSession, dispatch, dispatchUser]);

  const logout = useCallback(async () => {
    try {
      await apollo.client.query<LogoutData>({
        query: LOGOUT_WITH_MAGIC,
        fetchPolicy: 'no-cache',
      });

      unloadAllContracts();

      if (getEnvironment(process.env.NEXT_PUBLIC_API_URL || '') === 'prod') {
        window.Intercom('shutdown');
      }

      discardSession({ forceMagicLogout: false });
      router.push('/');
    } catch (e) {
      discardSession();
      router.push('/');
      throw e;
    }
  }, [discardSession, unloadAllContracts]);

  const fetchWallet = useCallback(async () => {
    const { data } = await apollo.client.query<GetUserWalletData>({
      query: GET_USER_WALLET,
      fetchPolicy: 'no-cache',
    });

    if (data?.userWallet) {
      dispatch(setWallet(data.userWallet));

      return data?.userWallet;
    }

    return null;
  }, [dispatch]);

  const enableDynamicLogin = useCallback(
    (extra = {}) => {
      router.push({ query: { ...router.query, ...extra, login: true } }, undefined, { shallow: true });
    },
    [router]
  );

  const disableDynamicLogin = useCallback(() => {
    const query = { ...router.query };
    delete query.login;
    router.push({ query }, undefined, { shallow: true });
  }, [router]);

  return {
    validate,
    login,
    registerValidate,
    register,
    logout,
    loadActiveSession,
    fetchWallet,
    enableDynamicLogin,
    disableDynamicLogin,
  };
};

export default useAuth;
