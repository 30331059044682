import { NextPage } from 'next';
import React from 'react';

import Loading from '../components/Loading';
import LoginPage from '../pages/login';
import { AuthStatus } from '../redux/auth/authSlice';
import { useAppSelector } from '../redux/hooks';

const withAuth = (Component: NextPage) => {
  const Auth = (props: JSX.IntrinsicAttributes) => {
    const authStatus = useAppSelector(({ auth }) => auth.status);

    if (authStatus === AuthStatus.LOADING) {
      return <Loading />;
    } else if (authStatus === AuthStatus.LOGGED_OUT) {
      return <LoginPage />;
    } else {
      return <Component {...props} />;
    }
  };

  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
};

export default withAuth;
