import { useLazyQuery } from '@apollo/client';
import { Box, Button, Flex, Icon, Input, Text } from '@tyb-u/tyb-ui-components';
import copy from 'copy-to-clipboard';
import { utils } from 'ethers';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import DropDown from 'src/components/DropDown';
import { IUserWallet } from 'src/interface/IUserWallet';
import { useAppSelector } from 'src/redux/hooks';
import { ContractManager } from 'src/services/contract-manager';
import { BaseContract } from 'src/services/contract-manager/contract';
import { errorToast, successToast } from 'src/utils/toasts';

import AvatarImage from '../../../components/AvatarImage';
import CenteredModal from '../../../components/Modals/Centered';
import { USER_BY_ADDRESS, UserByWalletData, UserByWalletVars } from '../../../graphql/queries/userByWalletAddress';
import { ICollectible } from '../../../types';
import { getCChainAddressUrl, stringMiddleCrop } from '../../../utils';

type CollectibleOwnerProps = {
  collectible: ICollectible;
  data: { userPage: { avatarUrl: string; userName: string; cChainPublicAddress: string; userWallet: IUserWallet } };
  urlSlug: string;
  userSlug: string;
  isCoin?: boolean;
  canTransfer: boolean;
  canToggleVisibility: boolean;
  isVisible: boolean;
  isOnBrandPage?: boolean;
  onChangeVisibility: () => void;
};

const CollectibleOwner: React.FC<CollectibleOwnerProps> = ({
  collectible,
  data,
  urlSlug,
  userSlug,
  isCoin,
  canTransfer,
  canToggleVisibility,
  isVisible,
  isOnBrandPage,
  onChangeVisibility,
}) => {
  const [enableTransfer, setEnableTransfer] = useState<boolean>(false);
  const [showTransferUser, setShowTransferUser] = useState<boolean>(false);
  const [transferAddress, setTransferAddress] = useState<string>('');
  const [transferAmount, setTransferAmount] = useState<string>('');
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user);

  const [getUserByAddress, { data: userWalletData }] = useLazyQuery<UserByWalletData, UserByWalletVars>(
    USER_BY_ADDRESS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const onShareCollectible = () => {
    copy(`${process.env.NEXT_PUBLIC_DOMAIN}/user/${userSlug}`);
    successToast('✔️ Copied to clipboard!');
  };

  const handleTransferAddressChange = (ev) => {
    if (ev?.target) {
      if (ev.target.name === 'walletAddress') {
        setTransferAddress(ev.target.value);
      } else {
        setTransferAmount(ev.target.value);
      }
    }

    if (utils.isAddress(ev?.target?.value)) {
      getUserByAddress({
        variables: {
          walletAddress: ev.target.value,
        },
      });
    }
  };

  const handleTransferAmountChange = (ev) => {
    if (ev?.target) {
      if (ev.target.name === 'walletAddress') {
        setTransferAddress(ev.target.value);
      } else {
        setTransferAmount(ev.target.value);
      }
    }
  };

  const handleTransferCollectible = useCallback(
    async (toAddress: string, amount?: number) => {
      try {
        setTransferLoading(true);

        toast.info('Transferring in progress...', {
          toastId: 'transfer-collectible',
          position: 'top-right',
          closeButton: false,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });

        console.log('START TRANSFER COLLECTIBLE', { from: user.publicAddress, to: toAddress }, collectible);
        const type = ContractManager.getType(collectible.type);
        const contract = ContractManager.instance().loadContract<BaseContract>(collectible.contractAddress, type);
        await contract.transfer(user.publicAddress, toAddress, {
          tokenId: collectible.tokenId,
          amount: amount || 1,
        });

        toast.dismiss('transfer-collectible');
      } catch (err) {
        console.error(err);

        toast.dismiss('transfer-collectible');
        errorToast('Failed to process transaction!', {
          position: 'top-right',
        });
      } finally {
        setTransferLoading(false);
      }
    },
    [collectible, user.publicAddress]
  );

  const handleTransferSubmit = useCallback(() => {
    if (['ERC1155', 'ERC20'].includes(collectible.type)) {
      setIsTransferModalOpen(true);
    } else {
      handleTransferCollectible(transferAddress);
    }
  }, [transferAddress, collectible]);

  const handleTransferCancel = () => {
    setShowTransferUser(false);
    setTransferAddress('');
  };

  const dropdownItems = useMemo(
    () => [
      canToggleVisibility && {
        icon: isVisible ? <Icon.BoxiconsRegular.Hide size={16} /> : <Icon.BoxiconsRegular.Show size={16} />,
        title: isVisible ? 'Hide from wallet' : 'Show in wallet',
        onClick: () => onChangeVisibility(),
      },
      {
        icon: <Icon.BoxiconsRegular.Share size={16} />,
        title: 'Share collectible',
        onClick: () => onShareCollectible(),
      },
    ],
    [isVisible]
  );

  return (
    <>
      <Flex flexDirection="column" pb={[5, 'unset']}>
        <Flex alignItems={showTransferUser && 'center'} justifyContent="space-between" __css={{ gap: '10px' }}>
          <Flex flexDirection={'column'} flex={1} alignItems="start" __css={{ gap: '10px' }}>
            <Flex flexDirection="column" __css={{ gap: '15px' }}>
              <Flex flexWrap="wrap" __css={{ gap: '5px' }} pt={1}>
                <Text variant="text4-500" color="neutrals-900">
                  Owner:
                </Text>
                <Flex alignItems="center" __css={{ gap: '15px', cursor: 'pointer' }} as="a" href={`/user/${urlSlug}`}>
                  <Text py="0" variant="text4-500" color="neutrals-900">
                    {data.userPage.userName}
                  </Text>
                  <AvatarImage size={24} src={data.userPage.avatarUrl} />
                </Flex>
              </Flex>
              {canTransfer && !enableTransfer && (
                <Button variant="secondary" onClick={() => setEnableTransfer(true)}>
                  {isCoin ? 'Transfer coins' : 'Transfer collectibles'}
                </Button>
              )}
            </Flex>

            {enableTransfer && (
              <Flex
                flexDirection={['column', 'row']}
                flex={1}
                alignItems={showTransferUser ? 'center' : 'start'}
                __css={{ gap: '10px' }}
                width="100%"
              >
                {showTransferUser ? (
                  userWalletData?.userByWalletAddress ? (
                    <Flex
                      flexDirection={['column', 'row']}
                      alignItems={['start', 'center']}
                      flex={1}
                      __css={{ gap: '10px' }}
                      width="100%"
                    >
                      <Icon.BoxiconsRegular.Transfer size={30} />
                      <Flex
                        alignItems="center"
                        __css={{ gap: '5px', cursor: 'pointer' }}
                        as="a"
                        href={`/user/${userWalletData.userByWalletAddress.urlSlug}`}
                      >
                        <AvatarImage size={24} src={userWalletData.userByWalletAddress.avatarUrl} />
                        <Text py="0" variant="text4-500" color="neutrals-900">
                          {userWalletData.userByWalletAddress.userName}
                        </Text>
                      </Flex>

                      <Flex
                        alignItems={['unset', 'center']}
                        flex={1}
                        flexDirection={['column', 'row']}
                        __css={{ gap: '5px', ':hover': 'gray04' }}
                      >
                        <Flex __css={{ gap: '5px' }} alignItems="center">
                          <Text
                            py="0"
                            color="gray04"
                            onClick={() =>
                              window.open(
                                getCChainAddressUrl(userWalletData.userByWalletAddress.cChainPublicAddress),
                                '_blank'
                              )
                            }
                          >
                            {stringMiddleCrop(userWalletData.userByWalletAddress.cChainPublicAddress, 12, 6)}
                          </Text>
                          <Icon.BoxiconsRegular.Edit
                            size={18}
                            onClick={() => {
                              if (transferLoading) return;
                              setShowTransferUser(false);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </Flex>
                        {showTransferUser && (
                          <Flex __css={{ gap: '5px' }}>
                            <Button
                              width="90px"
                              fontSize={13}
                              py={2}
                              px={2}
                              variant="primary"
                              disabled={!utils.isAddress(transferAddress) || transferLoading}
                              onClick={handleTransferSubmit}
                            >
                              Transfer
                            </Button>
                            <Button
                              width="90px"
                              fontSize={13}
                              py={2}
                              px={2}
                              variant="secondary"
                              disabled={transferLoading}
                              onClick={handleTransferCancel}
                            >
                              Cancel
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex flexDirection="column" justifyContent="center" __css={{ gap: '10px' }}>
                      <Box>
                        <Flex alignItems="center" __css={{ gap: '5px' }}>
                          <Text
                            py="0"
                            color="gray04"
                            __css={{ cursor: 'pointer', ':hover': 'gray04' }}
                            onClick={() => window.open(getCChainAddressUrl(transferAddress), '_blank')}
                          >
                            {`Transfer to: ${stringMiddleCrop(transferAddress, 16, 8)}`}
                          </Text>
                        </Flex>
                      </Box>
                      <Text>
                        <Text py="0" color="error">
                          Wallet address not recognized by TYB.
                        </Text>
                        <Text py="0" color="error">
                          Make sure if you really want to transfer this collectible to an outside wallet.
                        </Text>
                      </Text>
                      <Button width="90px" fontSize={13} py={2} px={2} onClick={() => setShowTransferUser(false)}>
                        Reset
                      </Button>
                    </Flex>
                  )
                ) : (
                  <Flex flexDirection="column" alignItems="center" __css={{ gap: '5px' }} width="100%">
                    <Input
                      type="text"
                      name="walletAddress"
                      placeholder="Destination wallet address"
                      value={transferAddress}
                      width="100%"
                      onChange={handleTransferAddressChange}
                      height="30px"
                    />
                    <Flex __css={{ gap: '5px' }} alignSelf="end">
                      <Button
                        variant="primary"
                        width="90px"
                        fontSize={13}
                        py={2}
                        px={2}
                        disabled={
                          !utils.isAddress(transferAddress) ||
                          transferAddress.toLowerCase() === data.userPage.cChainPublicAddress.toLowerCase() ||
                          transferLoading
                        }
                        onClick={() => setShowTransferUser(true)}
                      >
                        Get Wallet
                      </Button>
                      <Button
                        variant="secondary"
                        width="90px"
                        fontSize={13}
                        py={2}
                        px={2}
                        disabled={transferLoading}
                        onClick={() => setEnableTransfer(false)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>

          {!isOnBrandPage && (
            <Flex alignItems="start">
              <DropDown items={dropdownItems} position="above">
                <Box __css={{ cursor: 'pointer' }}>
                  <Icon.BoxiconsRegular.DotsVerticalRounded size="24" />
                </Box>
              </DropDown>
            </Flex>
          )}
        </Flex>
      </Flex>

      <CenteredModal
        isOpen={isTransferModalOpen}
        onRequestClose={() => setIsTransferModalOpen(false)}
        body={
          <Flex flexDirection="column" justifyContent="center">
            <Text variant="text3-600" mb="3">
              Please inform the amount to be transferred
            </Text>

            <Text variant="text6-400">Balance: {collectible.balance}</Text>
            <Input
              type="number"
              name="transferAmount"
              placeholder="Amount to be transfered"
              value={transferAmount}
              onChange={handleTransferAmountChange}
              style={{
                fontSize: '16px',
              }}
            />

            <Button
              variant="primary"
              fontSize="13px"
              px="7px"
              mt="5"
              disabled={Number(transferAmount) < 1 || Number(transferAmount) > collectible.balance || transferLoading}
              onClick={() => {
                handleTransferCollectible(transferAddress, Number(transferAmount));
                setIsTransferModalOpen(false);
              }}
            >
              Transfer
            </Button>
          </Flex>
        }
      />
    </>
  );
};

export default CollectibleOwner;
