import { Flex } from '@tyb-u/tyb-ui-components';
import { useCallback, useMemo, useState } from 'react';
import RedemptionHeader from 'src/components/Redemption/RedemptionHeader';
import { RedeemEcommRedemptionCheckSubscriptionsData } from 'src/graphql/mutations/redeemEcommRedemptionCheckSubscriptions';
import { ICollectionInfoData } from 'src/graphql/queries/collectionInfo';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { IUser } from 'src/interface/IUser';
import { CoinRedemptionType, ICollectible, RedemptionCoupon } from 'src/types';
import { convertRemToPx } from 'src/utils';
import { sanitizeUrl } from 'src/utils/sanitizeUrl';
import { useTheme } from 'styled-components';

import CoinRedemptionAmount from './steps/CoinRedemptionAmount';
import CoinRedemptionComplete from './steps/CoinRedemptionComplete';
import CoinRedemptionDetails from './steps/CoinRedemptionDetails';
import CoinRedemptionReview from './steps/CoinRedemptionReview';

export enum CoinRedemptionSteps {
  Details,
  Amount,
  Review,
  Complete,
}

interface CoinRedemptionProps {
  initialStep: CoinRedemptionSteps;
  user: IUser;
  collectible: ICollectible;
  collectionInfoData: ICollectionInfoData;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  onBack: () => void;
  onFinish: () => void;
}

const CoinRedemption: React.FC<CoinRedemptionProps> = ({
  initialStep = CoinRedemptionSteps.Details,
  user,
  collectible,
  collectionInfoData,
  ecommBrandRedemption,
  onBack,
  onFinish,
}) => {
  const [currentStep, setCurrentStep] = useState<CoinRedemptionSteps>(initialStep);
  const [applyTo, setApplyTo] = useState<CoinRedemptionType | null>('checkout');
  const [applicableSubscription, setApplicableSubscription] =
    useState<RedeemEcommRedemptionCheckSubscriptionsData | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [coupon, setCoupon] = useState<RedemptionCoupon>({
    code: '',
    expiredAt: '',
  });

  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const brandName = useMemo(
    () => collectionInfoData?.collectionInfo?.brand?.name,
    [collectionInfoData?.collectionInfo?.brand?.name]
  );

  const brandWebsiteURL = useMemo(
    () =>
      collectionInfoData?.collectionInfo?.brand?.websiteUrl
        ? sanitizeUrl(collectionInfoData.collectionInfo.brand.websiteUrl)
        : '',
    [collectionInfoData?.collectionInfo?.brand?.websiteUrl]
  );

  const handleNextStep = useCallback(
    async (data?: any) => {
      const nextStep = currentStep + 1;
      const lastStep = Number(Object.values(CoinRedemptionSteps).pop());

      if (nextStep > lastStep) {
        onFinish();
      } else {
        if (currentStep === CoinRedemptionSteps.Details) {
          setApplyTo(data.applyTo);
          setApplicableSubscription(data.applicableSubscription);
        } else if (currentStep === CoinRedemptionSteps.Amount) {
          setAmount(data);
        } else if (currentStep === CoinRedemptionSteps.Review) {
          setCoupon({
            code: data.redeemEcommRedemption?.code,
            expiredAt: data.redeemEcommRedemption?.expiredAt,
          });
        }
        setCurrentStep(nextStep);
      }
    },
    [currentStep]
  );

  const handlePreviousStep = useCallback(() => {
    const previewStepValue = currentStep - 1;

    if (previewStepValue >= 1) {
      setCurrentStep(previewStepValue);
    }
  }, [currentStep]);

  const handleClose = () => {
    setCurrentStep(CoinRedemptionSteps.Details);
    setAmount(0);
    setCoupon({
      code: '',
      expiredAt: '',
    });
    setApplicableSubscription(null);
    setApplyTo(null);
    onFinish();
  };

  return (
    <Flex flexDirection="column" height="100%" __css={{ gap: '25px' }}>
      <RedemptionHeader type="coin" onBack={onBack} onClose={() => handleClose()} />

      <Flex flexDirection="column" flex={1} minHeight={['80%', '50vh']}>
        {currentStep === CoinRedemptionSteps.Details && (
          <CoinRedemptionDetails
            collectible={collectible}
            ecommBrandRedemption={ecommBrandRedemption}
            onNextStep={handleNextStep}
          />
        )}
        {currentStep === CoinRedemptionSteps.Amount && (
          <CoinRedemptionAmount
            initialAmount={amount}
            applyTo={applyTo}
            brandName={brandName}
            collectible={collectible}
            ecommBrandRedemption={ecommBrandRedemption}
            applicableSubscription={applicableSubscription}
            isMobile={isMobile}
            onNextStep={handleNextStep}
          />
        )}
        {currentStep === CoinRedemptionSteps.Review && (
          <CoinRedemptionReview
            collectible={collectible}
            amount={amount}
            ecommBrandRedemption={ecommBrandRedemption}
            collectionInfoData={collectionInfoData}
            user={user}
            applyTo={applyTo}
            isMobile={isMobile}
            onPreviousStep={handlePreviousStep}
            onNextStep={handleNextStep}
          />
        )}
        {currentStep === CoinRedemptionSteps.Complete && (
          <CoinRedemptionComplete
            applyTo={applyTo}
            ecommBrandRedemption={ecommBrandRedemption}
            brandName={brandName}
            brandWebsiteURL={brandWebsiteURL}
            coupon={coupon}
            amount={amount}
            applicableSubscription={applicableSubscription}
            isMobile={isMobile}
            onNextStep={handleNextStep}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default CoinRedemption;
