import React from 'react';
import { ImgHTMLAttributes } from 'react';

import Box, { BoxProps } from '../Box/Box';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> &
  BoxProps & {
    variant?: 'avatar' | 'thumb';
    borderRadius?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '100%';
  };

const Image = React.forwardRef<HTMLElement, ImageProps>(({ variant, ...props }, ref) => {
  return (
    <Box ref={ref} as="img" variant={`images.${variant}`} __css={{ maxWidth: '100%', height: 'auto' }} {...props} />
  );
});

Image.defaultProps = {
  variant: 'thumb',
};

export default Image;
