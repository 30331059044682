import { StandardTypes } from './base';
import { ContractERC721 } from './erc721';

export class ContractERC721Rare extends ContractERC721 {
  standardName = StandardTypes.ERC721RARE;

  public async getMaxSupply(): Promise<number> {
    return (await this.contract.getMaxSupply())?.toNumber();
  }
}
