import { IBrandEcommRedemptionEffectData } from 'src/interface/IBrandEcommRedemptionData';

interface GetCoinRedemptionValueInput {
  amount: number;
  effect: IBrandEcommRedemptionEffectData;
}

export const getCoinRedemptionValue = ({ amount, effect }: GetCoinRedemptionValueInput): number => {
  const { method = 'FLAT_AMOUNT', value = 0 } = effect ?? {};
  return method === 'FLAT_AMOUNT' ? amount * value : Math.round(amount * value * 100) / 100;
};
