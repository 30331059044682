/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Flex, Image, Text } from '@tyb-u/tyb-ui-components';
import { useCallback } from 'react';
import { Carousel } from 'react-responsive-carousel';

type Item = {
  image: string;
  title: string;
  description?: string;
};

type OnboardingTutorialProps = {
  items: Item[];
  onChange?: (index: number, lastItem: boolean) => void;
  isMobile?: boolean;
};

const OnboardingTutorial: React.FC<OnboardingTutorialProps> = ({ items, onChange = () => null, isMobile = false }) => {
  const handleChangeItem = useCallback(
    (index: number) => {
      onChange(index, index === items.length - 1);
    },
    [items, onChange]
  );

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      elevation={isMobile ? 'high' : 'none'}
      borderRadius={isMobile ? '26px' : 'none'}
      px="5px"
    >
      <Flex flexDirection="column" width={['100%', '120%']} p="2" data-testid="onboarding_tutorial">
        <Carousel
          showThumbs={false}
          showStatus={false}
          autoPlay={false}
          interval={99999999999}
          onChange={handleChangeItem}
          renderIndicator={(clickHandler, isSelected, index) => (
            <li
              className="dot"
              value={index}
              role="button"
              tabIndex={0}
              onClick={clickHandler}
              style={{
                transition: 'unset',
                opacity: 'unset',
                boxShadow: 'unset',
                backgroundColor: isSelected ? '#BEBABA' : '#fff',
                border: '1px solid #BEBABA',
                marginRight: '0px',
                width: '6px',
                height: '6px',
              }}
            />
          )}
          emulateTouch
        >
          {items.map((item, index) => (
            <Flex
              key={`onboarding_tutorial_item_${index}`}
              flexDirection="column"
              //justifyContent="center"
              alignItems="center"
              px="2"
              pb="2"
            >
              {index !== 0 && (
                <Text variant="title0-cherry" fontSize={['2rem', '3rem']} fontWeight="800" lineHeight="123%">
                  {item.title}
                </Text>
              )}
              <Flex justifyContent="center" alignItems="center" height="auto">
                {!isMobile && (
                  <Image
                    src={item.image}
                    height={index === 0 ? '400px' : '360px'}
                    __css={{ objectFit: 'contain', aspectRatio: '1/1' }}
                  />
                )}
                {isMobile && (
                  <Image
                    src={item.image}
                    height={index === 0 ? '128px' : '264px'}
                    marginBottom={index === 0 ? '36px' : '0px'}
                    __css={{ objectFit: 'contain', aspectRatio: '1/1' }}
                  />
                )}
              </Flex>
              <Flex
                flexDirection="column"
                flex={1}
                justifyContent="center"
                margin={index === 0 && !isMobile && '18px 0 62px 0'}
              >
                {index === 0 && (
                  <Text
                    variant="title0-cherry"
                    fontSize={['2.35rem', '2.5rem']}
                    lineHeight="123%"
                    marginBottom={index === 0 && !isMobile && '24px'}
                  >
                    {item.title}
                  </Text>
                )}
                {item.description && (
                  <Text variant="text3-400" fontSize={(index === 0 && !isMobile && '1.4rem') || undefined}>
                    {item.description}
                  </Text>
                )}
              </Flex>
            </Flex>
          ))}
        </Carousel>
      </Flex>
    </Flex>
  );
};

export default OnboardingTutorial;
