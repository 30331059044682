import React, { InputHTMLAttributes } from 'react';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Radio = React.forwardRef<HTMLElement, RadioProps>(({ name, ...props }, ref) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      mr="2"
      __css={{
        cursor: 'pointer',
        'input:disabled': {
          opacity: 0.5,
        },
      }}
    >
      <Box as="input" type="Radio" ref={ref} name={name} data-testid="radio" {...props} />
    </Flex>
  );
});

export default Radio;
