import { Flex, Text } from '@tyb-u/tyb-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { LaunchDarklyFlags } from '../../constants';

const MaintenanceProvider = ({ children }) => {
  const flags = useFlags();

  const maintenanceMode = flags[LaunchDarklyFlags.MAINTENANCE_MODE];

  if (maintenanceMode) {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center" flexDirection="column" py="6">
        <Text variant="text3-400">{`We'll be back soon!`}</Text>
        <Text>Sorry, we are under maintenance.</Text>
      </Flex>
    );
  }
  return <>{children}</>;
};

export default MaintenanceProvider;
