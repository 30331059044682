import { providers, utils } from 'ethers';
import { IInternalForwardRequestData } from 'src/types';

const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
];

const ForwardRequestData = [
  { name: 'ref', type: 'bytes' },
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'gas', type: 'uint256' },
  { name: 'data', type: 'bytes' },
];

function createTypedStructData(chainId: number, verifyingContract: string) {
  return {
    types: {
      EIP712Domain,
      ForwardRequestData,
    },
    domain: {
      name: 'InternalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequestData',
  };
}

async function signTypedData(signer: providers.JsonRpcProvider, from: string, data: any) {
  return await signer.send('eth_signTypedData_v4', [from, JSON.stringify(data)]);
}

function buildRequest(input: IInternalForwardRequestData) {
  const { ref, from, to, gas, data } = input;

  return {
    ref: utils.keccak256(utils.toUtf8Bytes(ref)),
    from,
    to,
    value: 0,
    gas,
    data,
  };
}

async function buildTypedData(forwarderAddress: string, request: IInternalForwardRequestData, chainId: number) {
  const typeData = createTypedStructData(chainId, forwarderAddress.toLowerCase());

  return { ...(typeData as any), message: request };
}

export async function createMetaTx(
  signer: providers.JsonRpcProvider,
  input: IInternalForwardRequestData,
  forwarderAddress: string,
  chainId: number
) {
  const request = buildRequest(input);
  const toSign = await buildTypedData(forwarderAddress, request, chainId);
  const signature = await signTypedData(signer, input.from, toSign);
  return { signature, request };
}
