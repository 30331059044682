import { gql } from '@apollo/client';
import { IBrand } from 'src/interface/IBrand';

import { ISessionUser } from '../../interface/ISessionUser';

export interface RegisterUserVars {
  data: {
    userName: string;
    email?: string;
    didToken: string;
    avatarUrl?: string;
    brandSlug?: string;
  };
}

export interface RegisterUserData {
  registerUser: {
    user: ISessionUser;
    joinedBrands: IBrand[];
  };
}

export const REGISTER_USER = gql`
  mutation RegisterUser($data: RegisterUserInput!) {
    registerUser(data: $data) {
      joinedBrands {
        membershipCollectible {
          contractAddress
          tokenId
          quantity
          metadata {
            animationUrl
            description
            image
            name
          }
        }
        urlSlug
      }
      user {
        id
        email
        isEmailVerified
        phoneNumber
        userName
        firstName
        lastName
        avatarUrl
        bio
        token
        urlSlug
        birthdate
        reps
        cChainPublicAddress
        unnotifiedNotificationsCount
        instagramUrl
        twitterUrl
        tiktokUrl
        managedBrands {
          id
          uuid
          name
          isLive
          avatarUrl
          heroImageUrl
          minParticipationAge
          description
          websiteUrl
          twitterUrl
          instagramUrl
          tiktokUrl
          logoUrl
          urlSlug
        }
        activeBrands {
          id
          uuid
          name
          isLive
          avatarUrl
          heroImageUrl
          minParticipationAge
          description
          websiteUrl
          twitterUrl
          instagramUrl
          tiktokUrl
          logoUrl
          urlSlug
        }
        userWallet {
          relatedContracts {
            type
            contractAddress
            collectibleExtraMetadatas {
              contractAddress
              tokenId
              description
              additionalDetails
            }
          }
          collectiblesSettings {
            contractAddress
            tokenId
            visibility
          }
        }
      }
    }
  }
`;
