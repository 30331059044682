import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

import CollectiblePreview from '../../../components/CollectiblePreview';
import ImageCDN from '../../../components/ImageCDN';
import Loading from '../../../components/Loading';
import AddressCollectibles from '../../../containers/AddressCollectibles';
import { INotification } from '../../../types';

const RepcardParticipationApprovedContent: React.FC<{ notification: INotification; onClose: () => void }> = ({
  notification,
  onClose,
}) => {
  const repCard = notification.items[0].repCard;
  const brand = repCard.brand;

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={4}
      data-testid="modal-submission-approved-content"
    >
      {brand.logoUrl && (
        <Flex mb={2} flexDirection="column" height="48px" justifyContent="center" alignItems="center">
          <ImageCDN src={brand.logoUrl} height="100%" style={{ objectFit: 'cover' }} />
        </Flex>
      )}

      {repCard.reward.contractAddress ? (
        <AddressCollectibles
          address={brand.brandWallet.address}
          collections={brand.brandWallet.collections}
          asContractOwner
        >
          {(collectibles, { isReady }) => {
            if (!isReady) {
              return <Loading />;
            }
            const collectible = collectibles.find(
              (v) =>
                `${v.contractAddress}${v.tokenId || ''}` ==
                `${repCard.reward.contractAddress}${repCard.reward.assetId || ''}`
            );
            return (
              <>
                <Text pb={2} fontWeight="bold" textAlign="center" variant="text3-600">
                  You’ve earned {repCard.reward.quantity}{' '}
                  {collectible?.type == 'ERC20'
                    ? brand.name + (repCard.reward.quantity > 1 ? ' Coins' : ' Coin')
                    : collectible?.metadata?.description}
                </Text>
                <Text mt={0} variant="text5-400">
                  Submission approved!
                </Text>
                <Text mt={0} variant="text5-400">
                  RepCard: {repCard.prompt}
                </Text>
                <Box mt={4} data-testid="modal-submission-reward-info-media">
                  <CollectiblePreview collectible={collectible} width={'196px'} height={'196px'} />
                </Box>
              </>
            );
          }}
        </AddressCollectibles>
      ) : (
        <>
          <Text pb={2} fontWeight="bold" textAlign="center" variant="text3-600">
            You’ve earned {repCard.reward.reps} {repCard.reward.reps > 1 ? 'Reps' : 'Rep'}
          </Text>
          <Text mt={0} variant="text5-400" textAlign="center">
            Submission approved!
          </Text>
          <Text mt={0} variant="text5-400" textAlign="center">
            RepCard: {repCard.prompt}
          </Text>
        </>
      )}

      <Box mt={4}>
        <Button
          onClick={() => {
            onClose();
          }}
          scale="lg"
        >
          Add to wallet
        </Button>
      </Box>
    </Flex>
  );
};

export default RepcardParticipationApprovedContent;
