import { gql } from '@apollo/client';

import { IWallletActivity } from '../../interface/IWallletActivity';

export interface WallletActivityData {
  walletActivity: IWallletActivity[];
}

export interface WalletActivityVars {
  walletAddress: string;
}

export const WALLET_ACTIVITY = gql`
  query WalletActivity($walletAddress: String!) {
    walletActivity(walletAddress: $walletAddress) {
      uuid
      tx
      from
      to
      quantity
      isPending
      date
      type
      collectible {
        contractAddress
        tokenId
        balance
        type
        uri
        metadata {
          name
          description
          image
          animationUrl
        }
      }
    }
  }
`;
