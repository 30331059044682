import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { LaunchDarklyFlags } from 'src/constants';

import { useAppSelector } from '../../redux/hooks';
import Logo from '../Logo';
import MenuDropdown from '../MenuDropdown';

const WalletOverview: React.FC = () => {
  const { reps, notificationCount } = useAppSelector(({ user }) => user);

  return (
    <Flex
      flexDirection="row"
      justifyContent={['flex-end', 'space-between']}
      alignItems="center"
      data-testid="wallet-overview"
    >
      <Box
        backgroundColor="black"
        width={['120px', '220px']}
        py="7px"
        px="15px"
        mr={['15px', 'unset']}
        borderRadius="100px"
      >
        <Flex flexDirection="row" alignItems="center" justifyContent="space-between" height="100%">
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            minHeight="24px"
            width={['auto', '120px']}
          >
            <Box display={['none', 'unset']} mt="1px">
              <Logo colorMode="dark" height={16} />
            </Box>
            <Flex flexDirection="row">
              <Text display={['none', 'unset']} variant="text5-400" color="white" lineHeight="0px" mr="1">
                YOUR
              </Text>
              <Text variant="text5-400" color="white" lineHeight="0px">
                REPS
              </Text>
            </Flex>
          </Flex>
          <Text variant="text5-400" fontWeight="bold" color="white" lineHeight="0px">
            {reps}
          </Text>
        </Flex>
      </Box>

      <Link href="/notifications">
        <Flex position="relative" alignItems="center" mx="3" __css={{ cursor: 'pointer' }}>
          {notificationCount > 0 ? (
            <Box>
              <Icon.BoxiconsSolid.Bell color="#000" size="20px" />

              <Box
                position="absolute"
                color="white"
                backgroundColor="#FF6900"
                px="6px"
                py="1px"
                borderRadius="lg"
                fontSize="12px"
                top={-8}
                right={-10}
              >
                {notificationCount > 9 ? '9+' : notificationCount}
              </Box>
            </Box>
          ) : (
            <Icon.BoxiconsRegular.Bell color="#000" size="20px" />
          )}
        </Flex>
      </Link>

      <MenuDropdown position="bellow">
        {/* <UserAvatar size={32} avatarUrl={avatarUrl} /> */}
        <Icon.BoxiconsRegular.Cog size={24} />
      </MenuDropdown>
    </Flex>
  );
};

export interface NavbarProps {
  isAuthenticated?: boolean;
  showSignup?: boolean;
  showHamburger?: boolean;
  onSignup?: () => void | null;
  onClickMenu?: () => void | null;
  minimal?: boolean;
  maxWidth?: null | string | number;
  avatarUrl?: string | null;
  colorMode?: 'light' | 'dark' | 'gray';
}

const Navbar: React.FC<NavbarProps> = ({
  isAuthenticated = false,
  showSignup = false,
  showHamburger = true,
  onSignup = null,
  onClickMenu = () => null,
  minimal = false,
  colorMode = 'light',
}) => {
  const router = useRouter();
  const flags = useFlags();

  const showBanner = useMemo(
    () => (flags[LaunchDarklyFlags.FRONTEND_GLOBAL_BANNER] === 'hidden' ? false : true),
    [flags]
  );

  const handleSignup = () => {
    if (onSignup === null) {
      return router.push('/login');
    }
    return onSignup();
  };

  return (
    <>
      {showBanner && (
        <Flex
          bg="orange"
          //position="fixed"
          top={64}
          width="100%"
          px="2"
          py={[0, 2]}
          justifyContent="center"
          alignItems="center"
          style={{ cursor: 'pointer' }}
        >
          <Text textAlign="center" color="white" variant="text5-400">
            {flags[LaunchDarklyFlags.FRONTEND_GLOBAL_BANNER]}
          </Text>
        </Flex>
      )}
      <Flex
        width="100%"
        data-testid="navbar"
        justifyContent="center"
        bg={colorMode === 'dark' ? 'black' : 'white'}
        color={colorMode === 'dark' ? 'white' : 'black'}
        height={80}
        borderBottom={minimal ? 'none' : '1px solid'}
        borderBottomColor={'gray02'}
        px={3}
      >
        <Flex flex={1} alignItems="center" alignContent="center" position="relative">
          {!minimal && isAuthenticated && showHamburger && (
            <Box
              data-testid="hamburger-icon"
              mr={['2', '3']}
              display={['inline-block', 'inline-block', 'none']}
              onClick={() => onClickMenu()}
              __css={{ cursor: 'pointer' }}
            >
              <Icon.BoxiconsRegular.Menu size={32} />
            </Box>
          )}
        </Flex>
        <Flex alignItems="center">
          {isAuthenticated && !minimal && <WalletOverview />}
          {showSignup && !minimal && !isAuthenticated && (
            <>
              <Text>Already have a wallet?</Text>
              <Text __css={{ cursor: 'pointer', 'text-decoration': 'underline' }} ml={1} onClick={handleSignup}>
                Login
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Navbar;
