/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/authSlice';
import selectedBrandReducer from './brand/selectedBrandSlice';
import modalReducer from './modal/modalSlice';
import sessionReducer from './session/sessionSlice';
import userReducer from './user/userSlice';
import web3Reducer from './web3/web3Slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    web3: web3Reducer,
    selectedBrand: selectedBrandReducer,
    session: sessionReducer,
    modal: modalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/stackModal'],
        ignoredPaths: ['modal.modals'],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
