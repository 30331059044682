import { OAuthExtension } from '@magic-ext/oauth';
import Avalanche from 'avalanche';
import { BigNumber, Contract, ContractInterface, ethers, providers, utils } from 'ethers';
import { _TypedDataEncoder } from 'ethers/lib/utils';
import { Magic } from 'magic-sdk';
import {
  CREATE_POOL_ITEM,
  ICreatePoolItemData,
  ICreatePoolItemVars,
} from 'src/graphql/mutations/createTransactionPoolItem';
import { ICollectionTypes } from 'src/types';
import { v4 as uuidv4 } from 'uuid';

import { MAGIC_KEY } from '../constants';
import { MAGIC_USER, MagicUserData } from '../graphql/queries/magicUser';
import { createMetaTx } from '../utils/metaTx';
import { apollo } from './apolloClient';

const forwarderAddress = process.env.NEXT_PUBLIC_INTERNAL_FORWARDER_BEACON_PROXY_ADDRESS;

/*
 * Avalanche Mainnet  C-Chain     chainId	43114
 * Fuji Testnet       C-Chain     chainId	43113
 */

const createMagic = (key: string) => {
  return (
    typeof window !== 'undefined' &&
    new Magic(key, {
      network: {
        rpcUrl: process.env.NEXT_PUBLIC_AVALANCHE_RPC_MAGIC_LINK,
        chainId: Number(process.env.NEXT_PUBLIC_AVALANCHE_CHAIN_ID),
      },
      extensions: [new OAuthExtension()],
    })
  );
};

export const magic = createMagic(MAGIC_KEY);

const avalanche = new Avalanche(
  new URL(process.env.NEXT_PUBLIC_AVALANCHE_RPC_MAGIC_LINK).hostname,
  undefined,
  'https',
  Number(process.env.NEXT_PUBLIC_AVALANCHE_CHAIN_ID)
);

export interface ICalldata {
  method: string;
  args: (string | number | BigNumber | string[] | number[] | BigNumber[])[];
}

export interface IPermitERC20 {
  spenderAddress: string;
  value: string;
  deadline: string;
}

export const calculateFeeData = async (maxFeePerGas = undefined, maxPriorityFeePerGas = undefined) => {
  const cchain = avalanche.CChain();

  const baseFee = parseInt(await cchain.getBaseFee(), 16) / 1e9;
  maxPriorityFeePerGas =
    maxPriorityFeePerGas == undefined
      ? parseInt(await cchain.getMaxPriorityFeePerGas(), 16) / 1e9
      : maxPriorityFeePerGas;
  maxFeePerGas = maxFeePerGas == undefined ? baseFee + maxPriorityFeePerGas : maxFeePerGas;

  if (maxFeePerGas < maxPriorityFeePerGas) {
    throw 'Error: Max fee per gas cannot be less than max priority fee per gas';
  }

  return {
    maxFeePerGas: maxFeePerGas.toString(),
    maxPriorityFeePerGas: maxPriorityFeePerGas.toString(),
  };
};

export const verifyAuthentication = async () => {
  const isLoggedIn = await magic.user.isLoggedIn();

  // If the user is not logged in anymore, we must authenticate again
  if (!isLoggedIn) {
    try {
      await magic.user.getIdToken();
      return true;
    } catch (error) {
      console.error('Failed to refresh magic session', error);
      console.warn('Initializing new magic auth flow');

      const res = await apollo.client.query<MagicUserData>({
        query: MAGIC_USER,
        fetchPolicy: 'no-cache',
      });

      if (res?.data?.magicUser) {
        const { magicUser } = res.data;

        if (!magicUser.isEmailVerified && !magicUser.isPhoneNumberVerified) {
          throw new Error('Account is not verified');
        }

        try {
          if (magicUser.isPhoneNumberVerified) {
            await magic.auth.loginWithSMS({
              phoneNumber: `+${magicUser.phoneNumber}`,
            });
          } else {
            await magic.auth.loginWithEmailOTP({
              email: magicUser.email,
            });
          }

          return true;
        } catch (err) {
          console.error('Failed to create new magic session', err);
          console.warn('Aborting magic flow');
          return false;
        }
      }

      return false;
    }
  }

  return true;
};

export const runContractABI = async (contratAddress: string, abi: ContractInterface, calldata: ICalldata) => {
  const { maxFeePerGas, maxPriorityFeePerGas } = await calculateFeeData();

  const provider = new providers.Web3Provider(magic.rpcProvider);
  const contract = new Contract(contratAddress, abi, provider.getSigner());

  const tx = await contract[calldata.method](...calldata.args, {
    maxFeePerGas: ethers.utils.parseUnits(maxFeePerGas, 'gwei'),
    maxPriorityFeePerGas: ethers.utils.parseUnits(maxPriorityFeePerGas, 'gwei'),
  });
  const receipt = await tx.wait(8);

  return receipt;
};

export const buildMetaTx = async (
  contractAddress: string,
  contractType: ICollectionTypes,
  abi: utils.Interface,
  _calldata: ICalldata
) => {
  const isLoggedIn = await verifyAuthentication();

  // If the user is not logged in anymore, we must authenticate again
  if (!isLoggedIn) {
    throw new Error('Magic session is not active anymore');
  }

  const provider = new providers.Web3Provider(magic.rpcProvider);

  const { publicAddress } = await magic.user.getMetadata();

  const uuid = uuidv4();
  const gas = (1e6).toString();
  const encodedData = abi.encodeFunctionData(_calldata.method, _calldata.args);

  const metaTx = await createMetaTx(
    provider,
    {
      ref: uuid,
      from: publicAddress,
      to: contractAddress,
      data: encodedData,
      gas,
      value: 0,
    },
    forwarderAddress,
    Number(process.env.NEXT_PUBLIC_AVALANCHE_CHAIN_ID)
  );

  return { publicAddress, metaTx, encodedData, gas, uuid };
};

export const runMetaTx = async (
  contractAddress: string,
  contractType: ICollectionTypes,
  abi: utils.Interface,
  _calldata: ICalldata
) => {
  const { publicAddress, metaTx, encodedData, gas, uuid } = await buildMetaTx(
    contractAddress,
    contractType,
    abi,
    _calldata
  );

  // Add transaction to the pool
  const res = await apollo.client.mutate<ICreatePoolItemData, ICreatePoolItemVars>({
    mutation: CREATE_POOL_ITEM,
    fetchPolicy: 'no-cache',
    variables: {
      walletAddress: publicAddress,
      data: encodedData,
      signature: metaTx.signature,
      raw: {
        function: _calldata.method,
        args: _calldata.args.map((v) => v.toString()),
      },
      contractAddress,
      contractType,
      gas,
      uuid,
    },
  });

  if (res.data?.createPoolItem) {
    return res.data.createPoolItem;
  }

  if (res.errors.length > 0) {
    throw new Error(res.errors[0].message);
  }

  throw new Error('Failed to execute meta transaction');
};

export const signPermit = async (contractAddress: string, abi: utils.Interface, data: IPermitERC20) => {
  const isLoggedIn = await verifyAuthentication();

  // If the user is not logged in anymore, we must authenticate again
  if (!isLoggedIn) {
    throw new Error('Magic session is not active anymore');
  }

  const provider = new providers.Web3Provider(magic.rpcProvider);
  const { publicAddress } = await magic.user.getMetadata();

  // ERC20 Contract
  const contract = new Contract(contractAddress, abi, provider);

  const nonce = await contract.nonces(publicAddress);

  const PERMIT_TYPES = {
    Permit: [
      {
        name: 'owner',
        type: 'address',
      },
      {
        name: 'spender',
        type: 'address',
      },
      {
        name: 'value',
        type: 'uint256',
      },
      {
        name: 'nonce',
        type: 'uint256',
      },
      {
        name: 'deadline',
        type: 'uint256',
      },
    ],
  };

  const DOMAIN = {
    name: await contract.name(),
    version: '1',
    chainId: (await contract.provider.getNetwork()).chainId,
    verifyingContract: contractAddress,
  };

  const VALUES = {
    owner: publicAddress,
    spender: data.spenderAddress,
    value: data.value,
    deadline: data.deadline,
    nonce: nonce.toString(),
  };

  // Get EIP712Domain typed payload
  const payload = _TypedDataEncoder.getPayload(DOMAIN, PERMIT_TYPES, VALUES);

  // Create signature
  const signature: string = await magic.rpcProvider.request({
    method: 'eth_signTypedData_v4',
    params: [publicAddress, JSON.stringify(payload)],
  });

  // Verify if the signature is valid
  const recovered = utils.verifyTypedData(DOMAIN, PERMIT_TYPES, VALUES, signature);
  if (publicAddress.toLowerCase() !== recovered.toLowerCase()) {
    throw new Error('ERC20Permit: invalid signature');
  }

  return {
    values: VALUES,
    signature,
  };
};
