import React, { useMemo } from 'react';
import LoadingCollectibles from 'src/components/LoadingCollectibles';
import { useContractManager } from 'src/hooks/useContractManager';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { IUser } from 'src/interface/IUser';
import { IUserWallet } from 'src/interface/IUserWallet';
import { useAppSelector } from 'src/redux/hooks';
import { ICollectible, ICollection, ICollectionTypes } from 'src/types';
import { convertRemToPx } from 'src/utils';
import { useTheme } from 'styled-components';

import AddressCollectibles from '../AddressCollectibles';

interface UserWalletItemsOptions {
  findCollectible: (tokenId: string, contractAddress: string) => ICollectible;
}

interface UserWalletItemsProps {
  wallet: IUserWallet;
  user: IUser;
  filterTypes?: ICollectionTypes[];
  loadingState?: React.ReactElement;
  children?: (collectibles: ICollectible[], options: UserWalletItemsOptions) => React.ReactElement;
}

const UserWalletItems = ({ wallet, user, filterTypes, loadingState, children }, ref) => {
  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const sessionUser = useAppSelector(({ user }) => user);

  const { loading: loadingCollectibles } = useContractManager();

  const LoadingState = useMemo(
    () => loadingState || <LoadingCollectibles count={isMobile ? 2 : 4} width="150px" height="150px" />,
    [loadingState, isMobile]
  );

  const isUserCollectibleVisible = (collectible?: Pick<ICollectible, 'contractAddress' | 'tokenId' | 'address'>) => {
    if (!collectible) return true;

    const collectibleSettings = wallet.collectiblesSettings.find(
      ({ contractAddress, tokenId }) =>
        contractAddress === collectible.contractAddress && tokenId === (collectible.tokenId || '')
    );

    return collectibleSettings?.visibility ?? true;
  };

  const showUserCollectible = (collectible?: Pick<ICollectible, 'contractAddress' | 'tokenId' | 'address'>) => {
    if (!collectible) return true;

    return sessionUser?.publicAddress == collectible.address || isUserCollectibleVisible(collectible);
  };

  return loadingCollectibles ? (
    LoadingState
  ) : (
    <AddressCollectibles
      ref={ref}
      address={user.cChainPublicAddress}
      collections={(wallet?.relatedContracts || []) as ICollection[]}
      filterTypes={filterTypes}
    >
      {(collectibles, { isLoading, findCollectible }) => {
        if (isLoading) {
          return LoadingState;
        }

        const filteredCollectibles = collectibles.filter((c) =>
          showUserCollectible({
            address: user.cChainPublicAddress,
            contractAddress: c.contractAddress,
            tokenId: c.tokenId,
          })
        );

        return children ? children(filteredCollectibles, { findCollectible }) : null;
      }}
    </AddressCollectibles>
  );
};

export default React.forwardRef<any, UserWalletItemsProps>(UserWalletItems);
