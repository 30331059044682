import React from 'react';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';

export interface ResultProps {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  extra?: React.ReactNode;
}

const Result: React.FC<ResultProps> = ({ title, subtitle, extra = null }) => {
  return (
    <Flex data-testid="result" flexDirection="column" alignItems="center" justifyContent="center" alignContent="center">
      <Box width="140px" height="140px" bg="gray02" borderRadius="100%" mt="4" mb="4" />
      <Flex mb="4" flexDirection="column" alignItems="center" justifyContent="center" alignContent="center">
        {typeof title === 'string' ? (
          <Text variant="text0" textAlign="center">
            {title}
          </Text>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Text textAlign="center" maxWidth="480px">
            {subtitle}
          </Text>
        ) : (
          subtitle
        )}
      </Flex>
      {extra}
    </Flex>
  );
};

export default Result;
