import React, { InputHTMLAttributes } from 'react';

import Box from '../Box/Box';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  variant?: 'default' | 'filled' | 'success' | 'error';
  as?: 'textarea' | 'input';
  rows?: number;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ name, variant, as, rows, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      as={as}
      variant={`input.${variant}`}
      name={name}
      data-testid="input"
      rows={rows}
      {...props}
      __css={{
        display: 'block',
        appearance: 'none',
        outline: 'none',
        paddingY: '10px',
        paddingX: '12px',
        width: '100%',
        variant: 'default',
        borderStyle: 'solid',
        color: 'black',
        fontFamily: 'Inter',
        fontSize: '14px',
      }}
    />
  );
});

Input.defaultProps = {
  variant: 'default',
  disabled: false,
  as: 'input',
  rows: 4,
};

export default Input;
