import { Box, Button, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';

import ContentModal from '../../components/Modals/Content';
import useAuth from '../../hooks/useAuth';

const EmailAlreadyInUseModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  message: string;
}> = ({ isOpen, onClose, message }) => {
  const auth = useAuth();

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      body={
        <Flex padding="20px" flexDirection="column" width={['auto', '250px']}>
          <Flex justifyContent="center">
            <Box __css={{ cursor: 'pointer', position: 'absolute', right: 20 }} onClick={onClose} color={'black'}>
              <Icon.BoxiconsRegular.X size={32} />
            </Box>
            <Flex mt="40px" mb="20px" flexDirection="column" justifyContent="center" alignItems="center">
              <Box my="20px">
                <Text>{message}</Text>
              </Box>
              <Button
                width="100%"
                onClick={() => {
                  auth.logout();
                }}
              >
                Login
              </Button>
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
};

export default EmailAlreadyInUseModal;
