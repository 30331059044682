type CustomBehaviorName = 'BRAND_EVENT_GATED_OUT_USER' | 'UNLOCK_COLLECTIBLE_TO_CREATE_WALLET';

interface IGraphQlError {
  supressToast: boolean;
  customBehaviorName: CustomBehaviorName;
  message: string;
  [key: string]: any;
}

export const extractErrorsFromGraphQl = (error): Array<IGraphQlError> => {
  return error?.graphQLErrors?.map((value) => value.extensions?.exception?.response);
};
