import React from 'react';

import PageLayout from '../components/PageLayout';
import UserWallet from '../containers/UserWallet/UserWallet';
import withAuth from '../hoc/withAuth';
import { AuthStatus } from '../redux/auth/authSlice';
import { useAppSelector } from '../redux/hooks';

const Wallet: React.FC = () => {
  const user = useAppSelector(({ user }) => user);
  const authStatus = useAppSelector(({ auth }) => auth.status);

  return (
    <PageLayout
      isAuthenticated={authStatus === AuthStatus.LOGGED_IN}
      showLogo={authStatus === AuthStatus.LOGGED_OUT}
      centerLogo
      mparticleEventName="page_view_wallet"
      mparticleEventExtraData={{
        is_own: true,
      }}
    >
      <UserWallet user={user} wallet={user.userWallet} activity={user.activity} loading={!user.activity} />
    </PageLayout>
  );
};

export default withAuth(Wallet);
