import React, { ButtonHTMLAttributes } from 'react';

import Flex, { FlexProps } from '../Flex/Flex';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  FlexProps & {
    children?: React.ReactNode;
    variant?: 'primary' | 'secondary' | 'link';
    scale?: 'sm' | 'lg';
    rightIcon?: React.ReactNode;
    leftIcon?: React.ReactNode;
  };

const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ children, leftIcon, rightIcon, variant, scale, ...props }, ref) => {
    return (
      <Flex
        as="button"
        type="submit"
        ref={ref}
        variant={`buttons.${variant}`}
        alignItems="center"
        justifyContent="center"
        opacity={props.disabled ? 0.5 : 1}
        __css={{
          fontFamily: 'Inter',
          fontWeight: scale === 'sm' ? '400' : '400',
          cursor: props.disabled ? 'default' : 'pointer',
          borderRadius: scale === 'sm' ? '24px' : '32px',
          paddingX: scale === 'sm' ? '18px' : '22px',
          paddingY: scale === 'sm' ? '10px' : '12px',
          fontSize: scale === 'sm' ? '14px' : '16px',
        }}
        {...props}
      >
        {leftIcon} {children} {rightIcon}
      </Flex>
    );
  }
);

Button.defaultProps = {
  variant: 'primary',
  scale: 'sm',
};

export default Button;
