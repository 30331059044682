import { gql } from '@apollo/client';
import { ICollectionTypes } from 'src/types';

export interface RedeemEcommRedemptionVars {
  uuid: string;
  quantity: number;
  applyTo: 'checkout' | 'subscription';
  approvalTx?: {
    uuid: string;
    walletAddress: string;
    contractAddress: string;
    contractType: ICollectionTypes;
    data: string;
    signature: string;
    gas: string;
    raw: {
      function: string;
      args: string[];
    };
  };
}

export interface RedeemEcommRedemptionData {
  redeemEcommRedemption: {
    code: string;
    expiredAt?: Date;
  };
}

export const REDEEM_ECOMM_REDEMPTION = gql`
  mutation RedeemEcommRedemption($uuid: String!, $quantity: Float!, $applyTo: String!, $approvalTx: CreatePoolItemDto) {
    redeemEcommRedemption(uuid: $uuid, quantity: $quantity, applyTo: $applyTo, approvalTx: $approvalTx) {
      code
      expiredAt
    }
  }
`;
