import { gql } from '@apollo/client';

import { ICollection } from '../../types';

export interface ICollectionInfoVars {
  contractAddress: string;
}

export interface ICollectionInfoData {
  collectionInfo: Pick<ICollection, 'contractAddress' | 'type'> & {
    account: string;
    brand: {
      id: number;
      name: string;
      uuid: string;
      avatarUrl?: string;
      heroImageUrl?: string;
      websiteUrl?: string;
      urlSlug: string;
    };
  };
}

export const COLLECTION_INFO = gql`
  query CollectionInfo($contractAddress: String!) {
    collectionInfo(contractAddress: $contractAddress) {
      contractAddress
      type
      account
      brand {
        id
        name
        uuid
        avatarUrl
        heroImageUrl
        websiteUrl
        urlSlug
      }
    }
  }
`;
