import mParticle from '@mparticle/web-sdk';

import { getEnvironment } from './index';

const extraInfo = {
  env: getEnvironment(process.env.NEXT_PUBLIC_API_URL),
  appName: 'world-v1',
};

export const logMparticleEvent: typeof mParticle.logEvent = (...params) => {
  const [, , eventInfo] = params;

  params.splice(2, 1, { ...eventInfo, ...extraInfo });

  mParticle.logEvent(...params);
};
