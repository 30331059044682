import mParticle from '@mparticle/web-sdk';
import { Box, Flex } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import { AuthStatus } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks';
import splitRoute from '../../utils/splitRoute';
import Footer from '../Footer';
import Menu from '../Menu';
import Navbar from '../Navbar';

interface PageLayoutProps extends PropsWithChildren {
  showSignup?: boolean;
  showMenu?: boolean;
  showLogo?: boolean;
  minimal?: boolean;
  isAuthenticated?: boolean;
  onSignup?: () => void | null;
  disableLogoRedirect?: boolean;
  noPadding?: boolean;
  backgroundColor?: string;
  hideNavbar?: boolean;
  centerLogo?: boolean;
  colorMode?: 'light' | 'dark' | 'gray';
  minHeight?: string;
  navbarMaxWidth?: string;
  maxHeight?: string;
  mparticleEventName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mparticleEventExtraData?: any;
  hideFooter?: boolean;
  fixedBottomContent?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  showMenu = true,
  showSignup = false,
  minimal = false,
  isAuthenticated = false,
  onSignup = null,
  disableLogoRedirect = false,
  noPadding = false,
  backgroundColor = 'white',
  hideNavbar = false,
  centerLogo = false,
  showLogo = false,
  colorMode = 'gray',
  minHeight = '100vh',
  navbarMaxWidth = '100%',
  maxHeight,
  mparticleEventName,
  mparticleEventExtraData,
  hideFooter = false,
  fixedBottomContent,
}) => {
  const [isMenuFocused, setIsMenuFocused] = useState<boolean>(false);
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const nextRouter = useRouter();
  const fixedComponentRef = useRef(null);

  const show = showMenu && isAuthenticated && !minimal;

  useEffect(() => {
    if (mparticleEventName && authStatus != AuthStatus.LOADING) {
      logMparticleEvent(mparticleEventName, mParticle.EventType.Other, {
        auth_status: authStatus.toLowerCase(),
        ...splitRoute(nextRouter.asPath),
        ...(mparticleEventExtraData || {}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mparticleEventName, authStatus, nextRouter.asPath]);

  return (
    <Flex data-testid="page-layout" bg={backgroundColor} minHeight={minHeight} width="100%">
      {show && (
        <Menu
          isFocused={isMenuFocused}
          onUnfocus={() => setIsMenuFocused(false)}
          disableLogoRedirect={disableLogoRedirect}
        />
      )}
      <Flex
        flex={1}
        flexDirection="column"
        bg={backgroundColor}
        alignItems="center"
        position="relative"
        overflowX="hidden"
      >
        {!hideNavbar && (
          <Navbar
            maxWidth={navbarMaxWidth}
            showHamburger={show}
            showSignup={showSignup}
            isAuthenticated={isAuthenticated}
            onClickMenu={() => setIsMenuFocused(true)}
            minimal={minimal}
            colorMode={colorMode}
          />
        )}
        <Flex
          flex={1}
          pt={noPadding ? '0' : '4'}
          width="100%"
          px={noPadding ? '0' : [3, 3, 6]}
          pb={fixedBottomContent && fixedComponentRef?.current?.clientHeight}
          flexDirection="column"
          alignItems="center"
          maxHeight={maxHeight}
        >
          {children}
        </Flex>
        {!hideFooter && (
          <Footer
            showLogo={showLogo}
            disableLogoRedirect={disableLogoRedirect}
            centerLogo={centerLogo}
            colorMode={colorMode}
          />
        )}
      </Flex>
      {fixedBottomContent && (
        <Flex ref={fixedComponentRef} position="fixed" bottom={0} backgroundColor="white" width="100%">
          <Box width="100%" border="thin" pb="28px" pt="16px" px="20px">
            {fixedBottomContent}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default PageLayout;
