import React from 'react';

import Box from '../Box/Box';

export interface AProps {
  children?: React.ReactNode;
  variant?: 'default';
  href: string;
}

export const A = React.forwardRef<HTMLLinkElement, AProps>(({ href, variant, children, ...props }, ref) => (
  <Box
    as="a"
    href={href}
    ref={ref}
    variant={`link.${variant}`}
    __css={{
      fontFamily: 'Inter',
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'inherit',
    }}
    {...props}
  >
    {children}
  </Box>
));

A.defaultProps = {
  variant: 'default',
};

export default A;
