import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { USE_HEADERS } from '../../constants';

const AxiosProvider = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const token = localStorage.getItem('tybToken');
    if (USE_HEADERS) {
      axios.defaults.headers['Authorization'] = token ? `Bearer ${token}` : '';
    } else {
      axios.defaults.withCredentials = true;
    }
    const brandUuid = router.query['uuid'] || router.query['brandUuid'] || '';
    brandUuid && (axios.defaults.headers['x-brand-uuid'] = brandUuid);
  }, [router.query, router.asPath]);

  return children;
};

export default AxiosProvider;
