import { gql } from '@apollo/client';

import { IUserWallet } from '../../interface/IUserWallet';

export interface GetUserWalletData {
  userWallet: IUserWallet;
}

export const GET_USER_WALLET = gql`
  query userWallet {
    userWallet {
      relatedContracts {
        type
        contractAddress
        collectibleExtraMetadatas {
          contractAddress
          tokenId
          description
          additionalDetails
        }
      }
      collectiblesSettings {
        contractAddress
        tokenId
        visibility
      }
    }
  }
`;
