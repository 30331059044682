import React from 'react';

import Box from '../../Box/Box';
import Flex from '../../Flex/Flex';
import Icon from '../../Icon/Icon';

interface TableRowActionsProps {
  onClickDetail?: () => void | null;
  onClickEdit?: () => void | null;
  onClickDelete?: () => void | null;
}

const TableRowActions: React.FC<TableRowActionsProps> = ({ onClickDetail, onClickEdit, onClickDelete }) => {
  return (
    <Flex>
      {onClickDetail && (
        <a>
          <Box mr="2" onClick={onClickDetail}>
            <Icon.BoxiconsRegular.Detail width={18} height={18} opacity={0.8} />
          </Box>
        </a>
      )}
      {onClickEdit && (
        <a>
          <Box mr="2" onClick={onClickEdit}>
            <Icon.BoxiconsRegular.Edit width={18} height={18} opacity={0.8} />
          </Box>
        </a>
      )}
      {onClickDelete && (
        <a>
          <Box onClick={onClickDelete}>
            <Icon.BoxiconsRegular.Trash width={18} height={18} opacity={0.8} />
          </Box>
        </a>
      )}
    </Flex>
  );
};

export default TableRowActions;
