import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

import ImageCDN from '../ImageCDN';

const TeamMenuItem: React.FC<{
  name: string;
  color: string;
  logoUrl?: string;
  isDark?: boolean;
  size?: string | number;
  showRole?: boolean;
  isSelected?: boolean;
}> = ({ name, color, isDark = false, size = 18, logoUrl, showRole, isSelected }) => {
  return (
    <Flex
      alignItems="center"
      data-testid="team-menu-item"
      p="2"
      backgroundColor={isSelected && 'neutrals-100'}
      __css={{ gap: '0.5rem' }}
    >
      <Flex
        width={size}
        height={size}
        borderRadius="100%"
        bg={logoUrl ? 'white' : color}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        {logoUrl ? (
          <ImageCDN src={logoUrl} height="100%" width="100%" style={{ objectFit: 'cover' }} resizeTo="thumbnail" />
        ) : (
          <Text fontSize="12px" fontWeight="900">
            {name.slice(0, 1)}
          </Text>
        )}
      </Flex>

      <Box>
        <Text
          //color={isSelected ? 'orange' : isDark ? 'white' : 'text'}
          variant={'text5-400'}
          color={isSelected ? 'neutrals-900' : 'neutrals-700'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 128,
          }}
          pb={!showRole ? '2px' : '0'}
        >
          {name}
        </Text>
        {showRole && (
          <Text variant="text6-400" color="neutrals-600" p="0">
            Brand Admin
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default TeamMenuItem;
