// import { TestDistributedTracing } from './TestDistributedTracing';
import TestSharedComponent from '@tyb-browser/lib/components/TestSharedComponent';
import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTheme } from 'styled-components';

import { mainMenu } from '../../config/menu';
import { LaunchDarklyFlags, PAGE_ROUTES, SHOW_BLOCKCHAIN_DETAILS } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { useAppSelector } from '../../redux/hooks';
import { convertRemToPx, getEnvironment, isTybEmployee } from '../../utils';
import { getIconElementFromName } from '../../utils/getIconElementFromName';
import Logo from '../Logo';
import SwitchBrandMenu from '../SwitchBrandMenu';
import TeamMenuItem from '../TeamMenuItem';
import UserAvatar from '../UserAvatar';
import UserWalletAddress from '../UserWalletAddress';

interface MenuContentProps {
  showUser?: boolean;
  isDark?: boolean;
  disableLogoRedirect?: boolean;
}

const MenuContent: React.FC<MenuContentProps> = ({ isDark = false, disableLogoRedirect = false }) => {
  const router = useRouter();
  const user = useAppSelector((state) => state.user);
  const selectedBrandState = useAppSelector((state) => state.selectedBrand);
  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);
  const [showSwitchBrand, setShowSwitchBrand] = useState(false);
  const flags = useFlags();

  const menu = mainMenu;

  return (
    <Flex flex={1} flexDirection="column" data-testid="menu-content">
      <Flex flex={1} flexDirection="column" __css={{ gap: '10px' }}>
        <Flex flexDirection="column" __css={{ gap: '10px' }}>
          <Box px="2">
            <Flex flexDirection="column" alignItems="center" __css={{ gap: '10px' }}>
              <Flex justifyContent="space-between" alignItems="center" height="80px" width="100%" borderBottom="thin">
                <Flex data-testid="menu-user-avatar" alignItems="center">
                  <UserAvatar avatarUrl={user.avatarUrl} size={42} />
                  <Flex justifyContent="center" flexDirection="column">
                    <Text
                      __css={
                        !isMobile && {
                          whiteSpace: 'nowrap',
                          width: 128,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }
                      }
                      color="neutrals-900"
                      variant="text4-500"
                      ml="2"
                      p="0"
                    >
                      {user.userName}
                    </Text>
                    {user.urlSlug && (
                      <Flex alignItems="center" color="neutrals-600">
                        <Box width="6px" />
                        <Icon.BoxiconsRegular.Globe size={14} />
                        <Text
                          __css={
                            !isMobile && {
                              whiteSpace: 'nowrap',
                              width: 128,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }
                          }
                          variant="text5-400"
                          color="neutrals-600"
                          ml="1"
                          p="0"
                        >
                          {`${user.urlSlug}.tyb.xyz`}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                {user.managedBrands.length > 0 && (
                  <Box
                    p="2"
                    color={isDark ? 'gray04' : 'text'}
                    __css={{ cursor: 'pointer' }}
                    onClick={() => setShowSwitchBrand(!showSwitchBrand)}
                  >
                    <Icon.BoxiconsRegular.DotsVerticalRounded size={24} data-testid="menu-drop-down-brands-admin" />
                  </Box>
                )}
              </Flex>

              {showSwitchBrand && user.managedBrands.length > 0 && <SwitchBrandMenu isDark={isDark} />}
            </Flex>
          </Box>

          {SHOW_BLOCKCHAIN_DETAILS && !showSwitchBrand && (
            <Box data-testid="menu-user-wallet-address">
              <UserWalletAddress
                cPublicAddress={user.publicAddress}
                isDark={false}
                borderRadius="8px"
                addressLength={12}
                endAddressLength={4}
              />
            </Box>
          )}
        </Flex>
        {showSwitchBrand && <Box borderTop="thin" />}

        <Flex flexDirection="column">
          {menu.map((item) => {
            if (
              (item.path.includes('/events') && !flags[LaunchDarklyFlags.BRAND_ADMIN_EVENTS]) ||
              (item.path.includes('/api-keys') && !flags[LaunchDarklyFlags.REDEMPTION])
            ) {
              return;
            }
            const isSelected = router.route == item.path || item.altPaths?.includes(router.route);

            return (
              <Flex
                key={item.name}
                flexDirection="column"
                bg={isSelected ? 'neutrals-100' : 'none'}
                borderRadius="4px"
                justifyContent="center"
                px="2"
                py="1"
                __css={{ cursor: 'pointer' }}
                onClick={() => {
                  const pathname = item.path;
                  router.push(pathname, undefined, { shallow: true });
                }}
              >
                <Flex py="2" pr="2" alignItems="center" justifyContent="space-between" borderRadius="md">
                  <Flex alignItems="center">
                    <Box mr="3" color={isSelected ? 'neutrals-900' : 'neutrals-700'}>
                      {getIconElementFromName(item.icon)}
                    </Box>
                    <Text variant="text5-400" color={isSelected ? 'neutrals-900' : 'neutrals-700'}>
                      {item.label}
                    </Text>
                  </Flex>
                </Flex>
                <Flex ml="38px" flexDirection="column" __css={{ gap: '15px' }}>
                  {item?.sub?.map((subItem) => {
                    const isSelected = router.route == subItem.path;

                    return (
                      <Box
                        key={`${item.name}_${subItem.name}`}
                        __css={{ cursor: 'pointer' }}
                        onClick={() => {
                          const shallow = !subItem.query;

                          const query = {
                            ...router.query,
                            ...subItem.query,
                          };

                          const pathname = item.path;

                          router.push({ pathname, query }, undefined, { shallow });
                        }}
                      >
                        <Text variant="text5-400" color={isSelected ? 'neutrals-900' : 'neutrals-700'}>
                          {subItem.name}
                        </Text>
                      </Box>
                    );
                  })}
                </Flex>
              </Flex>
            );
          })}
          {process.env.NODE_ENV === 'development' && isTybEmployee(user.email) && <TestSharedComponent />}
        </Flex>

        {!showSwitchBrand && (
          <Flex flexDirection="column" px={2} pb="50px" flex={1} flexGrow={1}>
            {user.activeBrands.length > 0 && (
              <Flex flexDirection="column" mt="3" flexGrow={1}>
                <Text variant="text5-400" color="neutrals-600" mb="2" mx="2">
                  Your Brands
                </Text>
                <Flex
                  flexDirection="column"
                  flexGrow={1}
                  overflowY="auto"
                  flexBasis={0}
                  data-testid="menu-your-brands-content"
                  mb="6"
                >
                  {[...user.activeBrands]
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true }))
                    .map((brand) => (
                      <Flex
                        key={brand.id}
                        flexDirection="column"
                        __css={{ cursor: 'pointer' }}
                        onClick={() => {
                          router.push(`/${PAGE_ROUTES.BRAND_PUBLIC_PAGE}/${brand.urlSlug}`);
                        }}
                      >
                        <TeamMenuItem
                          isSelected={selectedBrandState?.slug == brand.urlSlug}
                          name={brand.name}
                          logoUrl={brand.avatarUrl}
                          color="#F2C94C"
                          isDark={isDark}
                          size={36}
                        />
                      </Flex>
                    ))}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>

      {
        <Flex
          mb={3}
          alignItems="center"
          data-testid="logo-wrapper"
          onClick={() => {
            if (getEnvironment(process.env.NEXT_PUBLIC_API_URL || '') === 'prod') {
              window.Intercom('shutdown');
            }
            if (!disableLogoRedirect) {
              window.location.href = `http://${process.env.NEXT_PUBLIC_DOMAIN}`;
            }
          }}
          __css={{ cursor: disableLogoRedirect ? 'default' : 'pointer' }}
        >
          <Box opacity={0.5}>
            <Logo colorMode="light" />
          </Box>
        </Flex>
      }
    </Flex>
  );
};

export default MenuContent;
