import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { IntercomAppId } from '../constants';
import { useAppSelector } from '../redux/hooks';

export function useIntercom() {
  const { sub, userName, email } = useAppSelector((state) => state.user);
  const { pathname } = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && userName) {
      window.intercomSettings = {
        app_id: IntercomAppId,
        hide_default_launcher: true,
        email,
        name: userName,
        user_id: sub,
      };
      window.Intercom('boot');
    } else {
      window.Intercom('shutdown');
    }
  }, [email, sub, userName, pathname]);
}
