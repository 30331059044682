import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import React, { ReactElement, useRef, useState } from 'react';

import useOutside from '../../hooks/useOutside/useOutside';

interface DropDownProps {
  position?: 'above' | 'bellow';
  style?: React.CSSProperties;
  children: ReactElement;
  items: {
    icon: React.ReactNode;
    title: string | React.ReactNode;
    subtitle?: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
}

const DropDown: React.FC<DropDownProps> = ({ children, style, items, position = 'bellow' }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  useOutside(wrapperRef, () => setIsVisible(false));

  const height = '36px';

  return (
    <Box style={style} ref={wrapperRef} position="relative" data-testid="drop-down-options">
      <Box onClick={() => setIsVisible(!isVisible)}>{children}</Box>
      {isVisible && (
        <Box
          data-testid="drop-down-box"
          position="absolute"
          borderRadius="md"
          top={position === 'bellow' ? height : undefined}
          bottom={position === 'above' ? height : undefined}
          right="0px"
          elevation="high"
          minWidth="250px"
          bg="white"
          pt="2"
          pb="2"
          zIndex={2}
        >
          {items
            .filter((item) => item)
            .map((item, i) => (
              <Flex
                key={i}
                px="3"
                py="1"
                bg="white"
                __css={!item.disabled ? { cursor: 'pointer' } : { color: 'gray04' }}
                onClick={() => {
                  if (!item.disabled) {
                    setIsVisible(false);
                    item.onClick();
                  }
                }}
              >
                <Box pt="2px" mr="3" opacity={0.7} color={item.disabled ? 'neutrals-600' : 'neutrals-900'}>
                  {item.icon}
                </Box>
                <Box>
                  {React.isValidElement(item.title) ? (
                    item.title
                  ) : (
                    <Text variant="text4-500" color={item.disabled ? 'neutrals-600' : 'neutrals-900'}>
                      {item.title}
                    </Text>
                  )}
                  {item.subtitle && (
                    <Text variant="text6-400" color="neutrals-700">
                      {item.subtitle}
                    </Text>
                  )}
                </Box>
              </Flex>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default DropDown;
