import dayjs from 'dayjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ICacheData {
  expireAt: string;
  value: any;
}

export class BaseCache {
  constructor(private readonly name: string) {}

  /**
   * @param key
   * @param value
   * @param expireIn Seconds to expire the cache
   */
  set(key: string, value: any, expireIn = 86400) {
    const now = dayjs().add(expireIn, 'seconds');

    const cacheData: ICacheData = {
      expireAt: now.toDate().toISOString(),
      value,
    };

    localStorage.setItem(`${this.name}_${key}`, JSON.stringify(cacheData));
  }

  get(key: string) {
    try {
      if (this.has(key)) {
        const cacheData: ICacheData = JSON.parse(localStorage.getItem(`${this.name}_${key}`));
        return cacheData.value;
      }

      return null;
    } catch (err) {
      return null;
    }
  }

  has(key: string) {
    try {
      const storedItem = localStorage.getItem(`${this.name}_${key}`);
      if (!storedItem) {
        return false;
      }

      const cacheData: ICacheData = JSON.parse(storedItem);
      const expireAt = new Date(cacheData.expireAt);

      if (expireAt.getTime() < Date.now()) {
        localStorage.removeItem(`${this.name}_${key}`);
        return false;
      }

      return true;
    } catch {
      return false;
    }
  }
}
