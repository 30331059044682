import React, { SelectHTMLAttributes } from 'react';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  variant?: 'default' | 'filled' | 'success' | 'error';
}

const Select = React.forwardRef<HTMLElement, SelectProps>(({ name, variant, ...props }, ref) => {
  return (
    <Flex>
      <Box
        ref={ref}
        as="select"
        variant={`input.${variant}`}
        name={name}
        data-testid="select"
        {...props}
        __css={{
          fontFamily: 'Inter',
          display: 'block',
          width: '100%',
          appearance: 'none',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          borderWidth: '1px',
          borderColor: 'gray02',
          color: 'inherit',
          bg: 'transparent',
          //paddingY: "8px",
          paddingY: '10px',
          paddingX: '12px',
        }}
      />
      <Flex ml={-32} justifyContent="center" alignItems="center" __css={{ pointerEvents: 'none' }}>
        <Icon.BoxiconsSolid.DownArrow size={12} opacity={0.5} />
      </Flex>
    </Flex>
  );
});

Select.defaultProps = {
  variant: 'default',
};

export default Select;
