import { Box, Flex } from '@tyb-u/tyb-ui-components';
import { ReactNode } from 'react';

type CollectibleMediaOverlayProps = {
  variant?: 'light' | 'dark';
  justifyContent?: string;
  alignItems?: string;
  onClick?: () => void;
  children: ReactNode;
};

export const CollectibleMediaOverlay: React.FC<CollectibleMediaOverlayProps> = ({
  children,
  variant = 'light',
  justifyContent = 'center',
  alignItems = 'center',
  onClick,
}) => {
  const backgroundColor = variant === 'light' ? 'white' : 'black';

  return (
    <>
      <Box position="absolute" mx="auto" top={0} left={0} right={0} bottom={0} zIndex={0} width="80%" height="100%">
        <Flex alignItems="center" justifyContent="center" height="100%">
          {children}
        </Flex>
      </Box>
      <Flex
        zIndex={0}
        justifyContent={justifyContent}
        alignItems={alignItems}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundColor={backgroundColor}
        opacity={0.6}
        onClick={onClick || null}
        __css={onClick && { cursor: 'pointer' }}
      />
    </>
  );
};
