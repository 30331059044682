export const uploadMultipartFile = (url: string, blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', url, true);
    xhr.onreadystatechange = function (this) {
      if (this.status > 0) {
        const etag = this.getResponseHeader('ETag');
        if (etag?.length) {
          resolve(etag);
        } else {
          reject(this.status);
        }
      } else {
        reject(-1);
      }
    };

    xhr.send(blob);
  });
};
