export interface IEventManagerValue {
  address: string;
  type: string;
}

export class EventManager {
  private events: Map<string, Set<string>>;

  constructor() {
    this.events = new Map<string, Set<string>>();
  }

  get(contractAddress: string): Set<string> | null {
    if (!this.events.has(contractAddress)) {
      return null;
    }

    return this.events.get(contractAddress);
  }

  has(contractAddress: string, value: IEventManagerValue) {
    const currentValue = this.get(contractAddress);
    if (currentValue) {
      const hasValue = [value.address, value.type].join('_');

      return currentValue.has(hasValue);
    }

    return false;
  }

  add(contractAddress: string, value: IEventManagerValue) {
    const newValue = [value.address, value.type].join('_');
    const currentValue: Set<string> = this.get(contractAddress) || new Set<string>();

    currentValue.add(newValue);
    this.events.set(contractAddress, currentValue);
  }

  remove(contractAddress: string, value?: IEventManagerValue) {
    if (value) {
      const currentValue = this.get(contractAddress);
      if (currentValue) {
        const removeValue = [value.address, value.type].join('_');
        currentValue.delete(removeValue);

        this.events.set(contractAddress, currentValue);
      }
    } else {
      this.events.delete(contractAddress);
    }
  }
}
