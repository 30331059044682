import { withLDProvider } from 'launchdarkly-react-client-sdk';

const withDarkly = withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_DARKLY_CLIENT_ID,
  context: {
    kind: 'user',
    key: 'anonymous',
    anonymous: true,
  },
});

export default withDarkly;
