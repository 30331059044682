import React from 'react';

import Box from '../Box/Box';
import Text from '../Text/Text';

export interface BadgeProps {
  value: string | number;
  color?: string;
  textColor?: string;
  children?: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ value, color, textColor, children }) => {
  if (!children) {
    return (
      <Box bg={color} p="1" borderRadius="lg" display="inline-block" minWidth="22px">
        <Text
          color={textColor}
          variant="text6-400"
          fontWeight="bold"
          textAlign="center"
          p="0"
          __css={{
            justifyContent: 'center',
            mt: '2px',
          }}
        >
          {value}
        </Text>
      </Box>
    );
  }
  return (
    <Box position="relative" display="inline-block">
      {children}
      <Box bg={color} position="absolute" top="-8px" right="-8px" p="1" borderRadius="lg" minWidth="22px">
        <Text
          color={textColor}
          variant="text6-400"
          fontWeight="bold"
          textAlign="center"
          p="0"
          __css={{
            justifyContent: 'center',
            mt: '2px',
          }}
        >
          {value}
        </Text>
      </Box>
    </Box>
  );
};

Badge.defaultProps = {
  color: 'black',
  textColor: 'white',
};

export default Badge;
