import { useCallback } from 'react';
import { removePendingPermitSignature } from 'src/redux/user/userSlice';

import { apollo } from '../config/apolloClient';
import * as Magic from '../config/magic';
import { IStorePermitSignatureVars, STORE_PERMIT_SIGNATURE } from '../graphql/mutations/storePermitSignature';
import { useAppDispatch } from '../redux/hooks';
import { ContractERC20 } from '../services/contract-manager/contracts/erc20';
import { useContractManager } from './useContractManager';

export const useWalletSignature = () => {
  const { loadContract } = useContractManager();
  const dispatch = useAppDispatch();

  const signERC20Permit = useCallback(
    async (data: { id: string; contractAddress: string; spenderAddress: string; quantity: number }[]) => {
      const isLoggedIn = await Magic.verifyAuthentication();
      if (isLoggedIn) {
        try {
          for (const payload of data) {
            const contract = loadContract(payload.contractAddress, 'ERC20') as ContractERC20;
            const { values, signature } = await contract.permit(payload.spenderAddress, payload.quantity, 2628000 * 12); // 12 months

            await apollo.client.mutate<unknown, IStorePermitSignatureVars>({
              mutation: STORE_PERMIT_SIGNATURE,
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  id: payload.id,
                  permitPayload: {
                    values,
                    signature,
                  },
                },
              },
            });

            dispatch(removePendingPermitSignature(payload.id));
          }
        } catch (err) {
          console.error('[SignERC20Permit] Failed to sign permit data', err?.message);
        }
      }
    },
    []
  );

  return {
    signERC20Permit,
  };
};
