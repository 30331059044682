import { Box, Button, Flex, Input, Text } from '@tyb-u/tyb-ui-components';
import { useMemo, useState } from 'react';
import CollectiblePreview from 'src/components/CollectiblePreview';
import RedeemAll from 'src/components/Redemption/RedeemAll';
import { RedeemEcommRedemptionCheckSubscriptionsData } from 'src/graphql/mutations/redeemEcommRedemptionCheckSubscriptions';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { CoinRedemptionType, ICollectible } from 'src/types';

import { getCoinRedemptionValue } from '../../helpers';

interface CoinRedemptionAmountProps {
  initialAmount: number;
  applyTo: CoinRedemptionType;
  brandName: string;
  collectible: ICollectible;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  applicableSubscription?: RedeemEcommRedemptionCheckSubscriptionsData;
  isMobile: boolean;
  onNextStep: (amount: number) => void;
}

const CoinRedemptionAmount: React.FC<CoinRedemptionAmountProps> = ({
  initialAmount,
  applyTo,
  brandName,
  collectible,
  ecommBrandRedemption,
  applicableSubscription,
  isMobile,
  onNextStep,
}) => {
  const [amount, setAmount] = useState<number>(initialAmount);

  const redemptionValue = useMemo(
    () => getCoinRedemptionValue({ amount, effect: ecommBrandRedemption?.effect }),
    [amount, ecommBrandRedemption?.effect]
  );

  const isAmountUnavailable = useMemo(() => collectible.balance < amount, [collectible.balance, amount]);

  const isAmountOverRedemptionValue = useMemo(
    () => redemptionValue > applicableSubscription?.redeemEcommRedemptionCheckSubscriptions?.nextCharge?.subtotal,
    [redemptionValue, applicableSubscription?.redeemEcommRedemptionCheckSubscriptions?.nextCharge?.subtotal]
  );

  const inputColor = useMemo(() => {
    if (amount === 0) return '#CAC7C7';
    if (isAmountUnavailable || isAmountOverRedemptionValue) return '#FF3236';
    return '#4D4949';
  }, [amount, isAmountUnavailable]);

  const inputAmountSize = useMemo(() => {
    const [oneCount, restCount] = amount
      .toString()
      .split('')
      .reduce((acc, n) => (n == '1' ? [acc[0] + 1, acc[1]] : [acc[0], acc[1] + 1]), [0, 0]);

    return oneCount * 27 + restCount * 37 + 10;
  }, [amount]);

  const nextSubscriptionCharge = useMemo(
    () =>
      `${applicableSubscription?.redeemEcommRedemptionCheckSubscriptions?.nextCharge?.subtotal} ${applicableSubscription?.redeemEcommRedemptionCheckSubscriptions?.nextCharge?.currency}`,
    [applicableSubscription?.redeemEcommRedemptionCheckSubscriptions]
  );

  const errorMessage = useMemo(() => {
    if (isAmountUnavailable) {
      return 'Not enough coins';
    } else if (isAmountOverRedemptionValue) {
      return 'The amount is greater than the next subscription charge.';
    }

    return '';
  }, [isAmountUnavailable, isAmountOverRedemptionValue]);

  const hasError = useMemo(
    () => isAmountUnavailable || isAmountOverRedemptionValue || !amount,
    [isAmountUnavailable, isAmountOverRedemptionValue, amount]
  );

  const handleAmountChange = (event) => {
    if (event.target.value.length <= 9) {
      event.preventDefault();

      const value = Number(event.target.value) || 0;
      setAmount(value);
    }
  };

  const handleRedeemAll = () => setAmount(collectible.balance);

  return (
    <>
      <Flex flexDirection="column" flex={1} __css={{ gap: '10px' }}>
        <Flex flexDirection="column" __css={{ gap: '10px' }}>
          <Text variant="text1" color="neutrals-900">
            Redeem your coins
          </Text>
          <Text color="neutrals-900">
            {applyTo === 'checkout'
              ? `Redeem your coins for a discount in the ${brandName} online store. Note that once you redeem your coins, this action cannot be undone.`
              : 'Redeem your coins for a discount applied to your next subscription charge. Once you confirm, this action cannot be undone.'}
          </Text>
        </Flex>

        <Flex flex={1} flexDirection="column" justifyContent="center" __css={{ gap: '10px' }}>
          <Flex flexDirection="column" __css={{ gap: '10px' }}>
            <Flex justifyContent="center" alignItems="center" __css={{ gap: '5px' }}>
              <Text textAlign="center" variant="text3-400">
                You have <span style={{ fontWeight: 'bold' }}>{collectible.balance}</span> {collectible.metadata.name}{' '}
                {collectible.balance > 1 ? 'coins' : 'coin'}
              </Text>

              {!isMobile && <RedeemAll onClick={handleRedeemAll} />}
            </Flex>
            <Flex justifyContent="center">
              <Box width={`${inputAmountSize}px`}>
                <Input
                  style={{
                    fontSize: '60px',
                    border: 0,
                    outline: 0,
                    padding: '0px',
                  }}
                  width="100%"
                  color={inputColor}
                  name="redeem-coins"
                  type="tel"
                  value={amount}
                  onChange={handleAmountChange}
                  onFocus={(event) => event.target.select()}
                />
              </Box>
              <Flex alignItems="center">
                <CollectiblePreview collectible={collectible} width="50px" height="50px" borderRadius="12px" />
              </Flex>
            </Flex>
            {!isAmountUnavailable && ecommBrandRedemption && (
              <Text textAlign="center" color="neutrals-700">
                {`${redemptionValue} USD`}
              </Text>
            )}

            {errorMessage && (
              <Text textAlign="center" color="#FF3236">
                {errorMessage}
              </Text>
            )}
          </Flex>
          {isMobile && <RedeemAll onClick={handleRedeemAll} />}
          {applicableSubscription && (
            <Box textAlign="center">
              <Text variant="text5-400" color="neutrals-700" display="inline">
                Your next subscription charge is{' '}
              </Text>
              <Text variant="text5-600" color="neutrals-900" display="inline">
                {nextSubscriptionCharge}
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        {!isMobile && (
          <Text variant="text4-400" color="neutrals-900">
            1 / 3 - Enter amount
          </Text>
        )}
        <Button
          disabled={hasError}
          onClick={() => onNextStep(amount)}
          style={isMobile ? { width: '100%', padding: '15px', fontSize: '16px', fontWeight: 600 } : null}
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default CoinRedemptionAmount;
