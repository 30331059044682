// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { ApolloError } from '@apollo/client';
// import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/nextjs';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

import { getEnvironment } from './src/utils';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const environment = getEnvironment(process.env.NEXT_PUBLIC_API_URL || '');

Sentry.init({
  dsn: SENTRY_DSN || 'https://f4be342464af453a8ee47b07af02063d@o1176044.ingest.sentry.io/6273334',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: environment,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true' || environment !== 'development',
  normalizeDepth: 10,
  // integrations: [new SentryFullStory('tyb-qk')],
  beforeBreadcrumb: excludeGraphQLFetch,
  beforeSend: (event, hint) => {
    if (hint?.originalException instanceof ApolloError) {
      // has already been captured with apollo error link
      return null;
    } else {
      if (event.event_id) {
        console.error(
          `Captured exception on sentry: https://tyb-qk.sentry.io/issues/?project=6273334&query=id%3A${event.event_id}`
        );
      }
      return event;
    }
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
