/* eslint-disable @typescript-eslint/no-explicit-any */
import { ethers } from 'ethers';

import { BaseContract, StandardTypes } from './base';
import { EventListener } from './types';

const EventTypeArray = ['CreatedBeaconProxy'] as const;
export type ERC1155BeaconProxyFactoryType = (typeof EventTypeArray)[number];

export type ERC1155BeaconProxyFactoryTypeArgs = {
  ['CreatedBeaconProxy']: {
    uri: string;
    owner: string;
    proxy: string;
  };
};

export class ContractERC1155BeaconProxy extends BaseContract {
  standardName = StandardTypes.ERC1155_BEACON_FACTORY;

  public on<R = ERC1155BeaconProxyFactoryTypeArgs>(
    eventType: ERC1155BeaconProxyFactoryType,
    args: any[],
    listener: EventListener<R>
  ): void {
    const eventFilter = this.contract.filters[eventType as any](...args);

    console.debug(
      '[ContractManager] Listening to event ',
      eventType,
      ' with args ',
      args,
      ' on ContractERC1155BeaconProxy: ',
      this.contractAddress
    );

    this.addEventListener(eventFilter, (...args: any[]) => {
      const event = args[args.length - 1] as ethers.Event;
      const type = event.event as ERC1155BeaconProxyFactoryType;

      switch (type) {
        case 'CreatedBeaconProxy':
          return listener(event, {
            uri: args[0],
            owner: args[1],
            proxy: args[2],
          } as any);
      }
    });
  }
}
