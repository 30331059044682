import { gql } from '@apollo/client';

export interface RedeemEcommRedemptionCheckSubscriptionsVars {
  ecommBrandRedemptionUuid: string;
}

export interface RedeemEcommRedemptionCheckSubscriptionsData {
  redeemEcommRedemptionCheckSubscriptions: {
    isSubscriptionsVendorConfigured: boolean;
    nextCharge: {
      currency: string;
      subtotal: number;
      scheduledAt: Date;
    };
  };
}

export const REDEEM_ECOMM_REDEMPTION_CHECK_SUBSCRIPTIONS = gql`
  query RedeemEcommRedemptionCheckSubscriptions($ecommBrandRedemptionUuid: String!) {
    redeemEcommRedemptionCheckSubscriptions(ecommBrandRedemptionUuid: $ecommBrandRedemptionUuid) {
      isSubscriptionsVendorConfigured
      nextCharge {
        currency
        subtotal
        scheduledAt
      }
    }
  }
`;
