import { Box, Button, Flex } from '@tyb-u/tyb-ui-components';
import { useCallback, useState } from 'react';

import OnboardingTutorial from '../OnboardingTutorial';

const LoginOnboarding: React.FC<{
  loginAction?: () => void;
  isMobile?: boolean;
  brandName?: string;
  brandCoinImage?: string;
}> = ({ loginAction, brandName, brandCoinImage, isMobile = false }) => {
  const [showCTA, setShowCTA] = useState(false);

  const handleItemChange = useCallback((_, lastItem: boolean) => {
    setShowCTA(lastItem);
  }, []);

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Box m="20px" maxWidth={['350px', '450px']}>
        <OnboardingTutorial
          items={[
            {
              image: brandCoinImage || require('../../assets/onboarding/coin.gif'),
              title: 'Where brands and fans win together.',
              description: brandName
                ? `Claim your ${brandName} collectible and create your TYB wallet.`
                : 'Create your TYB account to start earning from the brands you love.',
            },
            {
              image: require('../../assets/onboarding/hands.gif'),
              title: 'Join a community',
              description: 'Become a member to co-create with the brands you love.',
            },
            {
              image: require('../../assets/onboarding/lock.gif'),
              title: 'Unlock access',
              description: 'Your collectibles are your key. Gain access to exclusive products, events, and channels.',
            },
            {
              image: require('../../assets/onboarding/doggy.gif'),
              title: 'Earn rewards',
              description:
                'Complete challenges to earn coins and cash in on product discounts and limited drops— cha-ching!',
            },
            {
              image: require('../../assets/onboarding/wallet.gif'),
              title: 'Track it all',
              description: 'Keep your coins and collectibles safe in your TYB wallet.',
            },
          ]}
          onChange={handleItemChange}
          isMobile={isMobile}
        />
      </Box>
      {isMobile && (
        <>
          <Flex width="100%" flexDirection="column" alignItems="center" mb="20px" height="50px">
            {showCTA ? (
              <Button
                variant="primary"
                scale="lg"
                fontSize="22px"
                py="16px"
                onClick={() => {
                  loginAction && loginAction();
                }}
                width="100%"
                maxWidth={['350px', '450px']}
              >
                Create Your Wallet
              </Button>
            ) : (
              <Button
                variant="link"
                __css={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  loginAction && loginAction();
                }}
              >
                Skip
              </Button>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default LoginOnboarding;
