import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 9999px;
  display: flex;
  gap: 16px;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 100%;
  cursor: pointer;

  .SSO-icon {
    height: 24px;
    position: relative;
    width: 24px;
  }
`;

const WrapperDisabled = styled.div`
  align-items: center;
  background-color: #0000001a;
  color: #0000004d;
  border: 1px solid;
  border-color: #00000000;
  border-radius: 9999px;
  display: flex;
  gap: 16px;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 100%;

  .SSO-icon {
    height: 24px;
    position: relative;
    width: 24px;
  }
`;

export { Wrapper, WrapperDisabled };
