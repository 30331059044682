import { Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { successToast } from 'src/utils/toasts';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

export interface RedemptionCoupon {
  code: string;
  expiredAt: string;
}

interface RedemptionCouponProps {
  coupon: RedemptionCoupon;
}

const RedemptionCoupon: React.FC<RedemptionCouponProps> = ({ coupon }) => {
  const couponExpiration = useMemo(() => {
    const expiredAt = dayjs.utc(coupon.expiredAt).tz('EST');
    const date = expiredAt.format('MMM DD, YYYY');
    // const hour = expiredAt.format('h:mm A');

    return { date };
  }, [coupon?.expiredAt]);

  const handleCouponCodeCopy = () => {
    navigator.clipboard.writeText(coupon.code.toUpperCase());
    successToast('Copied to clipboard!');
  };

  return (
    <>
      <Flex
        alignItems="center"
        p="10px 20px"
        border="1px solid #E1E0E0"
        borderRadius="100px"
        justifyContent="center"
        width={['100%', '350px']}
        __css={{ gap: '10px' }}
      >
        <Text variant="text5-400" data-testid="discount-code-label">
          {coupon?.code?.toUpperCase()}
        </Text>
        <Icon.BoxiconsRegular.Copy onClick={() => handleCouponCodeCopy()} size={16} style={{ cursor: 'pointer' }} />
      </Flex>
      <Flex __css={{ gap: '5px' }}>
        <Text variant="text5-400">This code expires on</Text>
        <Text variant="text5-600" display="inline">
          {couponExpiration.date}
        </Text>
        {/* <Text variant="text5-400" display="inline">
          at
        </Text>
        <Text variant="text5-600" display="inline">
          {couponExpiration.hour}
        </Text> */}
      </Flex>
    </>
  );
};

export default RedemptionCoupon;
