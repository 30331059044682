import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SessionState {
  brandAgeConfirmations: Record<string, boolean>;
}

export const userSlice = createSlice({
  name: 'session',
  initialState: {
    brandAgeConfirmations: {},
  },
  reducers: {
    addBrandAgeConfirmation: (state, action: PayloadAction<string>) => {
      state.brandAgeConfirmations = {
        ...state.brandAgeConfirmations,
        [action.payload]: true,
      };
    },
  },
});

export const { addBrandAgeConfirmation } = userSlice.actions;

export default userSlice.reducer;
