import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

import { IReadNotificationsVars, READ_NOTIFICATIONS } from '../../graphql/mutations/notifications';
import {
  INotificationListData,
  INotificationListVars,
  LIST_UNNOTIFIED_NOTIFICATIONS_WITH_REWARD,
} from '../../graphql/queries/notifications';
import UserNotificationModal, {
  SUPPORTED_MODAL_NOTIFICATIONS,
} from '../../modals/UserNotificationModal/UserNotificationModal';
import { useAppSelector } from '../../redux/hooks';
import { INotification } from '../../types';

const NotificationProvider = ({ children }) => {
  const user = useAppSelector((state) => state.user);

  const { refetch: fetchData } = useQuery<INotificationListData, INotificationListVars>(
    LIST_UNNOTIFIED_NOTIFICATIONS_WITH_REWARD,
    {
      fetchPolicy: 'no-cache',
      variables: {
        names: SUPPORTED_MODAL_NOTIFICATIONS,
      },
      skip: true,
    }
  );

  const [readNotifications] = useMutation<any, IReadNotificationsVars>(READ_NOTIFICATIONS);

  const [notifications, setNotifications] = useState<INotification[]>(null);

  useMemo(() => {
    if (user?.sub) {
      (async () => {
        setNotifications((await fetchData())?.data?.notifications);
      })();
    }
  }, [user?.sub]);

  return (
    <>
      {children}
      {notifications && !!notifications.length && (
        <UserNotificationModal
          notification={notifications[0]}
          onRequestClose={async () => {
            if (notifications.length > 0) {
              readNotifications({
                variables: { notificationUuids: [notifications[0].uuid] },
              });
            }
            setNotifications(notifications.filter((n, ni) => !!ni));
          }}
        />
      )}
    </>
  );
};

export default NotificationProvider;
