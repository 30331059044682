/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, ethers, FixedNumber } from 'ethers';

import { buildMetaTx, ICalldata, runMetaTx } from '../../../config/magic';
import { ICollectionTypes } from '../../../types';
import { MetadataCache } from '../cache/metadataCache';
import {
  EventListener,
  EventType,
  EventTypeArgs,
  IFetchMetadata,
  IIndexedTransaction,
  ParsedEtherEvent,
  ParsedToken,
  ParseTokenOptions,
  PermitSignResult,
  TransferEvents,
} from './types';

export enum StandardTypes {
  ERC1155,
  ERC20,
  ERC721,
  ERC721RARE,
  ERC721Membership,

  ERC1155_BEACON_FACTORY,
  ERC20_BEACON_FACTORY,
  ERC721_BEACON_FACTORY,
  ERC721RARE_BEACON_FACTORY,
}

const delay = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout));
const tryPolling = async <R>(fn: any, max: number, timeout = 5000, times = 0) => {
  if (times < max) {
    try {
      return (await fn()) as R;
    } catch {
      await delay(timeout);
      times++;
      return await tryPolling(fn, max, timeout, times);
    }
  }

  throw new Error(`Failed polling!`);
};

export abstract class BaseContractAbstract {
  public abstract get contractAddress(): string;

  public abstract createQueryFilter(filter: TransferEvents, args?: string[]): ethers.EventFilter;
  public abstract runQueryFilter(filter?: ethers.EventFilter, blockNumber?: number): Promise<ethers.Event[]>;

  public abstract getIndexedWalletTokens(address: string, transactions: IIndexedTransaction[]): Promise<ParsedToken[]>;
  public abstract getWalletTokens(address: string, fromBlockNumber: number): Promise<ParsedToken[]>;
  public abstract getEventToken(
    address: string,
    event: ParsedEtherEvent,
    transactions?: IIndexedTransaction[]
  ): Promise<ParsedToken>;
  public abstract getTokenMetadata(tokenId: string): Promise<IFetchMetadata>;
  public abstract getContractMetadata(): Promise<any>;

  public abstract getAddressBalance(address: string): Promise<any>;

  public abstract getContractTokens(): Promise<ParsedToken[]>;

  public abstract getTotalSupply(): Promise<number | FixedNumber>;

  public abstract permit(spenderAddress: string, value: number, expireInSeconds: number): Promise<PermitSignResult>;

  protected abstract parseToken(...args: any[]): Promise<any>;
  protected abstract parseMetadata(metadata: any): any;

  public abstract on<R = EventTypeArgs>(eventType: EventType, args: any[], listener: EventListener<R>): void;

  protected abstract addEventListener(
    event: string | ethers.EventFilter,
    listener: ethers.providers.Listener
  ): ethers.Contract;

  protected abstract removeEventListener(
    event: string | ethers.EventFilter,
    listener: ethers.providers.Listener
  ): ethers.Contract;
}

export class BaseContract extends BaseContractAbstract {
  standardName: StandardTypes = StandardTypes.ERC1155;

  protected readonly address: string;
  protected contract: ethers.Contract;
  protected readonly iface: ethers.utils.Interface;
  protected metadataUri: string | null;
  protected blockNumber: number;

  private events = new Map<string | ethers.EventFilter, ethers.providers.Listener>([]);
  protected metadataCache = new MetadataCache();

  private abi: any;

  constructor(
    address: string,
    provider: ethers.providers.JsonRpcProvider | ethers.providers.WebSocketProvider,
    iface: ethers.utils.Interface,
    abi: any
  ) {
    super();

    this.address = address;
    this.iface = iface;
    this.abi = abi;
    this.contract = new ethers.Contract(address, abi, provider);

    if (typeof this.contract.getGenesisBlockNumber === 'function') {
      this.contract
        .getGenesisBlockNumber()
        .then((blockNumber: any) => {
          this.blockNumber = blockNumber.toNumber();
        })
        .catch(() => {
          this.blockNumber = null;
          console.log('Could not get genesis block. Probably this is a factory contract');
        });
    } else {
      this.blockNumber = null;
    }
  }

  on<R = EventTypeArgs>(eventType: EventType, args: any[], listener: EventListener<R>) {
    throw new Error('Not implemented!');
  }

  public permit(spenderAddress: string, value: number, expireInSeconds: number): Promise<PermitSignResult> {
    throw new Error('Not implemented!');
  }

  createQueryFilter(filter: TransferEvents, args?: string[]): ethers.EventFilter {
    if (args?.length) {
      return this.contract.filters[filter](...args);
    }

    return this.contract.filters[filter]();
  }

  runQueryFilter(filter?: ethers.EventFilter, blockNumber?: number): Promise<ethers.Event[]> {
    return this.contract.queryFilter(filter, blockNumber);
  }

  async getAddressBalance(address: string): Promise<any> {
    throw new Error('Not implemented!');
  }

  async parseToken(
    tokenId: string,
    transactionHash: string,
    blockNumber: number,
    options?: ParseTokenOptions
  ): Promise<ParsedToken> {
    const { uri, metadata } = await this.getTokenMetadata(tokenId);

    let isOwner = false;
    if (
      (this.collectionType === 'ERC721Rare' || this.collectionType === 'ERC721Membership') &&
      isNaN(options?.balance) &&
      options?.address
    ) {
      const ownerAddress = await this.contract.ownerOf(tokenId);
      isOwner = ownerAddress.toLowerCase() === options.address.toLowerCase();
    }

    return {
      tx: transactionHash,
      blockNumber: blockNumber,
      tokenId: tokenId,
      balance: isNaN(options?.balance) ? (isOwner ? 1 : 0) : options?.balance,
      totalSupply: options?.totalSupply || undefined,
      type: this.collectionType,
      contractAddress: this.contractAddress,
      metadata,
      uri,
    };
  }

  async validateTokenOwnership(address: string, event: ethers.Event, allowZeroBalance?: boolean) {
    const tokenIdArgKey = this.standardName === StandardTypes.ERC1155 ? 'id' : 'tokenId';
    const tokenId = event.args[tokenIdArgKey];

    if ([StandardTypes.ERC721RARE, StandardTypes.ERC721Membership, StandardTypes.ERC721].includes(this.standardName)) {
      const owner = await this.contract.ownerOf(tokenId);
      if (owner.toString().toLowerCase() === address.toLowerCase()) {
        return event;
      }
    }

    if ([StandardTypes.ERC1155, StandardTypes.ERC20].includes(this.standardName)) {
      const balance: BigNumber =
        this.standardName === StandardTypes.ERC1155
          ? await this.contract.balanceOf(address, tokenId)
          : await this.contract.balanceOf(address);
      if (allowZeroBalance || !balance.isZero()) {
        return event;
      }
    }

    return null;
  }

  async getTokenMetadata(tokenId: string): Promise<IFetchMetadata> {
    // Metadata cache key by contract
    const cacheKey = `${this.contractAddress}_${tokenId}`;

    // Get metadata from in-memory cache
    if (this.metadataCache.has(cacheKey)) {
      return {
        metadata: this.metadataCache.get(cacheKey),
        uri: '',
      };
    }

    if (!this.metadataUri && this.standardName === StandardTypes.ERC1155) {
      this.metadataUri = (await this.contract.uri(0)).toString();
    }

    let uri: string = this.metadataUri;

    return tryPolling<any>(async () => {
      if (this.standardName === StandardTypes.ERC1155) {
        uri = uri.replace('{id}', tokenId);
      }
      if (this.standardName !== StandardTypes.ERC1155 && !uri) {
        uri = (await this.contract.tokenURI(tokenId)).toString();
      }

      const metadataResponse = await fetch(uri, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const metadata = await metadataResponse.json();

      // Save metdata to in-memory cache
      this.metadataCache.set(
        cacheKey,
        this.parseMetadata(metadata),
        this.standardName === StandardTypes.ERC721Membership ? 60 : 86400
      );

      return {
        uri,
        metadata: this.metadataCache.get(cacheKey),
      };
    }, 10);
  }

  async getContractMetadata(): Promise<any> {
    // Metadata cache key by contract
    const cacheKey = `contract_${this.contractAddress}`;

    // Get metadata from in-memory cache
    if (this.metadataCache.has(cacheKey)) {
      return this.metadataCache.get(cacheKey);
    }

    const uri = await this.getContractURI();
    if (!uri) return null;

    return tryPolling<any>(async () => {
      const metadataResponse = await fetch(uri, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const metadata = await metadataResponse.json();

      // Save metdata to in-memory cache
      this.metadataCache.set(cacheKey, this.parseMetadata(metadata));

      return this.metadataCache.get(cacheKey);
    }, 10);
  }

  parseMetadata(metadata: any) {
    if (!metadata) return null;

    metadata['animationUrl'] = metadata.animation_url || null;
    metadata['additionalDetails'] = metadata.additional_details || null;
    delete metadata.animation_url;
    delete metadata.additional_details;

    return metadata;
  }

  async getEventToken(
    address: string,
    event: ParsedEtherEvent,
    transactions?: IIndexedTransaction[]
  ): Promise<ParsedToken> {
    throw new Error('Not implemented!');
  }

  async getIndexedWalletTokens(
    address: string,
    transactions: IIndexedTransaction[] = [],
    allowZeroBalance?: boolean
  ): Promise<ParsedToken[]> {
    const events = transactions
      .filter((v) => v.type === this.collectionType)
      .map((v) => ({
        from: v.from,
        to: v.to,
        blockNumber: v.blockNumber,
        txHash: v.txHash,
        tokenId: v.payload.tokenId,
        quantity: Number(v.payload.quantity),
        indexed: true,
      }))
      .reduce((prev, cur) => {
        if (!prev.some((v) => v.tokenId === cur.tokenId)) {
          prev.push(cur);
        }

        return prev;
      }, []);

    let tokens: ParsedToken[] = [];
    if (this.standardName === StandardTypes.ERC1155) {
      tokens = await Promise.all(
        events.map((ev) =>
          this.getEventToken(
            address,
            ev,
            transactions.filter((v) => v.txHash !== ev.txHash)
          )
        )
      );
    } else {
      tokens = await Promise.all(events.map((v) => this.parseToken(v.tokenId, v.txHash, v.blockNumber, { address })));
    }

    return tokens.filter((v) => allowZeroBalance || v.balance > 0);
  }

  async getWalletTokens(address: string, fromBlockNumber: number, allowZeroBalance?: boolean): Promise<ParsedToken[]> {
    // Create event filter
    const eventFilter =
      this.standardName === StandardTypes.ERC1155
        ? this.createQueryFilter('TransferSingle', [null, null, address])
        : this.createQueryFilter('Transfer', [null, address]);

    const tokenIdArgKey = this.standardName === StandardTypes.ERC1155 ? 'id' : 'tokenId';

    // Run query filter
    const transferEvents = await this.runQueryFilter(eventFilter, fromBlockNumber);
    const onlyOwnedTokens = await Promise.all(
      transferEvents.map((event) => this.validateTokenOwnership(address, event, allowZeroBalance))
    );

    const events = onlyOwnedTokens
      .filter((v) => !!v)
      .map((v) => ({
        from: v.args['from'],
        to: v.args['to'],
        blockNumber: v.blockNumber,
        txHash: v.transactionHash,
        tokenId: v.args[tokenIdArgKey].toNumber().toString(),
        indexed: false,
      }));

    if (this.standardName === StandardTypes.ERC1155) {
      return Promise.all(events.map((ev) => this.getEventToken(address, ev)));
    } else {
      return Promise.all(events.map((v) => this.parseToken(v.tokenId, v.txHash, v.blockNumber)));
    }
  }

  async getContractTokens(): Promise<ParsedToken[]> {
    // Create event filter
    const eventFilter =
      this.standardName === StandardTypes.ERC1155
        ? this.createQueryFilter('TransferSingle')
        : this.createQueryFilter('Transfer');

    const tokenIdArgKey = this.standardName === StandardTypes.ERC1155 ? 'id' : 'tokenId';

    const transferEvents = await this.runQueryFilter(eventFilter, this.blockNumber);
    const events = transferEvents.map((v) => ({
      from: v.args['from'],
      to: v.args['to'],
      operator: v.args['operator'] || undefined,
      blockNumber: v.blockNumber,
      txHash: v.transactionHash,
      tokenId: v.args[tokenIdArgKey].toNumber().toString(),
      indexed: false,
    }));

    if (this.standardName === StandardTypes.ERC1155) {
      return Promise.all(events.map((ev) => this.getEventToken(ev.operator, ev)));
    } else {
      return Promise.all(events.map((v) => this.parseToken(v.tokenId, v.txHash, v.blockNumber)));
    }
  }

  async getContractURI(): Promise<string> {
    if (
      [
        StandardTypes.ERC20,
        StandardTypes.ERC721,
        StandardTypes.ERC721RARE,
        StandardTypes.ERC721Membership,
        StandardTypes.ERC1155,
      ].includes(this.standardName)
    ) {
      return this.contract.contractURI();
    }

    return undefined;
  }

  async getTotalSupply(): Promise<number | FixedNumber> {
    if (
      [StandardTypes.ERC20, StandardTypes.ERC721, StandardTypes.ERC721RARE, StandardTypes.ERC721Membership].includes(
        this.standardName
      )
    ) {
      return (await this.contract.totalSupply())?.toNumber();
    }

    return undefined;
  }

  async transfer(from: string, to: string, args: { tokenId?: string; amount: number | BigNumber }) {
    if (
      ![
        StandardTypes.ERC20,
        StandardTypes.ERC1155,
        StandardTypes.ERC721,
        StandardTypes.ERC721RARE,
        StandardTypes.ERC721Membership,
      ].includes(this.standardName)
    ) {
      throw new Error('Unsupported contract type for transfer');
    }

    let calldata: ICalldata;
    switch (this.standardName) {
      case StandardTypes.ERC1155:
        calldata = {
          method: 'safeTransferFrom',
          args: [from, to, args.tokenId, args.amount || 1, []],
        };
        break;
      case StandardTypes.ERC721:
      case StandardTypes.ERC721RARE:
      case StandardTypes.ERC721Membership:
        calldata = {
          method: 'transferFrom',
          args: [from, to, args.tokenId],
        };
        break;
      default:
        calldata = {
          method: 'transfer',
          args: [to, args.amount || 1],
        };
    }

    const tx = await runMetaTx(this.contractAddress, this.collectionType, this.iface, calldata);
    return tx.uuid;

    // const receipt = await runContractABI(this.contractAddress, this.iface, calldata);
    // return receipt;
  }

  async approval(adress: string, args: { amount: number | BigNumber }) {
    if (![StandardTypes.ERC20].includes(this.standardName)) {
      throw new Error('Unsupported contract type for transfer');
    }

    let calldata: ICalldata;
    switch (this.standardName) {
      default:
        calldata = {
          method: 'approve',
          args: [adress, args.amount || 1],
        };
    }

    const tx = await runMetaTx(this.contractAddress, this.collectionType, this.iface, calldata);
    return tx.uuid;
  }

  async approvalSignature(adress: string, args: { amount: number | BigNumber }) {
    if (![StandardTypes.ERC20].includes(this.standardName)) {
      throw new Error('Unsupported contract type for transfer');
    }

    let calldata: ICalldata;
    switch (this.standardName) {
      default:
        calldata = {
          method: 'approve',
          args: [adress, args.amount || 1],
        };
    }

    const approvalTx = await buildMetaTx(this.contractAddress, this.collectionType, this.iface, calldata);
    return {
      contractAddress: this.contractAddress,
      collectionType: this.collectionType,
      calldata,
      ...approvalTx,
    };
  }

  protected addEventListener(event: string | ethers.EventFilter, listener: ethers.providers.Listener) {
    if (this.events.has(event)) {
      this.contract.off(event, this.events.get(event));
      this.events.delete(event);
    }

    this.events.set(event, listener);
    return this.contract.on(event, listener);
  }

  protected removeEventListener(event: string | ethers.EventFilter, listener: ethers.providers.Listener) {
    this.events.delete(event);
    return this.contract.off(event, listener);
  }

  public reload(provider: ethers.providers.JsonRpcProvider | ethers.providers.WebSocketProvider) {
    console.debug('[ContractManager] Reloading contract: ', this.address);
    this.contract = new ethers.Contract(this.address, this.abi, provider);

    this.events.forEach((listener, ev) => {
      this.contract.on(ev, listener);
    });
  }

  public get contractAddress(): string {
    return this.address;
  }

  public destroy() {
    this.contract.removeAllListeners();
    this.contract = null;
  }

  public get collectionType(): ICollectionTypes {
    switch (this.standardName) {
      case StandardTypes.ERC1155:
        return 'ERC1155';
      case StandardTypes.ERC721:
        return 'ERC721';
      case StandardTypes.ERC721RARE:
        return 'ERC721Rare';
      case StandardTypes.ERC721Membership:
        return 'ERC721Membership';
      default:
        return 'ERC20';
    }
  }
}
