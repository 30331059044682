import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import { useEffect, useState } from 'react';

import { useContractManager } from '../../hooks/useContractManager';
import { ICollectible, ICollection, ICollectionTypes } from '../../types';
import { getCollectibleMetadata } from '../../utils/getCollectibleMetadata';

type AddressCollectiblesOptions = {
  isLoading: boolean;
  isEmpty: boolean;
  isReady: boolean;
  findCollectible: (tokenId: string, contractAddress: string) => ICollectible;
};

export type AddressCollectiblesChangeEvent = AddressCollectiblesOptions & {
  collectibles: ICollectible[];
};

type AddressCollectiblesProps = {
  address: string;
  collections: ICollection[];
  filterTypes?: ICollectionTypes[];
  fetch?: boolean;
  asContractOwner?: boolean;
  onChange?: (event?: AddressCollectiblesChangeEvent) => void;
  onCompleted?: (collectibles: ICollectible[], address: string) => void;
  children?: (collectibles: ICollectible[], options?: AddressCollectiblesOptions) => React.ReactElement;
};

const dedupeCollectibles = (collectibles: ICollectible[]): ICollectible[] =>
  collectibles.reduce<ICollectible[]>((acc, collectible) => {
    const collectibleNotAdded = !acc.some(({ contractAddress, tokenId }) =>
      compareCollectible({ contractAddress, tokenId, collectible })
    );

    return collectibleNotAdded ? [...acc, collectible] : acc;
  }, []);

const compareCollectible = ({
  contractAddress,
  tokenId = '',
  collectible,
}: {
  contractAddress: string;
  tokenId?: string;
  collectible: ICollectible;
}): boolean =>
  [contractAddress, tokenId || ''].join('_') === [collectible.contractAddress, collectible.tokenId || ''].join('_');

const AddressCollectibles: React.ForwardRefRenderFunction<any, AddressCollectiblesProps> = (props, ref) => {
  const {
    address,
    collections: propCollections,
    filterTypes = [],
    fetch = true,
    asContractOwner = false,
    onChange = () => null,
    onCompleted,
    children,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [collectibles, setCollectibles] = useState<ICollectible[]>([]);

  const {
    loadContract,
    loadAddressTokens,
    loadAddressBalance,
    getMaxSupply,
    getTotalSupply,
    getOwnerCollectibles,
    isAddressLoaded,
    globalLoading,
    collectibles: allCollectibles,
  } = useContractManager();

  const findCollectible = useCallback(
    (tokenId: string, contractAddress: string) =>
      collectibles.find(
        (v) => [v.contractAddress, v.tokenId || ''].join('_') === [contractAddress, tokenId || ''].join('_')
      ) || null,
    [collectibles]
  );

  const load = useCallback(
    async (collections: ICollection[], refetch?: boolean) => {
      // Loading flag
      setIsLoading(!refetch);

      const __collectibles: ICollectible[] = [];

      onChange({
        isLoading: !refetch,
        isEmpty: false,
        isReady: false,
        collectibles: [],
        findCollectible,
      });

      await Promise.allSettled(
        collections.map(async (collection) => {
          const shouldFetch = !refetch ? (fetch ? !isAddressLoaded(address, collection.contractAddress) : false) : true;

          // Is address contract owner?
          const isContractOwner = asContractOwner || collection.address === address;
          const filterMatch = filterTypes.length === 0 || filterTypes.includes(collection.type);

          if (filterMatch) {
            if (collection.type === 'ERC20') {
              const contract = loadContract(collection.contractAddress, 'ERC20');
              const metadata = await contract.getContractMetadata();
              const symbol = await contract.getSymbol();

              // Get balance
              const balance = await loadAddressBalance(collection.contractAddress, address);

              if (isContractOwner || !balance.isZero()) {
                const collectible = {
                  balance: balance.toUnsafeFloat(),
                  metadata,
                  extraMetadata: {
                    tokenId: null,
                    contractAddress: collection.contractAddress,
                    ...getCollectibleMetadata({
                      metadata: metadata,
                      collectibleExtraMetadatas: collection.collectibleExtraMetadatas,
                      contractAddress: collection.contractAddress,
                    }),
                  },
                  contractAddress: collection.contractAddress,
                  type: 'ERC20',
                  tokenId: null,
                  uri: '',
                  symbol,
                } as ICollectible;

                __collectibles.push(collectible);
              }
            } else {
              const isERC721 = ['ERC721', 'ERC721Rare', 'ERC721Membership'].includes(collection.type);

              // If we are handling a contract owner for 721, we have the metadata already, so no request must be made
              if (isERC721 && isContractOwner) {
                const [maxSupply, totalSupply] = await Promise.all([
                  getMaxSupply(collection.contractAddress, collection.type),
                  getTotalSupply(collection.contractAddress, collection.type),
                ]);

                const collectible = {
                  balance: 1,
                  metadata: collection.baseCollectibleMetadata,
                  extraMetadata: {
                    tokenId: null,
                    contractAddress: collection.contractAddress,
                    ...getCollectibleMetadata({
                      metadata: collection.baseCollectibleMetadata,
                      collectibleExtraMetadatas: collection.collectibleExtraMetadatas,
                      contractAddress: collection.contractAddress,
                    }),
                  },
                  contractAddress: collection.contractAddress,
                  type: collection.type,
                  tokenId: null,
                  totalSupply,
                  maxSupply,
                  uri: '',
                } as ICollectible;

                __collectibles.push(collectible);
              } else {
                if (shouldFetch) {
                  const result = await loadAddressTokens(
                    address,
                    collection.contractAddress,
                    collection.type,
                    refetch,
                    isContractOwner
                  );

                  __collectibles.push(...result);
                } else {
                  __collectibles.push(...getOwnerCollectibles(address));
                }
              }
            }
          }
        })
      );

      if (refetch) {
        setCollectibles([]);
      }

      setCollectibles((state) => [...state, ...__collectibles]);

      // Loading flag
      setIsLoading(false);
    },
    [
      address,
      asContractOwner,
      fetch,
      filterTypes,
      findCollectible,
      getMaxSupply,
      getOwnerCollectibles,
      getTotalSupply,
      isAddressLoaded,
      loadAddressBalance,
      loadAddressTokens,
      loadContract,
      onChange,
    ]
  );

  useEffect(() => {
    if (!isLoading && onCompleted) {
      onCompleted(collectibles, address);
    }
  }, [isLoading, onCompleted, address]);

  useEffect(() => {
    if (!globalLoading && !isLoaded && propCollections.length > 0) {
      load(propCollections);
    }

    setIsLoaded(true);
  }, [propCollections, globalLoading, isLoaded]);

  useMemo(() => {
    setCollectibles((state) => {
      if (state.length !== allCollectibles.length) {
        const __collectibles = getOwnerCollectibles(address).filter(
          ({ type }) => filterTypes.length === 0 || filterTypes.includes(type)
        );

        const dedupedCollectibles = dedupeCollectibles([...state, ...__collectibles]);

        onChange({
          findCollectible,
          isLoading: false,
          isEmpty: dedupedCollectibles.length === 0,
          isReady: true,
          collectibles: dedupedCollectibles,
        });

        return dedupedCollectibles;
      }

      return state;
    });
  }, [allCollectibles]);

  useImperativeHandle(ref, () => ({
    load,
  }));

  const uniqCollectibles = useMemo(() => dedupeCollectibles(collectibles), [collectibles]);

  return children
    ? children(uniqCollectibles, {
        isLoading,
        isEmpty: isLoaded && !isLoading && uniqCollectibles.length === 0,
        isReady: isLoaded && !isLoading,
        findCollectible,
      })
    : null;
};

export default React.forwardRef(AddressCollectibles);
