import { Flex, Icon } from '@tyb-u/tyb-ui-components';
import { useEffect } from 'react';
import { updateFullscreenOverflow } from 'src/utils/dom';

import MenuContent from '../MenuContent';

interface MenuProps {
  isFocused?: boolean;
  onUnfocus: () => void;
  disableLogoRedirect?: boolean;
}

const Menu: React.FC<MenuProps> = ({ isFocused, onUnfocus, disableLogoRedirect = false }) => {
  const isDark = isFocused ? false : true;

  useEffect(() => {
    updateFullscreenOverflow({ isFullscreen: isFocused, hideContent: true });
  }, [isFocused]);

  return (
    <>
      <Flex data-testid="menu" display={['none', 'none', 'flex']} bg="neutrals-50">
        <Flex minWidth="256px" ml="3" mr="4">
          <Flex position="fixed" minWidth="inherit" height="100vh" flexDirection="column" overflow="auto">
            <MenuContent isDark={isDark} disableLogoRedirect={disableLogoRedirect} />
          </Flex>
        </Flex>
      </Flex>
      {isFocused && (
        <Flex
          data-testid="menu-fullscreen"
          bg="neutrals-50"
          zIndex={1}
          width="100%"
          position="fixed"
          display={['flex', 'flex', 'none']}
        >
          <Flex width="100%" height="100vh">
            <Flex flexDirection="column" width="100%" overflow="auto">
              <Flex p="1" height="50px" alignItems="center" onClick={() => onUnfocus()} __css={{ cursor: 'pointer' }}>
                <Icon.BoxiconsRegular.X size={36} />
              </Flex>
              <Flex flex={1} pl="2">
                <MenuContent showUser isDark={isDark} disableLogoRedirect={disableLogoRedirect} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Menu;
