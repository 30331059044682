import { useCallback } from 'react';
import { Props } from 'react-modal';

import { useAppDispatch } from '../redux/hooks';
import { closeModal, stackModal } from '../redux/modal/modalSlice';

const useModal = () => {
  const dispatch = useAppDispatch();

  const open = useCallback(
    (key: string, Component: React.ReactNode, props?: Props) => {
      dispatch(stackModal({ key, Component, props }));
    },
    [stackModal]
  );

  const close = useCallback(
    (key: string) => {
      dispatch(closeModal({ key }));
    },
    [closeModal]
  );

  return {
    open,
    close,
  };
};

export default useModal;
