import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPublicBrand } from '../../interface/IPublicBrand';

interface State {
  loading: boolean;
  slug: string | null;
  data: IPublicBrand | null;
  dateTime: number;
}

const initialState: State = {
  loading: true,
  slug: null,
  data: null,
  dateTime: 0,
};

export const selectedBrandSlice = createSlice({
  name: 'selectedBrand',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.slug = action.payload;
    },
    setBrand: (state, action: PayloadAction<IPublicBrand>) => {
      state.slug = action.payload.urlSlug;
      state.data = action.payload;
      state.loading = false;
      state.dateTime = new Date().getTime();
    },
    resetBrand: () => {
      return initialState;
    },
  },
});

export const { setLoading, setBrand, resetBrand } = selectedBrandSlice.actions;

export default selectedBrandSlice.reducer;
