import { useMutation } from '@apollo/client';
import { Icon } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';

import { UserWalletButton } from '../../components/Button/UserWalletButton';
import { CREATE_DM, CreateDMData, CreateDMVars } from '../../graphql/mutations/channels';
import { IUser } from '../../interface/IUser';

export const SendDirectMessage = memo<{ user: IUser }>(({ user }) => {
  const router = useRouter();
  const [createDM] = useMutation<CreateDMData, CreateDMVars>(CREATE_DM);

  const { cChainPublicAddress } = user;

  const handleSendDM = useCallback(async () => {
    const res = await createDM({
      variables: {
        walletAddress: cChainPublicAddress,
      },
    });
    if (res?.data?.createDM) {
      router.push(`/channels?channel_id=${res.data.createDM}`);
    }
  }, [createDM, cChainPublicAddress, router]);

  return <UserWalletButton iconCls={Icon.BoxiconsRegular.Chat} text="Message" onClick={handleSendDM} />;
});
