import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo, useState } from 'react';

import { LaunchDarklyFlags } from '../../constants';
import { ICollectibleMetadata, ICollectionTypes } from '../../types';
import getThumbnail from '../../utils/getThumbnail';
import ImageCDN from '../ImageCDN';
import VideoCDN from '../VideoCDN';
// import CollectibleMediaOverlay from './components/CollectibleMediaOverlay';
import { CollectibleMediaOverlay } from './CollectibleMediaOverlay';

interface CollectibleMediaProps {
  metadata: ICollectibleMetadata;
  width: number | string | string[];
  height: number | string | string[];
  size?: 'sm' | 'md' | 'lg' | null;
  videoProps?: any;
  overlayContent?: string | React.ReactNode;
  tx?: string;
  tokenBalance?: string | number;
  tokenId?: string;
  type?: ICollectionTypes;
  containerProps?: any;
  hideBadges?: boolean;
  borderRadius?: string | number;
  showVideo?: boolean;
}

const CollectibleMedia: React.FC<CollectibleMediaProps> = ({
  metadata,
  width,
  height,
  size = 'md',
  videoProps,
  overlayContent,
  tx,
  tokenBalance,
  tokenId,
  type,
  containerProps,
  borderRadius = '0',
  hideBadges = 'false',
  showVideo: propShowVideo = false,
}) => {
  const [loadedMedia, setLoadedMedia] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState(propShowVideo);
  const [imageUrl, setImageUrl] = useState<string>();
  const flags = useFlags();

  const objectFit = useMemo(
    () => (flags[LaunchDarklyFlags.COLLECTIBLE_MEDIA_OBJECT_FIT] ? 'cover' : 'contain'),
    [flags]
  );

  const getVideoGifVersion = () => {
    const baseFallbackUrl = `https://${process.env.NEXT_PUBLIC_COLLECTIBLES_ASSETS_BUCKET}-resized.s3.us-west-2.amazonaws.com/preview`;
    if (metadata?.animationUrl) {
      const url = metadata.animationUrl.toLowerCase();
      if (url.endsWith('.gif')) {
        return url;
      }

      const file = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.mp4'));
      return `${baseFallbackUrl}/${file}.gif`;
    }

    return '';
  };

  const handleMediaError = (ev) => {
    console.log('handleMediaError', ev?.target?.currentSrc);
    if (metadata?.animationUrl) {
      setShowVideo(true);
    }
  };

  const handleMediaLoaded = () => {
    setLoadedMedia(true);
  };

  return (
    <Box
      width={width}
      height={!Array.isArray(height) ? height : 'unset'}
      maxHeight={Array.isArray(height) ? height : 'unset'}
      backgroundColor="transparent"
      overflow="hidden"
      __css={{
        position: 'relative',
        animation: !loadedMedia ? 'collectibleLoading 1s infinite alternate' : undefined,
        borderRadius,
      }}
      {...containerProps}
      data-testid="collectible-media"
    >
      {metadata?.animationUrl ? (
        <Flex
          height={height}
          width={width}
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          {...containerProps}
          data-testid="collectible-media-video"
        >
          {showVideo ? (
            <VideoCDN
              height="100%"
              muted
              autoPlay
              controls={false}
              loop
              width="100%"
              playsInline
              preload="metadata"
              src={metadata?.animationUrl}
              {...videoProps}
              style={{ display: loadedMedia ? 'block' : 'none', objectFit: objectFit, borderRadius }}
              onLoadedData={handleMediaLoaded}
            />
          ) : (
            <ImageCDN
              display="block"
              borderRadius="none"
              height={height.toString()}
              width="100%"
              src={getVideoGifVersion()}
              style={{ objectFit: objectFit }}
              onLoad={handleMediaLoaded}
              onError={handleMediaError}
            />
          )}
        </Flex>
      ) : (
        <Box height={height} width={width} overflow="hidden" {...containerProps}>
          {metadata?.image && (
            <>
              <img
                src={getThumbnail(metadata.image, size)}
                alt="Collectible preload media"
                onLoad={() => {
                  setImageUrl(getThumbnail(metadata?.image, size));
                }}
                onError={() => {
                  setImageUrl(metadata.image);
                }}
                style={{ display: 'none' }}
              />

              {imageUrl && (
                <ImageCDN
                  display={loadedMedia ? 'block' : 'none'}
                  borderRadius="0px"
                  width="100%"
                  height={height}
                  src={imageUrl}
                  style={{ objectFit: objectFit }}
                  onLoad={handleMediaLoaded}
                  onError={handleMediaError}
                />
              )}
            </>
          )}
        </Box>
      )}

      {overlayContent && <CollectibleMediaOverlay>{overlayContent}</CollectibleMediaOverlay>}

      {!!tokenBalance && !hideBadges && (
        <CollectibleMediaOverlay>
          <Text
            textAlign="center"
            backgroundColor="white"
            borderRadius={10}
            variant="text5-400"
            elevation="high"
            px={2}
          >
            Balance: {tokenBalance}
          </Text>
        </CollectibleMediaOverlay>
      )}

      {type === 'ERC721Rare' && !hideBadges && (
        <CollectibleMediaOverlay>
          <Text
            textAlign="center"
            backgroundColor="white"
            borderRadius={10}
            variant="text5-400"
            elevation="high"
            px={2}
          >
            RARE
          </Text>
        </CollectibleMediaOverlay>
      )}

      {tokenId && tx && !hideBadges && (
        <CollectibleMediaOverlay>
          <Text
            textAlign="center"
            backgroundColor="white"
            borderRadius={10}
            variant="text5-400"
            elevation="high"
            px={2}
          >
            Collectible unique #: {tokenId}
          </Text>
        </CollectibleMediaOverlay>
      )}
    </Box>
  );
};

export default CollectibleMedia;
