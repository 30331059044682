import mParticle from '@mparticle/web-sdk';
import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import copy from 'copy-to-clipboard';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CollectionTypes, SHOW_BLOCKCHAIN_DETAILS, UserWalletTabs } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import { isUserCollectibleVisible } from 'src/utils/isUserCollectibleVisible';
import { logMparticleEvent } from 'src/utils/mparticle';
import { useTheme } from 'styled-components';

import BioSocial from '../../components/BioSocial';
import PusherConsumer from '../../components/PusherConsumer';
import UserAvatar from '../../components/UserAvatar';
import UserWalletAddress from '../../components/UserWalletAddress';
import WalletActivity from '../../components/WalletActivity';
import { useContractManager } from '../../hooks/useContractManager';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { IUser } from '../../interface/IUser';
import { IUserWallet } from '../../interface/IUserWallet';
import { IWallletActivity } from '../../interface/IWallletActivity';
import CollectibleViewModal from '../../modals/CollectibleViewModal/CollectibleViewModal';
import EmailVerification from '../../modals/EmailVerification/EmailVerification';
import UserEditBioModal from '../../modals/UserEditBioModal/UserEditBioModal';
import { AuthStatus } from '../../redux/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setEmailVerified } from '../../redux/user/userSlice';
import { ICollectible } from '../../types';
import { convertRemToPx } from '../../utils';
import { getCollectibleMetadata } from '../../utils/getCollectibleMetadata';
import { successToast } from '../../utils/toasts';
import { SendDirectMessage } from './SendDirectMessage';
import UserWalletCoins from './UserWalletCoins';
import UserWalletCollectibles from './UserWalletCollectibles';
import WalletTabs from './UserWalletTabs';

type UserWalletProps = {
  user: IUser;
  wallet: IUserWallet;
  fullScreen?: boolean;
  activity: IWallletActivity[];
  loading: boolean;
};

const UserWallet: React.FC<UserWalletProps> = ({ user, wallet, fullScreen, activity, loading }) => {
  const dispatch = useAppDispatch();
  const sessionUser = useAppSelector(({ user }) => user);
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const { fetchWallet } = useAuth();
  const size = useWindowSize();
  const theme = useTheme();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<UserWalletTabs>(UserWalletTabs.Collectibles);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [isCollectibleModalOpen, setIsCollectibleModalOpen] = useState<boolean>(false);
  const [collectibleData, setCollectibleData] = useState<ICollectible>();
  const [isCoin, setIsCoin] = useState<boolean>(false);
  const [pendingTransactions, setPendingTransactions] = useState<IWallletActivity[]>([]);

  const [emailVerificationModalIsOpen, setEmailVerificationModalIsOpen] = useState(
    !!sessionUser.sub && !sessionUser.emailVerified
  );

  const addressCollectiblesRef = useRef<any>();

  const linkPublicPage = user?.urlSlug
    ? `https://${user.urlSlug}.${
        process.env.NEXT_PUBLIC_DOMAIN.startsWith('app.')
          ? process.env.NEXT_PUBLIC_DOMAIN.replace('app.', '')
          : process.env.NEXT_PUBLIC_DOMAIN
      }`
    : '';

  const { getOwnerCollectibles } = useContractManager();

  const newCollectibles = getOwnerCollectibles(user.cChainPublicAddress).filter((v) => v.byEvent);

  const handleCollectibleViewModalClose = useCallback(
    async (submitted?: boolean) => {
      if (submitted && wallet.relatedContracts) {
        await addressCollectiblesRef.current?.load(wallet.relatedContracts, true);
      }

      setIsCollectibleModalOpen(false);
    },
    [wallet]
  );

  useMemo(() => {
    if (isMobile && pendingTransactions.length > 0) {
      setSelectedTab(UserWalletTabs.Activity);
    } else {
      setSelectedTab(UserWalletTabs.Collectibles);

      if (newCollectibles.length > 0) {
        successToast(`You've received a collectible!`);
      }
    }
  }, [pendingTransactions.length, newCollectibles.length, isMobile]);

  useEffect(() => {
    if (activity?.length) {
      setPendingTransactions((state) => {
        activity
          .filter((item) => item.collectible?.metadata)
          .map((item) => {
            if (item.isPending && !state.some((v) => v.uuid === item.uuid)) {
              state = [item, ...state];
            }

            if (!item.isPending && state.some((v) => v.uuid === item.uuid)) {
              if (
                ['ERC20', 'ERC721Rare', 'ERC721Membership'].includes(item.collectible.type) &&
                newCollectibles.some((v) => v.contractAddress === item.collectible.contractAddress)
              ) {
                state = state.filter((v) => v.uuid !== item.uuid);
              } else {
                if (
                  newCollectibles.some(
                    (v) =>
                      v.contractAddress === item.collectible.contractAddress && v.tokenId === item.collectible.tokenId
                  )
                ) {
                  state = state.filter((v) => v.uuid !== item.uuid);
                }
              }
            }
          });

        return state;
      });
    }
  }, [activity, newCollectibles]);

  const copyPublicLink = (linkPublicPage: string) => {
    copy(linkPublicPage);

    logMparticleEvent('copy_user_url', mParticle.EventType.Other, {
      url: linkPublicPage,
      is_own: !user.urlSlug || sessionUser.urlSlug === user.urlSlug,
    });

    successToast('Copied to clipboard!');
  };

  const onEmailVerified = useCallback((data: { verified: boolean }) => {
    setEmailVerificationModalIsOpen(!data.verified);
    dispatch(setEmailVerified(data.verified));
  }, []);

  const handleClickCollectible = (collectible: ICollectible) => {
    setCollectibleData({
      ...collectible,
      metadata: {
        ...collectible.metadata,
        ...getCollectibleMetadata({
          metadata: collectible.metadata,
          collectibleExtraMetadatas: wallet.relatedContracts.flatMap((cs) => cs.collectibleExtraMetadatas),
          contractAddress: collectible.contractAddress,
          type: collectible.type,
          tokenId: collectible.tokenId,
        }),
      },
    });
    setIsCollectibleModalOpen(true);
  };

  const isPendingCollectible = useCallback(
    (collectible: ICollectible) => {
      if (!collectible) return true;

      return !!activity.find((a) => a.collectible.contractAddress === collectible.contractAddress && a.isPending);
    },
    [activity]
  );

  const brandList = sessionUser.managedBrands;
  const firstBrand = brandList.length ? brandList[0] : null;

  return (
    <>
      <PusherConsumer event="user.email_verified" onData={onEmailVerified} />

      {
        <Flex
          flexDirection={['unset', 'row']}
          maxWidth={authStatus === AuthStatus.LOGGED_IN ? '100%' : '1060px'}
          __css={{ gap: '70px' }}
          width="100%"
          pb={3}
        >
          <UserEditBioModal
            isOpen={showModal}
            onClose={() => {
              setShowModal(false);

              //TODO
              // if (submitted) {
              //   getUser(sessionUser.sub);
              // }
            }}
          />

          <Flex flex="2" flexDirection={['column', 'column', 'row']} data-testid="wallet-painel-content">
            <Flex flex="1" flexDirection="column">
              <Flex flexDirection={['column', 'column', 'row']}>
                <Flex alignSelf={['center', 'center', 'flex-start']} mr={['0', '0', '4']}>
                  {user.avatarUrl ? (
                    <>
                      <Box position="relative" width={['128px', '142px']} data-testid="user-wallet-user-profile-img">
                        <UserAvatar avatarUrl={user.avatarUrl} size={['128px', '142px']} resizeTo="md" />
                        {sessionUser?.publicAddress === user.cChainPublicAddress && (
                          <Box
                            data-testid="user-wallet-profile-edit"
                            position="absolute"
                            bottom={10}
                            right={16}
                            width={32}
                            height={32}
                            backgroundColor="#FFFFFF"
                            borderRadius={50}
                            padding="2px 0px 0px 5px"
                            elevation="low"
                            border="1px solid #000000"
                            __css={{ cursor: 'pointer' }}
                            onClick={() => setShowModal(true)}
                          >
                            <Icon.BoxiconsRegular.Edit size={20} />
                          </Box>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Flex
                      width="196px"
                      height="196px"
                      justifyContent="center"
                      alignItems="center"
                      bg="gray02"
                      borderRadius="196px"
                      color="white"
                      onMouseEnter={() => setEditProfile(true)}
                      onMouseLeave={() => setEditProfile(false)}
                      onClick={() => setShowModal(true)}
                      __css={{
                        cursor: 'pointer',
                        transition: 'background-color 0.8s',
                        '&:hover': {
                          bg: 'gray03',
                        },
                      }}
                    >
                      {editProfile ? <Icon.BoxiconsRegular.Edit size={64} /> : <Icon.BoxiconsRegular.User size={64} />}
                    </Flex>
                  )}
                </Flex>
                <Flex flex={['1', '1', '2']} flexDirection="column">
                  <Flex
                    flexDirection={'column'}
                    justifyContent={['center', 'center', 'space-between']}
                    alignItems={['center', 'unset']}
                  >
                    <Flex flexDirection="column" alignItems={['center', 'start']} data-testid="user-wallet-info">
                      <Text variant="text3-400" mt="2">
                        {user.userName}
                      </Text>
                      {user.urlSlug && (
                        <Flex flexDirection="row" data-testid="user-wallet-copy-public-page">
                          <Flex
                            alignItems="center"
                            pr="1"
                            pt="1"
                            __css={{ cursor: 'pointer' }}
                            onClick={() => copyPublicLink(`${linkPublicPage}`)}
                          >
                            <Icon.BoxiconsRegular.Globe size={16} style={{ marginBottom: 5 }} />
                          </Flex>
                          <Text
                            __css={{ cursor: 'pointer' }}
                            onClick={() => copyPublicLink(`${linkPublicPage}`)}
                            fontWeight="bold"
                          >
                            {
                              linkPublicPage.replace(
                                'https://',
                                ''
                              ) /* don't show https:// in the link text, WRLD-1716 */
                            }
                            <Icon.BoxiconsRegular.Copy size={16} style={{ marginLeft: 5, marginBottom: 5 }} />
                          </Text>
                        </Flex>
                      )}
                      {user.bio && (
                        <Text mb="2" fontWeight="bold">
                          {user.bio}
                        </Text>
                      )}

                      <BioSocial
                        instagram={user.instagramUrl}
                        twitter={user.twitterUrl}
                        tiktok={user.tiktokUrl}
                        origin="USER"
                      />
                    </Flex>
                    <Flex style={{ marginTop: 5, justifyContent: 'flex-start', gap: 10 }}>
                      {SHOW_BLOCKCHAIN_DETAILS && (
                        <UserWalletAddress cPublicAddress={user.cChainPublicAddress} showCopyIcon={false} />
                      )}
                      {authStatus === AuthStatus.LOGGED_IN &&
                        sessionUser.publicAddress !== user.cChainPublicAddress && <SendDirectMessage user={user} />}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Box width="100%" mt="4">
                  <WalletTabs selectedTab={selectedTab} onChange={setSelectedTab} />
                  <Box>
                    {selectedTab === UserWalletTabs.Collectibles && (
                      <UserWalletCollectibles
                        user={user}
                        wallet={wallet}
                        firstBrand={firstBrand}
                        pendingTransactions={pendingTransactions}
                        ref={addressCollectiblesRef}
                        onClickCollectible={(collectible) => {
                          handleClickCollectible(collectible);
                          setIsCoin(false);
                        }}
                      />
                    )}
                    {selectedTab === UserWalletTabs.Coins && (
                      <UserWalletCoins
                        user={user}
                        wallet={wallet}
                        onClickCollectible={(collectible) => {
                          handleClickCollectible(collectible);
                          setIsCoin(true);
                        }}
                      />
                    )}
                    {selectedTab === UserWalletTabs.Activity && (
                      <WalletActivity activity={activity} loading={loading} user={user} />
                    )}
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex="1" display={['none', 'none', 'block']}>
            <Box
              data-testid="user-wallet-activity"
              backgroundColor="neutrals-50"
              minWidth={fullScreen ? '380px' : '280px'}
              height="85vh"
              overflowY="auto"
              p="4"
              borderRadius="8px"
            >
              <Text variant="text3-600" fontWeight="bold" mb="2">
                Activity
              </Text>

              <WalletActivity activity={activity} loading={loading} user={user} />
            </Box>
          </Flex>
        </Flex>
      }
      <EmailVerification
        isOpen={emailVerificationModalIsOpen}
        onClose={() => {
          setEmailVerificationModalIsOpen(false);
        }}
      />

      <CollectibleViewModal
        slug={user.urlSlug}
        isOpen={isCollectibleModalOpen}
        onClose={handleCollectibleViewModalClose}
        collectible={collectibleData}
        isCoin={isCoin}
        isCollectibleVisible={isUserCollectibleVisible(wallet, collectibleData)}
        isPendingCollectible={isPendingCollectible(collectibleData)}
        onChangeVisibility={() => fetchWallet()}
      />
    </>
  );
};

export default UserWallet;
