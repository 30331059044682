import gql from 'graphql-tag';

export interface PostMessageData {
  channelId: number;
  userId: number;
  content: string;
  createdAt: Date;
}

export interface PostMessageVars {
  channelId: number;
  userId: number;
  content: string;
}

export const POST_MESSAGE = gql`
  mutation PostMessage($channelId: Int!, $userId: Int!, $content: String!) {
    postMessage(channelId: $channelId, userId: $userId, content: $content) {
      content
      createdAt
      userId
    }
  }
`;

export interface UpdateLastSeenAtData {
  updateLastSeenAt: Date;
}

export interface UpdateLastSeenAtVars {
  channelId: number;
  userId: number;
}

export const UPDATE_LAST_SEEN_AT = gql`
  mutation UpdateLastSeenAt($channelId: Int!, $userId: Int!) {
    updateLastSeenAt(channelId: $channelId, userId: $userId)
  }
`;

export interface ChannelData {
  name: string;
  tokenId?: string;
  contractAddress?: string;
}

export interface CreateChannelVars {
  channel: {
    name: string;
  };
}

export const CREATE_CHANNEL = gql`
  mutation CreateChannel($channel: ChannelDataInput!) {
    createChannel(channel: $channel) {
      name
      id
      description
    }
  }
`;

export interface UpdateChannelVars {
  id: number;
  channel: {
    name: string;
  };
}

export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel($id: Int!, $channel: ChannelDataInput!) {
    updateChannel(id: $id, channel: $channel) {
      id
      name
      description
    }
  }
`;

export const DELETE_CHANNEL = gql`
  mutation DeleteChannel($id: Int!) {
    deleteChannel(id: $id) {
      id
    }
  }
`;

export interface DeleteChannelVars {
  id: number;
}

export const UNFLAG_MESSAGE = gql`
  mutation UnflagMessage($messageId: String!) {
    unflagMessage(messageId: $messageId)
  }
`;

export interface UnflagMessageVars {
  messageId: string;
}

export const BAN_USER_FROM_CHANNEL = gql`
  mutation BanUserFromChannel($userId: String!, $channelId: String!, $isPermanentBan: Boolean!) {
    banUserFromChannel(userId: $userId, channelId: $channelId, isPermanentBan: $isPermanentBan)
  }
`;

export interface BanUserFromChannelVars {
  userId: string;
  channelId: string;
  isPermanentBan: boolean;
}

export const BAN_USER_FROM_BRAND_CHANNELS = gql`
  mutation BanUserFromBrandChannels($userId: String!, $isPermanentBan: Boolean!) {
    banUserFromBrandChannels(userId: $userId, isPermanentBan: $isPermanentBan)
  }
`;

export interface BanUserFromBrandChannelsVars {
  userId: string;
  isPermanentBan: boolean;
}

export const DELETE_MESSAGES = gql`
  mutation DeleteMessages($messagesIds: [String!]!) {
    deleteMessages(messagesIds: $messagesIds)
  }
`;

export interface DeleteMessagesVars {
  messagesIds: string[];
}

export const DEACTIVATE_USERS = gql`
  mutation DeactivateUsers($usersIds: [String!]!, $deleteMessages: Boolean) {
    deactivateUsers(usersIds: $usersIds, deleteMessages: $deleteMessages)
  }
`;

export interface DeactivateUsersVars {
  usersIds: string[];
  deleteMessages: boolean;
}

export const CREATE_USER_TOKEN = gql`
  mutation Mutation {
    createUserToken
  }
`;

export interface CreateUserTokenData {
  createUserToken: string;
}

export interface CreateDMData {
  createDM: string;
}

export interface CreateDMVars {
  userId?: string;
  walletAddress?: string;
}

export const CREATE_DM = gql`
  mutation CreateDM($userId: String, $walletAddress: String) {
    createDM(userId: $userId, walletAddress: $walletAddress)
  }
`;
