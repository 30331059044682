type IResize = 'sm' | 'md' | 'lg' | 'original' | string;

// Only `sm` and `thumbnail` are supported at the moment due to constant timeouts while resizing GIFs
const SUPPORTED_GIF_SIZES: IResize[] = ['thumbnail', 'sm'];

// eslint-disable-next-line no-useless-escape
const GLOBAL_CDN_REGEX =
  /(https?:\/\/[A-Za-z0-9.\-_]+.com)\/([A-Za-z0-9.\-_\/]+(.gif|.png|.jpg|.jpeg))/;

const SUPPORTED_BUCKETS = [
  process.env.NEXT_PUBLIC_ASSETS_BUCKET!,
  process.env.NEXT_PUBLIC_COLLECTIBLES_ASSETS_BUCKET_RESIZED!, // This must come before non-resized to be picked up first
  process.env.NEXT_PUBLIC_COLLECTIBLES_ASSETS_BUCKET!,
  process.env.NEXT_PUBLIC_COLLECTIONS_ASSETS_BUCKET!,
];

export const cdnPath = (src: string, resizeTo: IResize = 'original') => {
  if (!src) return src;

  // If valid for global CDN
  if (GLOBAL_CDN_REGEX.test(src)) {
    const [_, bucketUrl, path] = src.match(GLOBAL_CDN_REGEX)!;
    if (bucketUrl && path) {
      const bucket = SUPPORTED_BUCKETS.find((s) => bucketUrl.includes(s));
      if (bucket) {
        const query = String(resizeTo) !== 'original' ? `?size=${resizeTo}` : '';

        return `https://${process.env.NEXT_PUBLIC_CDN_GLOBAL!}/${bucket}/${path}${query}`;
      }
    }
  }

  if (
    src.toLowerCase().includes(process.env.NEXT_PUBLIC_COLLECTIBLES_ASSETS_BUCKET!) &&
    !src.endsWith('.gif') &&
    !src.endsWith('.mp4')
  ) {
    const image = src.split('.com/')[1];
    const path = `https://${process.env.NEXT_PUBLIC_CDN_COLLECTIBLES_ASSETS!}`;
    if (resizeTo === 'sm') return `${path}/sm/${image}`;
    if (resizeTo === 'md') return `${path}/md/${image}`;
    if (resizeTo === 'lg') return `${path}/lg/${image}`;

    return `${path}/${image}`;
  }

  return src;
};
