import React, { LabelHTMLAttributes } from 'react';

import Text from '../Text/Text';

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean;
  children?: React.ReactNode;
}

const Label = React.forwardRef<HTMLElement, LabelProps>(({ children, required, ...props }, ref) => {
  return (
    <Text as="label" ref={ref} display="flex" variant="text5-400" __css={{ alignItems: 'center' }} {...props}>
      {children} {required ? '*' : ''}
    </Text>
  );
});

Label.defaultProps = {
  required: false,
};

export default Label;
