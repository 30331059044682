import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import React, { useEffect, useState } from 'react';
import { SHOW_BLOCKCHAIN_DETAILS } from 'src/constants';

import Loading from '../../../components/Loading';
import { ICollectible } from '../../../types';
import { getCChainAddressUrl, getTokenStandard, stringMiddleCrop } from '../../../utils';

const CollectibleDetails = ({
  collectible,
  showTokenId,
  isCoin,
}: {
  collectible: ICollectible;
  showTokenId: boolean;
  isCoin: boolean;
}) => {
  const [isEvenOrOdd, setIsEvenOrOdd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasBalance, setHasBalance] = useState<boolean>(false);

  useEffect(() => {
    if (collectible.type === 'ERC1155' || collectible.type === 'ERC20') {
      setHasBalance(true);
    }

    if (!isCoin) {
      if ((showTokenId && collectible.type === 'ERC1155') || (!showTokenId && collectible.type !== 'ERC1155')) {
        setIsEvenOrOdd(true);
      } else {
        setIsEvenOrOdd(false);
      }
    }

    return setLoading(false);
  }, [collectible, isCoin, showTokenId]);

  if (loading) {
    return <Loading />;
  } else {
    const showDetails = (showTokenId && collectible.tokenId && !isCoin) || SHOW_BLOCKCHAIN_DETAILS;
    return showDetails ? (
      <Flex flexDirection="column" py="20px" data-testid="collectible-modal-details">
        {SHOW_BLOCKCHAIN_DETAILS && (
          <Flex justifyContent="space-between" bg="#F5F5F5" px="2" py="1">
            <Text>Contract address</Text>
            <Box
              onClick={() => window.open(getCChainAddressUrl(collectible.contractAddress), '_blank')}
              __css={{ cursor: 'pointer', ':hover': { color: 'gray04' } }}
            >
              <Text>{stringMiddleCrop(collectible.contractAddress, 12, 6)}</Text>
            </Box>
          </Flex>
        )}

        {showTokenId && collectible.tokenId && !isCoin && (
          <Flex justifyContent="space-between" px="2" py="1">
            <Text>Unique collectible #</Text>
            <Text>{collectible.tokenId}</Text>
          </Flex>
        )}

        {SHOW_BLOCKCHAIN_DETAILS && (
          <>
            {hasBalance && (
              <Flex justifyContent="space-between" bg={showTokenId && !isCoin ? '#F5F5F5' : ''} px="2" py="1">
                <Text>Balance </Text>
                <Text>
                  {collectible.balance} {isCoin ? collectible?.symbol : false}
                </Text>
              </Flex>
            )}

            <Flex justifyContent="space-between" bg={isEvenOrOdd ? '' : '#F5F5F5'} px="2" py="1">
              <Text>Token standard</Text>
              <Text>{getTokenStandard(collectible.type)}</Text>
            </Flex>

            <Flex justifyContent="space-between" bg={isEvenOrOdd ? '#F5F5F5' : ''} px="2" py="1">
              <Text>Blockchain</Text>
              <Text>Avalanche</Text>
            </Flex>
          </>
        )}
      </Flex>
    ) : null;
  }
};

export default CollectibleDetails;
