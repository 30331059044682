import { StyledIcon } from '@styled-icons/styled-icon';
import { Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

export function UserWalletButton({
  isDark = false,
  onClick,
  text,
  iconCls,
}: {
  isDark?: boolean;
  onClick?: () => void;
  text: string;
  iconCls?: StyledIcon;
}) {
  return (
    <Flex
      data-testid="user-wallet-copy-address"
      px="11px"
      py="1"
      justifyContent="center"
      alignItems="center"
      borderRadius="48px"
      backgroundColor={isDark ? '#4D4D4D' : '#fff'}
      border="1px solid #E1E0E0"
      __css={{ cursor: 'pointer' }}
      onClick={onClick}
      padding="10px 40px"
      height={40}
      flex={1}
    >
      {iconCls && (
        <>
          <Flex alignItems="center" color={isDark ? 'gray03' : 'black'} __css={{ cursor: 'pointer' }}>
            {React.createElement(iconCls, { size: 18 })}
          </Flex>
          <div style={{ minWidth: 7 }} />
        </>
      )}
      <Text variant="text5-400" mr="4px">
        {text}
      </Text>
    </Flex>
  );
}
