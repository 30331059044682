import { useMutation } from '@apollo/client';
import { Alert, Box, Button, Flex, Icon, Image, Input, Label, Text } from '@tyb-u/tyb-ui-components';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';

import ImageCropper from '../../components/ImageCropper';
import InputAddon from '../../components/InputAddon';
import ContentModal from '../../components/Modals/Content';
import UserAvatar from '../../components/UserAvatar';
import { UPDATE_USER, UpdateUserData, UpdateUserVars } from '../../graphql/mutations/updateUser';
import { useContractManager } from '../../hooks/useContractManager';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAvatar, setBio, setBirthDate, setUsername } from '../../redux/user/userSlice';
import { ICollectible } from '../../types';
import { convertRemToPx, INSTAGRAM_REGEX, TIKTOK_REGEX, TWITTER_REGEX } from '../../utils';
import { successToast } from '../../utils/toasts';

type Inputs = {
  userName: string;
  bio: string;
  twitter: string;
  instagram: string;
  tiktok: string;
};

type UserEditBioModalProps = {
  isOpen: boolean;
  onClose: (submitted?: boolean) => void;
};

const UserEditBioModal: React.FC<UserEditBioModalProps> = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<Inputs>();

  const size = useWindowSize();
  const theme = useTheme();
  const fullScreen = size.width <= convertRemToPx(theme.breakpoints[0]);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [avatarUrl, setAvatarUrl] = useState<string>(user.avatarUrl);
  const [birthDateBio, setBirthDateBio] = useState<string | null>(
    user.birthdate ? new Date(user.birthdate).toISOString().slice(0, 10) : null
  );
  const birthDateValue = birthDateBio ? new Date(birthDateBio) : null;
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(false);
  const [collectibles, setCollectibles] = useState<ICollectible[]>([]);
  const { getOwnerCollectibles, collectibles: allCollectibles } = useContractManager();

  const [updateUser] = useMutation<UpdateUserData, UpdateUserVars>(UPDATE_USER);

  const onSubmit: SubmitHandler<Inputs> = async (inputData) => {
    try {
      await updateUser({
        variables: {
          user: {
            userName: inputData.userName,
            bio: inputData.bio,
            avatarUrl: avatarUrl,
            instagramUrl: inputData.instagram ? `https://instagram.com/${inputData.instagram}` : null,
            twitterUrl: inputData.twitter ? `https://twitter.com/${inputData.twitter}` : null,
            tiktokUrl: inputData.tiktok ? `https://tiktok.com/@${inputData.tiktok}` : null,
            birthdate: birthDateValue,
          },
        },
      });

      dispatch(setUsername(inputData.userName));
      dispatch(setAvatar(avatarUrl));
      dispatch(setBio(inputData.bio));
      dispatch(setBirthDate(birthDateValue));

      successToast('✔️ Your bio was updated!');

      onClose(true);
    } catch (err) {
      if (err?.message?.toLowerCase()?.includes('username')) {
        setError('userName', {
          message: err.message,
        });
      }

      throw err;
    }
  };

  const hasGifImage = (animationUrl: string) => {
    try {
      const r = new RegExp('([\\w-]+).mp4');
      const mp4FileName = animationUrl.match(r)[1];
      const gitSrc = `https://${process.env.NEXT_PUBLIC_COLLECTIBLES_ASSETS_BUCKET_RESIZED}.s3.us-west-2.amazonaws.com/preview/${mp4FileName}.gif`;
      return <Image height="64px" borderRadius="100%" src={gitSrc} onClick={() => setAvatarUrl(gitSrc)} />;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (isOpen) {
      setValue('instagram', (user.instagramUrl?.match(INSTAGRAM_REGEX) || [])[2]);
      setValue('twitter', (user.twitterUrl?.match(TWITTER_REGEX) || [])[2]);
      setValue('tiktok', (user.tiktokUrl?.match(TIKTOK_REGEX) || [])[2]);
    }
  }, [user, isOpen]);

  useEffect(() => {
    if (user.cChainPublicAddress) {
      setCollectibles(getOwnerCollectibles(user.cChainPublicAddress).filter((v) => !!v?.metadata));
    }
  }, [user, allCollectibles, getOwnerCollectibles]);

  return (
    <ContentModal
      isOpen={isOpen}
      fullScreen={fullScreen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      body={
        <Box minWidth="360px" p="24px" maxHeight="80vh">
          {fullScreen && (
            <Box
              __css={{ cursor: 'pointer', position: 'absolute', left: 10 }}
              onClick={() => onClose()}
              color={'black'}
            >
              <Icon.BoxiconsRegular.XCircle size={32} />
            </Box>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Text variant="text3-600" textAlign="center" mb="3">
              Edit your bio
            </Text>
            {
              <>
                <Flex flexDirection={['column', 'row']} mb="5" __css={{ gap: '15px' }}>
                  <Flex flexDirection="column" mb="2">
                    <Label>Profile picture</Label>
                    {avatarUrl && avatarUrl.toLowerCase().endsWith('.mp4') ? (
                      <UserAvatar size={128} avatarUrl={avatarUrl} resizeTo="sm" />
                    ) : (
                      <ImageCropper
                        src={avatarUrl}
                        maxFileSize={50}
                        onChange={(src) => {
                          setAvatarUrl(src);
                        }}
                        onChangeLoading={(loading) => {
                          setLoadingAvatar(loading);
                        }}
                      />
                    )}
                  </Flex>
                  {!!collectibles.length && (
                    <Flex flexDirection="column" ml={['unset', '40px']} __css={{ gap: '10px' }}>
                      <Label>Use a picture from your wallet</Label>
                      <Flex
                        flexWrap="wrap"
                        __css={{ gap: '10px' }}
                        maxHeight="280px"
                        maxWidth={['100%', '256px']}
                        overflowY="auto"
                        flex={1}
                      >
                        {collectibles.map((v) => {
                          return (
                            <Flex
                              key={v.tokenId}
                              position="relative"
                              width="64px"
                              height="64px"
                              justifyContent="center"
                              alignItems="center"
                              color="white"
                              display="inline-block"
                              verticalAlign="top"
                              __css={{ cursor: 'pointer' }}
                            >
                              {v.metadata.animationUrl && v.metadata.animationUrl.endsWith('mp4') ? (
                                hasGifImage(v.metadata.animationUrl)
                              ) : (
                                <Image
                                  height="64px"
                                  borderRadius="100%"
                                  src={v.metadata.image}
                                  onClick={() => {
                                    setAvatarUrl(v.metadata.image);
                                  }}
                                />
                              )}
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Flex>
                  )}
                </Flex>

                <Box mb="2">
                  <Label>Username</Label>
                  <Input defaultValue={user.userName} {...register('userName')} />
                  {errors.userName && <Alert type="error">{errors.userName.message}</Alert>}
                </Box>

                <Box mb="2">
                  <Label>Bio</Label>
                  <Input
                    as="textarea"
                    defaultValue={user.bio}
                    rows={6}
                    placeholder="Tell us briefly about yourself"
                    {...register('bio')}
                  />
                </Box>
                <Box mb="2">
                  <Label>Birth Date</Label>
                  <Input
                    type="date"
                    name="birthdate"
                    value={birthDateBio || ''}
                    min={'1930-01-01'}
                    max={(() => {
                      const today = new Date();
                      today.setFullYear(today.getFullYear() - 1);
                      return today.toISOString().split('T')[0];
                    })()}
                    onChange={(e) => {
                      setBirthDateBio(e.target.value || null);
                    }}
                  />
                </Box>

                <Box mb="2">
                  <Label>Twitter</Label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputAddon text="https://twitter.com/" />
                    <Input {...register('twitter')} />
                  </div>
                  {errors.twitter && <Alert type="error">Please add a valid Twitter profile</Alert>}
                </Box>

                <Box mb="2">
                  <Label>Instagram</Label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputAddon text="https://instagram.com/" />
                    <Input {...register('instagram')} />
                  </div>
                  {errors.instagram && <Alert type="error">Please add a valid Instagram profile</Alert>}
                </Box>

                <Box mb="2">
                  <Label>TikTok</Label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputAddon text="https://tiktok.com/@" />
                    <Input {...register('tiktok')} />
                  </div>
                  {errors.tiktok && <Alert type="error">Please add a valid TikTok profile</Alert>}
                </Box>
              </>
            }

            <Flex flexDirection="column" justifyContent="center" mt="4">
              <Button disabled={loadingAvatar || isSubmitting} type="submit">
                Save
              </Button>
            </Flex>
          </form>
          <Box height="24px" />
        </Box>
      }
    />
  );
};

export default UserEditBioModal;
