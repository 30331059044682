import React from 'react';

import Box from '../Box/Box';
import Button from '../Button/Button';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';

export interface SectionTitleProps {
  title: string;
  subtitle?: string;
  hasButton?: boolean;
  buttonText?: string;
  onPressButton?: () => void;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, subtitle, hasButton, buttonText, onPressButton }) => {
  return (
    <Flex alignItems="center">
      <Flex flexDirection="column" flex={1}>
        <Text variant="text0">{title}</Text>
        {subtitle && (
          <Text variant="text3-400" opacity={0.5}>
            {subtitle}
          </Text>
        )}
      </Flex>

      {hasButton && (
        <Box>
          <Button scale="lg" onClick={onPressButton}>
            {buttonText}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

SectionTitle.defaultProps = {
  hasButton: false,
  buttonText: 'Button text',
  onPressButton: () => true,
};

export default SectionTitle;
