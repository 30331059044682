import { OAuthRedirectResult } from '@magic-ext/oauth';
import { Flex } from '@tyb-u/tyb-ui-components';

import RegisterUsernameForm from '../../forms/RegisterUsernameForm/RegisterUsernameForm';
import { AuthType } from '../../types';

type LoginRegisterProps = {
  authType: AuthType;
  email?: string;
  phoneNumber?: string;
  onChangeStep: (email: string) => void;
  oauthRedirectResult?: OAuthRedirectResult;
};

const LoginRegister: React.FC<LoginRegisterProps> = ({
  authType,
  email,
  phoneNumber,
  onChangeStep,
  oauthRedirectResult,
}) => {
  return (
    <Flex flex={1} justifyContent="center" alignItems="center" width="100%">
      <RegisterUsernameForm
        defaultValues={{
          username: '',
          avatar: '',
          email: email,
          phoneNumber: phoneNumber,
        }}
        email={email}
        phoneNumber={phoneNumber}
        authType={authType}
        onChangeStep={onChangeStep}
        oauthRedirectResult={oauthRedirectResult}
      />
    </Flex>
  );
};

export default LoginRegister;
