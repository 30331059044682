import Tab from 'src/components/Tab';
import { UserWalletTabs as WalletTabs } from 'src/constants';

interface UserWalletTabsProps {
  selectedTab: WalletTabs;
  onChange: (tab: WalletTabs) => void;
}

const UserWalletTabs: React.FC<UserWalletTabsProps> = ({ selectedTab, onChange }) => (
  <Tab selectedTab={selectedTab} onClick={onChange}>
    <Tab.Item tab={WalletTabs.Collectibles} fontSize="16px">
      Collectibles
    </Tab.Item>
    <Tab.Item tab={WalletTabs.Coins} fontSize="16px">
      Coins
    </Tab.Item>
    <Tab.Item tab={WalletTabs.Activity} hideMobile fontSize="16px">
      Activity
    </Tab.Item>
  </Tab>
);

export default UserWalletTabs;
