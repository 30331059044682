import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { AuthStatus } from 'src/redux/auth/authSlice';

import { useAppSelector } from '../../redux/hooks';

const LaunchDarklyProvider = ({ children }: { children: ReactElement }) => {
  const { sub, email, firstName, lastName } = useAppSelector((state) => state.user);
  const [isInitialized, setIsIntiliazed] = useState(false);
  const auth = useAppSelector((state) => state.auth);

  const user = useMemo(() => {
    if (sub) {
      let ldContext: Parameters<(typeof ldClient)['identify']>[0];
      if (navigator.webdriver) {
        ldContext = {
          kind: 'user',
          key: 'tyb_webdriver_user',
          email: 'tyb_webdriver_user@tyb.xyz',
          firstName: 'tyb',
          lastName: 'webdriver_user',
        };
      } else {
        ldContext = { kind: 'user', key: sub, email, firstName, lastName };
      }
      return ldContext;
    } else {
      return null;
    }
  }, [sub, email, firstName, lastName]);

  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && auth.status == AuthStatus.LOGGED_IN) {
      (async () => {
        setIsIntiliazed(false);
        await ldClient.waitUntilReady();

        if (user) {
          await ldClient.identify(user);
        }

        setIsIntiliazed(true);
      })();
    } else if (AuthStatus.LOGGED_OUT) {
      setIsIntiliazed(true);
    }
  }, [ldClient, user, auth?.status]);

  return isInitialized && children;
};

export default LaunchDarklyProvider;
