import { Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { IWallletActivity } from 'src/interface/IWallletActivity';
import { getTimeDuration } from 'src/utils';

import CollectibleMedia from '../CollectibleMedia';

interface WalletActivityItemProps {
  activity: IWallletActivity;
  address: string;
}

const WalletActivityItem: React.FC<WalletActivityItemProps> = ({ activity, address }) => {
  const [time, suffix] = getTimeDuration(activity.date);

  return (
    <Flex py="2" alignItems="center" borderBottom="thin">
      <Flex flexDirection="column" flex={1}>
        <Text variant="text4-400" mb="1">
          {activity.quantity > 1
            ? `${activity.quantity} x ${activity.collectible.metadata?.name}`
            : activity.collectible.metadata?.name}
        </Text>
        <Flex alignItems="center" __css={{ gap: '10px' }}>
          {activity.isPending && (
            <Text backgroundColor="gray03" borderRadius="10px" fontSize={12} color="gray01" px="8px">
              Pending
            </Text>
          )}
          <Text variant="text6-400" color="gray04">
            {time} {suffix.toUpperCase()} AGO
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="center" __css={{ marginLeft: '8px' }}>
        <Flex alignItems="center">
          <Text fontSize={18} mr="5px">
            {activity.to === address ? '+' : '-'}
          </Text>
          <CollectibleMedia width="32px" height="32px" size="sm" metadata={activity.collectible.metadata} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WalletActivityItem;
