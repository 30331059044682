import { gql } from '@apollo/client';
import { PermitSignResult } from 'src/services/contract-manager/contracts/types';

export interface IStorePermitSignatureVars {
  input: {
    id: string;
    permitPayload: PermitSignResult;
  };
}

export const STORE_PERMIT_SIGNATURE = gql`
  mutation StorePendingPermitSignature($input: StorePendingPermitSignatureInput!) {
    storePendingPermitSignature(input: $input)
  }
`;
