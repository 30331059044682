import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';

import { ICollectibleMetadata } from '../../types';
import ImageCDN from '../ImageCDN';

type CoinPreviewProps = {
  metadata: ICollectibleMetadata;
  balance: string;
  isPrivate?: boolean;
};

const CoinPreview: React.FC<CoinPreviewProps> = ({ metadata, balance, isPrivate }) => {
  return (
    <Flex
      position="relative"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      __css={{ gap: '10px' }}
      backgroundColor="neutrals-50"
      borderRadius="24px"
      padding="34px 16px"
    >
      {isPrivate && (
        <Box position="absolute" top={2} right={2}>
          <Icon.BoxiconsRegular.Hide size={24} color="#D6D3D3" />
        </Box>
      )}
      <ImageCDN
        width="130px"
        height="130px"
        variant="avatar"
        src={metadata?.image}
        __css={{ objectFit: 'cover', opacity: isPrivate && 0.5 }}
      />
      <Box>
        <Text data-testid="user-wallet-coin-balance" variant="text3-600">
          {balance}
        </Text>
      </Box>
    </Flex>
  );
};

export default CoinPreview;
