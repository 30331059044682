import { Text } from '@tyb-u/tyb-ui-components';
import { IUser } from 'src/interface/IUser';

import { IWallletActivity } from '../../interface/IWallletActivity';
import WalletActivityItem from './WalletActivityItem';

type WalletActivityProps = {
  user: IUser;
  activity: IWallletActivity[];

  loading: boolean;
};

const WalletActivity: React.FC<WalletActivityProps> = ({ activity, user, loading }) => {
  const hasNoActivities = !activity || activity?.length === 0;

  if (loading) return <Text>Loading...</Text>;
  else if (hasNoActivities) return <Text>No activities yet.</Text>;

  return (
    <>
      {activity.map((activity, idx) => (
        <WalletActivityItem key={`activity_${idx}`} activity={activity} address={user.cChainPublicAddress} />
      ))}
    </>
  );
};

export default WalletActivity;
