import { Box, Text } from '@tyb-u/tyb-ui-components';
import { CoinRedemptionType } from 'src/types';

interface RedemptionReviewInfoProps {
  applyTo: CoinRedemptionType;
  coinAmount: string;
  redemptionValue: number;
  brandName: string;
}

const RedemptionReviewInfo: React.FC<RedemptionReviewInfoProps> = ({
  coinAmount,
  redemptionValue,
  brandName,
  applyTo,
}) => {
  return (
    <Box>
      <Text color="neutrals-900" display="inline">
        You are redeeming{' '}
      </Text>
      <Text variant="text5-600" display="inline">
        {coinAmount}{' '}
      </Text>
      <Text color="neutrals-900" display="inline">
        for a{' '}
      </Text>
      {applyTo === 'checkout' ? (
        <>
          <Text variant="text5-600" display="inline">
            {redemptionValue}
            {' USD '}
          </Text>
          <Text color="neutrals-900" display="inline">
            {brandName} discount code. Once you confirm, this action cannot be undone.
          </Text>
        </>
      ) : (
        <>
          <Text variant="text5-600" display="inline">
            {redemptionValue}
            {' USD off '}
          </Text>
          <Text color="neutrals-900" display="inline">
            your next subscription charge. Once you confirm, this action cannot be undone.
          </Text>
        </>
      )}
    </Box>
  );
};

export default RedemptionReviewInfo;
