import { Flex } from '@tyb-u/tyb-ui-components';
import { useCallback, useEffect, useMemo } from 'react';
import useModal from 'src/hooks/useModal';
import { IBrand } from 'src/interface/IBrand';
import UnlockRewardModalContent from 'src/modals/UnlockRewardModalContent/UnlockRewardModalContent';
import { RewardTypes } from 'src/types';

import useAuth from '../../hooks/useAuth';
import useWalletActivity from '../../hooks/useWalletActivity';
import { IWallletActivity } from '../../interface/IWallletActivity';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setActivity } from '../../redux/user/userSlice';
import PusherConsumer from '../PusherConsumer';

const MODAL_EARN_COLLECTIBLE_MEMBERSHIP_PUSHER_KEY = 'MODAL_EARN_COLLECTIBLE_MEMBERSHIP_PUSHER_KEY';

const MagicAuthProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const activity = useWalletActivity();
  const user = useAppSelector((state) => state.user);
  const { open: openModal, close: closeModal } = useModal();

  useEffect(() => {
    auth.loadActiveSession();
  }, []);

  useMemo(() => {
    if (user.cChainPublicAddress) {
      (async () => {
        const result = await activity.fetch(user.cChainPublicAddress);
        if (result) {
          dispatch(setActivity(result.data?.walletActivity?.filter((v) => v.type === 'TRANSFER') || []));
        }
      })();
    }
  }, [user.cChainPublicAddress]);

  const onNewActivity = (newActivity: IWallletActivity) => {
    if (newActivity.type === 'TRANSFER') {
      activity.onNewActivity(newActivity);
      auth.fetchWallet();
    }
  };

  const onNewBrandMember = useCallback(
    (data: { brand: IBrand }) => {
      auth.loadActiveSession();

      openModal(
        MODAL_EARN_COLLECTIBLE_MEMBERSHIP_PUSHER_KEY,
        <Flex width="800px">
          <UnlockRewardModalContent
            rewardMetadata={data.brand.membershipCollectible.metadata}
            reward={{ ...data.brand.membershipCollectible, type: 'COLLECTIBLE' as RewardTypes }}
            onContinue={() => {
              closeModal(MODAL_EARN_COLLECTIBLE_MEMBERSHIP_PUSHER_KEY);
            }}
          />
        </Flex>
      );
    },
    [auth]
  );

  return (
    <>
      {/* TODO: maybe it's an overkill to refetch wallet for every new activity this may be redundant with the new brend member event */}
      <PusherConsumer event="new.activity" onData={onNewActivity} />
      <PusherConsumer event="update.activity" onData={activity.onUpdateActivity} />
      <PusherConsumer event="new.brand_member" onData={onNewBrandMember} />

      {children}
    </>
  );
};

export default MagicAuthProvider;
