import { Flex } from '@tyb-u/tyb-ui-components';
import { PropsWithChildren } from 'react';
import ContentModal from 'src/components/Modals/Content';
import useModal from 'src/hooks/useModal';
import { useAppSelector } from 'src/redux/hooks';

const ModalProvider = ({ children }: PropsWithChildren) => {
  const { close } = useModal();

  const { modals } = useAppSelector((state) => state.modal);

  return (
    <>
      <>{children}</>
      <>
        {modals.map(({ key, Component, props = {} }) => {
          return (
            <ContentModal
              key={key}
              isOpen={true}
              onRequestClose={() => {
                close(key);
              }}
              {...props}
            >
              {Component}
            </ContentModal>
          );
        })}
      </>
    </>
  );
};

export default ModalProvider;
