import { gql } from '@apollo/client';

export interface RequestUpdateUserEmailVars {
  email: string;
  baseUrl: string;
}

export interface RequestUpdateUserEmailData {
  requestUpdateUserEmail: boolean;
}

export const REQUEST_UPDATE_USER_EMAIL = gql`
  mutation RequestUpdateUserEmail($email: String!, $baseUrl: String!) {
    requestUpdateUserEmail(email: $email, baseUrl: $baseUrl)
  }
`;
