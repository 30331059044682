import { Icon } from '@tyb-u/tyb-ui-components';

export const getIconElementFromName = (name: string) => {
  switch (name) {
    case 'home_alt':
      return <Icon.BoxiconsRegular.HomeAlt size={20} />;
    case 'wallet':
      return <Icon.BoxiconsRegular.Wallet size={20} />;
    case 'hash':
      return <Icon.BoxiconsRegular.Hash size={20} />;
    case 'cog':
      return <Icon.BoxiconsRegular.Cog size={20} />;
    case 'wrench':
      return <Icon.BoxiconsRegular.Wrench size={20} />;
    case 'receipt':
      return <Icon.BoxiconsRegular.Receipt size={20} />;
    case 'collection':
      return <Icon.BoxiconsRegular.Collection size={20} />;
    case 'wallet_alt':
      return <Icon.BoxiconsRegular.WalletAlt size={20} />;
    case 'mail_send':
      return <Icon.BoxiconsRegular.MailSend size={20} />;
    case 'line_chart':
      return <Icon.BoxiconsRegular.LineChart size={20} />;
    case 'shop_bag':
      return <Icon.BoxiconsRegular.ShoppingBag size={20} />;
    case 'coin':
      return <Icon.BoxiconsRegular.Coin size={20} />;
    case 'calendar':
      return <Icon.BoxiconsRegular.Calendar size={20} />;
    case 'key':
      return <Icon.BoxiconsRegular.Key size={20} />;
    default:
      return null;
  }
};
