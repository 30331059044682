/* eslint-disable @typescript-eslint/no-explicit-any */
import { ethers, Event } from 'ethers';

import { BaseContract, StandardTypes } from './base';

const EventTypeArray = ['Transfer'] as const;
export type ERC721EventType = (typeof EventTypeArray)[number];

export type ERC721EventListener<T> = (event: ethers.Event, args: T) => void;
export type ERC721EventTypeArgs = {
  ['Transfer']: {
    from: string;
    to: string;
    tokenId: string;
  };
};

export class ContractERC721 extends BaseContract {
  standardName = StandardTypes.ERC721;

  public on<R = ERC721EventTypeArgs>(eventType: ERC721EventType, args: any[], listener: ERC721EventListener<R>) {
    const eventFilters: ethers.EventFilter[] = [];
    if (args.length > 0 && Array.isArray(args[0])) {
      eventFilters.push(...args.map((eventArgs) => this.contract.filters[eventType as any](...eventArgs)));
    } else {
      eventFilters.push(this.contract.filters[eventType as any](...args));
    }

    console.debug(
      '[ContractManager] Listening to event ',
      eventType,
      ' with args ',
      args,
      ' on ContractERC721: ',
      this.contractAddress
    );

    eventFilters.forEach((eventFilter) => {
      this.addEventListener(eventFilter, (...args: any[]) => {
        const event = args[args.length - 1] as ethers.Event;
        const type = event.event as ERC721EventType;

        switch (type) {
          case 'Transfer':
            return listener(event, {
              from: args[0],
              to: args[1],
              tokenId: args[2].toNumber().toString(),
            } as any);
        }
      });
    });
  }

  /*public async getContractTokens() {
    // Get all transfer events
    //const events = await this.getTransferEvents();

    return [];
    return Promise.all(
      events.map((ev) => {
        const tokenId = ev.args['tokenId'].toNumber().toString();

        return this.parseToken(tokenId, ev.transactionHash, ev.blockNumber);
      })
    );
  }*/
}
