import mParticle from '@mparticle/web-sdk';
import { useRouter } from 'next/router';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import { logMparticleEvent } from 'src/utils/mparticle';

import { useAppSelector } from '../../redux/hooks';
import splitRoute from '../../utils/splitRoute';

export const ErrorBoundary = ({ children }) => {
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const nextRouter = useRouter();

  useErrorBoundary((error: any, errorInfo) => {
    logMparticleEvent('error_message', mParticle.EventType.Other, {
      message: error.message,
      ...splitRoute(nextRouter.asPath),
      auth_status: authStatus.toLowerCase(),
      stack: errorInfo.componentStack,
      is_apollo_error: false,
    });
  });

  return children;
};

export default withErrorBoundary(ErrorBoundary);
