import React from 'react';

import TablePagination from './components/TablePagination';
import TableRow from './components/TableRow';

export interface TableProps {
  columns?: (string | React.ReactNode)[];
  data: { [key: string]: string | React.ReactNode }[];
  loading?: boolean;
  isStripped?: boolean;
  pagination?: {
    total: number;
    take: number;
    refetch: (take: number, skip: number) => void;
    limit?: number;
  };
  rowActions?: {
    onClick?: () => void | null;
    onClickDetail?: () => void | null;
    onClickEdit?: () => void | null;
    onClickDelete?: () => void | null;
  };
}

const Table: React.FC<TableProps> = ({ columns, data, loading, isStripped, pagination, rowActions }) => {
  return (
    <table
      style={{
        filter: loading ? 'blur(3px)' : 'none',
        borderCollapse: 'collapse',
        width: '100%',
      }}
    >
      {columns && (
        <thead>
          <tr>
            {columns.map((col, idx) => (
              <th
                key={idx}
                style={{
                  textAlign: 'left',
                  padding: '8px',
                  opacity: '0.5',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                }}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((value, idx) => (
          <TableRow key={idx} isStripped={isStripped} data={Object.values(value)} rowActions={rowActions} />
        ))}
      </tbody>
      {pagination && (
        <tfoot>
          <tr>
            <td colSpan={columns.length}>{pagination && <TablePagination {...pagination} />}</td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

Table.defaultProps = {
  isStripped: false,
};

export default Table;
