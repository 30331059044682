/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IMapper<K, V> {
  key: K;
  value: V;
}

export class Mapper {
  public static set<K, V>(_mapper: IMapper<K, V>[], key: K, value: V): IMapper<K, V>[] {
    const index = _mapper.findIndex((v) => v.key.toString().toLowerCase() === key.toString().toLowerCase());

    let mapper = Object.assign([], _mapper);

    if (index === -1) {
      mapper = [
        ...mapper,
        {
          key,
          value,
        },
      ];
    } else {
      mapper[index].value = value;
    }

    return mapper;
  }

  public static has<K, V>(_mapper: IMapper<K, V>[], key: K): boolean {
    return _mapper.some((v) => v.key.toString().toLowerCase() === key.toString().toLowerCase());
  }

  public static get<K, V>(_mapper: IMapper<K, V>[], key: K): V {
    return _mapper.find((v) => v.key.toString().toLowerCase() === key.toString().toLowerCase())?.value;
  }

  public static delete<K, V>(_mapper: IMapper<K, V>[], key: K) {
    return _mapper.filter((v) => v.key.toString().toLowerCase() !== key.toString().toLowerCase());
  }
}
