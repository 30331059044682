import React, { useEffect } from 'react';
import Modal, { Props } from 'react-modal';
import { updateFullscreenOverflow } from 'src/utils/dom';

Modal.setAppElement('#__next');

interface ContentModalProps extends Props {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  fullScreen?: boolean;
  forceOverflow?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: Modal.Styles;
}

const ContentModal: React.FC<ContentModalProps> = ({
  header,
  body,
  footer,
  style,
  fullScreen,
  forceOverflow,
  ...props
}) => {
  useEffect(() => {
    updateFullscreenOverflow({ isFullscreen: props.isOpen });
  }, [props.isOpen]);

  const contentModalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      padding: 0,
      display: 'flex',
      maxHeight: 'calc(100vh - 10px)',
      overflow: 'auto',
    },
  };

  const fullScreenContentModalStyle = {
    content: {
      top: '0',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
      padding: 0,
      borderRadius: 'none',
      maxHeight: '100vh',
      overflow: forceOverflow ? 'visible' : 'auto',
    },
  };
  return (
    <Modal
      style={fullScreen ? { ...fullScreenContentModalStyle, ...style } : { ...contentModalStyle, ...style }}
      {...props}
    >
      {header}
      {body || props.children}
      {footer}
    </Modal>
  );
};

export default ContentModal;
