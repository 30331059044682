import { Box, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import React, { useMemo, useRef, useState } from 'react';
import CoinPreview from 'src/components/CoinPreview';
import LoadingCollectibles from 'src/components/LoadingCollectibles';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { IUser } from 'src/interface/IUser';
import { IUserWallet } from 'src/interface/IUserWallet';
import { useAppSelector } from 'src/redux/hooks';
import { ICollectible } from 'src/types';
import { convertRemToPx } from 'src/utils';
import { isUserCollectibleVisible } from 'src/utils/isUserCollectibleVisible';
import { useTheme } from 'styled-components';

import UserWalletItems from './UserWalletItems';

const UserWalletCoinsHint: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Flex
    width="fit-content"
    p="15px 20px"
    alignItems="center"
    borderRadius="15px"
    border="1px solid #E1E0E0"
    __css={{ gap: '10px' }}
  >
    <Icon.BoxiconsSolid.Zap color="orange" size={16} />
    <Flex alignItems="center" __css={{ gap: '10px' }}>
      <Text variant="text5-400" color="neutrals-800">
        Click on the coins to redeem.
      </Text>
      <Text
        variant="text5-400"
        color="neutrals-800"
        onClick={onClick}
        __css={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        Dismiss.
      </Text>
    </Flex>
  </Flex>
);

const UserWalletCoinsEmptyState: React.FC = () => (
  <Box
    data-testid="user-wallet-empty-state"
    textAlign="center"
    backgroundColor="neutrals-50"
    mt="4"
    p="4"
    pb="6"
    borderRadius="8px"
  >
    <Icon.BoxiconsRegular.Coin size={34} />
    <Text variant="text3-600">Collect coins</Text>
    <Text color="#727578">Collect coins from your favorite brands and redeem them on new products!</Text>
  </Box>
);

type UserWalletCoinsProps = {
  user: IUser;
  wallet: IUserWallet;
  onClickCollectible: (collectible: ICollectible) => void;
};

const UserWalletCoins: React.FC<UserWalletCoinsProps> = ({ user, wallet, onClickCollectible }) => {
  const [showRedeemHint, setShowRedeemHint] = useState<boolean>(true);
  const sessionUser = useAppSelector(({ user }) => user);

  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const addressCollectiblesRef = useRef<any>();

  const isSessionUserWallet = useMemo(
    () => user.cChainPublicAddress == sessionUser?.cChainPublicAddress,
    [user?.cChainPublicAddress, sessionUser?.cChainPublicAddress]
  );

  return (
    <UserWalletItems
      user={user}
      wallet={wallet}
      filterTypes={['ERC20']}
      ref={addressCollectiblesRef}
      loadingState={<LoadingCollectibles count={isMobile ? 2 : 4} width="162px" height="235px" />}
    >
      {(collectibles: ICollectible[]) => {
        return collectibles.length === 0 ? (
          isSessionUserWallet ? (
            <UserWalletCoinsEmptyState />
          ) : (
            <Text color="neutrals-700">Nothing here yet</Text>
          )
        ) : (
          <Flex flexDirection="column" __css={{ gap: '15px' }} data-testid="collectible-item">
            {isSessionUserWallet && showRedeemHint && <UserWalletCoinsHint onClick={() => setShowRedeemHint(false)} />}
            <Flex __css={{ gap: '15px' }} flexWrap="wrap">
              {collectibles.map((collectible, index) => (
                <Flex
                  key={index}
                  onClick={() => onClickCollectible(collectible)}
                  __css={{ cursor: 'pointer' }}
                  flex="0"
                >
                  <CoinPreview
                    metadata={collectible.metadata}
                    balance={collectible.balance.toString()}
                    isPrivate={!isUserCollectibleVisible(wallet, collectible)}
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
        );
      }}
    </UserWalletItems>
  );
};

export default UserWalletCoins;
