/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICollectible, ICollectibleExtraMetadata } from '../../types';
import { IMapper, Mapper } from '../../utils/Mapper';

export type Web3State = {
  collectibles: IMapper<string, ICollectible[]>[]; // contractAddress - ICollectible[]
  listeners: IMapper<string, string[]>[]; // contractAddress - address[]
  loadedAddresses: IMapper<string, string[]>[]; // address - contractAddress[] / Already loaded wallet address
  loading: boolean;
  called: boolean;
  error?: any;
};

export type CollectiblePayload = {
  contractAddress: string;
  collectibles: ICollectible[];
  ownerAddress?: string;
};

export type RemoveContractCollectiblePayload = {
  contractAddress: string;
  collectible: ICollectible;
};

export type ListenerPayload = {
  contractAddress: string;
  address: string | string[];
};

export type StateChangePayload = {
  loading?: boolean;
  called?: boolean;
};

const initialState: Web3State = {
  collectibles: [],
  loadedAddresses: [],
  listeners: [],
  called: false,
  loading: false,
};

export function overrideCollectibleDescription(
  collectible: ICollectible,
  collectibleExtraMetadatas: ICollectibleExtraMetadata[]
) {
  const extraMetadata = collectibleExtraMetadatas?.find((item) => item.tokenId === collectible.tokenId);
  if (extraMetadata) {
    return {
      ...collectible,
      metadata: {
        ...collectible.metadata,
        ...extraMetadata,
      },
    };
  } else {
    return collectible;
  }
}

export const web3Slice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setState: (state: Web3State, action: PayloadAction<StateChangePayload>) => {
      const { loading, called } = action.payload;

      if (loading !== undefined) {
        state.loading = loading;
      }
      if (called !== undefined) {
        state.called = called;
      }
    },
    setCollectibles: (state: Web3State, action: PayloadAction<CollectiblePayload>) => {
      const { contractAddress, collectibles } = action.payload;

      state.collectibles = Mapper.set(state.collectibles, contractAddress, collectibles);
    },
    rehydrateCollectibles: (state: Web3State, action: PayloadAction<CollectiblePayload>) => {
      const { contractAddress, ownerAddress } = action.payload;

      // Remove duplicated
      const collectibles = action.payload.collectibles.reduce<ICollectible[]>((prev, cur) => {
        if (!prev.some((v) => v.tokenId === cur.tokenId)) {
          prev.push(cur);
        }

        return prev;
      }, []);

      if (!Mapper.has(state.collectibles, contractAddress)) {
        state.collectibles = Mapper.set(
          state.collectibles,
          contractAddress,
          collectibles.map((v) => ({
            ...v,
            balance: !ownerAddress ? v.balance : undefined,
            owners: ownerAddress
              ? [
                  {
                    address: ownerAddress,
                    balance: v.balance,
                  },
                ]
              : undefined,
          }))
        );
      } else {
        const rehydration = Mapper.get(state.collectibles, contractAddress);

        collectibles.forEach((v) => {
          const index = rehydration.findIndex((x) => x.tx === v.tx || x.tokenId === v.tokenId);

          if (index === -1) {
            rehydration.push({
              ...v,
              balance: !ownerAddress ? v.balance : undefined,
              owners: ownerAddress
                ? [
                    {
                      address: ownerAddress,
                      balance: v.balance,
                    },
                  ]
                : undefined,
            });
          } else {
            // Initialize owner array
            if (!rehydration[index].owners) {
              rehydration[index].owners = [];
            }

            // Upsert owner
            const ownerIndex = rehydration[index].owners.findIndex((p) => p.address === ownerAddress);
            if (ownerIndex === -1) {
              rehydration[index].owners.push({
                address: ownerAddress,
                balance: v.balance,
              });
            } else {
              // Updates only balance
              rehydration[index].owners[ownerIndex].balance = v.balance;
            }

            // We need to keep tracked the user blocknumber
            if (rehydration[index].blockNumber) {
              delete v.blockNumber;
            }

            rehydration[index] = {
              ...rehydration[index],
              ...v,
              balance: !ownerAddress ? v.balance : rehydration[index].balance, // Keeps tracked only the origin balance (generally will be the brand balance of the token)
            };
          }
        });

        state.collectibles = Mapper.set(state.collectibles, contractAddress, rehydration);
      }

      // Set this ownerAddress has already loaded
      if (ownerAddress) {
        const contracts = Mapper.has(state.loadedAddresses, ownerAddress)
          ? Mapper.get(state.loadedAddresses, ownerAddress)
          : [];

        contracts.push(contractAddress);
        state.loadedAddresses = Mapper.set(state.loadedAddresses, ownerAddress, contracts);
      }
    },
    removeContractCollectible: (state: Web3State, action: PayloadAction<RemoveContractCollectiblePayload>) => {
      const { contractAddress, collectible } = action.payload;

      const currentCollectibles = Mapper.get(state.collectibles, contractAddress);
      state.collectibles = Mapper.set(
        state.collectibles,
        contractAddress,
        currentCollectibles.filter((v) => v.tokenId !== collectible.tokenId)
      );
    },
    clearCollectibles: (state: Web3State) => {
      state.collectibles = [];
    },
    clearContractCollectibles: (state: Web3State, action: PayloadAction<string>) => {
      state.collectibles = Mapper.set(state.collectibles, action.payload, []);
    },
    addListener: (state: Web3State, action: PayloadAction<ListenerPayload>) => {
      const { contractAddress, address } = action.payload;

      const currentAddresses = Mapper.get(state.listeners, contractAddress) || [];
      const hasAddress = (addr: string) => {
        if (Array.isArray(address)) {
          return !address.includes(addr);
        }

        return addr === address;
      };

      if (!Mapper.has(state.listeners, contractAddress) || !currentAddresses.some(hasAddress)) {
        if (Array.isArray(address)) {
          currentAddresses.push(...address);
        } else {
          currentAddresses.push(address);
        }

        state.listeners = Mapper.set(state.listeners, contractAddress, currentAddresses);
      }
    },
    clearListener: (state: Web3State) => {
      state.listeners = [];
    },
  },
});

export const {
  setState,
  setCollectibles,
  rehydrateCollectibles,
  removeContractCollectible,
  clearCollectibles,
  clearContractCollectibles,
  addListener,
  clearListener,
} = web3Slice.actions;

export default web3Slice.reducer;
