import { Box, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

const Loading: React.FC<{ padding?: number }> = ({ padding }) => {
  return (
    <Box p={padding ?? 3}>
      <Text>Loading...</Text>
    </Box>
  );
};

export default Loading;
