export interface MenuItem {
  name: string;
  label: string;
  icon:
    | 'home_alt'
    | 'wallet'
    | 'hash'
    | 'cog'
    | 'wrench'
    | 'receipt'
    | 'collection'
    | 'wallet_alt'
    | 'mail_send'
    | 'line_chart'
    | 'shop_bag'
    | 'coin'
    | 'calendar'
    | 'key';
  path: string;
  query?: { [key: string]: string };
  altPaths?: string[];
  sub: {
    name: string;
    path: string;
    query?: { [key: string]: string };
  }[];
}

export const mainMenu: MenuItem[] = [
  //  {
  //   name: 'home',
  //   label: 'Home',
  //   isSelected: false,
  //   icon: 'home_alt',
  //   path: '/',
  //   sub: [],
  // },
  {
    name: 'wallet',
    label: 'Wallet',
    icon: 'wallet',
    path: '/wallet',
    altPaths: ['/'],
    sub: [],
  },
  {
    name: 'channels',
    label: 'Chat',
    icon: 'hash',
    path: '/channels',
    sub: [],
  },
  {
    name: 'events',
    label: 'Events',
    icon: 'calendar',
    path: `/events`,
    sub: [],
  },
  // {
  //   name: 'settings',
  //   label: 'Settings',
  //   icon: 'cog',
  //   path: '#',
  //   sub: [],
  // },
];
