import { gql } from '@apollo/client';

import { IMultipartUpload } from '../../types';

export const CREATE_MULTIPART_UPLOAD = gql`
  mutation CreateMultipartUpload($filename: String!, $partsNumber: Int!, $folder: String, $bucketName: String) {
    createMultipartUpload(filename: $filename, partsNumber: $partsNumber, folder: $folder, bucketName: $bucketName) {
      uploadParts
      uploadId
      bucketName
      objectKey
    }
  }
`;

export const COMPLETE_MULTIPART_UPLOAD = gql`
  mutation CompleteMultipartUpload($data: CompleteMultipartUploadInput!) {
    completeMultipartUpload(data: $data)
  }
`;

export const ABORT_MULTIPART_UPLOAD = gql`
  mutation AbortMultipartUpload($data: AbortMultipartUploadInput!) {
    abortMultipartUpload(data: $data)
  }
`;

export interface ICreateMultipartUploadData {
  createMultipartUpload: {
    uploadParts: string[];
    uploadId: string;
    bucketName: string;
    objectKey: string;
  };
}

export interface ICreateMultipartUploadVars {
  filename: string;
  partsNumber: number;
  folder?: string;
  bucketName?: string;
}

export interface ICompleteMultipartUploadVars {
  data: {
    multipartUpload: IMultipartUpload;
    multipartETags: {
      ETag: string;
      PartNumber: number;
    }[];
  };
}

export interface ICompleteMultipartUploadData {
  completeMultipartUpload: string;
}

export interface IAbortMultipartUploadVars {
  data: {
    multipartUpload: IMultipartUpload;
  };
}

export interface IAbortMultipartUploadData {
  abortMultipartUpload: boolean;
}
