export const beaconProxyFactory = {
  ERC1155: process.env.NEXT_PUBLIC_ERC1155_BEACON_PROXY_FACTORY_ADDRESS,
  ERC721Rare: process.env.NEXT_PUBLIC_ERC721RARE_BEACON_PROXY_FACTORY_ADDRESS,
  ERC20: process.env.NEXT_PUBLIC_ERC20_BEACON_PROXY_FACTORY_ADDRESS,
};

export const providerConfig = {
  RPC_URL: process.env.NEXT_PUBLIC_AVALANCHE_RPC,
  WS_URL: process.env.NEXT_PUBLIC_AVALANCHE_WS,
  CHAIN_ID: Number(process.env.NEXT_PUBLIC_AVALANCHE_CHAIN_ID),
};
