import Pusher from 'pusher-js';

const apiUrl =
  process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_WALLET_SVC_URL : process.env.NEXT_PUBLIC_API_URL;

if (process.env.NODE_ENV !== 'development') {
  Pusher.Runtime.createXHR = function () {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return xhr;
  };
}

let pusher: Pusher;
const getPusherInstance = () => {
  if (!pusher) {
    const token = typeof localStorage !== 'undefined' ? localStorage.getItem('tybToken') : undefined;

    pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
      cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
      userAuthentication: {
        endpoint: `${apiUrl}/api/v1/pusher/user-auth`,
        transport: 'ajax',
        params: {},
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        customHandler: null,
      },
      channelAuthorization: {
        endpoint: `${apiUrl}/api/v1/pusher/channel-auth`,
        transport: 'ajax',
        params: {},
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        customHandler: null,
      },
    });
  }

  return pusher;
};

export default getPusherInstance;
