import { gql } from '@apollo/client';

import { IUser } from '../../interface/IUser';
import { IUserWallet } from '../../interface/IUserWallet';

export interface UserPageVars {
  urlSlug: string;
}

export interface UserPageData {
  userPage: IUser & { userWallet: IUserWallet };
}

export const USER_PUBLIC_PAGE = gql`
  query userPage($urlSlug: String!) {
    userPage(urlSlug: $urlSlug) {
      avatarUrl
      bio
      createdAt
      email
      firstName
      lastName
      userName
      xChainPublicAddress
      cChainPublicAddress
      repCardParticipationCount
      birthdate
      twitterUrl
      instagramUrl
      tiktokUrl
      urlSlug
      userWallet {
        address
        relatedContracts {
          type
          contractAddress

          collectibleExtraMetadatas {
            contractAddress
            tokenId
            description
            additionalDetails
          }
        }
        collectiblesSettings {
          contractAddress
          tokenId
          visibility
        }
      }
    }
  }
`;
