import { Flex, Text } from '@tyb-u/tyb-ui-components';
import React, { PropsWithChildren } from 'react';

type TabType = string | number;

interface TabProps extends PropsWithChildren {
  selectedTab?: TabType;
  onClick?: (tab: TabType) => void;
}

interface TabItemProps extends PropsWithChildren {
  tab: TabType;
  active?: boolean;
  disabled?: boolean;
  hideMobile?: boolean;
  fontSize?: string;
  onClick?: (tab: TabType) => void;
}

const Tab: React.FC<TabProps> & { Item: React.FC<TabItemProps> } = ({ selectedTab, onClick, children }) => (
  <Flex mb="25px" borderBottom="1px solid #E1E0E0" data-testid="tab-component" flexWrap="wrap" justifyContent="stretch">
    {React.Children.map(children, (child: React.ReactElement) =>
      React.cloneElement(child, {
        onClick,
        active: selectedTab == child.props.tab,
        __css: { flex: 1 },
      })
    )}
  </Flex>
);

const TabItem: React.FC<TabItemProps> = ({ tab, active, disabled, hideMobile, onClick, children }) => {
  const color = disabled ? 'neutrals-400' : active ? 'neutrals-900' : 'neutrals-600';
  const display = hideMobile ? ['block', 'block', 'none'] : 'block';
  const border = active ? { borderBottom: '2px solid', borderBottomColor: 'neutrals-900' } : null;
  const css = { cursor: disabled ? 'not-allowed' : 'pointer', ...border };

  const handleClick = (tab: TabType) => {
    if (disabled) return;
    onClick(tab);
  };

  return (
    <Flex display={display} py="15px" px={[0, '25px']} flex={[1, 0]} __css={css} onClick={() => handleClick(tab)}>
      <Flex justifyContent="center" alignItems="center">
        <Text variant="text5-500" color={color} __css={{ whiteSpace: 'nowrap' }}>
          {children}
        </Text>
      </Flex>
    </Flex>
  );
};

Tab.defaultProps = {
  onClick: () => null,
};

TabItem.defaultProps = {
  active: false,
};

Tab.Item = TabItem;

export default Tab;
