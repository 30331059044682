import React from 'react';

import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

type AlertTypes = 'default' | 'error' | 'success' | 'warning';

export interface AlertProps {
  type?: AlertTypes;
  children?: React.ReactNode;
}

const getAlertIcon = (type: AlertTypes): React.ReactNode => {
  if (type === 'success') {
    return <Icon.BoxiconsRegular.CheckCircle size="16px" data-testid="icon" />;
  }
  if (type === 'error') {
    return <Icon.BoxiconsRegular.ErrorCircle size="16px" data-testid="icon" />;
  }
  if (type === 'warning') {
    return <Icon.BoxiconsRegular.Error size="16px" data-testid="icon" />;
  }
  return null;
};

const Alert: React.FC<AlertProps> = ({ type, children }) => {
  const AlertIcon = getAlertIcon(type);
  const alertColor = type === 'default' ? 'text' : `${type}-500`;
  return (
    <Flex flexDirection="row" alignItems="center" color={alertColor}>
      {AlertIcon}
      <Text ml="1" variant="text5-400" color={alertColor}>
        {children}
      </Text>
    </Flex>
  );
};

Alert.defaultProps = {
  type: 'default',
};

export default Alert;
