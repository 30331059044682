import { Flex, Text } from '@tyb-u/tyb-ui-components';

type RedeemAllProps = {
  onClick: () => void;
};

const RedeemAll: React.FC<RedeemAllProps> = ({ onClick }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    alignSelf="center"
    backgroundColor="neutrals-50"
    borderRadius="12px"
    p="6px 12px"
    __css={{ cursor: 'pointer' }}
    onClick={() => onClick()}
  >
    <Text variant="text7-600" color="neutrals-700">
      Redeem all
    </Text>
  </Flex>
);

export default RedeemAll;
