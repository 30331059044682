import { ReactElement } from 'react';
import { Wrapper, WrapperDisabled } from './Common';

type Provider = 'Google';

const Logos = {
  Google: require('./GoogleLogo.svg'),
};

interface SsoGoogleProps {
  label: ReactElement;
  provider: Provider;
  isLoading?: boolean;
  onClick: () => void;
}

export const SsoGoogle = ({ label, provider, isLoading, onClick }: SsoGoogleProps) => {
  return isLoading ? (
    <>
      <WrapperDisabled>
        <img className="SSO-icon" alt="Sso icon" src={Logos[provider]} />
        <div>{label}</div>
      </WrapperDisabled>
    </>
  ) : (
    <Wrapper onClick={onClick}>
      <img className="SSO-icon" alt="Sso icon" src={Logos[provider]} />
      <div>{label}</div>
    </Wrapper>
  );
};
