import { Box, Flex } from '@tyb-u/tyb-ui-components';

type LoadingCollectiblesProps = {
  width?: string | string[];
  height?: string | string[];
  count?: number;
  justifyContent?: string | string[];
};

const LoadingCollectibles: React.FC<LoadingCollectiblesProps> = ({
  width = '150p',
  height = '150p',
  count = 2,
  justifyContent,
}) => {
  const boxes = new Array(count).fill('');

  return (
    <Flex __css={{ gap: '15px' }} justifyContent={justifyContent}>
      {boxes.map((_, idx) => (
        <Box
          key={`loading_collectibles_box_${idx}`}
          borderRadius="24px"
          width={width}
          height={!Array.isArray(height) ? height : 'unset'}
          minHeight={Array.isArray(height) ? height : 'unset'}
          __css={{ animation: 'collectibleLoading 1s infinite alternate' }}
        />
      ))}
    </Flex>
  );
};

export default LoadingCollectibles;
