import { Button, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { convertRemToPx } from 'src/utils';
import { useTheme } from 'styled-components';

type RedemptionHeader = 'collectible' | 'coin';

interface RedemptionHeaderProps {
  type: RedemptionHeader;
  onBack: () => void;
  onClose: () => void;
}

const RedemptionHeader: React.FC<RedemptionHeaderProps> = ({ type, onBack, onClose }) => {
  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center" justifyContent="space-between" flex={1}>
        <Button
          variant="secondary"
          alignItems="center"
          borderColor="#E1E0E0"
          display="flex"
          alignContent="center"
          onClick={() => onBack()}
        >
          <Icon.BoxiconsRegular.ArrowBack size={16} />
          {!isMobile && (
            <Text ml={1} variant="text5-400" color="#272424">
              Back to {type}
            </Text>
          )}
        </Button>
      </Flex>
      <Text>Redeem {type}</Text>
      <Flex
        backgroundColor="white"
        onClick={() => onClose()}
        data-testid="redeem-collectible-modal-close"
        flex={1}
        justifyContent="end"
      >
        <Icon.BoxiconsRegular.X size={24} />
      </Flex>
    </Flex>
  );
};

export default RedemptionHeader;
