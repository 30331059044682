import { breakpoints } from '@tyb-browser/lib/consts';
import { createGlobalStyle } from 'styled-components';

import { ITheme } from './theme.types';

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  * {box-sizing: border-box; margin: 0; padding: 0}
`;

const colorsPrimary = {
  50: '#FFE6D5',
  100: '#FFCDAA',
  200: '#FFB480',
  300: '#FF9B55',
  400: '#FF822B',
  500: '#FF6900',
  600: '#BF4F00',
  700: '#9F4200',
  800: '#803500',
  900: '#602700',
};

const colorsNeutrals = {
  50: '#F9F9F9',
  100: '#EDECEC',
  200: '#E1E0E0',
  300: '#D6D3D3',
  400: '#CAC7C7',
  500: '#BEBABA',
  600: '#9A9393',
  700: '#746D6D',
  800: '#4D4949',
  900: '#272424',
};

const colorsDark = {
  50: '#393D49',
  100: '#363B47',
  200: '#343844',
  300: '#2F3440',
  400: '#2D313E',
  500: '#282D3A',
  600: '#262A37',
  700: '#242835',
  800: '#1F2431',
  900: '#131826',
};

const colorsSuccess = {
  50: '#F5FEEB',
  100: '#EBFCD5',
  200: '#D4F9AC',
  300: '#B1EE80',
  400: '#8FDE5D',
  500: '#5FC92E',
  600: '#44AC21',
  700: '#2D9017',
  800: '#1A740E',
  900: '#0D6008',
};

const colorsError = {
  50: '#FFF3EB',
  100: '#FFE3D6',
  200: '#FFC0AD',
  300: '#FF9783',
  400: '#FF6F65',
  500: '#FF3236',
  600: '#DB2438',
  700: '#B71938',
  800: '#930F35',
  900: '#7A0933',
};

const colorsWarning = {
  50: '#FEFAED',
  100: '#FDF0C8',
  200: '#FBE7A3',
  300: '#F9DD7E',
  400: '#F7CE46',
  500: '#F7CE46',
  600: '#C6A538',
  700: '#947C2A',
  800: '#63521C',
  900: '#31290E',
};

const legacyColors = {
  text: colorsNeutrals[900],
  background: '#FFFFFF',
  primary: '#0007B6',
  cyan: '#00AEEF',
  blue: '#0007B6',
  black: '#000000',
  white: '#FFFFFF',
  gray01: '#F8FAFC',
  gray02: '#E1E4E8',
  gray03: '#A2A7AC',
  gray04: '#727578',
  transparent: 'transparent',
};

export const colors = {
  ...legacyColors,
  black: '#000000',
  white: '#FFFFFF',
  transparent: 'transparent',
  primary: {
    default: colorsPrimary[500],
    ...colorsPrimary,
  },
  neutrals: {
    default: colorsNeutrals[500],
    ...colorsNeutrals,
  },
  dark: {
    default: colorsDark[500],
    ...colorsDark,
  },
  success: {
    default: colorsSuccess[500],
    ...colorsSuccess,
  },
  error: {
    default: colorsError[500],
    ...colorsError,
  },
  warning: {
    default: colorsWarning[500],
    ...colorsWarning,
  },
};

type IColorsOrigin = typeof colors;

export const parseColors = (colors: IColorsOrigin) => {
  const parsed = {};
  Object.keys(colors).map((key) => {
    if (typeof colors[key] === 'object') {
      Object.keys(colors[key]).map((key2) => {
        parsed[`${key}-${key2}`] = colors[key][key2];
      });
    }
    if (typeof colors[key] === 'string') {
      parsed[key] = colors[key];
    }
  });
  return parsed;
};

export type FontVariantKeyType =
  | 'title0-cherry'
  | 'title0'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'title5'
  | 'text0'
  | 'text1'
  | 'text2'
  | 'text3-400'
  | 'text3-500'
  | 'text3-600'
  | 'text4-400'
  | 'text4-500'
  | 'text4-600'
  | 'text5-400'
  | 'text5-500'
  | 'text5-600'
  | 'text6-400'
  | 'text6-500'
  | 'text6-600'
  | 'text7-400'
  | 'text7-500'
  | 'text7-600';

export type FontVariantsTypes = { [variant in FontVariantKeyType]: unknown };

export const fonts: FontVariantsTypes = {
  'title0-cherry': {
    fontFamily: 'Cherry Bomb One',
    fontWeight: '400',
    fontSize: '38px',
    lineHeight: '36px',
  },
  title0: {
    fontFamily: 'Grey LL',
    fontWeight: '400',
    fontSize: '34px',
    lineHeight: '36px',
  },
  title1: {
    fontFamily: 'Grey LL',
    fontWeight: '400',
    fontSize: '32px',
    lineHeight: '32px',
  },
  title2: {
    fontFamily: 'Grey LL',
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '37px',
  },
  title3: {
    fontFamily: 'Grey LL',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '32px',
  },
  title4: {
    fontFamily: 'Grey LL',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '28px',
  },
  title5: {
    fontFamily: 'Grey LL',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',
  },
  text0: {
    fontFamily: 'Inter',
    fontSize: '32px',
    lineHeight: '32px',
  },
  text1: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '30px',
  },
  text2: {
    fontFamily: 'Inter',
    fontSize: '20px',
    lineHeight: '26px',
  },
  'text3-400': {
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '400',
  },
  'text3-500': {
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '500',
  },
  'text3-600': {
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '600',
  },
  'text4-400': {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  'text4-500': {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  'text4-600': {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '600',
  },
  'text5-400': {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
  },
  'text5-500': {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
  },
  'text5-600': {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
  },
  'text6-400': {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
  },
  'text6-500': {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '500',
  },
  'text6-600': {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '600',
  },
  'text7-400': {
    fontFamily: 'Inter',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: '400',
  },
  'text7-500': {
    fontFamily: 'Inter',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: '500',
  },
  'text7-600': {
    fontFamily: 'Inter',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: '600',
  },
};

// const fontFamilies = {
//   body: 'Grey LL, sans-serif',
//   heading: 'Grey LL, sans-serif',
//   monospace: 'Grey LL Mono, monospace',
// };

// const fontSizes = {
//   xs: '0.75rem',
//   sm: '0.875rem',
//   md: '1rem',
//   lg: '1.125rem',
//   xl: '1.25rem',
//   '2xl': '1.5rem',
//   '3xl': '1.875rem',
//   '4xl': '2.25rem',
//   '5xl': '3rem',
//   '6xl': '3.75rem',
//   '7xl': '4.5rem',
//   '8xl': '6rem',
//   '9xl': '8rem',
// };

// const fontWeights = {
//   regular: 400,
//   medium: 500,
//   semibold: 600,
//   bold: 700,
// };

// const lineHeights = {
//   normal: 'normal',
//   none: 1,
//   shorter: 1.25,
//   short: 1.375,
//   base: 1.5,
//   tall: 1.625,
//   taller: 2,
// };

// const legacyFonts = {
//   display: { //TITLE0
//     fontSize: fontSizes['4xl'],
//     fontWeight: fontWeights.bold,
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   h1: { //title1
//     fontSize: fontSizes['3xl'],
//     fontWeight: fontWeights.bold,
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   h2: { //text3
//     fontSize: fontSizes['2xl'],
//     fontWeight: fontWeights.bold,
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   h3: { // title4
//     fontSize: fontSizes['xl'],
//     fontWeight: fontWeights.bold,
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   subtitle: { // text3-400
//     fontSize: fontSizes['lg'],
//     fontWeight: 'medium',
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   body: { //text4-400
//     fontSize: fontSizes['md'],
//     fontWeight: 'regular',
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   caption: { // text5-400
//     fontSize: fontSizes['sm'],
//     fontWeight: 'regular',
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   small: { //text6-400
//     fontSize: fontSizes['xs'],
//     fontWeight: 'regular',
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
//   label: {
//     fontSize: fontSizes['sm'],
//     fontWeight: 'regular',
//     fontFamily: fontFamilies.body,
//     paddingTop: space[1],
//     paddingBottom: space[1],
//   },
// };

const space = ['0rem', '0.25rem', '0.5rem', '1rem', '1.5rem', '2rem', '2.5rem', '3rem', '3.5rem', '4rem'];

const borders = {
  none: 'none',
  thin: `1px solid ${colors.neutrals[200]}`,
  dark: '1px solid #000000',
};

const borderWidths = {
  thin: '1px',
};

const radii = {
  none: '0',
  sm: '0.25rem',
  md: '0.5rem',
  lg: '1rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
};

const shadows = {
  low: '0px 2px 4px rgba(0, 0, 0, 0.16)',
  high: '0px 8px 16px rgba(0, 0, 0, 0.16)',
};

const sizes = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};

const defaultTheme: ITheme = {
  colors: parseColors(colors),
  //fonts: fontFamilies,
  // fontSizes,
  // fontWeights,
  // lineHeights,
  space,
  sizes,
  borderWidths,
  borders,
  radii,
  shadows,
  breakpoints,
  mediaQueries,
  variants: {
    text: fonts,
    link: {
      default: {
        color: colors.blue,
      },
    },
    buttons: {
      primary: {
        backgroundColor: colors.black,
        border: borders.none,
        color: colors.white,
      },
      secondary: {
        backgroundColor: colors.white,
        border: borders.thin,
        color: colors.neutrals[900],
        // '&:hover': {
        //   backgroundColor: 'red',
        // },
      },
      link: {
        backgroundColor: colors.transparent,
        border: borders.none,
        color: colors.black,
      },
    },
    images: {
      avatar: {
        borderRadius: '100%',
      },
      thumb: {
        borderRadius: radii.sm,
      },
    },
    input: {
      default: {
        borderWidth: '1px',
        borderColor: colors.neutrals[200],
        '&:focus': {
          borderColor: colors.black,
          borderWidth: '1px',
        },
      },
      success: {
        borderWidth: '1px',
        borderColor: colors.success,
        '&:focus': {
          borderWidth: '1px',
        },
      },
      error: {
        borderWidth: '1px',
        borderColor: colors.error,
        '&:focus': {
          borderWidth: '1px',
        },
      },
      filled: {
        borderWidth: '2px',
        borderColor: colors.gray01,
        '&:focus': {
          borderWidth: '2px',
        },
      },
    },
  },
};

export const theme: ITheme = {
  ...defaultTheme,
};
