import { Flex, Text } from '@tyb-u/tyb-ui-components';
import { useMemo } from 'react';
import { getCoinRedemptionValue } from 'src/containers/Redemption/helpers';
import { RedeemEcommRedemptionCheckSubscriptionsData } from 'src/graphql/mutations/redeemEcommRedemptionCheckSubscriptions';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';

interface RedemptionCouponInfoProps {
  brandName: string;
  brandWebsiteURL: string;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  amount?: number;
  applicableSubscription?: RedeemEcommRedemptionCheckSubscriptionsData;
}

const RedemptionCouponInfo: React.FC<RedemptionCouponInfoProps> = ({
  brandName,
  brandWebsiteURL,
  ecommBrandRedemption,
  amount,
  applicableSubscription,
}) => {
  const couponInfo = useMemo(() => {
    const storeText = brandWebsiteURL ? (
      <span>
        at{' '}
        <a style={{ cursor: 'pointer', textDecoration: 'underline' }} href={`https://${brandWebsiteURL}`}>
          {brandWebsiteURL}
        </a>
      </span>
    ) : (
      <span>in {brandName} online store</span>
    );

    return (
      <Text color="neutrals-900">
        Your code is ready. Keep it safe and use it at checkout {storeText}. We also emailed you the copy for your
        records.
      </Text>
    );
  }, [brandWebsiteURL, brandName]);

  const couponValue = useMemo(
    () => getCoinRedemptionValue({ amount, effect: ecommBrandRedemption?.effect }),
    [ecommBrandRedemption?.effect]
  );

  const nextSubscriptionCharge = useMemo(
    () =>
      `${applicableSubscription?.redeemEcommRedemptionCheckSubscriptions?.nextCharge?.subtotal} ${applicableSubscription?.redeemEcommRedemptionCheckSubscriptions?.nextCharge?.currency}`,
    [applicableSubscription?.redeemEcommRedemptionCheckSubscriptions]
  );

  return (
    <Flex flexDirection="column" __css={{ gap: '10px' }}>
      {applicableSubscription ? (
        <>
          <Flex flexDirection="column" __css={{ gap: '10px' }}>
            <Text variant="text1" color="neutrals-900">
              Your discount is applied!
            </Text>
            {ecommBrandRedemption && (
              <>
                <Text color="neutrals-900" display="inline">
                  Yay! <span style={{ color: 'black', fontWeight: '600' }}>{`${couponValue} USD`}</span> discount will
                  be automatically applied to your next{' '}
                  <span style={{ color: 'black', fontWeight: '600' }}>{nextSubscriptionCharge}</span> subscription
                  charge. Do not forget to return here next time you want to redeem.
                </Text>
              </>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Text variant="text1" color="neutrals-900">
            Your discount code
          </Text>
          {couponInfo}
        </>
      )}
    </Flex>
  );
};

export default RedemptionCouponInfo;
