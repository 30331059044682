import { Box, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import React from 'react';

import { ADMIN_BASE_URL, IS_DEV_MODE } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import TeamMenuItem from '../TeamMenuItem';

const SwitchBrandMenu: React.FC<{
  isDark?: boolean;
}> = ({ isDark }) => {
  const user = useAppSelector((state) => state.user);
  const router = useRouter();

  return (
    <Flex flexDirection="column" width="100%" __css={{ gap: '10px' }}>
      <Text variant="text5-400" color="neutrals-600">
        Switch account
      </Text>
      <Box maxHeight="256px" overflowY="auto">
        {user.managedBrands
          .map((v) => v)
          .sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true }))
          .map((team) => (
            <Flex
              key={team.id}
              flexDirection="column"
              __css={{ cursor: 'pointer', gap: '0.5rem' }}
              onClick={() => {
                if (IS_DEV_MODE) {
                  router.push('/admin');
                } else {
                  window.open(ADMIN_BASE_URL.replace('app', team.urlSlug), '_blank');
                }
              }}
            >
              <TeamMenuItem
                name={team.name}
                color={'#F2C94C'}
                isDark={isDark}
                size={42}
                logoUrl={team.avatarUrl}
                showRole
              />
            </Flex>
          ))}
      </Box>
    </Flex>
  );
};

export default SwitchBrandMenu;
