import { gql } from '@apollo/client';

import { IIndexedTransaction } from '../../services/contract-manager/contracts/types';

export interface IIndexedBlock {
  lastIndexedBlock: number;
  publicAddress: string;
  transactions: IIndexedTransaction[];
}

export interface IGetIndexedVars {
  address: string;
}

export interface IGetIndexedWalletData {
  indexedWallet: IIndexedBlock;
}

export const GET_INDEXED_WALLET = gql`
  query IndexerWallet($address: String!) {
    indexedWallet: indexerWallet(address: $address) {
      lastIndexedBlock
      publicAddress
      transactions {
        blockNumber
        contractAddress
        from
        to
        txHash
        type
        payload {
          tokenId
          method
          quantity
        }
      }
    }
  }
`;
