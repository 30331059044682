import A from './components/A/A';
import Alert from './components/Alert/Alert';
import Avatar from './components/Avatar/Avatar';
import Badge from './components/Badge/Badge';
import Box from './components/Box/Box';
import Button from './components/Button/Button';
import Card from './components/Card/Card';
import Checkbox from './components/Checkbox/Checkbox';
import Flex from './components/Flex/Flex';
import Grid from './components/Grid/Grid';
import Icon from './components/Icon/Icon';
import Image from './components/Image/Image';
import Input from './components/Input/Input';
import Label from './components/Label/Label';
import Radio from './components/Radio/Radio';
import Result from './components/Result/Result';
import SectionTitle from './components/SectionTitle/SectionTitle';
import Select from './components/Select/Select';
import Survey from './components/Survey/Survey';
import Tab from './components/Tab/Tab';
import Table from './components/Table/Table';
import Text from './components/Text/Text';
//import Logo from "./Logo/Logo";
import { theme } from './theme';
import { ITheme } from './theme.types';

export {
  A,
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Image,
  Input,
  Label,
  Radio,
  Result,
  SectionTitle,
  Select,
  Survey,
  Tab,
  Table,
  Text,
  //  Logo,
};

export { theme };
export type { ITheme };
