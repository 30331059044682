import { gql, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import { IBrandCustomization } from '../interface/IBrandCustomization';

interface GetBrandCustomizationBySlugVars {
  urlSlug: string;
}

interface GetBrandCustomizationBySlugData {
  brand: {
    uuid: string;
    name: string;
    logoUrl: string;
    customization: IBrandCustomization;
  };
}

const GET_BRAND_CUSTOMIZATION_BY_SLUG = gql`
  query BrandBySlug($urlSlug: String!) {
    brand: brandBySlug(urlSlug: $urlSlug) {
      uuid
      name
      logoUrl
      customization {
        loginPage {
          enabled
          coin
          coinImage
        }
      }
    }
  }
`;

export const useBrandCustomization = () => {
  const [customization, setCustomization] = useState<
    IBrandCustomization & { brandName: string; brandLogoUrl: string }
  >();

  const { refetch: getBySlug } = useQuery<GetBrandCustomizationBySlugData, GetBrandCustomizationBySlugVars>(
    GET_BRAND_CUSTOMIZATION_BY_SLUG,
    {
      fetchPolicy: 'standby',
    }
  );

  const getCustomizationBySlug = useCallback(async (urlSlug: string) => {
    const res = await getBySlug({
      urlSlug,
    });

    if (res?.data?.brand) {
      setCustomization({
        brandName: res.data.brand.name,
        brandLogoUrl: res.data.brand.logoUrl,
        loginPage: res.data.brand.customization?.loginPage,
      });
    }
  }, []);

  return {
    customization,
    getCustomizationBySlug,
  };
};
