import { sanitizeUrl } from '@braintree/sanitize-url';
import { Flex } from '@tyb-u/tyb-ui-components';
import { useCallback, useMemo, useState } from 'react';
import RedemptionHeader from 'src/components/Redemption/RedemptionHeader';
import { ICollectionInfoData } from 'src/graphql/queries/collectionInfo';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { IBrandEcommRedemptionData } from 'src/interface/IBrandEcommRedemptionData';
import { IUser } from 'src/interface/IUser';
import { ICollectible, RedemptionCoupon } from 'src/types';
import { convertRemToPx } from 'src/utils';
import { useTheme } from 'styled-components';

import CollectibleRedemptionComplete from './steps/CollectibleRedemptionComplete';
import CollectibleRedemptionDetails from './steps/CollectibleRedemptionDetails';

interface CollectibleRedemptionProps {
  user: IUser;
  ecommBrandRedemption: IBrandEcommRedemptionData;
  collectible: ICollectible;
  collectionInfoData: ICollectionInfoData;
  onBack: () => void;
  onFinish: () => void;
}

export enum CollectibleRedemptionSteps {
  Details,
  Complete,
}

const CollectibleRedemption: React.FC<CollectibleRedemptionProps> = ({
  user,
  collectible,
  collectionInfoData,
  ecommBrandRedemption,
  onBack,
  onFinish,
}) => {
  const [currentStep, setCurrentStep] = useState<CollectibleRedemptionSteps>(CollectibleRedemptionSteps.Details);
  const [coupon, setCoupon] = useState<RedemptionCoupon>({
    code: '',
    expiredAt: '',
  });

  const theme = useTheme();
  const size = useWindowSize();
  const isMobile = size.width <= convertRemToPx(theme.breakpoints[1]);

  const brandName = useMemo(
    () => collectionInfoData?.collectionInfo?.brand?.name,
    [collectionInfoData?.collectionInfo?.brand?.name]
  );

  const brandWebsiteURL = useMemo(
    () =>
      collectionInfoData?.collectionInfo?.brand?.websiteUrl
        ? sanitizeUrl(collectionInfoData.collectionInfo.brand.websiteUrl)
        : '',
    [collectionInfoData?.collectionInfo?.brand?.websiteUrl]
  );

  const handleNextStep = useCallback(
    async (data?: any) => {
      const nextStep = currentStep + 1;
      const lastStep = Number(Object.values(CollectibleRedemptionSteps).pop());

      if (nextStep > lastStep) {
        onFinish();
      } else {
        setCoupon({
          code: data?.redeemEcommRedemption?.code,
          expiredAt: data?.redeemEcommRedemption?.expiredAt,
        });
        setCurrentStep(nextStep);
      }
    },
    [currentStep]
  );

  const handleClose = () => {
    setCurrentStep(CollectibleRedemptionSteps.Details);
    setCoupon({
      code: '',
      expiredAt: '',
    });
    onFinish();
  };

  return (
    <Flex flexDirection="column" height="100%" __css={{ gap: '25px' }}>
      <RedemptionHeader type="collectible" onBack={onBack} onClose={() => handleClose()} />
      <Flex flexDirection="column" flex={1} minHeight={['80%', '50vh']}>
        {currentStep === CollectibleRedemptionSteps.Details && (
          <CollectibleRedemptionDetails
            user={user}
            collectible={collectible}
            collectionInfoData={collectionInfoData}
            ecommBrandRedemption={ecommBrandRedemption}
            brandName={brandName}
            brandWebsiteURL={brandWebsiteURL}
            isMobile={isMobile}
            onNextStep={handleNextStep}
          />
        )}

        {currentStep === CollectibleRedemptionSteps.Complete && (
          <CollectibleRedemptionComplete
            brandName={brandName}
            brandWebsiteURL={brandWebsiteURL}
            coupon={coupon}
            isMobile={isMobile}
            onNextStep={handleNextStep}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default CollectibleRedemption;
