import { SUPPORTED_MIME_TYPES_EXTENSIONS } from '../constants';
import { bytesToMegaBytes } from '.';

export const isImageMimeType = (mimeType: string) => /image/.test(mimeType);

export const isImageSrc = (src: string) => /.png|.jpg|.jpeg|.gif|.webp/.test(src);

export const isVideoMimeType = (mimeType: string) => /video\//.test(mimeType);

export const isVideoSrc = (src: string) => /.mp4|.mov/.test(src);

export const isMediaMimeType = (mimeType: string) => isImageMimeType(mimeType) || isVideoMimeType(mimeType);

export const isMediaSrc = (src: string) => isImageSrc(src) || isVideoSrc(src);

export const getSupportedImageMimeTypes = ({ exclude }: { exclude?: string[] } = {}): string =>
  Object.keys(SUPPORTED_MIME_TYPES_EXTENSIONS)
    .filter((mimeType) => isImageMimeType(mimeType) && !exclude?.includes(mimeType))
    .join(',');

export const getSupportedVideoMimeTypes = (): string =>
  Object.keys(SUPPORTED_MIME_TYPES_EXTENSIONS).filter(isVideoMimeType).join(',');

export const getSupportedMediaMimeTypes = (): string => Object.keys(SUPPORTED_MIME_TYPES_EXTENSIONS).join(',');

export const parseSupportedMimeTypes = (mimeTypes: string): string => {
  const supportedMimeTypes = Object.keys(SUPPORTED_MIME_TYPES_EXTENSIONS);

  return mimeTypes
    .split(',')
    .map((mimeType) => mimeType.replace(' ', ''))
    .reduce(
      (acc, mimeType) =>
        supportedMimeTypes.includes(mimeType) ? [...acc, SUPPORTED_MIME_TYPES_EXTENSIONS[mimeType]] : acc,
      []
    )
    .join(', ');
};

export const isMimeTypeSupported = ({ mimeTypes, mimeType }: { mimeTypes: string; mimeType: string }): boolean => {
  if (!mimeType) return false;

  const allMimeTypesSupported = mimeTypes === '*/*';
  const generalMimeType = mimeType.split('/')[0];

  const generalMimeTypeSupported = mimeTypes === `${generalMimeType}/*`;
  const mimeTypeSupported = mimeTypes.includes(mimeType);

  return allMimeTypesSupported || generalMimeTypeSupported || mimeTypeSupported;
};

export const isFileExtensionSupported = ({ mimeTypes, extension }: { mimeTypes: string; extension: string }) => {
  if (!extension) return false;

  const allMimeTypesSupported = mimeTypes === '*/*';
  const supportedExtensions = parseSupportedMimeTypes(mimeTypes);

  return allMimeTypesSupported || supportedExtensions.includes(extension.toLowerCase());
};

export const validateFileUpload = ({
  mimeTypes,
  size,
  file,
}: {
  mimeTypes?: string;
  size?: number;
  file: File;
}): string => {
  const mimeType = file?.type;
  const extension = file?.name.split('.').pop();

  if (
    mimeTypes &&
    !isMimeTypeSupported({ mimeTypes, mimeType }) &&
    !isFileExtensionSupported({ mimeTypes, extension })
  ) {
    const formattedFileTypes = parseSupportedMimeTypes(mimeTypes);
    return `Unable to upload a file: this file type is not supported (try ${formattedFileTypes} instead)`;
  } else if (size !== undefined && bytesToMegaBytes(file?.size) > size) {
    return `Unable to upload a file: exceeds maximum file size (${size}MB)`;
  }

  return '';
};
