import { Image } from '@tyb-u/tyb-ui-components';
import { useTheme } from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { cdnPath, convertRemToPx } from '../../utils';

const ImageCDN = ({ src, resizeTo = null, ...props }) => {
  const windowSize = useWindowSize();
  const theme = useTheme();
  const isMobile = windowSize.width <= convertRemToPx(theme.breakpoints[1]);
  const isTablet = !isMobile && windowSize.width <= convertRemToPx(theme.breakpoints[2]);
  const size = resizeTo ?? ((isMobile && 'sm') || (isTablet && 'md') || 'original');

  return <Image {...props} src={cdnPath(src, size)} />;
};

export default ImageCDN;
