export const updateFullscreenOverflow = ({
  isFullscreen,
  hideContent,
}: {
  isFullscreen: boolean;
  hideContent?: boolean;
}): void => {
  if (window && document) {
    const appWrapper = document.getElementById('app-wrapper');
    if (appWrapper) {
      if (isFullscreen) {
        appWrapper.style.overflowY = 'hidden';

        if (hideContent) {
          appWrapper.style.height = '0';
        }
      } else {
        appWrapper.style.overflowY = 'unset';
        appWrapper.style.height = 'unset';
      }
    }
  }
};
