import { gql } from '@apollo/client';

export interface UpdateUserVars {
  user: {
    userName?: string;
    email?: string;
    phoneNumber?: string;
    avatarUrl?: string;
    bio?: string;
    birthdate?: Date;
    twitterUrl?: string;
    instagramUrl?: string;
    tiktokUrl?: string;
  };
}

export interface UpdateUserData {
  updateUser: {
    id: string;
    email: string;
    userName: string | null;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    avatarUrl: string | null;
    bio: string | null;
    birthdate: Date | null;
    twitterUrl: string | null;
    instagramUrl: string | null;
    tiktokUrl: string | null;
  };
}

export const UPDATE_USER = gql`
  mutation updateUser($user: UserUpdateInput!) {
    updateUser(user: $user) {
      id
      email
      userName
      userName
      firstName
      lastName
      phoneNumber
      avatarUrl
      bio
      birthdate
      twitterUrl
      instagramUrl
      tiktokUrl
    }
  }
`;
