import { Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';
import Confetti from 'react-confetti';
import ImageCDN from 'src/components/ImageCDN';
import VideoCDN from 'src/components/VideoCDN';
import { RepCardRewardMetadata } from 'src/types';
import { numberFormatter } from 'src/utils';

import { RepCardRewardData } from '../RepCardCompleteModal/graphql/queries/getRepCard';

interface UnlockRewardModalContentProps {
  rewardMetadata?: RepCardRewardMetadata;
  reward: RepCardRewardData;
  onContinue?: () => void;
  continueButtonMessage?: string;
}

const UnlockRewardModalContent: React.FC<UnlockRewardModalContentProps> = ({
  reward,
  rewardMetadata,
  onContinue,
  continueButtonMessage,
}) => {
  return (
    <Flex width="100%" height="100%" flexDirection="column">
      <Flex
        bg="neutrals-900"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={'100%'}
        __css={{ gap: '60px' }}
        py="60px"
      >
        <Flex flexDirection="column" __css={{ gap: '24px' }} alignItems="center">
          <Text textAlign="center" variant="text1" color="neutrals-800" fontWeight={500}>
            {reward?.type === 'REPS_ONLY' ? (
              <>You earned {reward?.reps || reward?.quantity || 1} Reps</>
            ) : (
              <>
                {reward?.type === 'BRAND_COIN'
                  ? `You earned ${numberFormatter.format(reward?.quantity)} ${rewardMetadata.name} coin${
                      (reward?.quantity ?? 0) !== 1 ? 's' : ''
                    }`.toUpperCase()
                  : 'You unlocked'}
              </>
            )}
          </Text>
          {rewardMetadata && (
            <Text variant="text0" textAlign="center" color="white" pb="0">
              {rewardMetadata.name}
            </Text>
          )}
        </Flex>
        <Flex flexDirection="column" __css={{ gap: '40px' }}>
          <Confetti style={{ height: '100%', width: '100%' }} />
          {rewardMetadata && (
            <>
              <Flex width="310px" height="310px" justifyContent="center" __css={{ gap: '40px' }}>
                {rewardMetadata.animationUrl ? (
                  <VideoCDN height="100%" width="100%" autoPlay={true} src={rewardMetadata.animationUrl} />
                ) : (
                  <ImageCDN
                    resizeTo="original"
                    height="auto"
                    width="100%"
                    src={rewardMetadata.image}
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </Flex>
              <Button variant="secondary" onClick={onContinue}>
                {continueButtonMessage || 'Continue'}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UnlockRewardModalContent;
