import { IBrandWallet } from 'src/interface/IBrandWallet';
import { IUserWallet } from 'src/interface/IUserWallet';
import { ICollectible } from 'src/types';

export const isUserCollectibleVisible = (
  wallet: IUserWallet | IBrandWallet,
  collectible?: Pick<ICollectible, 'contractAddress' | 'tokenId'>
) => {
  if (!collectible) return true;

  const collectibleSettings = wallet.collectiblesSettings.find(
    ({ contractAddress, tokenId }) =>
      contractAddress === collectible.contractAddress && (tokenId || '') === (collectible.tokenId || '')
  );

  return collectibleSettings?.visibility ?? true;
};
