/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contract, ethers, FixedNumber } from 'ethers';

import { beaconProxyFactory, providerConfig } from '../../config/contractManager';
import {
  erc20ABI,
  erc20BeaconProxyFactoryABI,
  erc721MembershipABI,
  erc721RareABI,
  erc721RareBeaconProxyFactoryABI,
  erc1155ABI,
  erc1155BeaconProxyFactoryABI,
} from './abi';
import { SupportedInterface } from './constants';
import { BaseContract } from './contract';
import { StandardTypes } from './contracts/base';
import { ContractERC20, ERC20EventTypeArgs } from './contracts/erc20';
import { ContractERC20BeaconProxy } from './contracts/erc20BeaconFactory';
import { ERC721EventTypeArgs } from './contracts/erc721';
import { ERC721BeaconProxyFactoryTypeArgs } from './contracts/erc721BeaconFactory';
import { ContractERC721Membership } from './contracts/erc721Membership';
import { ContractERC721Rare } from './contracts/erc721Rare';
import { ContractERC721RareBeaconProxy } from './contracts/erc721RareBeaconFactory';
import { ContractERC1155, ERC1155EventTypeArgs } from './contracts/erc1155';
import { ContractERC1155BeaconProxy, ERC1155BeaconProxyFactoryTypeArgs } from './contracts/erc1155BeaconFactory';
import { EventManager, IEventManagerValue } from './eventManager';
import { ContractManagerProvider } from './provider';

export type AddressTokenBalance = {
  tokenId: string;
  balance: number;
};

type EventName = 'mint' | 'safeMint' | 'safeTransferFrom' | 'transfer' | 'transferFrom';

interface ICollectibleMetadata {
  name: string;
  description: string;
  image?: string | null;
  animationUrl?: string | null;
  externalUrl?: string | null;
}

interface ICollectible {
  tx?: string;
  tokenId: string;
  metadata: ICollectibleMetadata;
  uri: string;
  balance: number | FixedNumber;
  totalSupply?: number | FixedNumber;
  status?: string;
  byEvent?: boolean;
}

interface ICollectionDeploy {
  uri: string;
  owner: string;
  proxy: string;
  maxSupply?: number;
  initialSupply?: FixedNumber;
}

export type EventListenerType =
  | 'NEW_COLLECTIBLE'
  | 'CHANGE_COLLECTIBLE'
  | 'DEPLOY_COLLECTION'
  | 'NEW_COIN'
  | 'TRANSFER_COIN';
type EventListenerCallback = (
  type: EventListenerType,
  contractAddress: string,
  address: string,
  nonce: number,
  blockNumber: number,
  standardName: StandardTypes,
  data: ICollectible | ICollectionDeploy
) => void;
type ListenBatch = {
  contractAddress: string;
  contractType: StandardTypes;
  address: string;
  type: EventListenerType;
  cb: EventListenerCallback;
};

type IfaceType =
  | StandardTypes.ERC20
  | StandardTypes.ERC20_BEACON_FACTORY
  | StandardTypes.ERC1155
  | StandardTypes.ERC1155_BEACON_FACTORY
  | StandardTypes.ERC721RARE
  | StandardTypes.ERC721Membership
  | StandardTypes.ERC721RARE_BEACON_FACTORY;

export class ContractManager<T extends BaseContract> {
  private networkUrl: Record<'ws' | 'rpc', string>;
  private provider: ethers.providers.WebSocketProvider;
  private readonly iface: Record<IfaceType, ethers.utils.Interface>;
  private readonly abi: Record<IfaceType, any>;
  private static _instance;

  private providerManager: ContractManagerProvider;
  private eventManager: EventManager;
  private contractManager: Map<string, T>;

  constructor() {
    this.networkUrl = {
      rpc: providerConfig.RPC_URL,
      ws: providerConfig.WS_URL,
    };

    // this.provider = new ethers.providers.WebSocketProvider(this.networkUrl.ws);
    this.providerManager = new ContractManagerProvider(this.networkUrl.ws);
    this.provider = this.providerManager.provider;

    this.providerManager.on('change', (provider: ethers.providers.WebSocketProvider) => {
      this.provider = provider;

      // Reload contracts
      this.contractManager.forEach((contract) => {
        contract.reload(this.provider);
      });
    });

    this.iface = {
      [StandardTypes.ERC20]: new ethers.utils.Interface(erc20ABI),
      [StandardTypes.ERC20_BEACON_FACTORY]: new ethers.utils.Interface(erc20BeaconProxyFactoryABI),
      [StandardTypes.ERC1155]: new ethers.utils.Interface(erc1155ABI),
      [StandardTypes.ERC1155_BEACON_FACTORY]: new ethers.utils.Interface(erc1155BeaconProxyFactoryABI),
      [StandardTypes.ERC721RARE]: new ethers.utils.Interface(erc721RareABI),
      [StandardTypes.ERC721Membership]: new ethers.utils.Interface(erc721MembershipABI),
      [StandardTypes.ERC721RARE_BEACON_FACTORY]: new ethers.utils.Interface(erc721RareBeaconProxyFactoryABI),
    };
    this.abi = {
      [StandardTypes.ERC20]: erc20ABI,
      [StandardTypes.ERC20_BEACON_FACTORY]: erc20BeaconProxyFactoryABI,
      [StandardTypes.ERC1155]: erc1155ABI,
      [StandardTypes.ERC1155_BEACON_FACTORY]: erc1155BeaconProxyFactoryABI,
      [StandardTypes.ERC721RARE]: erc721RareABI,
      [StandardTypes.ERC721Membership]: erc721MembershipABI,
      [StandardTypes.ERC721RARE_BEACON_FACTORY]: erc721RareBeaconProxyFactoryABI,
    };
    this.contractManager = new Map<string, T>();

    // Initialize event manager
    this.eventManager = new EventManager();
  }

  public static instance<T extends BaseContract>() {
    if (!this._instance) {
      this._instance = new ContractManager<T>();
    }

    return this._instance as ContractManager<T>;
  }

  public static isValidEvent(eventName: EventName, data: any, type?: StandardTypes) {
    try {
      this.instance().decodeData(eventName, data, type);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public listen(
    contractAddress: string,
    address: string,
    type: EventListenerType,
    cb: EventListenerCallback,
    contractType = StandardTypes.ERC1155
  ) {
    const value: IEventManagerValue = {
      address: address,
      type: type,
    };

    if (!this.eventManager.has(contractAddress, value)) {
      const contract = this.loadContract(contractAddress, contractType);

      if (contract) {
        this.handleListen(contract, address, type, cb);
      }
    }
  }

  public listenBatch(batch: ListenBatch[]) {
    for (const item of batch) {
      const value: IEventManagerValue = {
        address: item.address,
        type: item.type,
      };

      if (!this.eventManager.has(item.contractAddress, value)) {
        this.eventManager.add(item.contractAddress, { address: item.address, type: item.type });
        const contract = this.loadContract(item.contractAddress, StandardTypes.ERC1155);

        if (contract) {
          this.handleListen(contract, item.address, item.type, item.cb);
        }
      }
    }
  }

  private handleListen(contract: BaseContract, address: string, type: EventListenerType, cb: EventListenerCallback) {
    switch (type) {
      case 'NEW_COLLECTIBLE':
        if (contract.standardName === StandardTypes.ERC1155) {
          return contract.on<ERC1155EventTypeArgs['TransferSingle']>(
            'TransferSingle',
            [null, null, address],
            async (event, args) => {
              const tx = await event.getTransaction();
              const txData = this.getEventTransactionData(tx);

              if (ContractManager.isValidEvent('mint', txData)) {
                const { uri, metadata } = await (contract as any).getTokenMetadata(args.tokenId);
                cb(
                  type,
                  contract.contractAddress,
                  address || args.operator,
                  event.blockNumber,
                  tx.nonce,
                  StandardTypes.ERC1155,
                  {
                    byEvent: true,
                    tx: event.transactionHash,
                    tokenId: args.tokenId,
                    balance: args.amount,
                    uri,
                    metadata,
                  }
                );
              }
            }
          );
        } else {
          return contract.on<ERC721EventTypeArgs['Transfer']>('Transfer', [null, address], async (event, args) => {
            const tx = await event.getTransaction();
            const txData = this.getEventTransactionData(tx);

            if (
              ContractManager.isValidEvent('safeMint', txData, StandardTypes.ERC721RARE) ||
              ContractManager.isValidEvent('safeMint', txData, StandardTypes.ERC721Membership)
            ) {
              const { uri, metadata } = await (contract as any).getTokenMetadata(args.tokenId);
              cb(type, contract.contractAddress, address, event.blockNumber, tx.nonce, contract.standardName, {
                byEvent: true,
                tx: event.transactionHash,
                tokenId: args.tokenId,
                balance: 1,
                uri,
                metadata,
              });
            }
          });
        }

      case 'CHANGE_COLLECTIBLE':
        if (contract.standardName == StandardTypes.ERC1155) {
          return contract.on<ERC1155EventTypeArgs['TransferSingle']>(
            'TransferSingle',
            [
              [null, null, address],
              [null, address],
            ],
            async (event, args) => {
              const tx = await event.getTransaction();
              const txData = this.getEventTransactionData(tx);

              if (ContractManager.isValidEvent('safeTransferFrom', txData)) {
                const currentBalance = await (contract as any).getTokenBalance(address, args.tokenId);
                const { uri, metadata } = await (contract as any).getTokenMetadata(args.tokenId);

                cb(
                  type,
                  contract.contractAddress,
                  address || args.operator,
                  event.blockNumber,
                  tx.nonce,
                  StandardTypes.ERC1155,
                  {
                    byEvent: true,
                    tx: event.transactionHash,
                    tokenId: args.tokenId,
                    balance: currentBalance.balance,

                    uri,
                    metadata,
                  }
                );
              }
            }
          );
        } else {
          return contract.on<ERC721EventTypeArgs['Transfer']>(
            'Transfer',
            [
              [address, null],
              [null, address],
            ],
            async (event, args) => {
              const tx = await event.getTransaction();
              const txData = this.getEventTransactionData(tx);

              if (
                ContractManager.isValidEvent('transfer', txData, StandardTypes.ERC721RARE) ||
                ContractManager.isValidEvent('transferFrom', txData, StandardTypes.ERC721RARE) ||
                ContractManager.isValidEvent('transfer', txData, StandardTypes.ERC721Membership) ||
                ContractManager.isValidEvent('transferFrom', txData, StandardTypes.ERC721Membership)
              ) {
                const { uri, metadata } = await (contract as any).getTokenMetadata(args.tokenId);
                const isOwner = await contract.validateTokenOwnership(address, event);

                cb(type, contract.contractAddress, address, event.blockNumber, tx.nonce, contract.standardName, {
                  byEvent: true,
                  tx: event.transactionHash,
                  tokenId: args.tokenId,
                  balance: isOwner ? 1 : 0, // TODO: Check ownerOf to validate the current balance
                  uri,
                  metadata,
                });
              }
            }
          );
        }

      case 'NEW_COIN':
        if (contract.standardName == StandardTypes.ERC20) {
          return contract.on<ERC20EventTypeArgs['Transfer']>('Transfer', [null, address], async (event) => {
            console.log('NEW_COIN', event);
            const tx = await event.getTransaction();
            const txData = this.getEventTransactionData(tx);

            if (ContractManager.isValidEvent('mint', txData, StandardTypes.ERC20)) {
              const currentBalance = await (contract as ContractERC20).getAddressBalance(address);
              const currentTotalSupply = await (contract as ContractERC20).getTotalSupply();
              const metadata = await (contract as ContractERC20).getContractMetadata();

              cb(type, contract.contractAddress, address, event.blockNumber, tx.nonce, StandardTypes.ERC20, {
                byEvent: true,
                tx: event.transactionHash,
                tokenId: null,
                balance: currentBalance,
                totalSupply: currentTotalSupply,
                uri: '',
                metadata,
              });
            }
          });
        }
        break;

      case 'TRANSFER_COIN':
        if (contract.standardName == StandardTypes.ERC20) {
          return contract.on<ERC20EventTypeArgs['Transfer']>('Transfer', [null, address], async (event) => {
            console.log('TRANSFER_COIN', event);
            const tx = await event.getTransaction();
            const txData = this.getEventTransactionData(tx);

            if (ContractManager.isValidEvent('transfer', txData, StandardTypes.ERC20)) {
              const currentBalance = await contract.getAddressBalance(address);
              const currentTotalSupply = await contract.getTotalSupply();
              const metadata = await contract.getContractMetadata();

              cb(type, contract.contractAddress, address, event.blockNumber, tx.nonce, StandardTypes.ERC20, {
                byEvent: true,
                tx: event.transactionHash,
                tokenId: null,
                balance: currentBalance,
                totalSupply: currentTotalSupply,
                uri: '',
                metadata,
              });
            }
          });
        }
        break;

      case 'DEPLOY_COLLECTION':
        if (
          [
            StandardTypes.ERC1155_BEACON_FACTORY,
            StandardTypes.ERC721RARE_BEACON_FACTORY,
            StandardTypes.ERC20_BEACON_FACTORY,
          ].includes(contract.standardName)
        ) {
          const filterArgs = [null];
          if (contract.standardName === StandardTypes.ERC20_BEACON_FACTORY) {
            filterArgs.push(null, address);
          } else {
            filterArgs.push(address);
          }

          return contract.on<
            ERC1155BeaconProxyFactoryTypeArgs & ERC721BeaconProxyFactoryTypeArgs['CreatedBeaconProxy']
          >('CreatedBeaconProxy', filterArgs, async (event, args) => {
            const transaction = await event.getTransaction();
            const data = this.decodeData('createBeaconProxy', transaction.data, contract.standardName);

            if (data) {
              console.log('DEPLOY_COLLECTION', data);
              let callbackData: ICollectionDeploy = {
                uri: data.uri,
                owner: data.owner,
                proxy: args.proxy,
              };

              if (contract.standardName === StandardTypes.ERC721RARE_BEACON_FACTORY) {
                callbackData = {
                  uri: data._contractURI,
                  owner: data._owner,
                  proxy: args.proxy,
                  maxSupply: data._maxSupply?.toNumber(),
                };
              }

              if (contract.standardName === StandardTypes.ERC20_BEACON_FACTORY) {
                callbackData = {
                  uri: data._contractURI,
                  owner: data._owner,
                  proxy: args.proxy,
                  initialSupply: FixedNumber.fromValue(data._initialSupply),
                };
              }

              cb(
                type,
                contract.contractAddress,
                address,
                event.blockNumber,
                transaction.nonce,
                contract.standardName,
                callbackData
              );
            }
          });
        }
        break;

      default:
        throw new Error('Listener type not supported!');
    }
  }

  public hasLoadedContract(contractAddress: string) {
    return this.contractManager.has(contractAddress);
  }

  public loadContract<T = any>(contractAddress: string, type: StandardTypes) {
    const contractTypes = {
      [StandardTypes.ERC20]: ContractERC20,
      [StandardTypes.ERC20_BEACON_FACTORY]: ContractERC20BeaconProxy,

      [StandardTypes.ERC1155]: ContractERC1155,
      [StandardTypes.ERC1155_BEACON_FACTORY]: ContractERC1155BeaconProxy,

      [StandardTypes.ERC721RARE]: ContractERC721Rare,
      [StandardTypes.ERC721RARE_BEACON_FACTORY]: ContractERC721RareBeaconProxy,

      [StandardTypes.ERC721Membership]: ContractERC721Membership,
    };

    if (!contractTypes[type]) {
      throw new Error(`Contract type not supported: ${type}`);
    }

    if (this.contractManager.has(contractAddress)) {
      return this.contractManager.get(contractAddress);
    }

    console.debug('[ContractManager] Loading contract: ', contractAddress, type);

    const contract = new contractTypes[type](contractAddress, this.provider, this.getIface(type), this.getAbi(type));
    this.contractManager.set(contractAddress, contract);

    return contract as T;
  }

  public unloadContract(contractAddress: string) {
    if (this.contractManager.has(contractAddress)) {
      console.debug('[ContractManager] Unloading contract: ', contractAddress);

      this.contractManager.get(contractAddress).destroy();
      this.contractManager.delete(contractAddress);
    }
  }

  public unloadAllContract() {
    this.contractManager.forEach((contract) => {
      contract.destroy();
    });

    this.contractManager.clear();
  }

  public decodeData(functionName: string, data: any, type?: StandardTypes) {
    const iface = this.getIface(type);

    return iface.decodeFunctionData(functionName, data);
  }

  public static getType(input: string): StandardTypes {
    input = input.toLowerCase();

    switch (input) {
      case 'erc1155':
        return StandardTypes.ERC1155;
      case 'erc1155_beacon_factory':
        return StandardTypes.ERC1155_BEACON_FACTORY;
      case 'erc721':
        return StandardTypes.ERC721;
      case 'erc721_beacon_factory':
        return StandardTypes.ERC721_BEACON_FACTORY;
      case 'erc721rare':
        return StandardTypes.ERC721RARE;
      case 'erc721rare_beacon_factory':
        return StandardTypes.ERC721RARE_BEACON_FACTORY;
      case 'erc721membership':
        return StandardTypes.ERC721Membership;
      case 'erc20':
        return StandardTypes.ERC20;
      case 'erc20_beacon_factory':
        return StandardTypes.ERC20_BEACON_FACTORY;
      default:
        return null;
    }
  }

  public get blockNumber() {
    return this.providerManager.blockNumber;
  }

  private getIface(type?: StandardTypes): ethers.utils.Interface {
    return (type && this.iface[type]) || this.iface[StandardTypes.ERC1155];
  }

  private getAbi(type?: StandardTypes) {
    return (type && this.abi[type]) || this.abi[StandardTypes.ERC1155];
  }

  private getEventTransactionData(tx: ethers.providers.TransactionResponse) {
    try {
      const iface = new ethers.utils.Interface([
        'function execute(tuple(address from, address to, uint256 value, uint256 gas, uint256 nonce, bytes data) req, bytes signature)',
      ]);
      const result = iface.decodeFunctionData('execute', tx.data);
      return result['req']['data'];
    } catch (err) {
      return tx.data;
    }
  }

  public async supportsInterface(contractAddress: string): Promise<StandardTypes | null> {
    try {
      const contract = new Contract(
        contractAddress,
        ['function supportsInterface(bytes4 interfaceId) view returns (bool)'],
        this.provider
      );

      for (const key in SupportedInterface) {
        const isSupported = await contract.supportsInterface(SupportedInterface[key]);
        if (isSupported) {
          return Number(key);
        }
      }

      return null;
    } catch {
      const { ERC20, ERC721Rare, ERC1155 } = beaconProxyFactory;
      if (![ERC20, ERC721Rare, ERC1155].includes(contractAddress)) {
        return StandardTypes.ERC20;
      }

      return null;
    }
  }
}
