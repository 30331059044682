import styled from 'styled-components';
import { display, DisplayProps, grid, GridProps as StyledGridProps } from 'styled-system';

import Box from '../Box/Box';
import { BoxProps } from '../Box/Box';

export type GridProps = StyledGridProps & DisplayProps & BoxProps;

const Grid: React.FC<GridProps> = styled(Box)<GridProps>`
  display: grid;
  ${grid}
  ${display}
`;

export default Grid;
