import { Box, Button, Flex, Icon, Text } from '@tyb-u/tyb-ui-components';

import ContentModal from '../../components/Modals/Content';

const ConfirmationEmailNotificationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirmThatsMe: () => void;
  onConfirmThatsNotMe: () => void;
}> = ({ isOpen, onClose, onConfirmThatsMe, onConfirmThatsNotMe }) => {
  return (
    <ContentModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      body={
        <Flex padding="20px" flexDirection="column" width={['auto', '500px']}>
          <Flex justifyContent="center">
            <Box __css={{ cursor: 'pointer', position: 'absolute', right: 20 }} onClick={onClose} color={'black'}>
              <Icon.BoxiconsRegular.X size={32} />
            </Box>
            <Flex
              px="40px"
              width="100%"
              maxWidth="500px"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text mt="10px" variant="text3-600" textAlign="center">
                A TYB wallet already exists with that email
              </Text>
              <Flex mt="10px" width="100%">
                <Button width="100%" mr="20px" onClick={onConfirmThatsNotMe}>
                  <Flex flexDirection="column">
                    <Text p="0" variant="text3-600" width="100%" textAlign="center">
                      That&apos;s not me ...
                    </Text>
                    <Text p="0" variant="text6-400" textAlign="center">
                      Go back and edit my email
                    </Text>
                  </Flex>
                </Button>
                <Button variant="secondary" width="100%" onClick={onConfirmThatsMe}>
                  <Flex flexDirection="column">
                    <Text p="0" variant="text3-600" width="100%" textAlign="center">
                      That&apos;s me!
                    </Text>
                    <Text p="0" variant="text6-400" textAlign="center">
                      Sign into my existing wallet using email
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
};

export default ConfirmationEmailNotificationModal;
