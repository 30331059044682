import { Box, Flex, Icon, Image, Text } from '@tyb-u/tyb-ui-components';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useCallback } from 'react';
import {
  isImageMimeType,
  isImageSrc,
  isMediaMimeType,
  isMediaSrc,
  parseSupportedMimeTypes,
  validateFileUpload,
} from 'src/utils/file';
import { errorToast } from 'src/utils/toasts';

import MultipartUploader from '../../containers/MultipartUploader';
import { MultipartUploaderRef } from '../../containers/MultipartUploader/MultipartUploader';
import { MultipartUploadStatus } from '../../types';
import Video from '../Video';
import VideoCDN from '../VideoCDN';

export type FileUploaderRef = {
  upload: (file?: File) => Promise<string>;
  setFile: (file: File) => void;
};

type UploadContainerLabels = {
  dragging?: string;
  empty?: string;
  dirty?: string;
};

type FileUploaderProps = {
  ref: any;
  placeholderImage?: string;
  hide?: boolean;
  accept: string;
  folder: string;
  withBorderRadius?: boolean;
  instantUpload?: boolean;
  width?: string | number;
  /**
   * @description Maximum file size in megabytes
   */
  maxFileSize?: number;
  labels?: UploadContainerLabels;
  onFileChange?: (file: File) => void;
  onChange?: (
    status: MultipartUploadStatus,
    file: File,
    uploadedUrl: string,
    uploadProgress?: number,
    setFile?: (file: File) => void,
    error?: string
  ) => void;
};

type UploadContainerProps = {
  src?: string;
  accept: string;
  file: File;
  maxFileSize: number;
  width?: string | number;
  withBorderRadius: boolean;
  isUploading: boolean;
  uploadingProgress: number;
  labels?: UploadContainerLabels;
  onOpenDialog: () => void;
  onDropFile: (file: File) => void;
};

type MediaProps = {
  src?: string;
  file?: File;
  borderRadius?: any;
  mediaRef: any;
};

const Media: React.FC<MediaProps> = React.memo(({ src, file, borderRadius, mediaRef }) => {
  const isImage = isImageMimeType(file?.type) || isImageSrc(src);

  if (isImage) {
    const imageSrc = file ? URL.createObjectURL(file) : src;

    return (
      <Flex justifyContent="center">
        <Image
          ref={mediaRef}
          src={imageSrc}
          borderRadius={borderRadius}
          verticalAlign="middle"
          width="200px"
          height="200px"
          __css={{
            objectFit: 'cover',
          }}
        />
      </Flex>
    );
  }

  const videoSrc = file ? URL.createObjectURL(file) : src;

  return (
    <Box ref={mediaRef} width="200px" height="200px">
      {file ? (
        <Video src={videoSrc} width="100%" data-testid="challenge-cover-video" />
      ) : (
        <VideoCDN
          width="100%"
          height="100%"
          controls={false}
          preload="metadata"
          src={videoSrc}
          style={{ objectFit: 'cover' }}
          data-testid="challenge-cover-video"
          muted
          autoPlay
          loop
          playsInline
        />
      )}
    </Box>
  );
});

type FileProps = {
  file?: File;
};

const File: React.FC<FileProps> = React.memo(({ file }) => (
  <Box
    py="2"
    px="3"
    textAlign="center"
    borderRadius="6px"
    __css={{
      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.07)',
      backgroundColor: '#FFF',
    }}
  >
    {file?.name}
  </Box>
));

const UploadContainer: React.FC<UploadContainerProps> = React.memo(
  ({
    src,
    accept,
    maxFileSize,
    file,
    width,
    withBorderRadius,
    isUploading,
    uploadingProgress,
    labels,
    onOpenDialog,
    onDropFile,
  }) => {
    const [dragging, setDragging] = useState(false);
    const mediaRef = useRef<HTMLDivElement>();
    const borderRadius = withBorderRadius || (!file && !src) ? '100%' : '0';
    const isMedia = isMediaMimeType(file?.type) || isMediaSrc(src);

    return (
      <Flex flexDirection="column" flex="1">
        <Flex
          border="dashed"
          borderColor="#E1E0E0"
          borderRadius="24px"
          p="4"
          width={width}
          justifyContent="center"
          alignItems="center"
          backgroundColor={dragging && '#F8F8F8'}
          flexDirection="column"
          __css={{
            gap: '15px',
          }}
          onDragOver={(ev) => {
            ev.preventDefault();

            if (!isUploading) {
              setDragging(true);
            }
          }}
          onDragLeave={(ev) => {
            ev.preventDefault();

            if (!isUploading) {
              setDragging(false);
            }
          }}
          onDrop={(ev) => {
            ev.preventDefault();

            if (!isUploading) {
              setDragging(false);

              if (ev.dataTransfer.items.length > 0) {
                const item = ev.dataTransfer.items[0];
                const itemFile = item.getAsFile();
                const validationError = validateFileUpload({ mimeTypes: accept, size: maxFileSize, file: itemFile });

                if (validationError) {
                  errorToast(validationError);
                } else {
                  onDropFile(itemFile);
                }
              }
            }
          }}
        >
          <Flex alignSelf="center" flexDirection="column" __css={{ gap: '24px' }}>
            {isMedia ? (
              <Box __css={{ opacity: isUploading ? 0.7 : 1 }}>
                <Media src={src} file={file} borderRadius={borderRadius} mediaRef={mediaRef} />
              </Box>
            ) : file ? (
              <File file={file} />
            ) : null}
            <Flex
              border="1px solid #E1E0E0"
              borderRadius="20px"
              py="2"
              px="5"
              alignItems="center"
              justifyContent="center"
              onClick={onOpenDialog}
              alignSelf="center"
              width="100%"
              __css={{ cursor: isUploading ? 'normal' : 'pointer', gap: '10px' }}
            >
              <Icon.BoxiconsRegular.Upload size="24" />
              <Text variant="text5-600">
                {dragging
                  ? labels?.dragging || 'Drop here'
                  : isUploading
                  ? `Uploading: ${uploadingProgress}%`
                  : file
                  ? labels?.dirty || 'Change file'
                  : labels?.empty || 'Select file'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Text variant="text6-400">Supported file types: {parseSupportedMimeTypes(accept)}</Text>
      </Flex>
    );
  }
);

const FileUploader = React.forwardRef<any, FileUploaderProps>((props, ref) => {
  const {
    placeholderImage,
    accept,
    folder,
    withBorderRadius = false,
    instantUpload = false,
    hide = false,
    maxFileSize,
    width,
    onFileChange = () => null,
    onChange = () => null,
  } = props;

  const [progress, setProgress] = useState(0);
  const multipartUploaderRef = useRef<MultipartUploaderRef>();

  const handleFileChange = useCallback(async (name, file) => {
    onFileChange(file);

    if (instantUpload && multipartUploaderRef?.current) {
      multipartUploaderRef.current.upload(file);
    }
  }, []);

  const handleChange = useCallback(
    (
      status: MultipartUploadStatus,
      file: File,
      uploadedUrl: string,
      uploadProgress?: number,
      setFile?: (file: File) => void,
      error?: string
    ) => {
      const validationError = validateFileUpload({ mimeTypes: accept, size: maxFileSize, file });

      if (validationError) {
        errorToast(validationError);
      } else {
        onChange(status, file, uploadedUrl, uploadProgress, setFile, error);
        setProgress(uploadProgress || 0);
      }
    },
    [onChange]
  );

  useImperativeHandle(ref, () => ({
    setFile: (file: File) => {
      if (multipartUploaderRef?.current) {
        multipartUploaderRef.current.setFile(file);
      }
    },
    upload: (file?: File) => {
      if (multipartUploaderRef?.current) {
        return multipartUploaderRef.current.upload(file);
      }
    },
  }));

  return (
    <MultipartUploader
      ref={multipartUploaderRef as any}
      accept={accept}
      folder={folder}
      maxFileSize={maxFileSize}
      onFileChange={handleFileChange}
      onChange={handleChange}
    >
      {(openDialog, _upload, _uploadedUrl, { status, file, setFile }) => (
        <>
          {!hide && (
            <UploadContainer
              src={placeholderImage}
              accept={accept}
              file={file}
              width={width}
              maxFileSize={maxFileSize}
              withBorderRadius={withBorderRadius}
              isUploading={status === 'UPLOADING' || status === 'PREPARING'}
              uploadingProgress={progress}
              onOpenDialog={openDialog}
              onDropFile={setFile}
              labels={props.labels}
            />
          )}
        </>
      )}
    </MultipartUploader>
  );
});

export default FileUploader;
