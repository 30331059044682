import React from 'react';

import Badge from '../Badge/Badge';
import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import Text from '../Text/Text';

export interface CardHeaderProps {
  title: string;
}

const CardHeader: React.FC<CardHeaderProps> = ({ title }) => {
  return (
    <Box borderBottom="thin" paddingX="3" paddingTop="3" paddingBottom="2">
      <Text variant="text3-600">{title}</Text>
    </Box>
  );
};

export interface CardFooterProps {
  likes?: number;
  comments?: number;
  timeAgo?: string;
  hashtags?: string[];
  status?: string;
}

const CardFooter: React.FC<CardFooterProps> = ({ likes, timeAgo, comments, hashtags, status }) => {
  return (
    <Box pl="3" pr="3" pb="3">
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <Flex flexDirection="row" opacity={0.5} alignItems="center">
          {comments !== undefined && (
            <>
              <Icon.BoxiconsRegular.Message size={16} />
              <Text variant="text5-400" ml="1" mr="3">
                {comments}
              </Text>
            </>
          )}
          {likes !== undefined && (
            <>
              <Icon.BoxiconsRegular.Like size={16} />
              <Text variant="text5-400" ml="1" mr="3">
                {likes}
              </Text>
            </>
          )}
          {timeAgo !== undefined && (
            <>
              <Icon.BoxiconsRegular.Time size={16} />
              <Text variant="text5-400" ml="1" mr="3">
                {timeAgo}
              </Text>
            </>
          )}
          {hashtags !== undefined && (
            <Text variant="text5-400" ml="1" mr="3">
              {hashtags.map((hash) => ' ' + hash)}
            </Text>
          )}
        </Flex>
        {status !== undefined && (
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            border="thin"
            borderRadius="lg"
            px="2"
            py="1"
          >
            <Box bg="success" width="8px" height="8px" borderRadius="8px" mr="2" />
            <Text variant="text6-400">{status.toUpperCase()}</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export interface CardProps {
  overline?: string;
  text?: string;
  thumbSrc?: string;
  thumbBadgeValue?: string | number;
  backgroundColor?: string;
  borderRadius?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  elevation?: 'low' | 'high';
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
}

const Card: React.FC<CardProps> & { Header: React.FC<CardHeaderProps> } & {
  Footer: React.FC<CardFooterProps>;
} = ({ header, body, thumbSrc, thumbBadgeValue, footer, overline, text, backgroundColor, borderRadius, elevation }) => {
  const getThumb = () => {
    if (thumbSrc && thumbBadgeValue !== null && thumbBadgeValue !== undefined && thumbBadgeValue !== '') {
      return (
        <Badge value={thumbBadgeValue}>
          <Image src={thumbSrc} width="56px" height="56px" />
        </Badge>
      );
    }

    if (thumbSrc) {
      return <Image src={thumbSrc} width="56px" height="56px" />;
    }

    return null;
  };

  return (
    <Box elevation={elevation} bg={backgroundColor} borderRadius={borderRadius}>
      {header}
      <Box padding="3">
        <Flex flexDirection="row">
          <Flex flexDirection="column" flex={1}>
            {overline && <Text variant="text5-400">{overline}</Text>}
            {text && (
              <Text variant="text4-400" mt="2">
                {text}
              </Text>
            )}
          </Flex>
          {getThumb()}
        </Flex>
        {body}
      </Box>
      {footer}
    </Box>
  );
};

Card.defaultProps = {
  backgroundColor: 'white',
  borderRadius: 'md',
  elevation: 'low',
};

Card.Header = CardHeader;
Card.Footer = CardFooter;

export default Card;
