0; /* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { FixedNumber, utils } from 'ethers';
import { useCallback, useState } from 'react';

import { GET_INDEXED_WALLET, IGetIndexedVars, IGetIndexedWalletData } from '../../graphql/queries/indexer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  addListener,
  clearCollectibles,
  clearContractCollectibles,
  clearListener,
  rehydrateCollectibles,
  setState,
} from '../../redux/web3/web3Slice';
import { ContractManager } from '../../services/contract-manager';
import { BaseContract } from '../../services/contract-manager/contract';
import { StandardTypes } from '../../services/contract-manager/contracts/base';
import { ICollectible, ICollection, ICollectionTypes } from '../../types';

export type Web3State = {
  error?: any;
  loading: boolean;
  called: boolean;
};

export const useContractManager = () => {
  const [web3State, setWeb3State] = useState<Web3State>({ loading: false, called: false });
  const [contract, setContract] = useState<BaseContract>();

  const collectibles = useAppSelector((state) => state.web3.collectibles);
  const loadedAddresses = useAppSelector((state) => state.web3.loadedAddresses);
  const globalLoading = useAppSelector((state) => state.web3.loading);
  const globalCalled = useAppSelector((state) => state.web3.called);

  const dispatch = useAppDispatch();

  const { error, loading, called } = web3State;

  const { refetch: getIndexedWallet } = useQuery<IGetIndexedWalletData, IGetIndexedVars>(GET_INDEXED_WALLET, {
    fetchPolicy: 'no-cache',
    skip: true,
  });

  const loadContract = useCallback((contractAddress: string, type?: ICollectionTypes) => {
    const contract = ContractManager.instance().loadContract(
      contractAddress,
      type ? ContractManager.getType(type) : StandardTypes.ERC1155
    );

    if (contract?.contract) {
      setContract(contract);
      return contract;
    }

    return null;
  }, []);

  const unloadAllContracts = useCallback(() => {
    dispatch(clearCollectibles());
    dispatch(clearListener());
    ContractManager.instance().unloadAllContract();

    setContract(null);
  }, [dispatch]);

  const loadAddressTokens = useCallback(
    async (
      address: string,
      contractAddress?: string,
      type?: ICollectionTypes,
      clearCache?: boolean,
      allowZeroBalance?: boolean
    ) => {
      if (!address || !utils.isAddress(address)) {
        console.error('Trying to load collectibles to an invalid address : ' + address);
        return [];
      }

      let loadedContract = contract;

      if (!contract && !contractAddress) {
        throw new Error('There is no contract loaded');
      }

      // Load contract
      if (contractAddress) {
        loadedContract = loadContract(contractAddress, type);
      }

      if (clearCache) {
        dispatch(clearContractCollectibles(loadedContract.contractAddress));
      }

      // Set loading
      setWeb3State((state) => ({
        ...state,
        loading: true,
      }));

      dispatch(setState({ loading: true, called: false }));

      const indexedResponse = await getIndexedWallet({
        address,
      });

      const indexed = indexedResponse?.data?.indexedWallet
        ? {
            transactions: indexedResponse.data.indexedWallet.transactions.filter(
              (v) => v.contractAddress === loadedContract.contractAddress
            ),
            lastBlockNumber: indexedResponse.data.indexedWallet.lastIndexedBlock,
          }
        : null;

      const indexedTokens = await loadedContract.getIndexedWalletTokens(
        address,
        indexed?.transactions,
        allowZeroBalance
      );

      dispatch(
        rehydrateCollectibles({
          contractAddress,
          collectibles: indexedTokens as any,
          ownerAddress: address,
        })
      );

      const eventTokens =
        (await loadedContract.getWalletTokens(address, indexed?.lastBlockNumber, allowZeroBalance)) || [];

      dispatch(
        rehydrateCollectibles({
          contractAddress,
          collectibles: eventTokens as any,
          ownerAddress: address,
        })
      );

      setWeb3State((state) => ({
        ...state,
        loading: false,
        called: true,
      }));

      dispatch(setState({ loading: false, called: true }));

      return [...indexedTokens, ...eventTokens].reduce<ICollectible[]>((prev, cur) => {
        if (!prev.some((v) => v.tokenId === cur.tokenId)) {
          prev.push(cur as any);
        }

        return prev;
      }, []);
    },
    [contract, loadContract, dispatch]
  );

  const loadContractTokens = useCallback(
    async (contractAddress: string, type?: ICollectionTypes) => {
      let loadedContract = contract?.contractAddress === contractAddress ? contract : null;

      // Load contract
      if (contractAddress) {
        loadedContract = loadContract(contractAddress, type);
      }

      // Set loading
      setWeb3State((state) => ({
        ...state,
        loading: true,
      }));

      const tokens = await loadedContract.getContractTokens();

      dispatch(
        rehydrateCollectibles({
          contractAddress,
          collectibles: tokens as ICollectible[],
        })
      );

      setWeb3State((state) => ({
        ...state,
        loading: false,
        called: true,
      }));

      return tokens;
    },
    [contract, loadContract, dispatch]
  );

  const loadAddressBalance = useCallback(async (contractAddress: string, address: string) => {
    let loadedContract = contract?.contractAddress === contractAddress ? contract : null;

    // Load contract
    if (contractAddress) {
      loadedContract = loadContract(contractAddress, 'ERC20');
    }

    const balance: FixedNumber = await loadedContract.getAddressBalance(address);

    // TODO: Store this balance in redux by contract and address

    return balance;
  }, []);

  const getMaxSupply = useCallback(
    async (contractAddress: string, type: ICollectionTypes) => {
      if (type !== 'ERC721Rare') {
        return undefined;
      }

      let loadedContract = contract;

      if (!contract && !contractAddress) {
        throw new Error('There is no contract loaded');
      }

      // Load contract
      if (contractAddress) {
        loadedContract = loadContract(contractAddress, type);
      }

      if (!(loadedContract as any).getMaxSupply) {
        return undefined;
      }

      return await (loadedContract as any).getMaxSupply();
    },
    [contract, loadContract]
  );

  const getTotalSupply = useCallback(
    async (contractAddress: string, type: ICollectionTypes) => {
      if (type !== 'ERC721Rare' && type !== 'ERC721Membership') {
        return undefined;
      }

      let loadedContract = contract;

      if (!contract && !contractAddress) {
        throw new Error('There is no contract loaded');
      }

      // Load contract
      if (contractAddress) {
        loadedContract = loadContract(contractAddress, type);
      }

      if (!(loadedContract as any).getMaxSupply) {
        return undefined;
      }

      return await (loadedContract as any).getTotalSupply();
    },
    [contract, loadContract]
  );

  const addEventListener = useCallback(
    (contractAddress: string, address: string | string[]) => {
      dispatch(
        addListener({
          contractAddress,
          address,
        })
      );
    },
    [dispatch]
  );

  const getOwnerCollectibles = useCallback(
    (ownerAddress: string) => {
      const _collectibles: ICollectible[] = [];
      const collections = collectibles.filter((v) =>
        v.value.some((x) => x.owners?.some((y) => y.address === ownerAddress))
      );

      collections.forEach((v) => {
        _collectibles.push(
          ...v.value
            .filter((x) => x.owners?.some((y) => y.address === ownerAddress))
            .map((x) => ({
              tx: x.tx,
              tokenId: x.tokenId,
              nonce: x.nonce,
              blockNumber: x.blockNumber,
              address: ownerAddress,
              metadata: x.metadata,
              extraMetadata: x.extraMetadata,
              uri: x.uri,
              type: x.type || 'ERC1155',
              balance: x.owners.find((y) => y.address === ownerAddress).balance,
              contractAddress: v.key,
              byEvent: x.byEvent || false,
            }))
        );
      });

      return _collectibles;
    },
    [collectibles]
  );

  const getContractCollectibles = useCallback(
    (contractAddress: string, address?: string) => {
      const contractCollectibles = collectibles.find((v) => v.key.toLowerCase() === contractAddress.toLowerCase());
      if (!contractCollectibles) return [];

      if (!address) return contractCollectibles.value;

      return contractCollectibles.value.filter((v) => v.address === address);
    },
    [collectibles]
  );

  const findContractCollectible = useCallback(
    (contractAddress: string, tokenId: string, address?: string) => {
      const contractCollectibles = collectibles.find((v) => v.key === contractAddress);
      if (!contractCollectibles) return null;

      return contractCollectibles.value.find((v) => {
        if (address) {
          return v.tokenId === tokenId && v.address === address;
        }

        return v.tokenId === tokenId;
      });
    },
    [collectibles]
  );

  const getAllCollectibles = useCallback(
    (collections: ICollection[] | string[], address?: string) => {
      const _collectibles: ICollectible[] = [];

      collections.forEach((collectionOrAddress: ICollection | string) => {
        if (typeof collectionOrAddress === 'string') {
          _collectibles.push(...getContractCollectibles(collectionOrAddress, address));
        } else {
          _collectibles.push(...getContractCollectibles(collectionOrAddress.contractAddress, address));
        }
      });

      return _collectibles;
    },
    [getContractCollectibles]
  );

  const getCollectibleContract = useCallback(
    (tokenId: string, possibleContractAddress: string[]) => {
      const address = possibleContractAddress.find((contractAddress) => {
        const _collectibles = getContractCollectibles(contractAddress);
        return _collectibles.some((v) => v.tokenId === tokenId);
      });

      if (!address) {
        return undefined;
      }

      return loadContract(address) as BaseContract;
    },
    [getContractCollectibles, loadContract]
  );

  const getCollectibleOwners = useCallback(
    (contractAddress: string, tokenId?: string) => {
      const contractCollectibles = collectibles.find((v) => v.key === contractAddress)?.value;
      if (!contractCollectibles) return null;

      const collectible = contractCollectibles.find((v) => v.tokenId === tokenId);
      if (!collectible) return null;

      return collectible.owners;
    },
    [collectibles]
  );

  const isAddressLoaded = useCallback(
    (address: string, contractAddress: string) => {
      return loadedAddresses.some((v) => v.key === address && v.value.includes(contractAddress));
    },
    [loadedAddresses]
  );

  return {
    contract,
    error,
    globalLoading,
    globalCalled,
    loading,
    called,
    collectibles,
    loadedAddresses,

    loadContract,
    unloadAllContracts,
    addEventListener,
    loadAddressTokens,
    loadContractTokens,
    loadAddressBalance,
    getTotalSupply,
    getMaxSupply,
    getContractCollectibles,
    findContractCollectible,

    getAllCollectibles,
    getCollectibleContract,
    getOwnerCollectibles,
    getCollectibleOwners,
    isAddressLoaded,
  };
};
