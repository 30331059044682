import { Alert, Box, Button, Flex, Input } from '@tyb-u/tyb-ui-components';
import React, { Dispatch, SetStateAction } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';

import { AuthType } from '../../types';
import { emailPattern } from '../../utils';

export type Inputs = {
  email: string;
};

export type EmailInputsSubmitHandler = SubmitHandler<Inputs>;

export interface LoginEmailFormProps {
  onSubmit: EmailInputsSubmitHandler;
  defaultValues?: DefaultValues<Inputs>;
  loading?: boolean;
  setAuthType: Dispatch<SetStateAction<AuthType>>;
}

const LoginEmailForm: React.FC<LoginEmailFormProps> = ({ onSubmit, defaultValues, loading = false, setAuthType }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues });

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Box>
          <Input
            placeholder="Enter your email"
            style={{
              borderRadius: '30px',
              border: '1px solid #E8E8E8',
              paddingLeft: '20px',
              paddingRight: '20px',
              width: '100%',
              height: '52px',
            }}
            {...register('email', {
              required: true,
              pattern: {
                value: emailPattern,
                message: 'Enter a valid e-mail address',
              },
            })}
          />
          {errors.email && <Alert type="error">{errors.email.message || 'This field is required'}</Alert>}
        </Box>

        <Flex flexDirection="column" mt="2">
          <Button type="submit" scale="lg" height={52} fontSize="16px" py="12px" disabled={loading}>
            {!loading ? 'Next' : 'Loading...'}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default LoginEmailForm;
