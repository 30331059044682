import styled from 'styled-components';
import { display, DisplayProps, flexbox, FlexboxProps } from 'styled-system';

import Box from '../Box/Box';
import { BoxProps } from '../Box/Box';

export type FlexProps = FlexboxProps & DisplayProps & BoxProps;

const Flex: React.FC<FlexProps> = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
  ${display}
`;

export default Flex;
