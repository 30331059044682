const splitRoute = (route: string) => {
  const parts = route.split('?');
  return {
    host: window.location.host,
    referrer: window.document.referrer,
    path: parts[0],
    search: parts.length > 1 ? `?${parts[1]}` : '',
  };
};

export default splitRoute;
