import mParticle from '@mparticle/web-sdk';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import { PREFIX_UNVERIFIED_EMAIL_WHITELIST } from '../../config/unverifiedEmailWhitelist';
import useAuth from '../../hooks/useAuth';
import useSelectedBrand from '../../hooks/useSelectedBrand';
import LoginPage from '../../pages/login';
import Wallet from '../../pages/wallet';
import { AuthStatus } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks';
import splitRoute from '../../utils/splitRoute';
import Loading from '../Loading';

export const RouterProvider = ({ children }) => {
  const router = useRouter();
  const { login } = router.query as Record<string, string>;
  const auth = useAuth();
  const authStatus = useAppSelector(({ auth }) => auth.status);
  const user = useAppSelector(({ user }) => user);
  const { reset: resetSelectedBrand } = useSelectedBrand();
  const selectedBrand = useAppSelector((state) => state.selectedBrand?.data);
  const showLogin = useMemo(() => login !== undefined && authStatus == AuthStatus.LOGGED_OUT, [login, authStatus]);

  useEffect(() => {
    if (login !== undefined && authStatus == AuthStatus.LOGGED_IN) {
      auth.disableDynamicLogin();
    }
  }, [login, authStatus]);

  useEffect(() => {
    if (selectedBrand && !router.route.startsWith('/brand/')) {
      resetSelectedBrand();
    }

    if (authStatus != AuthStatus.LOADING) {
      logMparticleEvent('page_view', mParticle.EventType.Other, {
        ...splitRoute(router.asPath),
        auth_status: authStatus,
      });
    }
  }, [router.asPath, authStatus]);

  if (user?.sub && !user.emailVerified && !PREFIX_UNVERIFIED_EMAIL_WHITELIST.find((v) => router.asPath.startsWith(v))) {
    return <Wallet />;
  }

  if (showLogin) {
    return <LoginPage />;
  }

  return authStatus == AuthStatus.LOADING ? <Loading /> : children;
};
