const InputAddon = ({ text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderRight: 0,
      color: '#000000d9',
      paddingLeft: '12px',
      paddingRight: '4px',
      height: 38.89,
    }}
  >
    {text}
  </div>
);

export default InputAddon;
