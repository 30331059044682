import React from 'react';

import { FontVariantKeyType } from '../../theme';
import Box, { BoxProps } from '../Box/Box';

export type TextProps = BoxProps & {
  as?: string;
  //variant?: 'display' | 'h1' | 'h2' | 'h3' | 'subtitle' | 'body' | 'caption' | 'small' | 'label';
  variant?: FontVariantKeyType;
};

const Text = React.forwardRef<HTMLElement, TextProps>(({ as, variant, color, ...props }, ref) => {
  return (
    <Box
      data-testid="text"
      as={as}
      variant={`text.${variant}`}
      ref={ref}
      color={color}
      //alignItems="center"
      {...props}
    />
  );
});

Text.defaultProps = {
  as: 'p',
  variant: 'text5-400',
  color: 'neutrals-900',
};

export default Text;
