import mParticle from '@mparticle/web-sdk';
import { Icon } from '@tyb-u/tyb-ui-components';
import copy from 'copy-to-clipboard';
import React from 'react';
import { logMparticleEvent } from 'src/utils/mparticle';

import { useAppSelector } from '../../redux/hooks';
import { stringMiddleCrop } from '../../utils';
import { successToast } from '../../utils/toasts';
import { UserWalletButton } from '../Button/UserWalletButton';

interface UserWalletAddressProps {
  cPublicAddress?: string;
  isDark?: boolean;
  borderRadius?: number | string;
  addressLength?: number;
  endAddressLength?: number;
  showCopyIcon?: boolean;
}

const UserWalletAddress2: React.FC<UserWalletAddressProps> = ({
  cPublicAddress,
  isDark = false,
  addressLength = 10,
  endAddressLength = 4,
}) => {
  const user = useAppSelector(({ user }) => user);
  const handleCopyLink = () => {
    copy(cPublicAddress);
    logMparticleEvent('copy_wallet_address', mParticle.EventType.Other, {
      address: cPublicAddress,
      is_own: cPublicAddress === user?.publicAddress,
    });
    successToast('Copied to clipboard!');
  };

  return (
    (cPublicAddress && (
      <UserWalletButton
        isDark={isDark}
        iconCls={Icon.BoxiconsRegular.Copy}
        text={stringMiddleCrop(cPublicAddress, addressLength, endAddressLength)}
        onClick={handleCopyLink}
      />
    )) ||
    null
  );
};

export default UserWalletAddress2;
