import { Flex, Icon } from '@tyb-u/tyb-ui-components';

import ImageCDN from '../ImageCDN';

interface AvatarImageProps {
  src?: string;
  size?: number;
}

const AvatarImage: React.FC<AvatarImageProps> = ({ src, size = 196 }) => {
  if (src) {
    return <ImageCDN width={size} height={size} variant="avatar" src={src} resizeTo="thumbnail" />;
  }
  return (
    <Flex
      data-testid="avatar-image"
      width={size}
      height={size}
      justifyContent="center"
      alignItems="center"
      bg="gray02"
      borderRadius={size}
      color="white"
    >
      <Icon.BoxiconsRegular.User size={size / 2.5} />
    </Flex>
  );
};

export default AvatarImage;
