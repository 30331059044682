import { Flex } from '@tyb-u/tyb-ui-components';
import Icon from '@tyb-u/tyb-ui-components/components/Icon/Icon';
import React from 'react';

import { ICollectible } from '../../types';
import CollectibleMedia from '../CollectibleMedia';

const CollectiblePreviewPrivateOverlay: React.FC = () => (
  <Flex position="absolute" top={2} right={0}>
    <Icon.BoxiconsRegular.Hide size={24} color="black" />
  </Flex>
);

interface CollectiblePreviewProps {
  collectible?: ICollectible;
  width?: number | string | string[];
  height?: number | string | string[];
  style?: Record<string, unknown>;
  borderRadius?: string | number;
  showVideo?: boolean;
  isPrivate?: boolean;
}

const CollectiblePreview: React.FC<CollectiblePreviewProps> = ({
  collectible,
  width = '100%',
  height = '196px',
  borderRadius = '24px',
  showVideo = false,
  isPrivate,
  style,
}) => {
  return (
    <Flex width={['100%', 'unset']} __css={style}>
      <CollectibleMedia
        height={height}
        width={width}
        metadata={collectible?.metadata}
        tx={collectible?.tx}
        tokenBalance={collectible?.balance}
        tokenId={collectible?.tokenId}
        type={collectible?.type}
        overlayContent={isPrivate && <CollectiblePreviewPrivateOverlay />}
        hideBadges
        borderRadius={borderRadius}
        showVideo={showVideo}
      />
    </Flex>
  );
};

export default CollectiblePreview;
