import { useMutation, useQuery } from '@apollo/client';
import { Divider } from '@mantine/core';
import mParticle from '@mparticle/web-sdk';
import { Box, Flex, Icon } from '@tyb-u/tyb-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CoinRedemption, { CoinRedemptionSteps } from 'src/containers/Redemption/CoinRedemption';
import CollectibleRedemption from 'src/containers/Redemption/CollectibleRedemption';
import {
  REDEEM_ECOMM_REDEMPTION_CHECK_SUBSCRIPTIONS,
  RedeemEcommRedemptionCheckSubscriptionsData,
  RedeemEcommRedemptionCheckSubscriptionsVars,
} from 'src/graphql/mutations/redeemEcommRedemptionCheckSubscriptions';
import { isCollectibleEqual } from 'src/utils/compareCollectibles';
import { logMparticleEvent } from 'src/utils/mparticle';
import { successToast } from 'src/utils/toasts';
import { useTheme } from 'styled-components';

import Loading from '../../components/Loading';
import ContentModal from '../../components/Modals/Content/ContentModal';
import { CollectionTypes, LaunchDarklyFlags } from '../../constants';
import {
  SAVE_USER_COLLECTIBLE_SETTINGS,
  SaveUserCollectibleSettingsData,
  SaveUserCollectibleSettingsVars,
} from '../../graphql/mutations/saveCollectibleSettings';
import { COLLECTION_INFO, ICollectionInfoData, ICollectionInfoVars } from '../../graphql/queries/collectionInfo';
import {
  GET_ECOMM_REDEMPTIONS_BY_INTEGRATION_TYPE,
  GetEcommRedemptionsByIntegrationTypeData,
  GetEcommRedemptionsByIntegrationTypeVars,
} from '../../graphql/queries/getEcommRedemptionsByIntegrationType';
import { USER_PUBLIC_PAGE, UserPageData, UserPageVars } from '../../graphql/queries/userPage';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { useAppSelector } from '../../redux/hooks';
import { ICollectible } from '../../types';
import { convertRemToPx } from '../../utils';
import CoinInfo from './components/CoinInfo';
import CollectibleBrand from './components/CollectibleBrand';
import CollectibleDetails from './components/CollectibleDetails';
import CollectibleInfo from './components/CollectibleInfo';
import CollectibleOwner from './components/CollectibleOwner';

type CollectibleViewModalProps = {
  isOpen: boolean;
  onClose: (submitted?: boolean) => void;
  collectible: ICollectible;
  showTokenId?: boolean;
  isOnBrandPage?: boolean;
  isCoin?: boolean;
  isCollectibleVisible?: boolean;
  slug?: string;
  isPendingCollectible?: boolean;
  onChangeVisibility?: () => void;
};

const CollectibleViewModal: React.FC<CollectibleViewModalProps> = ({
  isOpen,
  onClose,
  collectible,
  showTokenId,
  isOnBrandPage = false,
  isCoin = false,
  slug,
  isCollectibleVisible,
  isPendingCollectible,
  onChangeVisibility,
}) => {
  const size = useWindowSize();
  const theme = useTheme();
  const fullScreen = size.width <= convertRemToPx(theme.breakpoints[0]);
  const router = useRouter();
  const user = useAppSelector((state) => state.user);
  const flags = useFlags();
  const urlSlug = slug || (router.query['urlSlug'] as string);

  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isRedemptionOpen, setIsRedemptionOpen] = useState<boolean>(false);
  const [coinRedemptionInitialStep, setCoinRedemptionInitialStep] = useState<CoinRedemptionSteps>(
    CoinRedemptionSteps.Amount
  );

  const contractAddress = collectible?.contractAddress;
  const tokenId = collectible?.tokenId;

  const { data: userData, loading: userLoading } = useQuery<UserPageData, UserPageVars>(USER_PUBLIC_PAGE, {
    fetchPolicy: 'network-only',
    variables: {
      urlSlug,
    },
    skip: isOnBrandPage || !isOpen,
  });

  const { data: collectionInfoData, loading: collectionInfoLoading } = useQuery<
    ICollectionInfoData,
    ICollectionInfoVars
  >(COLLECTION_INFO, {
    variables: {
      contractAddress,
    },
    skip: !contractAddress,
  });

  const { data: ecommBrandRedemptions } = useQuery<
    GetEcommRedemptionsByIntegrationTypeData,
    GetEcommRedemptionsByIntegrationTypeVars
  >(GET_ECOMM_REDEMPTIONS_BY_INTEGRATION_TYPE, {
    fetchPolicy: 'network-only',
    variables: {
      contractAddress: collectible?.contractAddress,
      tokenId: collectible?.type != 'ERC721Rare' ? collectible?.tokenId : null,
    },
    skip: isOnBrandPage || !isOpen || !collectible || user.publicAddress !== userData?.userPage?.cChainPublicAddress,
  });

  const ecommBrandRedemption = useMemo(
    () =>
      ecommBrandRedemptions?.getEcommRedemptionsByIntegrationType &&
      [...ecommBrandRedemptions.getEcommRedemptionsByIntegrationType].find((e) => e.type === 'COUPON'), // TODO: Change this in the future
    [ecommBrandRedemptions]
  );

  const { data: checkApplicableSubscriptionsData } = useQuery<
    RedeemEcommRedemptionCheckSubscriptionsData,
    RedeemEcommRedemptionCheckSubscriptionsVars
  >(REDEEM_ECOMM_REDEMPTION_CHECK_SUBSCRIPTIONS, {
    variables: {
      ecommBrandRedemptionUuid: ecommBrandRedemption?.uuid,
    },
    skip: !ecommBrandRedemption?.uuid,
  });

  const [saveUserCollectibleSettings] = useMutation<SaveUserCollectibleSettingsData, SaveUserCollectibleSettingsVars>(
    SAVE_USER_COLLECTIBLE_SETTINGS
  );

  const handleChangeVisibility = useCallback(async () => {
    await saveUserCollectibleSettings({
      variables: {
        item: {
          contractAddress,
          tokenId,
          visibility: !isVisible,
        },
      },
    });

    setIsVisible(!isVisible);
    successToast('Collectible visibility changed');

    if (onChangeVisibility) {
      onChangeVisibility();
    }
  }, [saveUserCollectibleSettings, contractAddress, tokenId, isVisible]);

  useEffect(() => {
    setIsVisible(true);
    const validateByTokenID = ['ERC1155'];
    const validateByContractId = ['ERC721Rare', 'ERC20'];
    if (collectible) {
      if (validateByTokenID.includes(collectible.type)) {
        userData?.userPage.userWallet.collectiblesSettings
          .filter((item) => item?.tokenId === collectible.tokenId)
          .map((item) => setIsVisible(item?.visibility));
      }
      if (validateByContractId.includes(collectible.type)) {
        const collectibleSettings = userData?.userPage.userWallet.collectiblesSettings.find((item) =>
          isCollectibleEqual(item, collectible)
        );

        setIsVisible(collectibleSettings?.visibility ?? true);
      }
    }
  }, [tokenId, userData, contractAddress]);

  useEffect(() => {
    setIsVisible(isCollectibleVisible);
  }, [isCollectibleVisible]);

  useEffect(() => {
    if (isOpen && collectionInfoData && collectible) {
      logMparticleEvent(isCoin ? 'view_coins' : 'view_collectible', mParticle.EventType.Other, {
        brand_uuid: collectionInfoData?.collectionInfo?.brand?.uuid,
        brand_name: collectionInfoData?.collectionInfo?.brand?.name,
        collectible_contract_address: collectible.contractAddress,
        collectible_token_id: collectible.tokenId,
        collectible_name: collectible?.metadata?.name,
        collectible_type: collectible.type.toLowerCase(),
        is_own: slug && slug == user.urlSlug,
      });
    }
  }, [isOpen, collectionInfoData]);

  const loading = userLoading || collectionInfoLoading;

  const isCollectibleOwner = useMemo(
    () => userData?.userPage?.cChainPublicAddress?.toLowerCase() === user.publicAddress?.toLowerCase(),
    [userData, user]
  );

  const canTransfer = useMemo(() => {
    const isUserEmailAllowed =
      /(tyb.xyz|inbox.testmail.app)$/.test(userData?.userPage?.email) || flags[LaunchDarklyFlags.WALLET_TRANSFER_COINS];
    const collectibleHasTokenId = !!collectible?.tokenId;
    const isCollectibleERC20 = collectible?.type === CollectionTypes.ERC20;

    return (
      isCollectibleOwner && isUserEmailAllowed && (collectibleHasTokenId || isCollectibleERC20) && !isPendingCollectible
    );
  }, [userData?.userPage?.email, isCollectibleOwner, collectible, flags]);

  const handleRedeem = useCallback(() => {
    logMparticleEvent('start_redeeming_collectible', mParticle.EventType.Other, {
      user_id: user.id,
      user_email: user.email,
      user_name: user.userName,
      wallet_address: user.cChainPublicAddress,
      collectible_name: collectible.metadata.name,
      collectible_type: collectible.type,
      redemption_type: ecommBrandRedemption.type,
      brand_name: collectionInfoData?.collectionInfo?.brand?.name,
    });

    if (checkApplicableSubscriptionsData?.redeemEcommRedemptionCheckSubscriptions?.nextCharge) {
      setCoinRedemptionInitialStep(CoinRedemptionSteps.Details);
    }

    setIsRedemptionOpen(true);
  }, [
    checkApplicableSubscriptionsData?.redeemEcommRedemptionCheckSubscriptions,
    collectible,
    ecommBrandRedemption?.type,
  ]);

  const minHeight = useMemo(() => {
    if (isCoin) {
      return isOnBrandPage ? ['90%', '35vh'] : ['90%', '50vh'];
    }

    return ['90%', '50vh'];
  }, [isOnBrandPage, isCoin]);

  const handleClose = () => {
    setCoinRedemptionInitialStep(CoinRedemptionSteps.Amount);
    setIsRedemptionOpen(false);
    onClose();
  };

  const handleRedemptionBack = () => setIsRedemptionOpen(false);

  const handleRedemptionFinish = () => {
    setIsRedemptionOpen(false);
    handleClose();
  };

  return (
    <ContentModal
      isOpen={isOpen}
      fullScreen={fullScreen}
      onRequestClose={handleClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      body={
        <Box px="20px" py="25px" width={['100%', '755px']}>
          {isRedemptionOpen &&
            (isCoin ? (
              <CoinRedemption
                initialStep={coinRedemptionInitialStep}
                user={user}
                collectible={collectible}
                collectionInfoData={collectionInfoData}
                ecommBrandRedemption={ecommBrandRedemption}
                onBack={handleRedemptionBack}
                onFinish={handleRedemptionFinish}
              />
            ) : (
              <CollectibleRedemption
                user={user}
                ecommBrandRedemption={ecommBrandRedemption}
                collectible={collectible}
                collectionInfoData={collectionInfoData}
                onBack={handleRedemptionBack}
                onFinish={handleRedemptionFinish}
              />
            ))}

          {!isRedemptionOpen && (
            <Flex flexDirection="column" height="100%" __css={{ gap: '25px' }}>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <CollectibleBrand
                    collectionInfoData={collectionInfoData}
                    router={router}
                    isOnBrandPage={isOnBrandPage}
                    isMobile={false}
                    onClose={handleClose}
                  />
                </Box>

                <Flex backgroundColor="white" onClick={handleClose} data-testid="collectible-modal-close">
                  <Icon.BoxiconsRegular.X size={28} />
                </Flex>
              </Flex>
              {loading && (
                <Flex justifyContent="center" alignItems="center">
                  <Loading padding={35} />
                </Flex>
              )}

              {!loading && collectible && (
                <Flex flexDirection="column" minHeight={minHeight} __css={{ gap: '20px' }}>
                  {isCoin ? (
                    <CoinInfo
                      collectible={collectible}
                      onChangeVisibility={handleChangeVisibility}
                      ecommBrandRedemption={ecommBrandRedemption}
                      isVisible={isVisible}
                      isOnBrandPage={isOnBrandPage}
                      userSlug={slug}
                      userPublicAddress={userData?.userPage?.cChainPublicAddress}
                      onRedeem={() => handleRedeem()}
                    />
                  ) : (
                    <CollectibleInfo
                      collectible={collectible}
                      isVisible={isVisible}
                      isOnBrandPage={isOnBrandPage}
                      ecommBrandRedemption={ecommBrandRedemption}
                      isPendingCollectible={isPendingCollectible}
                      onRedeem={() => handleRedeem()}
                    />
                  )}
                  <CollectibleDetails collectible={collectible} showTokenId={showTokenId} isCoin={isCoin} />

                  {userData && (
                    <Flex flexDirection="column" __css={{ gap: '15px' }}>
                      <Divider color="#EDECEC" />
                      <CollectibleOwner
                        data={userData}
                        urlSlug={urlSlug}
                        userSlug={slug}
                        collectible={collectible}
                        isVisible={isVisible}
                        isCoin={isCoin}
                        canTransfer={canTransfer}
                        canToggleVisibility={isCollectibleOwner}
                        onChangeVisibility={handleChangeVisibility}
                      />
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          )}
        </Box>
      }
    />
  );
};

export default CollectibleViewModal;
