import { gql } from '@apollo/client';

import { IBrandEcommRedemptionData } from '../../interface/IBrandEcommRedemptionData';

export interface GetEcommRedemptionsByIntegrationTypeVars {
  contractAddress: string;
  tokenId?: string;
}

export interface GetEcommRedemptionsByIntegrationTypeData {
  getEcommRedemptionsByIntegrationType: Array<IBrandEcommRedemptionData>;
}

export const GET_ECOMM_REDEMPTIONS_BY_INTEGRATION_TYPE = gql`
  query GetEcommRedemptionsByIntegrationType($contractAddress: String!, $tokenId: String) {
    getEcommRedemptionsByIntegrationType(contractAddress: $contractAddress, tokenId: $tokenId) {
      ecommIntegration {
        name
      }
      type
      uuid
      effect {
        value
        maxQuantity
        method
      }
    }
  }
`;
