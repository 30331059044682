import { Flex, Image } from '@tyb-u/tyb-ui-components';

interface LogoProps {
  colorMode?: 'light' | 'dark' | 'gray';
  height?: number;
  showPoweredBy?: boolean;
}

const Logo: React.FC<LogoProps> = ({ colorMode = 'light', height = 32, showPoweredBy = false }) => {
  if (colorMode === 'dark') {
    return (
      <Flex style={{ gap: '8px' }} alignItems="center" justifyContent="center" alignContent="center">
        {showPoweredBy && (
          <Image
            height={Math.max(1, height - 10)}
            src={require('./assets/Powered-by-white.svg')}
            data-testid="tyb_powered_by_logo"
          />
        )}
        <Image height={height} src={require('./assets/Logo-white.svg')} data-testid="tyb_logo" />
      </Flex>
    );
  }

  if (colorMode === 'gray') {
    return (
      <Flex style={{ gap: '8px' }} alignItems="center" justifyContent="center" alignContent="center">
        {showPoweredBy && (
          <Image
            height={Math.max(1, height - 10)}
            src={require('./assets/Powered-by-gray.svg')}
            data-testid="tyb_powered_by_logo"
          />
        )}
        <Image height={height} src={require('./assets/Logo-gray.svg')} data-testid="tyb_logo" />
      </Flex>
    );
  }

  return (
    <Flex style={{ gap: '8px' }} alignItems="center" justifyContent="center" alignContent="center">
      {showPoweredBy && (
        <Image
          height={Math.max(1, height - 10)}
          src={require('./assets/Powered-by.svg')}
          data-testid="tyb_powered_by_logo"
        />
      )}
      <Image height={height} src={require('./assets/Logo.svg')} data-testid="tyb_logo" />
    </Flex>
  );
};

export default Logo;
