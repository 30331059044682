import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  html, body {
    margin: 0;
    padding: 0;
    font-family: "Inter", "Grey LL", sans-serif;
    background-color: #f5f5f5;
  }
  * {box-sizing: border-box; margin: 0; padding: 0%}
  a {text-decoration: none; cursor: pointer; color: inherit}

  .custom-chat-class {
    height: 100%;
    max-height: 100%;

    font-family: var(--second-font);
    color: var(--black);

    /* @media screen and (max-width: 960px) {
      height: calc(100vh - 180px);
    } */

    *,
    *::after,
    *::before {
      box-sizing: inherit;
      font-family: var(--second-font);
    }

    &.messaging,
    &.commerce {
      
      background-color: var(--grey-gainsboro);

      &.dark {
        background-color: var(--dark-grey);
      }
    }

    &.team {
      &.dark {
        background: var(--dark-grey);
      }
    }

    &.livestream {
      &.dark {
        background: var(--dark-grey);
      }
    }

    .str-chat__date-separator-date {
      font-weight: 500;
      color: #E1E0E0;
      font-size: 12px;
    }
    
    .str-chat__date-separator {
      margin-bottom: 10px;
    }

    .str-chat__date-separator-line {
      background-color: #E1E0E0;
    }
  }

  .str-chat__virtual-list-message-wrapper:hover .str-chat__message-simple__actions {
    position: absolute;
  
    &__action--options {
      display: flex;
    }
  }

  .str-chat__message-actions-box {
    left: 10px;
    bottom: 10px; 
  }

  .str-chat-channel-list {
    width: 30%;
    max-width: 325px;

  }

  .str-chat__message-reactions-list-item .latest-user img {
    border: none;
  }

  .str-chat__list .str-chat__reverse-infinite-scroll {
    padding: 0px 40px;
  }

  .str-chat__list--thread {
    max-height: 100%;
    overflow-y: auto;
    padding-top: 0 !important;
  }

  .str-chat__typing-indicator {
    display: none;
  }

  .str-chat__li--bottom {
    margin: 0 0 10px;
  }

  .str-chat__virtual-list .str-chat__virtual-list-message-wrapper {
    padding: 1px 20px;
    width: 100%;
  }

  .str-chat__list .str-chat__reverse-infinite-scroll {
    padding: 0px 20px;
  }

  .str-chat__textarea textarea {
    font-size: 14px;
    border: none;
  }

  .str-chat__textarea textarea:focus {
    background-color: #F6F5F8;
    border: none;
    box-shadow: none;
  }

  .str-chat__input--emojipicker {
    right: unset;
    margin-bottom: 50px;
  }

  .str-chat__message-reactions-list .emoji-mart-emoji-native {
    margin: 10px 5px;
  }

  .str-chat__list--thread {
    max-height: calc(85vh - 178px);
    overflow-y: auto;
  }

  .str-chat__list-notifications {
    display: none;
    position: absolute;
    bottom: 130px;
    width: calc(100% - 400px);
    height: 30px;
  }

  @media screen and (max-width: 960px) {
    .str-chat-channel-list-burger {
      display: flex;
    }

    .str-chat-channel-list {
      width: 100%;
      max-width: 100%;

      &.messaging,
      &.team,
      &.commerce {
        position: relative !important;
        left: calc(-150%);
        top: 0;
        z-index: 1001;
        min-height: 100vh;
        overflow-y: auto;
        box-shadow: 7px 0 9px 0 var(--black5), 1px 0 0 0 var(--black5);
        transition: none !important;

        @media screen and (max-width: 830px) {
          min-height: calc(100vh - 80px);
        }
      }
    }

    .str-chat-channel-list--open {
      &.messaging,
      &.team,
      &.commerce {
        left: 0;
      }
    }

    .str-chat__virtual-list-message-wrapper {
      padding: 0px 8px !important;
    }

    .str-chat__list-notifications {
      margin-bottom: 10px;
    }

    .str-chat__textarea textarea {
      background-color: transparent;
      border: none;
    }

    .str-chat__textarea textarea:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .str-chat__list--thread {
      max-height: 100%;
      overflow-y: auto;
    }
  }

  .str-chat__main-panel {
    padding: 0 !important;
    height: 100%;

    textarea::placeholder {
      color: #CAC7C7;
    }
  }

  .str-chat__list .str-chat__reverse-infinite-scroll {
      padding: 0px !important;
    }

  .rta__autocomplete.str-chat__emojisearch {
    z-index: 10000;
    position: absolute;
    top: unset;
    right: unset;
    left: unset;
    bottom: 15%;
    width: 30%;
    min-width: 300px;
    min-height: 180px;
    background: #fafafa;
    margin: 4px 10px;
    border-radius: 16px !important;

    @media screen and (max-width: 960px) {
      bottom: 15%;
    }
  }

  .str-chat__reaction-selector {
    left: calc(100% - 350px) !important;
    height: auto !important;
    padding: 10px 0 !important;
    @media screen and (max-width: 960px) {
      left: auto !important;
    }
  }

  .str-chat__message-reactions-list-item .latest-user {
    display: none;
  }

  .str-chat__message-reactions-list-item__count {
    display: none;
  }

  .str-chat__message-actions-box {
    top: initial;
    left: -100px;
  }

  .str-chat__message-attachment--image {
    max-height: min-content;
    max-width: 355px;
    @media screen and (max-width: 960px) {
      max-height: fit-content;
      max-width: fit-content;
    }
  }

  .str-chat__suggestion-list-container {
    display: none !important;
  }

  .suggestion-header {
    font-weight: 700;
    font-size: 16px;
  }

  .suggestion-item {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 18px;
    padding-left: 16px;
  }

  .suggestion-item.selected {
    background: var(--white-smoke);
    color: var(--primary-color);
    font-weight: 700;
    cursor: pointer;
  }

  .carousel .slide {
    padding: 0 15%;
  }

  .carousel.carousel-slider {
    height: auto !important;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    display: flex;
    justify-content: center;
  }

  .carousel.carousel-slider .control-arrow {
      box-shadow: 0px 0px 15px rgba(190, 186, 186, 0.30);
      top: 95%;
      width: 24px;
      height: 24px;
      //background-color: red;
      border-radius: 100%;
      color: #000;
      opacity: 1;
  }

  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-top: none;
    border-right: none;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: #fff;
  }

  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: 5px;
    left: 3px;
    position: relative;
    top: 4px;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 5px;
  }

  .carousel .control-next.control-arrow:before {
    left: -2px;
	  transform: rotate(45deg);
  }

  .carousel .control-prev.control-arrow:before {
    left: 0px;
	  transform: rotate(-135deg);
  }

  .carousel .control-next.control-arrow {
    right: 15px;
  }

  .carousel .control-prev.control-arrow {
    left: 15px;
  }

  @media screen and (max-width: 832px) { 
    .carousel.carousel-slider .control-arrow {
      display: none;
    }
    .carousel .slide {
      padding: 0;
    }
    .carousel.carousel-slider {
      height: 420px !important;
    }
  }

  @keyframes collectibleLoading {
    from {
      background-color: #727578;
    }
    to {
      background-color: #A2A7AC;
    }
  }

  .react-tel-input .selected-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }

  .react-tel-input .selected-flag .arrow {
    display: none;
  }
  .str-chat__textarea textarea {
    font-family: Inter,sans-serif;
  }

  .str-chat__message-reactions-list {
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 2px 1px
    }
  }

  .dangerous_html {
    font-family: Inter, sans-serif, Arial;
    
  }
  .dangerous_html ul,
  .dangerous_html ol {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    padding: 0 0 0 10%;
    margin-bottom: 10px;
    
  }
  .dangerous_html strong {
    font-weight: bold;
  }
  .dangerous_html p{ 
    padding: 0 0 10px 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .dangerous_html strong{ 
    font-weight: 600,
  }

  @keyframes inifite-rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


  .str-chat__message-attachment-dynamic-size .str-chat__player-wrapper {
    height: auto !important;
  }

  .str-chat__message-attachment-dynamic-size {
    --video-max-height: auto;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .collectible__additional-details {
    ul {
      padding: revert;
    }

    a {
      text-decoration: revert;
      color: #0000EE;
    }

    * {
      line-height: normal;
    }
  }

  .ant-picker {
    border-radius: 0;
    padding: 9px 12px;
    width: 100%;
    color: #000000;
    font-family: Inter;
    font-size: 14px;
    border-color: #E1E0E0;
    box-shadow: none;

    &-focused {
      outline: none;
      border-width: 1px;
      border-color: black !important;
    }

    &-active-bar {
      background: #000 !important;
    }

    &-cell-selected, &-cell-range-start {
      .ant-picker-cell-inner {
        background: #000 !important;
      }
    }

    &-cell-in-range, &-cell-range-start, &-cell-range-end {
      &::before {
        background: #f1f4f6 !important;
      }

      &.ant-picker-cell-range-hover-start, &.ant-picker-cell-range-hover-end {
        &::before {
          background: #f1f4f6 !important;
        }
      }
    }

    &:hover, &-focused {
      border-color: #000 !important;
    }

    &-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: #f1f4f6 !important;
      }
    }

  }

  .ant-btn {
    box-shadow: none;
    background-color: black;
    border-radius: 0;
  }

  .ant-picker-dropdown .ant-picker-time-panel-column::after {
    content: unset;
  }

  .ReactModal {
    &__Content {
      border-radius: 15px !important;
  
      @media screen and (max-width: 640px) {
        border-radius: 0 !important;
      }
    }

    &__Body--open {
      overflow: hidden !important;
      scrollbar-width: none;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
