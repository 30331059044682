import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import { useRouter } from 'next/router';
import React from 'react';
import CollectiblePreview from 'src/components/CollectiblePreview';
import { IBrand } from 'src/interface/IBrand';
import { IUser } from 'src/interface/IUser';
import { IUserWallet } from 'src/interface/IUserWallet';
import { IWallletActivity } from 'src/interface/IWallletActivity';
import { useAppSelector } from 'src/redux/hooks';
import { ICollectible } from 'src/types';
import { isUserCollectibleVisible } from 'src/utils/isUserCollectibleVisible';

import UserWalletItems from './UserWalletItems';

const UserWalletInitialState: React.FC<{ firstBrand: IBrand }> = ({ firstBrand }) => {
  const router = useRouter();

  return (
    <Flex
      data-testid="user-wallet-empty-state"
      flexDirection="column"
      alignItems="center"
      backgroundColor="white"
      elevation="high"
      p={[2, 3]}
      pb={4}
      mt="2"
      borderRadius="lg"
    >
      <Text textAlign="center" variant="text3-600">
        Congrats on claiming your TYB wallet!
      </Text>
      <Text color="black" mt={3} mb={4} textAlign="center">
        {`While your collectible settles on the blockchain, jump into your community and get started below.`}
      </Text>
      <Box>
        <Button
          justifySelf="center"
          variant="primary"
          px="70px"
          onClick={() => {
            router.push(`/brand/${firstBrand?.urlSlug}` || '/');
          }}
        >
          {`Go to ${firstBrand?.name}`}
        </Button>
      </Box>
    </Flex>
  );
};

type UserWalletCollectiblesProps = {
  user: IUser;
  wallet: IUserWallet;
  firstBrand: IBrand;
  pendingTransactions: IWallletActivity[];
  onClickCollectible: (collectible: ICollectible) => void;
};

const UserWalletCollectibles = ({ user, wallet, firstBrand, pendingTransactions, onClickCollectible }, ref) => {
  const sessionUser = useAppSelector(({ user }) => user);

  return (
    <UserWalletItems ref={ref} user={user} wallet={wallet} filterTypes={['ERC1155', 'ERC721Rare', 'ERC721Membership']}>
      {(collectibles: ICollectible[], { findCollectible }) => {
        const filteredPendingTransactions = pendingTransactions.filter(
          (v) =>
            v.collectible &&
            v.collectible.type !== 'ERC20' &&
            (v.collectible.type === 'ERC1155'
              ? !findCollectible(v.collectible.tokenId, v.collectible.contractAddress)
              : !collectibles.some((c) => c.contractAddress === v.collectible.contractAddress))
        );

        const hasNoCollectibles = [...collectibles, ...filteredPendingTransactions].length === 0;
        const hasOneCollectible = [...collectibles, ...filteredPendingTransactions].length <= 1;

        return hasNoCollectibles ? (
          <Text color="neutrals-700">Nothing here yet.</Text>
        ) : (
          <>
            <Flex __css={{ gap: '15px' }} flexWrap="wrap" data-testid="collectible-item">
              {filteredPendingTransactions.map((transaction) => (
                <Flex
                  key={`collectible-preview-${transaction.uuid}`}
                  onClick={() => onClickCollectible(transaction.collectible)}
                  __css={{ cursor: 'pointer' }}
                  flex="0"
                >
                  <CollectiblePreview
                    collectible={transaction.collectible}
                    width={['110px', '150px']}
                    height={['110px', '150px']}
                  />
                </Flex>
              ))}

              {collectibles
                .sort((a: ICollectible, b: ICollectible) => b.blockNumber - a.blockNumber)
                .map((collectible) => {
                  return (
                    <Flex
                      key={collectible.tx}
                      onClick={() => onClickCollectible(collectible)}
                      __css={{ cursor: 'pointer' }}
                      flex="0"
                    >
                      <CollectiblePreview
                        collectible={collectible}
                        width={['110px', '150px']}
                        height={['110px', '150px']}
                        isPrivate={!isUserCollectibleVisible(wallet, collectible)}
                      />
                    </Flex>
                  );
                })}
            </Flex>
            {sessionUser?.publicAddress === user.cChainPublicAddress && hasOneCollectible && firstBrand && (
              <UserWalletInitialState firstBrand={firstBrand} />
            )}
          </>
        );
      }}
    </UserWalletItems>
  );
};
export default React.forwardRef<any, UserWalletCollectiblesProps>(UserWalletCollectibles);
