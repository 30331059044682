import { gql } from '@apollo/client';

import { INotification } from '../../types';

export interface INotificationListVars {
  take?: number;
  skip?: number;
  brandUuid?: string;
  names?: string[];
}

export interface INotificationListData {
  notifications: INotification[];
}

export const LIST_NOTIFICATIONS = gql`
  query ListUserNotifications($take: Int, $skip: Int, $brandUuid: String, $names: [String!]) {
    notifications: listUserNotifications(take: $take, skip: $skip, brandUuid: $brandUuid, names: $names) {
      uuid
      context
      name
      message
      status
      createdAt
      readAt
      items {
        info
        contractAddress
        tokenId
        info
        fundingProject {
          name
          description
          image
          status
        }
        repCard {
          id
          prompt
          coverImageUrl
          reward {
            assetId
            contractAddress
            quantity
            reps
            type
          }
        }
        brandEvent {
          type
          link
          address
          coverImageUrl
          status
          name
          reward {
            assetId
            contractAddress
            quantity
            reps
            type
          }
          entitySlug {
            slug
            type
            altId
          }
        }
        type
        user {
          id
          userName
          avatarUrl
        }
      }
      brand {
        uuid
        name
        avatarUrl
        logoUrl
        urlSlug
        brandWallet {
          address
          collections {
            contractAddress
            type
            baseCollectibleMetadata {
              name
              description
              image
              animationUrl
            }
            collectibleExtraMetadatas {
              contractAddress
              tokenId
              description
            }
          }
        }
      }
    }
  }
`;

export const LIST_UNNOTIFIED_NOTIFICATIONS_WITH_REWARD = gql`
  query ListUserNotifications($take: Int, $skip: Int, $brandUuid: String, $names: [String!]) {
    notifications: listUserNotifications(
      take: $take
      skip: $skip
      brandUuid: $brandUuid
      names: $names
      onlyPending: true
      onlyUnnotified: true
    ) {
      uuid
      context
      name
      message
      status
      createdAt
      readAt
      items {
        info
        contractAddress
        tokenId
        fundingProject {
          name
          description
          image
          status
        }
        repCard {
          brand {
            id
            name
            logoUrl
            brandWallet {
              address
              collections {
                contractAddress
                type
                baseCollectibleMetadata {
                  name
                  description
                  image
                  animationUrl
                }
                collectibleExtraMetadatas {
                  contractAddress
                  tokenId
                  description
                }
              }
            }
          }
          prompt
          coverImageUrl
          reward {
            type
            assetId
            contractAddress
            reps
            quantity
          }
        }
        type
        user {
          id
          userName
          avatarUrl
        }
      }
      brand {
        uuid
        name
        avatarUrl
        logoUrl
        urlSlug
      }
    }
  }
`;
