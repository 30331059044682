import _dayjs, { ConfigType, Dayjs, extend } from 'dayjs';
import duration, { CreateDurationType } from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

interface Extended {
  now: (...args: any) => Dayjs;
  duration: CreateDurationType;
  utc: (config?: ConfigType, format?: string, strict?: boolean) => Dayjs;
}

const extended = {
  ...(extend(duration) as Extended & Dayjs),
  ...(extend(utc) as Extended & Dayjs),
};

extended.now = _dayjs;

export const dayjs = extended;
