import React from 'react';

import Box from '../Box/Box';
import Card from '../Card/Card';
import Flex from '../Flex/Flex';
import Image from '../Image/Image';
import Input from '../Input/Input';
import Text from '../Text/Text';

export interface SurveyProps {
  type: 'text';
  overline: string;
  text: string;
  thumbSrc: string;
  thumbBadgeValue: number | string;
  comments: number;
  timeAgo: string;
  hashtags: string[];
  status: string;
}

const Survey: React.FC<SurveyProps> = ({
  type,
  overline,
  text,
  thumbSrc,
  thumbBadgeValue,
  comments,
  timeAgo,
  hashtags,
  status,
}) => {
  return (
    <Card
      overline={overline}
      text={text}
      thumbSrc={thumbSrc}
      thumbBadgeValue={thumbBadgeValue}
      body={
        <Box mt="3">
          <Flex flexDirection="row" mb="3">
            <Image src="https://picsum.photos/200?random=1" width="56px" height="56px" mr="2" />
            <Image src="https://picsum.photos/200?random=2" width="56px" height="56px" mr="2" />
            <Image src="https://picsum.photos/200?random=3" width="56px" height="56px" mr="2" />
            <Flex width="56px" height="56px" bg="black" borderRadius="sm" justifyContent="center" alignItems="center">
              <Text variant="text3-600" color="white">
                +3
              </Text>
            </Flex>
          </Flex>
          <Input name="answer" variant="default" as="textarea" placeholder="Enter your answer here..." />
        </Box>
      }
      footer={<Card.Footer comments={comments} timeAgo={timeAgo} hashtags={hashtags} status={status} />}
    />
  );
};

export default Survey;
