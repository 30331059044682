import { Box, Button, Flex, Text } from '@tyb-u/tyb-ui-components';
import React from 'react';

import CollectiblePreview from '../../../components/CollectiblePreview';
import { IBrandEcommRedemptionData } from '../../../interface/IBrandEcommRedemptionData';
import { ICollectible } from '../../../types';
import CollectibleVisibility from './CollectibleVisibility';

type CoinInfoProps = {
  collectible: ICollectible;
  onChangeVisibility: () => void;
  isVisible: boolean;
  ecommBrandRedemption?: IBrandEcommRedemptionData;
  isOnBrandPage: boolean;
  userSlug?: string;
  userPublicAddress?: string;
  children?: React.ReactElement;
  isPendingCollectible?: boolean;
  onRedeem?: () => void;
};

const CoinInfo: React.FC<CoinInfoProps> = ({
  collectible,
  ecommBrandRedemption,
  isOnBrandPage,
  isVisible,
  children,
  isPendingCollectible,
  onRedeem,
}) => {
  return (
    <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="space-around" __css={{ gap: '20px' }}>
      <Flex flexDirection="column" alignItems="center" __css={{ gap: '20px' }} width="80%">
        <Box>
          <CollectiblePreview collectible={collectible} width="170px" height="170px" borderRadius="16px" />
        </Box>
        {!isOnBrandPage && <CollectibleVisibility isVisible={isVisible} />}

        {!!ecommBrandRedemption && (
          <Flex
            justifyContent="center"
            alignItems="center"
            backgroundColor="neutrals-50"
            borderRadius="25px"
            p="6px 12px"
            __css={{ gap: '5px' }}
          >
            <CollectiblePreview
              collectible={collectible}
              width="16px"
              height="16px"
              borderRadius="16px"
              style={{ width: 'unset !important' }}
            />
            <Text variant="text7-600" color="neutrals-700">
              {`1 ${collectible.symbol} = ${ecommBrandRedemption.effect.value} USD`.toUpperCase()}
            </Text>
          </Flex>
        )}

        {isOnBrandPage ? (
          <Text textAlign="center" variant="text0">
            {collectible.metadata.name}
          </Text>
        ) : (
          <Text textAlign="center" variant="text3-400">
            You have <span style={{ fontWeight: 'bold' }}>{collectible.balance}</span> {collectible.metadata.name}{' '}
            {collectible.balance > 1 ? 'coins' : 'coin'}
          </Text>
        )}

        {!!ecommBrandRedemption && (
          <Button width="215px" onClick={() => onRedeem()} disabled={isPendingCollectible}>
            Redeem
          </Button>
        )}
      </Flex>

      {children}
    </Flex>
  );
};

export default CoinInfo;
