import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { WALLET_ACTIVITY, WalletActivityVars, WallletActivityData } from '../graphql/queries/walletActivity';
import { IWallletActivity } from '../interface/IWallletActivity';
import { useAppDispatch } from '../redux/hooks';
import { updateActivity } from '../redux/user/userSlice';

const useWalletActivity = () => {
  const [walletActivity, setWalletActivity] = useState<IWallletActivity[]>([]);
  const { data, loading, refetch } = useQuery<WallletActivityData, WalletActivityVars>(WALLET_ACTIVITY, {
    fetchPolicy: 'standby',
  });

  const dispatch = useAppDispatch();

  const fetch = useCallback((walletAddress: string) => {
    return refetch({
      walletAddress,
    });
  }, []);

  useEffect(() => {
    if (!loading && data?.walletActivity) {
      setWalletActivity(data.walletActivity.filter((v) => v.type === 'TRANSFER'));
    }
  }, [loading, data]);

  const onNewActivity = (activity: IWallletActivity) => {
    if (activity.type === 'TRANSFER') {
      dispatch(updateActivity(activity));
    }
  };

  const onUpdateActivity = (activity: IWallletActivity) => {
    if (activity.type === 'TRANSFER') {
      dispatch(updateActivity(activity));
    }
  };

  return {
    activity: walletActivity,
    loading,
    onUpdateActivity,
    onNewActivity,
    fetch,
  };
};

export default useWalletActivity;
