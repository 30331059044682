import { gql } from '@apollo/client';

export interface ValidateRegisterUserVars {
  data: {
    userName: string;
    email?: string;
    emailOrPhoneNumber?: string;
    avatarUrl?: string;
    brandSlug?: string;
  };
}

export interface ValidateRegisterUserData {
  validateRegisterUser: {
    valid: boolean;
    emailHasExistingUser: boolean;
  };
}

export const VALIDATE_REGISTER_USER = gql`
  mutation ValidateRegisterUser($data: ValidateRegisterUserInput!) {
    validateRegisterUser(data: $data) {
      valid
      emailHasExistingUser
    }
  }
`;
