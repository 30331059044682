import { Box } from '@tyb-u/tyb-ui-components';
import React from 'react';
import { useTheme } from 'styled-components';

import ContentModal from '../../components/Modals/Content';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { INotification } from '../../types';
import { convertRemToPx } from '../../utils';
import RepcardParticipationApprovedContent from './components/RepcardParticipationApprovedContent';

interface UserNotificationModalProps {
  notification: INotification;
  onRequestClose: () => void;
}

export const SUPPORTED_MODAL_NOTIFICATIONS = ['REPCARD_PARTICIPATION_APPROVED'];

const UserNotificationModal: React.FC<UserNotificationModalProps> = (props: UserNotificationModalProps) => {
  const size = useWindowSize();
  const theme = useTheme();
  const fullScreen = size.width <= convertRemToPx(theme.breakpoints[0]);

  const getContent = () => {
    switch (props.notification.name) {
      case 'REPCARD_PARTICIPATION_APPROVED':
        return <RepcardParticipationApprovedContent onClose={props.onRequestClose} notification={props.notification} />;
      default:
        return 'Invalid notification';
    }
  };

  return (
    <>
      <ContentModal
        isOpen={true}
        fullScreen={fullScreen}
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          props.onRequestClose();
        }}
        body={
          <Box __css={fullScreen ? { width: '100%' } : { width: '460px' }} p="24px" bg="white">
            {getContent()}
          </Box>
        }
      />
    </>
  );
};

export default UserNotificationModal;
