import { Box, Flex } from '@tyb-u/tyb-ui-components';
import React from 'react';

import { getEnvironment } from '../../utils/index';
import Logo from '../Logo';

export interface FooterProps {
  centerLogo?: boolean;
  colorMode?: 'light' | 'dark' | 'gray';
  showLogo?: boolean;
  disableLogoRedirect: boolean;
}

const Footer: React.FC<FooterProps> = ({
  centerLogo = false,
  colorMode = 'gray',
  showLogo = false,
  disableLogoRedirect = false,
}) => {
  // TODO: remove conditional if there is more stuff other than logo to show
  if (!showLogo) return null;

  return (
    <>
      <Flex
        width="100%"
        data-testid="footer"
        justifyContent="center"
        bg={colorMode === 'dark' ? 'black' : 'white'}
        color={colorMode === 'dark' ? 'white' : 'black'}
        minHeight={80}
        borderBottomColor={'gray02'}
      >
        <Flex flexDirection="column" width={'100%'}>
          <Flex flex={1} pr={[3]} pl={[3]} py={[3]}>
            <Flex flex={1} alignItems="center" alignContent="center" position="relative">
              {showLogo && (
                <Flex
                  justifyContent={centerLogo ? 'center' : ['center', 'center', 'flex-start']}
                  data-testid="logo-wrapper"
                  flex={1}
                >
                  <Box
                    onClick={() => {
                      if (getEnvironment(process.env.NEXT_PUBLIC_API_URL || '') === 'prod') {
                        window.Intercom('shutdown');
                      }
                      if (!disableLogoRedirect) {
                        window.location.href = `http://${process.env.NEXT_PUBLIC_DOMAIN}`;
                      }
                    }}
                    __css={{ cursor: disableLogoRedirect ? 'default' : 'pointer' }}
                  >
                    <Logo colorMode={colorMode} showPoweredBy height={25} />
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Footer;
