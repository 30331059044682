import { useMutation } from '@apollo/client';
import { Alert, Box, Button, Flex, Image, Text } from '@tyb-u/tyb-ui-components';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import InputV2 from '../../components/InputV2/InputV2';
import ContentModal from '../../components/Modals/Content';
import {
  REQUEST_UPDATE_USER_EMAIL,
  RequestUpdateUserEmailData,
  RequestUpdateUserEmailVars,
} from '../../graphql/mutations/requestUpdateUserEmail';
import { useAppSelector } from '../../redux/hooks';
import { EMAIL_PATTERN } from '../../utils/validateEmail';

const EmailVerification: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen }) => {
  const user = useAppSelector(({ user }) => user);

  const [requestUpdateUserEmail, { loading }] = useMutation<RequestUpdateUserEmailData, RequestUpdateUserEmailVars>(
    REQUEST_UPDATE_USER_EMAIL
  );

  const [emailVerificationStep, setEmailVerificationStep] = useState<1 | 2>(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({ defaultValues: { email: user.email } });

  const onEmailVerify = useCallback(
    async (values) => {
      const domain = process.env.NEXT_PUBLIC_DOMAIN.replace('app.', 'web.');

      await requestUpdateUserEmail({
        variables: {
          email: values.email,
          baseUrl: `http://${domain}/onboarding/email-verify`,
        },
      });

      setEmailVerificationStep(2);
    },
    [requestUpdateUserEmail]
  );

  const SendEmailStep = useCallback(
    () => (
      <Flex my="5px" flexDirection="column" alignItems="center">
        <Text variant="text3-600" textAlign="center">
          One more step!
        </Text>
        <Text variant="text4-400" textAlign="center">
          We need to verify your email for notifications. Click the button below to receive a one-time verification
          email.
        </Text>
        <Box my="20px">
          <InputV2
            placeholder="Enter your email"
            {...register('email', {
              required: true,
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Enter a valid e-mail address',
              },
            })}
          />
          {errors.email && <Alert type="error">{errors.email.message || 'This field is required'}</Alert>}
        </Box>
        <Box my="5px">
          <Button disabled={loading} width="150px">
            Verify
          </Button>
        </Box>
      </Flex>
    ),
    [errors.email, register, loading]
  );

  const VerifyEmailStep = useCallback(
    () => (
      <Flex my="5px" flexDirection="column" justifyItems="center">
        <Text variant="text4-400" textAlign="center">
          Thanks! We just sent you an email.
        </Text>
        <Text variant="text4-400" textAlign="center" color="gray04">
          Don&apos;t see the email in your inbox? Be sure to check your spam folder, or click the button below to edit
          your email address
        </Text>
        <Flex my="5px" justifyContent="center">
          <Button my="5px" onClick={() => setEmailVerificationStep(1)}>
            Go back
          </Button>
        </Flex>
      </Flex>
    ),
    [setEmailVerificationStep]
  );

  return (
    <ContentModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      body={
        <Flex padding="20px" flexDirection="column" width={'300px'}>
          <Flex justifyContent="center">
            <form onSubmit={handleSubmit(onEmailVerify)} style={{ width: '80%' }}>
              <Flex maxWidth="350px" flexDirection="column" justifyContent="center" alignItems="center">
                <Box my="14px">
                  <Image src={require('../../assets/try-your-best-coin.gif')} alt="test" width={150} />
                </Box>
                {emailVerificationStep === 1 && <SendEmailStep />}
                {emailVerificationStep === 2 && <VerifyEmailStep />}
              </Flex>
            </form>
          </Flex>
        </Flex>
      }
    />
  );
};

export default EmailVerification;
