import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

import { Box } from '@tyb-u/tyb-ui-components';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ModalProvider from 'src/containers/ModalProvider/ModalProvider';
import WalletSignatureProvider from 'src/containers/WalletSignatureProvider';
import { ThemeProvider } from 'styled-components';

import AxiosProvider from '../components/AxiosProvider';
import ClientOnly from '../components/ClientsOnly/ClientsOnly';
import { ContractEventProvider } from '../components/ContractEventProvider/ContractEventProvider';
import ErrorBoundary from '../components/ErrorBoundary';
import FullStoryProvider from '../components/FullStoryProvider';
import { AppHead } from '../components/Head/Head';
import MagicAuthProvider from '../components/MagicAuthProvider';
import NotificationProvider from '../components/NotificationProvider';
import { RouterProvider } from '../components/RouterProvider/RouterProvider';
import ApolloProvider from '../containers/ApolloProvider';
import LaunchDarklyProvider from '../containers/LaunchDarklyProvider';
import MaintenanceProvider from '../containers/MaintenanceProvider/MaintenanceProvider';
import MparticleProvider from '../containers/MparticleProvider';
import withDarkly from '../hoc/withDarkly';
import { store } from '../redux/store';
import GlobalStyle from '../styles/global';
import { theme } from '../styles/theme';

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  let key = pageProps?.key ? pageProps.key : router.route.toString();

  if (!pageProps?.key) {
    Object.keys(router.query).forEach((v) => {
      key = key.replace(`[${v}]`, router.query[v] as string);
    });
  }

  return (
    <Box id="app-wrapper">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastContainer />
        <ClientOnly>
          <Provider store={store}>
            <ApolloProvider>
              <MagicAuthProvider>
                <MparticleProvider>
                  <LaunchDarklyProvider>
                    <MaintenanceProvider>
                      <AxiosProvider>
                        <FullStoryProvider>
                          <RouterProvider>
                            <ErrorBoundary>
                              <ContractEventProvider>
                                <NotificationProvider>
                                  <WalletSignatureProvider>
                                    <ModalProvider>
                                      <AppHead />
                                      <Component {...pageProps} key={key} />
                                    </ModalProvider>
                                  </WalletSignatureProvider>
                                </NotificationProvider>
                              </ContractEventProvider>
                            </ErrorBoundary>
                          </RouterProvider>
                        </FullStoryProvider>
                      </AxiosProvider>
                    </MaintenanceProvider>
                  </LaunchDarklyProvider>
                </MparticleProvider>
              </MagicAuthProvider>
            </ApolloProvider>
          </Provider>
        </ClientOnly>
      </ThemeProvider>
    </Box>
  );
};

export default withDarkly(App);
