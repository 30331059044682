import { Flex, Icon, Text } from '@tyb-u/tyb-ui-components';

type CollectibleVisibilityProps = {
  isVisible: boolean;
};

const CollectibleVisibility: React.FC<CollectibleVisibilityProps> = ({ isVisible }) => (
  <Text variant="text5-500" color="neutrals-500">
    <Flex alignItems="center" __css={{ gap: '5px' }}>
      {isVisible ? (
        <>
          <Icon.BoxiconsRegular.Show size={16} />
          Public
        </>
      ) : (
        <>
          <Icon.BoxiconsRegular.Hide size={16} />
          Private
        </>
      )}
    </Flex>
  </Text>
);

export default CollectibleVisibility;
